import { useEffect, useRef, useState } from "react";
import SockJS from 'sockjs-client';
import { over } from 'stompjs';

const SERVER_PREFIX = '/';

export const useWebSocket = (options) => {

    const SERVER_URL = `${JSON.parse(sessionStorage.getItem("systemConfig"))?.serverUrl}/`;
    const { endpoint, listeners, onConnect, onDisconnect } = options;
    let sock = null;
    const stompClientRef = useRef(null);
    const reconnectIntervalRef = useRef(null);
    const onlineRef = useRef(true);
    const firstTimeRef = useRef(true);

    const [connected, setConnected] = useState(false);

    const _connect = (fromReconnect) => {
        sock = new SockJS(SERVER_URL + endpoint, null, { timeout: 60000000 });
        stompClientRef.current = over(sock);
        stompClientRef.current.debug = null
        stompClientRef.current.reconnect_delay = 5000;
        stompClientRef.current.heartbeat.outgoing = 0;
        stompClientRef.current.heartbeat.incoming = 0;
        stompClientRef.current.connect({}, () => _onConnected(fromReconnect), (error) => { firstTimeRef.current = false; setConnected(false) })
    }

    const _onConnected = (fromReconnect) => {
        listeners.map(listener => {
            return (
                stompClientRef.current.subscribe(listener.url, (message) => {
                    if (listener.action) {
                        listener.action(message.body);
                    }
                })
            )
        })
        firstTimeRef.current = false;
        setConnected(true);
        if (onConnect && !fromReconnect) {
            onConnect();
        }
    }

    const closeConnection = () => {
        try {
            let needCallDisconnect = stompClientRef.current.connected && onDisconnect;
            setConnected(false);
            if (needCallDisconnect) {
                onDisconnect();
            }
            stompClientRef.current.disconnect(() => { })
            sock.close();
            window.removeEventListener('beforeunload', () => { });
        } catch (e) {
            //ignore
        }
    }

    const _reConnect = () => {
        firstTimeRef.current = true;
        stompClientRef.current.disconnect(() => { })
        sock.close();
        _connect(true);
    }

    useEffect(() => {
        _connect(false);

        window.addEventListener('beforeunload', (event) => {
            closeConnection();
        });

        window.addEventListener('offline', (event) => {
            onlineRef.current = false;
            stompClientRef.current.disconnect(() => { })
            sock.close();
        });

        window.addEventListener('online', (event) => {
            _reConnect();
            onlineRef.current = true
        });

        document.addEventListener('visibilitychange', function (event) {
            if (!document.hidden) {
                _reConnect();
            }
        });

        if (reconnectIntervalRef.current) {
            clearInterval(reconnectIntervalRef.current)
        }

        reconnectIntervalRef.current = setInterval(() => {
            if (!document.hidden && !stompClientRef.current.connected && onlineRef.current && !firstTimeRef.current) {
                _reConnect();
            }
        }, [100])

        return () => {
            window.removeEventListener('offline', () => { });
            window.removeEventListener('online', () => { });
            document.removeEventListener('visibilitychange', () => { })
            closeConnection();
            clearInterval(reconnectIntervalRef.current)
        }
    }, [])

    const sendMessage = (url, data) => {
        try {
            stompClientRef.current.send(SERVER_PREFIX + url, {}, JSON.stringify(data))
        } catch (error) {
            console.error("", error)
        }
    }

    return { connected, sendMessage, closeConnection }

}