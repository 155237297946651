import Checkbox from "@ipgd-gauge/checkbox";
import { FrmDecimalInput } from "@ipgd-gauge/decimal-input";
import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { DeleteBtn } from "@ipgd-gauge/table";
import { confirmationDialog, isEmpty, isNotEmpty } from "@ipgd-gauge/utils";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Table from "../../../components/table";
import { getActionList, getDomainValues, getEvaluationEstimationStatuses, getTaskDescriptionLOV } from "../../../util/apis";
import { ATTACHMENTS_DOMAIN, SCHEDULING_UNIT_DOMAIN, STATUS_ACTIVE } from "../../../util/constants";
import { RSM3061_WORKFLOW_PROCEDURE_AND_MESSAGES_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';
import { useState } from "react";

const dialogId = "workflow-procedure-messages-dialog";

const MessagesTasksRow = (props) => {
    const { data, index, rowActions, virtualizedStyle, rowClassName, onRowClick } = props;
    const { actionMessageTaskDetails, handleDeleteLine, handleDataChanged, setSelectedRow } = rowActions;

    return (
        <div dir={i18next.language === 'en' ? "ltr" : "rtl"} className={`v_table_row ${rowClassName}  ${style.messagesTasksRow}`} style={virtualizedStyle} onClick={() => {
            onRowClick()
            setSelectedRow(index)
        }
        }>
            <div><div> <label>{data?.sequence}</label> </div></div>
            <div>
                <div className="inputRow">
                    <FrmInput
                        id={`${dialogId}-row-description-${index}`}
                        value={data?.description}
                        onBlur={(value) => handleDataChanged(index, "description", value)}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${dialogId}-row-message-template-${index}`}
                        key={`${dialogId}-row-message-template-${index}-${data?.id}`}
                        value={data.mtoId}
                        api={() => getTaskDescriptionLOV(isEmpty(data.mtoId) ? [] : [data.mtoId], STATUS_ACTIVE)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={value => handleDataChanged(index, "mtoId", value.value)}
                        portalTarget={document.body}
                    />
                </div>
            </div>
            <div>
                <div>
                    <Checkbox
                        id={`${dialogId}-row-change-status-${index}`}
                        isChecked={data?.changeStatus}
                        setIsChecked={value => handleDataChanged(index, "changeStatus", value)}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${dialogId}-row-output-status-${index}`}
                        value={data.outputStatusOaeId}
                        api={() => getEvaluationEstimationStatuses(isEmpty(data.outputStatusOaeId) ? [] : [data.outputStatusOaeId], STATUS_ACTIVE)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={value => handleDataChanged(index, "outputStatusOaeId", value.value)}
                        isDisabled={!data?.changeStatus}
                        portalTarget={document.body}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${dialogId}-row-dependency-${index}`}
                        options={(actionMessageTaskDetails || []).filter(actionMessageTaskDetail => actionMessageTaskDetail.key != data?.key && isNotEmpty(actionMessageTaskDetail.description))}
                        value={data.dependencyId}
                        isClearable={true}
                        idName="key"
                        descLo={['description']}
                        descFo={['description']}
                        onChange={value => handleDataChanged(index, "dependencyId", value.key)}
                        portalTarget={document.body}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmDecimalInput
                        id={`${dialogId}-row-duration-${index}`}
                        value={data?.duration}
                        onBlur={(value) => handleDataChanged(index, "duration", value)}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${dialogId}-row-interval-period-${index}`}
                        value={data.intervalPeriod}
                        api={() => getDomainValues(SCHEDULING_UNIT_DOMAIN)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={value => handleDataChanged(index, "intervalPeriod", value.value)}
                        portalTarget={document.body}
                    />
                </div>
            </div>
            <div>
                <div>
                    <Checkbox
                        id={`${dialogId}-row-related-to-action-${index}`}
                        isChecked={data?.relatedToAction}
                        setIsChecked={value => handleDataChanged(index, "relatedToAction", value)}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${dialogId}-row-action-${index}`}
                        value={data.actionDdvId}
                        api={() => getActionList("0")}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={value => handleDataChanged(index, "actionDdvId", value.value)}
                        isDisabled={!data?.relatedToAction}
                        portalTarget={document.body}
                    />
                </div>
            </div>
            <div>
                <div>
                    <Checkbox
                        id={`${dialogId}-row-send-notification-${index}`}
                        isChecked={data?.sendNotification}
                        setIsChecked={value => handleDataChanged(index, "sendNotification", value)}
                    />
                </div>
            </div>
            <div>
                <div>
                    <Checkbox
                        id={`${dialogId}-row-send-sms-${index}`}
                        isChecked={data?.sendSms}
                        disabled={!data.sendNotification}
                        setIsChecked={value => handleDataChanged(index, "sendSms", value)}
                    />
                </div>
            </div>
            <div>
                <div>
                    <Checkbox
                        id={`${dialogId}-row-send-email-${index}`}
                        isChecked={data?.sendEmail}
                        disabled={!data.sendNotification}
                        setIsChecked={value => handleDataChanged(index, "sendEmail", value)}
                    />
                </div>
            </div>
            <div>
                <div>
                    <Checkbox
                        id={`${dialogId}-row-pdf-with-email-${index}`}
                        isChecked={data?.pdfWithEmail}
                        disabled={!data.sendEmail}
                        setIsChecked={value => handleDataChanged(index, "pdfWithEmail", value)}
                    />
                </div>
            </div>

            <div>
                <div>
                    <Checkbox
                        id={`${dialogId}-row-est-fees-comm-${index}`}
                        isChecked={data?.estFeesComm}
                        setIsChecked={value => handleDataChanged(index, "estFeesComm", value)}
                    />
                </div>
            </div>
            <div>
                <div>
                    <Checkbox
                        id={`${dialogId}-row-attachments-with-email-${index}`}
                        isChecked={data?.attachmentsWithEmail}
                        disabled={!data.sendEmail}
                        setIsChecked={value => handleDataChanged(index, "attachmentsWithEmail", value)}
                    />
                </div>
            </div>
            <div>
                <div className="inputRow">
                    <FrmSelectMenu
                        id={`${dialogId}-row-action-${index}`}
                        isMulti={true}
                        value={data.attachmentLevel}
                        api={() => getDomainValues(ATTACHMENTS_DOMAIN)}
                        descLo={['descLo']}
                        descFo={['descFo']}
                        onChange={value => handleDataChanged(index, "attachmentLevel", value)}
                        portalTarget={document.body}
                        isDisabled={!data?.attachmentsWithEmail}
                    />
                </div>
            </div>
            <div>
                <div className='containerButtonTable deleteBtnSm'>
                    <DeleteBtn onClick={() => handleDeleteLine(index)} />
                </div>
            </div>
        </div>
    );
};


const MessagesTasks = (props) => {
    const { data, setData, user, selectedRow } = props;
    const { t } = useTranslation();

    const columns = [
        { title: '#', width: "5%" },
        { title: <span data-property="description" className="mandatory" >{t('workflowProcedureMessages:description')}</span>, width: "7%" },
        { title: <span data-property="mtoId" className="mandatory" >{t('workflowProcedureMessages:message_template')}</span>, width: "7%" },
        { title: t('workflowProcedureMessages:status_changed'), width: "5%" },
        { title: <span data-property="outputStatusOaeId" className="mandatory" >{t('workflowProcedureMessages:output_status')}</span>, width: "5%" },
        { title: t('workflowProcedureMessages:dependency'), width: "5%" },
        { title: <span data-property="duration" className="mandatory" >{t('workflowProcedureMessages:duration')}</span>, width: "5%" },
        { title: t('workflowProcedureMessages:interval_period'), width: "5%" },
        { title: t('workflowProcedureMessages:is_related_to_action'), width: "5%" },
        { title: <span data-property="actionDdvId" className="mandatory" >{t('workflowProcedureMessages:action')}</span>, width: "6%" },
        { title: t('workflowProcedureMessages:send_notification'), width: "6%" },
        { title: t('workflowProcedureMessages:sms'), width: "5%" },
        { title: t('workflowProcedureMessages:email'), width: "6%" },
        { title: t('workflowProcedureMessages:with_pdf'), width: "6%" },
        { title: t('workflowProcedureMessages:include_in_estimation_fees_and_commission_calculations'), width: "8%" },
        { title: t('workflowProcedureMessages:is_attachment'), width: "9%" },
        { title: t('workflowProcedureMessages:attachments'), width: "9%" },
        { title: "", width: "6%" },
    ];

    const _handleDeleteLine = (index) => {
        if (!data.actionMessageTaskDetails) {
            data.actionMessageTaskDetails = [];
        }

        data.actionMessageTaskDetails.splice(index, 1);
        setData({ ...data });
    }

    const _handleDataChanged = (index, name, value) => {

        if (!data.actionMessageTaskDetails) {
            data.actionMessageTaskDetails = [];
            return
        }

        const line = data.actionMessageTaskDetails[index];

        if (name == "relatedToAction" && !value) {
            line.actionDdvId = null
        }

        if (name == "attachmentsWithEmail" && !value) {
            line.attachmentLevel = null
        }

        if (name == "changeStatus") {
            line.outputStatusOaeId = null
        } else if (name == "sendNotification") {
            line.sendSms = false
            line.sendEmail = false
        } else if (name == "sendEmail" || name == "sendNotification") {
            line.attachmentsWithEmail = false
            line.pdfWithEmail = false
        }

        line[name] = value;
        setData({ ...data });
    }

    return (
        <Table
            id={`${dialogId}-messages-tasks`}
            formId={RSM3061_WORKFLOW_PROCEDURE_AND_MESSAGES_FORM_ID}
            columns={columns}
            list={data.actionMessageTaskDetails || []}
            rowActions={{
                handleDataChanged: _handleDataChanged,
                handleDeleteLine: _handleDeleteLine,
                setSelectedRow: (index) => selectedRow.current = index,
                actionMessageTaskDetails: (data.actionMessageTaskDetails || [])
            }}
            Row={MessagesTasksRow}
            tableStyle={style.messagesTasksTable}
            rowHeight={40}
            user={user}
            tableWidth={240}
        // showNoDataFound={(data) => (data && data.length == 0)}
        />
    );
}

const Tab2 = (props) => {
    const { tab, data, setData, user, selectedRow } = props;

    const { t } = useTranslation();

    const _deleteAllAction = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            data.actionMessageTaskDetails = [];
            setData({ ...data });
        });
    }


    const _insertAction = () => {
        if (!data.actionMessageTaskDetails) {
            data.actionMessageTaskDetails = [];
        }

        let newLine = { key: Date.now(), attachmentLevel: [], intervalPeriod: 192002, duration: 10 };
        if (isEmpty(selectedRow.current)) {
            data.actionMessageTaskDetails.push(newLine);
        }
        else {
            data.actionMessageTaskDetails.splice(selectedRow.current + 1, 0, newLine);
        }

        // data.actionMessageTaskDetails = [...data.actionMessageTaskDetails, ...[{ key: Date.now(), attachmentLevel: [], intervalPeriod: 192002, duration: 10 }]];
        setData({ ...data, actionMessageTaskDetails: [...data.actionMessageTaskDetails] });
    }

    return (
        <div className={tab == 2 ? 'active' : ''}>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w50"></div>
                    <div className={`w50 insertBtn`}>
                        <button
                            title={t('add')}
                            className="primary-button"
                            onClick={_insertAction}>
                            {t('add')}
                        </button>

                        <button
                            className="delete-button"
                            title={t('workflowProcedureMessages:delete_all')}
                            onClick={_deleteAllAction}
                            disabled={(data?.actionMessageTaskDetails || []).length === 0}>
                            {t('workflowProcedureMessages:delete_all')}
                        </button>
                    </div>
                </div>
            </div>
            <MessagesTasks data={data} setData={setData} user={user} selectedRow={selectedRow} />
        </div>
    )
}

export default Tab2;