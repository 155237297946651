import { DeleteBtn, EditBtn } from "@ipgd-gauge/table";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slisting from "../../../components/slisting";
import { deleteDivision, getDivisionList } from "../../../util/apis";
import { DELETE_ACTION, VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { RSM2667_DIVISIONS_DEFINITIONS_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import style from './style.module.scss';
import { confirmationDialog, handleError, isAllowedAction, isEnglish, isNotEmpty, showSuccessMessage } from "@ipgd-gauge/utils";

const Row = (props) => {
    const { data, rowActions } = props;
    const { dialogRef, deleteAction, user } = rowActions;
    const { t } = useTranslation();

    return (
        <div className={`${style.divisionDefinitionRow}`}>
            <div title={data.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data.sequence}</label>
            </div>

            <div title={data.code} className="cardRowMode">
                <label className="nameLblMobile">{t('divisionDefinition:code')}</label>
                <label>{data.code}</label>
            </div>

            <div title={isEnglish() ? data.descLo : data.descFo} className="cardRowMode">
                <label className="nameLblMobile">{t('divisionDefinition:name')}</label>
                <label>{isEnglish() ? data.descLo : data.descFo}</label>
            </div>

            <div className="table_body_label cardRowMode">
                <label className="nameLblMobile">{t('divisionDefinition:remarks')}</label>
                <label className="cardRowDesc" title={data?.rem}>
                    {data?.rem}
                </label>
            </div>

            <div className="cardRowStatusMode">
                <label className="nameLblMobile">{t('divisionDefinition:status')}</label>
                <label title={isEnglish() ? data?.statusDescLo : data?.statusDescFo} className={`status-${data.isActive}`}>
                    {isEnglish() ? data?.statusDescLo : data?.statusDescFo}
                </label>
            </div>

            <div>
                <div className='containerButtonTable'>
                    {isAllowedAction(user, RSM2667_DIVISIONS_DEFINITIONS_FORM_ID, VIEW_EDIT_ACTION) &&
                        <EditBtn onClick={() => dialogRef?.current?.open(data?.id)} />
                    }
                    {isAllowedAction(user, RSM2667_DIVISIONS_DEFINITIONS_FORM_ID, DELETE_ACTION) &&
                        <DeleteBtn onClick={() => deleteAction(data?.id)} />
                    }
                </div>
            </div>
        </div>
    );
};


const DivisionDefinitionTable = (props) => {
    const { filterRef, dialogRef, tableRef, searchTextRef, user } = props;

    const { t } = useTranslation();

    const columns = [
        { title: '#', width: "5%" },
        { title: t('divisionDefinition:code'), width: "14%" },
        { title: t('divisionDefinition:name'), width: "22%" },
        { title: t('divisionDefinition:remarks'), width: "20%" },
        { title: t('divisionDefinition:status'), width: "19%" },
        { title: "", width: "20%" },
    ];

    const [list, setList] = useState({});

    const _deleteAction = (id) => {
        if (isNotEmpty(id)) {
            confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
                return deleteDivision(id)
                    .then(() => {
                        showSuccessMessage("delete_successfully", t);
                        tableRef?.current?.refresh();
                    })
                    .catch((error) => handleError(error, 'delete_fields', t));
            });
        }
    }

    return (
        <div className={style.divisionDefinitionTableContainer}>
            <Slisting
                id="division-definition"
                ref={tableRef}
                columns={columns}
                Row={Row}
                tableStyle={style.divisionDefinitionTable}
                pagingData={list}
                getData={(page, size) => {
                    getDivisionList(page, size, searchTextRef.current.getSearchText(), filterRef.current?.getFilterData())
                        .then((response) => setList(response.data))
                        .catch((error) => console.log("", error))
                }}
                rowActions={{ dialogRef: dialogRef, deleteAction: _deleteAction, user: user }}
                withFilter
                rowHeight={80}
                showWating={(data) => {
                    return data == null
                }}
                showNoDataFound={(data) => {
                    return (data && data.length == 0)
                }}
                newAction={() => dialogRef.current?.open()}
                deleteAction={deleteDivision}
                viewAction={(id) => dialogRef.current?.open(id)}
                cardInMobile
                labelCardWidth="115px"
                formId={RSM2667_DIVISIONS_DEFINITIONS_FORM_ID}
                user={user}
            />
        </div>
    );
};

export default DivisionDefinitionTable;
