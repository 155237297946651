import Image from '@ipgd-gauge/image';
import { formatDateNTime, handleError, isEnglish } from "@ipgd-gauge/utils";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Slisting from "../../../components/slisting";
import PhoneIcon from "../../../icons/Phone";
import { exportUserDefinition, getUserList } from "../../../util/apis";
import { USER_DEFINITION_PROFILE_IMAGE } from "../../../util/constants";
import { FND0042_USERS_DEFINITION_FORM_ID } from "../../../util/constants/forms";
import { getUser } from '../../../util/cookies';
import UsersDefinitionDialog from "../dialogs";
import Actions from './Actions';
import LogDialog from './log-dialog';
import style from './style.module.scss';

const Row = (props) => {
    const { data, rowActions } = props;
    const { user, tableRef, dialogRef, serverUrl, logDialogRef } = rowActions;
    const { t } = useTranslation();

    return (
        <div className={`${style.usersDefinitionTableRow}`}>
            <div title={data?.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data?.sequence}</label>
            </div>

            <div className="cardRowMode">
                <label className="nameLblMobile">{t('usersDefinition:employee_image')}</label>
                <label className={`tableImgContainer without-label`}>
                    <span>
                        <Image
                            className="tableImg"
                            src={`${serverUrl}/apis/attachments/download-attachment/${data.id}/${USER_DEFINITION_PROFILE_IMAGE}`}
                            altSrc="/images/avatar.png"
                        />
                    </span>
                </label>
            </div>

            <div title={data?.code} className="cardRowMode">
                <label className="nameLblMobile">{t('usersDefinition:code')}</label>
                <label>{data?.code}</label>
            </div>

            <div title={isEnglish() ? data?.nameLo : data?.nameFo} className="cardRowMode">
                <label className="nameLblMobile">{t('usersDefinition:name')}</label>
                <label>{isEnglish() ? data?.nameLo : data?.nameFo}</label>
            </div>

            <div title={data?.nickname} className="cardRowMode">
                <label className="nameLblMobile">{t('usersDefinition:user_name')}</label>
                <label>{data?.nickname}</label>
            </div>

            <div title={isEnglish() ? data?.targetDescLo : data?.targetDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('usersDefinition:party')}</label>
                {isEnglish() ? data?.targetDescLo : data?.targetDescFo &&
                    <label>{isEnglish() ? data?.targetDescLo : data?.targetDescFo}</label>
                    ||
                    <label title={t('not_found')}>{t('not_found')}</label>
                }
            </div>

            <div className="columnSm">
                <div className="columnRowNew">
                    <label className="table_body_label_start" title={data.email}>{data.email}</label>
                    <div title={data.mobile} className="mobileTable">
                        <PhoneIcon /><a href={`tel:${data.mobile}`}>{data.mobile}</a>
                    </div>
                </div>

                <div className="cardRowTrxMode">
                    <label className="nameLblMobile">{t('usersDefinition:email')}</label>
                    <div title={data.email}>
                        {data.email}
                    </div>
                </div>

                <div className="cardRowTrxMode">
                    <label className="nameLblMobile">{t('usersDefinition:mobile_num')}</label>
                    <div title={data.mobile} className="mobileTable">
                        <PhoneIcon /><a href={`tel:${data.mobile}`}>{data.mobile}</a>
                    </div>
                </div>
            </div>

            <div title={data.historyTime ? formatDateNTime(data.historyTime) : t('usersDefinition:not_found')} className={`cardRowMode ${data.historyTime ? "clickable" : ""}`}>
                <label className="nameLblMobile">{t('usersDefinition:last_login_date')}</label>
                <label onClick={() => data.historyTime ? logDialogRef.current.open(data.id) : {}}>
                    {data.historyTime ? formatDateNTime(data.historyTime) : t('usersDefinition:not_found')}
                </label>
            </div>

            <div title={isEnglish() ? data?.currentStatusDescLo : data?.currentStatusDescFo} className="cardRowStatusMode">
                <label className="nameLblMobile">{t('usersDefinition:current_status')}</label>
                <label className={`status-${data.currStatus}`} >{isEnglish() ? data?.currentStatusDescLo : data?.currentStatusDescFo}</label>
            </div>

            <div title={isEnglish() ? data?.statusDescLo : data?.statusDescFo} className="cardRowStatusMode">
                <label className="nameLblMobile">{t('usersDefinition:status')}</label>
                <label className={`status-${data.isActive}`}>{isEnglish() ? data?.statusDescLo : data?.statusDescFo}</label>
            </div>

            <div className='containerButtonTable'>
                <Actions
                    user={user}
                    data={data}
                    tableRef={tableRef}
                    dialogRef={dialogRef}
                />
            </div>
        </div>
    );
};

const UsersDefinitionTable = (props) => {

    const { tableRef, searchTextRef, filtersRef } = props;
    const serverUrl = JSON.parse(sessionStorage.getItem("systemConfig"))?.serverUrl;
    const user = getUser();

    const { t } = useTranslation();
    const dialogRef = useRef();
    const logDialogRef = useRef();

    const columns = [
        { title: '#', width: "3%" },
        { title: t('usersDefinition:employee_image'), width: "10%" },
        { title: t('usersDefinition:code'), width: "8%" },
        { title: t('usersDefinition:name'), width: "10%" },
        { title: t('usersDefinition:user_name'), width: "10%" },
        { title: t('usersDefinition:party'), width: "9%" },
        { title: t('usersDefinition:contact_informations'), width: "12%" },
        { title: t('usersDefinition:last_login_date'), width: "12%" },
        { title: t('usersDefinition:current_status'), width: "9%" },
        { title: t('usersDefinition:status'), width: "8%" },
        { title: "", width: "9%" },
    ];

    const [users, setUsers] = useState();

    const _fetchData = (page, size) => {
        const filterData = {
            searchValue: searchTextRef.current.getSearchText(),
            ...filtersRef.current?.getFilterData()
        }
        getUserList(page, size, filterData)
            .then((response) => setUsers(response.data))
            .catch((error) => handleError(error, null, t));
    }

    const _export = () => {
        const filterData = {
            ...filtersRef.current.getFilterData(),
            searchValue: searchTextRef?.current?.getSearchText(),
            columnSetting: tableRef.current.getSelectedSettingId()
        }

        exportUserDefinition(filterData);
    }

    return (
        <>
            <Slisting
                id="users-definition-table"
                ref={tableRef}
                formId={FND0042_USERS_DEFINITION_FORM_ID}
                columns={columns}
                Row={Row}
                tableStyle={style.usersDefinitionTable}
                pagingData={users}
                getData={_fetchData}
                rowActions={{ user, tableRef, dialogRef, serverUrl, logDialogRef }}
                cardInMobile
                withFilter
                exportExcelAction={_export}
                showWating={(data) => data == null}
                showNoDataFound={(data) => data && data.length == 0}
                newAction={() => dialogRef.current?.open()}
                viewAction={(id) => dialogRef.current?.open(id)}
                labelCardWidth="100px"
            />

            <UsersDefinitionDialog ref={dialogRef} user={user} tableRef={tableRef} />
            <LogDialog ref={logDialogRef} user={user} />
        </>
    );
};

export default UsersDefinitionTable;