import BranchAndPolicy from "./BranchAndPolicy";
import ContractDetails from "./ContractDetail";
import Customer from "./Customer";
import Header from "./Header";
import Details from "../details";
import style from "../style.module.scss";
import { isNotEmpty } from "@ipgd-gauge/utils";

const Tab1 = (props) => {
    const { tab, currentUser, valuationContract, handleDataChanged, setValuationContract } = props

    return (
        <div className={tab == 1 ? 'active' : ''}>
            <div className={`toggle-mode ${style.toggleMode}`}>
                <Header
                    valuationContract={valuationContract}
                    handleDataChanged={handleDataChanged}
                />

                <BranchAndPolicy
                    currentUser={currentUser}
                    valuationContract={valuationContract}
                    handleDataChanged={handleDataChanged}
                />

                {isNotEmpty(valuationContract?.quotationType?.fields) &&
                    <>
                        <Customer valuationContract={valuationContract} handleDataChanged={handleDataChanged} />
                        <ContractDetails valuationContract={valuationContract} handleDataChange={handleDataChanged} />
                        <Details valuationContract={valuationContract} setValuationContract={setValuationContract} handleDataChanged={handleDataChanged} user={currentUser} />
                    </>
                }
            </div>
        </div>

    )

}

export default Tab1;