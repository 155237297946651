import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { useTranslation } from "react-i18next";
import { getWorkflowStatusFilter } from "../../util/apis";

const WorkflowStatusFilter = ({ id, formId, value, onChange }) => {
    const { t } = useTranslation();

    return (
        <>
            <label className='form-label'>{t('workflow_status')}</label>
            <FrmSelectMenu
                id={`${id}-workflow-status`}
                value={value}
                onChange={(value) => onChange(value)}
                api={() => getWorkflowStatusFilter(formId)}
                descLo={["descLo"]}
                descFo={["descFo"]}
                isMulti={true}
                portalTarget={document.body}
            />
        </>
    );
}

export default WorkflowStatusFilter;