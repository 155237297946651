import TableActionsButton from "@ipgd-gauge/table-actions-buttons";
import { confirmationDialog, handleError, isAllowedAction, isEnglish, isNotEmpty, showSuccessMessage } from "@ipgd-gauge/utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import ApproveIcon from "../../../icons/Approve";
import DeleteTowIcon from "../../../icons/Delete2";
import EditTableIcon from "../../../icons/EditTable";
import PrinterIcon from "../../../icons/Printer";
import RejectIcon from "../../../icons/Reject";
import ViewIcon from "../../../icons/View";
import { confirmEstimatorInvoice, deleteEstimatorInvoice, rejectEstimatorInvoice } from "../../../util/apis";
import { GLOBAL_STATUS_CONFIRMED, GLOBAL_STATUS_INCOMPLETE, GLOBAL_STATUS_REJECTED, GLOBAL_STATUS_SUBMITTED, STEP_TYPE_TRIGGERING } from "../../../util/constants";
import { CONFIRM_FORM_ACTION, DELETE_ACTION, PRINT_ACTION, REJECT_ACTION, VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID } from "../../../util/constants/forms";
import { ActionIcon } from "../../../icons/WorkflowIcons";

const Actions = (props) => {
    const { user, data, tableRef, printDialogRef, workflowsActionLogDialogRef } = props;

    const { t } = useTranslation();
    const [actions, setActions] = useState([]);
    const navigate = useNavigate();

    const _executeAction = (api, isDeleteAction) => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return api(data.id)
                .then(() => {
                    tableRef?.current?.refresh();
                    showSuccessMessage(isDeleteAction ? "delete_successfully" : null, t);
                })
                .catch((error) => {
                    let message = error?.response?.data?.message;
                    if (message.includes('#')) {
                        message = message.split('#')[0]
                    }
                    handleError(error, message, t)
                });
        });
    }

    const isWorkflow = () => isNotEmpty(data?.workflowId) && isNotEmpty(data?.stepId);
    const isIncomplete = () => data.globalStatus == GLOBAL_STATUS_INCOMPLETE;
    const isSubmitted = () => data.globalStatus == GLOBAL_STATUS_SUBMITTED;
    const isRejected = () => data.globalStatus == GLOBAL_STATUS_REJECTED;
    const isConfirmed = () => data.globalStatus == GLOBAL_STATUS_CONFIRMED;
    const _isAllowedAction = (action) => isAllowedAction(user, RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID, action);

    useEffect(() => {
        let actions = [];

        if (isWorkflow() && isNotEmpty(data.actionType) && data.actionType != STEP_TYPE_TRIGGERING) {
            actions.push({
                label: (isEnglish() ? data?.actionDescLo : data?.actionDescFo) || t('submit'),
                action: () => navigate(`/estimators-inspectors-invoice?id=${data.id}`),
                icon: <ActionIcon actionType={data.actionType} />,
            });
        }

        if (_isAllowedAction(VIEW_EDIT_ACTION)) {
            actions.push({
                label: t((isRejected() || isConfirmed()) ? 'view' : 'edit'),
                action: () => navigate(`/estimators-inspectors-invoice?id=${data.id}`),
                icon: (isRejected() || isConfirmed()) ? <ViewIcon /> : <EditTableIcon />,
            });
        }

        if (isIncomplete() && _isAllowedAction(DELETE_ACTION)) {
            actions.push({
                label: t('estimatorsInspectorsInvoices:delete'),
                color: "var(--action-delete-bg)",
                iconColor: "var(--action-delete-bg)",
                action: () => _executeAction(deleteEstimatorInvoice, true),
                icon: <DeleteTowIcon />,
            });
        }

        if (isSubmitted() && _isAllowedAction(REJECT_ACTION)) {
            actions.push({
                label: t('estimatorsInspectorsInvoices:reject'),
                action: () => _executeAction(rejectEstimatorInvoice, false),
                icon: <RejectIcon />,
            });
        }

        if (isSubmitted() && _isAllowedAction(CONFIRM_FORM_ACTION)) {
            actions.push({
                label: t('estimatorsInspectorsInvoices:confirm'),
                action: () => _executeAction(confirmEstimatorInvoice, false),
                icon: <ApproveIcon />,
            });
        }

        if (_isAllowedAction(PRINT_ACTION)) {
            actions.push({
                label: t('estimatorsInspectorsInvoices:print'),
                action: () => printDialogRef.current?.open([data?.id]),
                icon: <PrinterIcon />
            });
        }

        if (isNotEmpty(data?.workflowId)) {
            actions.push({
                label: t('workflow_status'),
                action: () => workflowsActionLogDialogRef?.current?.open(data?.workflowId, data?.id, data?.stepId, data?.globalStatus),
                icon: <ApproveIcon />,
            });
        }

        setActions(actions);
    }, [data.id, data.globalStatus])

    return <TableActionsButton defaultIndex={0} buttons={actions} />;
}

export default Actions;