import FrmInput from "@ipgd-gauge/frm-input";
import { useTranslation } from "react-i18next";
import Map from "../../../components/map";
import MapPinIcon from "../../../icons/MapPin";
import style from './style.module.scss';

const Tab2 = (props) => {
    const { tab, handleDataChanged, city, mapRef } = props;
    const { t } = useTranslation("cities");

    return (
        <div className={tab == 2 ? 'active' : ''}>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w100">
                        <label className='form-label'>{t('latitude')}</label>
                        <FrmInput
                            id="latitude"
                            value={city?.latitude}
                            onBlur={(value) => handleDataChanged("latitude", value)}
                            type="number"
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('longitude')}</label>
                        <FrmInput
                            id="longitude"
                            value={city?.longitude}
                            onBlur={(value) => handleDataChanged("longitude", value)}
                            type="number"
                        />
                    </div>
                    <div className="w100">
                        <div>
                            <Map
                                withSearch
                                key={`map-${city.id}`}
                                mapStyle={style.map}
                                ref={mapRef}
                                value={city?.longitude && city.latitude ? `${city?.latitude},${city?.longitude}` : null}
                                withPin={true}
                                MapPinCard={<MapPinIcon />}
                                handleChange={(e) => {
                                    console.log("", e)
                                    handleDataChanged("longitude", e.lng)
                                    handleDataChanged("latitude", e.lat)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tab2;