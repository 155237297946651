import Image from "@ipgd-gauge/image";
import { fixImagePath, handleError, hideWaiting, isEmpty, isNotEmpty, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import Modal from "../../../../../components/modal";
import DeleteTowIcon from "../../../../../icons/Delete2";
import NewTabIcon from "../../../../../icons/NewTab";
import PrevDownLoadIcon from "../../../../../icons/PrevDownLoad";
import { RotatImageIcon } from "../../../../../icons/Rotat";
import UpdateArrowIcon from "../../../../../icons/UploadArrow";
import { rotateAttachments, updateSimilaritiesAttachment } from "../../../../../util/apis";
import style from "./style.module.scss";

const SimilaritiesAttachmentDialog = (props, ref) => {
    const { getTrxKey, onAddAttachment } = props;
    const serverUrl = JSON.parse(sessionStorage.getItem("systemConfig"))?.serverUrl;
    const DEFAULT_DATA = { file: null, filePath: null, internalName: null };

    const { t } = useTranslation();
    const dialogRef = useRef();

    const [data, setData] = useState({ ...DEFAULT_DATA });

    const { getRootProps: getRootProps, getInputProps: getInputProps } = useDropzone({
        accept: ['image/*', '.pdf', '.doc', '.docx', '.txt', '.xlsx', '.xls', '.csv'],
        multiple: false,
        onDrop: (acceptedFile) => _handleUpload(acceptedFile),
        maxFiles: 10000,
        onDropRejected: (err => console.log("", err))
    });

    const _handleUpload = async (acceptedFiles) => {
        if ((acceptedFiles || []).length === 0) {
            return;
        }
        try {
            data.file = acceptedFiles[0];
            setData({ ...data });
        } catch (error) {
            console.log("", error);
        }
    }

    const _uploadAttachment = () => {
        if (isNotEmpty(data.filePath)) {
            dialogRef.current.close();
            return;
        }
        showWaiting("upload-similarities-attachment-dialog-okButton", true);
        updateSimilaritiesAttachment(getTrxKey(), data.file, data.internalName)
            .then((response) => onAddAttachment(response.data))
            .catch((error) => handleError(error, null, t))
            .finally(() => {
                hideWaiting("upload-similarities-attachment-dialog-okButton");
                dialogRef.current.close();
            });
    }

    const _rotateAttachment = () => {
        rotateAttachments({ trxKey: getTrxKey(), imgKeys: [data.internalName] })
            .then((response) => {
                const changes = response.data["flush-changed-values"];
                if (changes && changes[data.internalName]) {
                    data.filePath = changes[data.internalName];
                    data.file = null;
                    setData({ ...data });
                }
                onAddAttachment(response.data)
            })
            .catch((error) => handleError(error, null, t));
    }

    const open = (internalName, filePath) => {
        data.internalName = internalName;
        data.filePath = filePath;
        setData({ ...data });
        dialogRef.current.open();
    }

    useImperativeHandle(ref, () => ({ open }));

    return (
        <Modal
            id="upload-similarities-attachment-dialog"
            ref={dialogRef}
            title={t('upload')}
            className={style.modalContainer}
            withFooter
            withOkButton
            okBtnAction={_uploadAttachment}
            withCancelButton
            postClose={() => setData({ ...DEFAULT_DATA })}
            smallSize
        >
            {(data.file === null && isEmpty(data.filePath)) ?
                <div className={style.uploadImgContainer} {...getRootProps({})}>
                    <label className='form-label'>{t('estimationTransaction:add_attachments')}</label>
                    <div>
                        <input {...getInputProps()} />
                        <div><UpdateArrowIcon /></div>
                    </div>
                </div>
                :
                <div className={style.imageContainer}>
                    <Image src={data.file ? URL.createObjectURL(data.file) : `${serverUrl}/apis/attachments/path-img-attachment/${fixImagePath(data.filePath)}`} />
                    {/* <Image src='../../images/TestHome.jpeg' /> */}
                    <div className={style.bottomActionsContainer}>
                        {data.filePath && <button onClick={_rotateAttachment}><RotatImageIcon /></button>}
                        <a
                            href={data.file
                                ? URL.createObjectURL(data.file)
                                : `${serverUrl}/apis/attachments/path-img-attachment/${fixImagePath(data.filePath)}`}
                            download={data.file ? data.file.name : "downloaded-image"}
                            title={t('download')}
                        >
                            <PrevDownLoadIcon />
                        </a>
                        {!data.file &&
                            <a target={"_blank"} title="Open New Tab" href={`${serverUrl}/apis/attachments/path-img-attachment/${fixImagePath(data.filePath)}`}>
                                <NewTabIcon />
                            </a>
                        }
                        <button title={t('delete')} onClick={() => setData({ ...data, file: null, filePath: null })} className={style.deleteBtn} >
                            <DeleteTowIcon />
                        </button>
                    </div>
                </div>
            }
        </Modal >
    );
}

export default forwardRef(SimilaritiesAttachmentDialog);