import FrmInput from "@ipgd-gauge/frm-input";
import { FrmTimeDatePickr } from "@ipgd-gauge/date-picker"
import { RSM3272_EVALUATION_CONTRACTS_FORM_ID } from "../../../../util/constants/forms";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { handleError, isEnglish, isNotEmpty } from "@ipgd-gauge/utils";
import { getDomainValue } from "../../../../util/apis";
import style from "../style.module.scss"
import CodeInput from "../../../../components/code-input";
import HijriDate from "../../../../components/hijri-date";

const StatusPanel = ({ data }) => {
    const { t } = useTranslation();

    const [status, setStatus] = useState({});

    useEffect(() => {
        if (isNotEmpty(data.globalStatus)) {
            getDomainValue(data.globalStatus)
                .then((response) => setStatus(response.data))
                .catch((error) => handleError(error, null, t));
        }
    }, [data.globalStatus]);

    return (
        <div className={style.statusHeader}>
            <label className={`status-${data?.globalStatus}`}>{isEnglish() ? status?.descLo : status?.descFo}</label>
        </div>
    );
}

const Header = (props) => {
    const { valuationContract, handleDataChanged } = props;
    const { t } = useTranslation("");

    return (
        <div>
            <div className="frm-title-panel-border-toggle">
                <div>
                    <label className="frm-title-form-border">
                        {t('valuationContracts:header')}
                    </label>
                    <div className={style.headerStatus}>
                        <StatusPanel data={valuationContract} />
                        {valuationContract?.workflowStatus &&
                            <div className={style.statusHeader} style={{ backgroundColor: `${valuationContract?.workflowStatus?.backgroundColor}`, color: `${valuationContract?.workflowStatus?.color}` }}>
                                <label className="status-label">
                                    {isEnglish() ? valuationContract?.workflowStatus?.descLo : valuationContract?.workflowStatus?.descFo}
                                </label>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="w25">
                        <CodeInput
                            id="estimation-quotation-code"
                            value={valuationContract?.code}
                            onBlur={(value) => handleDataChanged("code", value)}
                            column='IOT_CODE'
                            formId={RSM3272_EVALUATION_CONTRACTS_FORM_ID}
                        />
                    </div>
                    <div className="w25">
                        <label id='date-g-lbl' className='form-label mandatory'>{t('valuationContracts:date_g')}</label>
                        <FrmTimeDatePickr
                            id='date-g'
                            value={valuationContract?.dateDgr}
                            onChange={(value) => handleDataChanged("dateDgr", value)}
                        />
                    </div>
                    <div className="w25">
                        <label id='date-h-lbl' className='form-label mandatory'>{t('valuationContracts:date_h')}</label>
                        <HijriDate
                            id='date-h'
                            hijriDate={valuationContract?.dateDhi}
                            gregorianDate={valuationContract?.dateDgr}
                            onChange={(value) => handleDataChanged("dateDhi", value)}
                            disabled={true}
                        />
                    </div>
                    <div className="w25">
                        <label id='approval-date-lbl' className='form-label mandatory'>{t('valuationContracts:approval_date')}</label>
                        <FrmTimeDatePickr
                            id='approval-date'
                            value={valuationContract?.approveDateTime}
                            disabled
                            onChange={(value) => handleDataChanged("approveDateTime", value)}
                        />
                    </div>
                    <div className="w100">
                        <label className='form-label'>{t('remarks')}</label>
                        <FrmInput
                            id="estimation-quotation-rem"
                            value={valuationContract?.rem}
                            onBlur={(value) => handleDataChanged("rem", value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;