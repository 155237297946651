import { FrmTimeDatePickr } from "@ipgd-gauge/date-picker";
import FrmInput from "@ipgd-gauge/frm-input";
import { useTranslation } from "react-i18next";
import CodeInput from "../../../components/code-input";
import HijriDate from "../../../components/hijri-date";
import { RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID } from "../../../util/constants/forms";
import { ACTION_SET_HEADER_VALUE } from "./reducer";
import { isEnglish } from "@ipgd-gauge/utils";

const ID = "credit-notes-for-customer-estimation";

const Header = (props) => {
    const { creditNote, dispatchCreditNote, workflowStatus } = props;
    const { t } = useTranslation();

    const _handleChange = (name, value) => {
        dispatchCreditNote({ type: ACTION_SET_HEADER_VALUE, name, value })
    }

    return (
        <div>
            <div className="frm-title-panel-border-toggle">
                <div>
                    <label className="frm-title-form-border">
                        {t('home')}
                    </label>
                    <div className="frm-title-container-status">
                        <label className={`status-${creditNote?.header?.globalStatus}`} >{t(`status_${creditNote.header.globalStatus}`)}</label>
                        {workflowStatus &&
                            <label className="status-label" style={{ backgroundColor: `${workflowStatus?.backgroundColor}`, color: `${workflowStatus?.color}` }}>
                                {isEnglish() ? workflowStatus?.descLo : workflowStatus?.descFo}
                            </label>
                        }
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row  rowSmModel'>
                    <div className="w25">
                        <CodeInput
                            id={`${ID}-code`}
                            value={creditNote?.header?.code || ''}
                            onBlur={(value) => _handleChange("code", value)}
                            column="DCE_CODE"
                            formId={RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID}
                        />
                    </div>

                    <div className="w25">
                        <label id={`${ID}-date-dgr-lbl`} className='form-label mandatory'>{t('creditNotesForCustomersEstimation:date_g')}</label>
                        <FrmTimeDatePickr
                            id={`${ID}-date-dgr`}
                            value={creditNote?.header?.dateDgr}
                            onChange={(value) => _handleChange("dateDgr", value)}
                        />
                    </div>

                    <div className="w25">
                        <label className='form-label'>{t('creditNotesForCustomersEstimation:date_h')}</label>
                        <HijriDate
                            id={`${ID}-date-dhi`}
                            hijriDate={creditNote?.header?.dateDhi}
                            gregorianDate={creditNote?.header?.dateDgr}
                            onChange={(value) => _handleChange("dateDhi", value)}
                        />
                    </div>
                    <div className="w25">
                        <label className='form-label'>{t('creditNotesForCustomersEstimation:ref_code')}</label>
                        <FrmInput
                            id={`${ID}-ref-code`}
                            value={creditNote?.header?.refCode || ''}
                            onBlur={(value) => _handleChange("refCode", value)}
                        />
                    </div>

                    <div className="w100">
                        <label className='form-label'>{t('creditNotesForCustomersEstimation:remarks')}</label>
                        <FrmInput
                            id={`${ID}-remark`}
                            value={creditNote?.header?.rem || ''}
                            onBlur={(value) => _handleChange("rem", value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;