import { DEFAULT_ZOOM } from '@ipgd-gauge/map';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addBlockFeilds } from '../../../../store/est-trnx-data/actions';
import { deleteSimilars, fillDefaultDescription, fillSimilars, getEstBlockFeilds } from '../../../../util/apis';
import { DATA_TYPE_DATE, DATA_TYPE_DATE_TIME, DATA_TYPE_HIJRI_DATE, DATA_TYPE_LOV, DATA_TYPE_MULTI_LINES_TEXT, DATA_TYPE_MULTI_LOV, DATA_TYPE_NUMBER, DATA_TYPE_TEXT, DATA_TYPE_TEXT_ALIGN_CENTER, DATA_TYPE_TIME, DATA_TYPE_YES_NO, TRX_DISPLAY_MODE_1, TRX_DISPLAY_MODE_2, TRX_MODE_VIEW_APPROVAL, TRX_MODE_VIEW_ENTRY, TRX_MODE_VIEW_INSPEC_N_ESTIMATION, TRX_MODE_VIEW_REVISION } from '../../../../util/constants';
import { confirmationDialog, isEnglish } from '../../../../util/util';
import MapDialog from '../../../common/dialogs/MapDialog';
import AddSimilarLandNBulidFromMapDialog from '../../dialogs/add-similar-land-n-bulid-from-map-dialog';
import BackToTop from './BackToTop';
import DatePicker from './DatePicker';
import InputNumber from './InputNumber';
import InputText from './InputText';
import TrxMap from './Map';
import SelectMenu from './SelectMenu';
import SelfConstructionTreeBlock from './SelfConstructionBlock';
import Table from './Table';
import Title from './Title';
import UnitsDetailsBlock from './UnitsDetailsBlock';
import YesNoBtn from './YesNoBtn';
import GeneralEstimationBlock from './general-estimation-block';
import SimilaritiesAttachmentDialog from './similarities-attachment-dialog';
import style from './style.module.scss';
import TrxUploudImages from './upload-image';

const BLOCK_GENERAL = 9;
const BLOCK_MAP = 10;
const BLOCK_SELF_CONSTRUCTION = 26;
const BLOCK_SIMILAR_LAND = 21;
const BLOCK_SIMILAR_BULID = 20;
const BLOCK_LAND_CONCILIATION = 27;
const BLOCK_UNITS_DETAILS = 66;

const SIMILAR_TYPE_LAND = "LAND";
const SIMILAR_TYPE_BULID = "BULID";

const DESCRIPTION_COLUMNS = ['IAT_COMP_PRICE_PER_M_0', 'IAT_TIME_FACTOR_SET_0', 'IAT_FUNDING_FACTOR_SET_0', 'IAT_MARKET_FACTOR_SET_0', 'IAT_TOTAL_FUND_MARKT_SET_PER_0', 'IAT_TOTAL_FUND_MARKT_SET_VAL_0', 'IAT_PRICE_AFT_FUND_MARKT_SET_0', 'IAT_COMP_AREA_0', 'IAT_COMP_GEN_LOC_0', 'IAT_COMP_STREET_NO_0', 'IAT_COMP_MAIN_STREET_W_0', 'IAT_COMP_LAND_LEVEL_0', 'IAT_COMP_USAGE_0', 'IAT_COMP_ALLOWED_FLOORS_0', 'IAT_OTHER_FACTOR_SET_0', 'IAT_OTHER_FACTOR2_SET_0', 'IAT_OTHER_FACTOR3_SET_0', 'IAT_OTHER_FACTOR4_SET_0', 'IAT_OTHER_FACTOR5_SET_0', 'IAT_COMP_TOTAL_OF_SETT_PERC_0', 'IAT_COMP_SETTING_VALUE_0', 'IAT_COMP_PRICE_P_M_TO_CONS_0', 'IAT_PRICE_PORTION_PERC_0'];

const PinLocationFromMapDialog = forwardRef((props, ref) => {
    const { handleChange } = props;
    const mapDialogRef = useRef();
    const feildNameToPickLocation = useRef(null);

    const trxLat = useSelector(state => state.estTrnxReducer.data.TRI_LATITUDE);
    const trxLng = useSelector(state => state.estTrnxReducer.data.TRI_LONGITUDE);
    const trxZoom = useSelector(state => state.estTrnxReducer.data.TRI_MAP_ZOOM);

    const _fillCoordinatesFromMap = coordinates => {
        if (feildNameToPickLocation.current) {
            handleChange(feildNameToPickLocation.current, `${Number(coordinates?.lat).toFixed(8)}/${Number(coordinates?.lng).toFixed(8)}`);
        }
        mapDialogRef.current.close();
    }

    const open = (feildInternalName, value) => {
        if (feildInternalName) {
            feildNameToPickLocation.current = feildInternalName;
            if (value) {
                const coords = value.split('/');
                mapDialogRef.current.changePinLocation({ lat: coords[0], lng: coords[1], zoom: 15 });
            } else {
                mapDialogRef.current.changePinLocation({ lat: trxLat, lng: trxLng, zoom: trxZoom ? Number(trxZoom) : null });
            }

            mapDialogRef.current.open();
        }
    }

    useImperativeHandle(ref, () => ({ open }));

    return <MapDialog ref={mapDialogRef} okAction={_fillCoordinatesFromMap} />
});

const AddSimilerBtn = (props) => {
    const { openAddSimilarLandNBulidFromMapDialog, type } = props;
    const { t } = useTranslation("estimationTransaction")
    const data = useSelector(state => state.estTrnxReducer.data);
    const trxMode = useSelector(state => state.estTrnxReducer.data.TRX_MODE);

    const PREVENT_EDIT_ACTION_MODES = [TRX_MODE_VIEW_ENTRY, TRX_MODE_VIEW_INSPEC_N_ESTIMATION, TRX_MODE_VIEW_APPROVAL, TRX_MODE_VIEW_REVISION];

    const handleOpenDialog = (e) => {
        openAddSimilarLandNBulidFromMapDialog(e, {
            'TRI_ID': data.TRI_ID,
            'TRI_CODE': data.TRI_CODE,
            'TRI_DATE_TIME': data.TRI_DATE_TIME,
            'DTR_LAND_AREA': data.DTR_LAND_AREA,
            'DTR_PARCEL_PRICE_PER_M': data.DTR_PARCEL_PRICE_PER_M,
            'ARI_TOTAL_BUILD_AREA': data.ARI_TOTAL_BUILD_AREA,
            'DTR_BUILD_METER_VALUE': data.DTR_BUILD_METER_VALUE,
            "TRI_ATR_ID": data.TRI_ATR_ID,
            "ARI_PARCEL_AREA": data.ARI_PARCEL_AREA,
            "TRI_LATITUDE": data.TRI_LATITUDE,
            "TRI_LONGITUDE": data.TRI_LONGITUDE,
            "TRI_MAP_ZOOM": data.TRI_MAP_ZOOM
        }, type)
    }


    return (<button className='secondary-button' title={t('add_from_map')} onClick={handleOpenDialog} disabled={PREVENT_EDIT_ACTION_MODES.includes(trxMode)}> {t('add_from_map')} </button>)
}

const TitleInfo = (props) => {

    const { id, blockId, label, openAddSimilarLandNBulidFromMapDialog, displayMode, deleteSimilars, customClass, showFillDescription, fillDefaultDescription } = props;
    const { t } = useTranslation("estimationTransaction");

    const showBlockDetails = () => {
        if (blockId && displayMode == TRX_DISPLAY_MODE_1) {
            document.getElementById(id).classList.toggle('close');
            document.getElementById(`block-content-${blockId}`).classList.toggle('close');
        }
    }

    return (
        <div id={id} className={`frm-display-panel-header block-title ${style.blockTitle} ${customClass} ${displayMode == TRX_DISPLAY_MODE_1 ? 'mode1' : 'mode2'}`}
            style={{ marginBottom: 0 }} onClick={showBlockDetails}>
            <div className={style.headerTitleInfo}>
                <div className='title-panel'>
                    <label className={style.infoTitle}>{label}</label>
                </div>
                {(blockId == BLOCK_SIMILAR_LAND || blockId == BLOCK_SIMILAR_BULID) &&
                    <div className={`${style.headerButtons} headerBtnsPanel`}>
                        <AddSimilerBtn
                            openAddSimilarLandNBulidFromMapDialog={openAddSimilarLandNBulidFromMapDialog}
                            type={blockId == BLOCK_SIMILAR_LAND ? SIMILAR_TYPE_LAND : SIMILAR_TYPE_BULID}
                        />
                        <button
                            title={t('delete_all')}
                            className='delete-button'
                            onClick={e => deleteSimilars(e, blockId == BLOCK_SIMILAR_LAND ? SIMILAR_TYPE_LAND : SIMILAR_TYPE_BULID)}
                        >
                            {t('delete_all')}
                        </button>
                    </div>}
                {showFillDescription &&
                    <div className={`headerBtnsPanel`}>
                        <button
                            title={t('fill_default_description')}
                            className='secondary-button'
                            onClick={e => fillDefaultDescription(e, blockId)}
                        >
                            {t('fill_default_description')}
                        </button>
                    </div>
                }
            </div>
        </div>
    )
}

const Feild = (props) => {
    const { user, getTrxKey, feild, handleChange, displayMode } = props;

    return (
        <>
            {(feild.dataType == DATA_TYPE_TEXT || feild.dataType == DATA_TYPE_MULTI_LINES_TEXT || feild.dataType == DATA_TYPE_TEXT_ALIGN_CENTER) &&
                <InputText
                    inCard
                    feild={feild}
                    handleChange={handleChange}
                    multiLines={displayMode == TRX_DISPLAY_MODE_2 &&
                        (feild.internalName != 'TRI_APPROVED_ORG_FEES_AFC' || feild.internalName != 'TRI_APPROVED_ORG_FEES_ALC')}
                />
            }

            {feild.dataType == DATA_TYPE_NUMBER &&
                <InputNumber
                    user={user}
                    inCard
                    feild={feild}
                    handleChange={handleChange}
                />
            }

            {feild.dataType == DATA_TYPE_YES_NO &&
                <YesNoBtn
                    inCard
                    feild={feild}
                    handleChange={handleChange}
                />
            }

            {(feild.dataType == DATA_TYPE_LOV || feild.dataType == DATA_TYPE_MULTI_LOV) &&
                <SelectMenu
                    inCard
                    feild={feild}
                    getTrxKey={getTrxKey}
                    handleChange={handleChange}
                />
            }

            {(feild.dataType == DATA_TYPE_DATE || feild.dataType == DATA_TYPE_DATE_TIME || feild.dataType == DATA_TYPE_TIME || feild.dataType == DATA_TYPE_HIJRI_DATE) &&
                <DatePicker
                    inCard
                    feild={feild}
                    handleChange={handleChange}
                />
            }
        </>
    )
}

const Block = (props) => {
    const { user, block, handleChange, displayMode, pickLocation, openAddSimilarLandNBulidFromMapDialog, getTrxKey, deleteSimilars, unitsDetailsBlockRef, handleChangeUnitDetail, fillDefaultDescription, similaritiesAttachmentDialogRef } = props;
    const [feilds, setFeilds] = useState([]);
    let dispatch = useDispatch();

    useEffect(() => {
        if (block?.id) {
            document.getElementById(`block-${block.id}`)?.classList?.add("loadingBlock");
            document.getElementById(`block-card-${block.id}`)?.classList?.add("loadingBlock");
            getEstBlockFeilds(block.id, { key: getTrxKey() })
                .then(res => {
                    setFeilds(res.data);
                    dispatch(addBlockFeilds(block?.id, res.data))
                })
                .catch(() => { })
                .finally(() => {
                    document.getElementById(`block-${block.id}`)?.classList?.remove("loadingBlock")
                    document.getElementById(`block-card-${block.id}`)?.classList?.remove("loadingBlock")
                })
        }
    }, [block, block.forceUpdate])

    const getFields = useCallback(() => {
        return feilds;
    }, [feilds])

    const isBlockTable = () => {
        let isTable = false;
        const fields = getFields();
        for (let index = 0; index < fields.length; index++) {
            if (fields[index].fieldDetails?.length > 2) {
                isTable = true;
                break;
            }
        }
        return isTable;
    }

    const feildsMode2 = displayMode == TRX_DISPLAY_MODE_2 ? getFields().reduce((r, o, i) => ((r[i % 3] = r[i % 3] || []).push(o), r), []) : [[], [], []];

    const _showFillDescription = () => {
        let show = false;
        const fields = getFields();
        if (block?.id == BLOCK_LAND_CONCILIATION) {
            if (fields.length > 0) {
                (fields[0].fieldDetails || []).every((field) => {
                    if (DESCRIPTION_COLUMNS.includes(field.internalName)) {
                        show = true;
                        return;
                    }
                })
            }
        }
        return show;
    }

    return (
        <>
            {block.id != BLOCK_GENERAL && block.id != BLOCK_SELF_CONSTRUCTION && <>
                <TitleInfo
                    id={`block-${block.id}`}
                    blockId={block.id}
                    label={isEnglish() ? block.userDescLo : block.userDescFo}
                    customClass={getFields()?.length > 0 ? '' : style.hiddenBlock}
                    openAddSimilarLandNBulidFromMapDialog={openAddSimilarLandNBulidFromMapDialog}
                    displayMode={displayMode}
                    deleteSimilars={deleteSimilars}
                    showFillDescription={_showFillDescription()}
                    fillDefaultDescription={fillDefaultDescription}
                />

                {isBlockTable(block) ?
                    <div id={`block-content-${block.id}`} className={`form-templates-panel block-content ${style.formTemplates} ${style.tableDetailsPanel} ${getFields()?.length > 0 ? '' : style.hiddenBlock}`}>
                        <Table user={user} handleChange={handleChange} fields={getFields()} pickLocation={pickLocation} similaritiesAttachmentDialogRef={similaritiesAttachmentDialogRef} />
                    </div>
                    :
                    <>
                        <div id={`block-content-${block.id}`} className={`form-templates-panel block-content ${style.formTemplates} ${displayMode == TRX_DISPLAY_MODE_1 ? style.formTemplatesMode1 : ''} ${getFields()?.length > 0 ? '' : style.hiddenBlock}`}>
                            <div className={`row ${style.row}`}>
                                {displayMode == TRX_DISPLAY_MODE_1 &&
                                    getFields().map(feild => {
                                        return <div key={feild.id} className="w33">
                                            <Feild user={user} getTrxKey={getTrxKey} feild={feild} handleChange={handleChange} displayMode={displayMode} />
                                        </div>
                                    })
                                }
                                {displayMode == TRX_DISPLAY_MODE_2 &&
                                    <>
                                        <div className="w33">
                                            {feildsMode2[0]?.map(feild => {
                                                return <Feild user={user} key={feild.id} getTrxKey={getTrxKey} feild={feild} handleChange={handleChange} displayMode={displayMode} />
                                            })}
                                        </div>
                                        <div className="w33">
                                            {feildsMode2[1]?.map(feild => {
                                                return <Feild user={user} key={feild.id} getTrxKey={getTrxKey} feild={feild} handleChange={handleChange} displayMode={displayMode} />
                                            })}
                                        </div>
                                        <div className="w33">
                                            {feildsMode2[2]?.map(feild => {
                                                return <Feild user={user} key={feild.id} getTrxKey={getTrxKey} feild={feild} handleChange={handleChange} displayMode={displayMode} />
                                            })}
                                        </div>
                                    </>
                                }
                            </div>
                            {block.id == BLOCK_MAP && <TrxMap handleChange={handleChange} displayMode={displayMode} getTrxKey={getTrxKey} />}
                            {block.id == BLOCK_UNITS_DETAILS &&
                                <UnitsDetailsBlock
                                    ref={unitsDetailsBlockRef}
                                    getTrxKey={getTrxKey}
                                    handleChangeUnitDetail={handleChangeUnitDetail}
                                />
                            }
                        </div>
                    </>
                }
            </>}
        </>
    )
}

const GeneralEstimation = (props) => {
    const { block, handleChange, getTrxKey, displayMode } = props;
    return (
        <>
            <TitleInfo id={`block-${BLOCK_GENERAL}`} label={isEnglish() ? block?.userDescLo : block?.userDescFo} displayMode={displayMode} customClass={style.customBlock} />
            <GeneralEstimationBlock handleChange={handleChange} block={block} getTrxKey={getTrxKey} />
        </>
    )
}

const SelfConstructionBlock = (props) => {
    const { user, block, handleChange, getTrxKey, selfConstructionTreeRef, displayMode } = props;

    return (
        <>
            <TitleInfo id={`block-${BLOCK_SELF_CONSTRUCTION}`} label={isEnglish() ? block?.userDescLo : block?.userDescFo} displayMode={displayMode} customClass={style.customBlock} />
            <SelfConstructionTreeBlock
                user={user}
                handleChange={handleChange}
                block={block}
                getTrxKey={getTrxKey}
                displayMode={displayMode}
                selfConstructionTreeRef={selfConstructionTreeRef}
            />
        </>
    )
}

const Lines = (props) => {
    const { user, blocks, handleChange, attachmentsRef, displayMode, selfConstructionTreeRef, getTrxKey, unitsDetailsBlockRef, handleChangeUnitDetail, handelTrxResponse } = props;
    const { t } = useTranslation("estimationTransaction");

    const blocksPositions = useRef([]);
    const pinLocationFromMapDialogRef = useRef(null);
    const addSimilarLandNBulidFromMapDialogRef = useRef(null);
    const containerRef = useRef(null);
    const similaritiesAttachmentDialogRef = useRef();

    const _handleSelectedBlockCard = (id) => {
        Array.from(document.querySelectorAll('.active-card')).forEach((el) => el.classList.remove('active-card'));
        document.getElementById(`block-card-${id}`).classList.add("active-card");
        var target = document.getElementById(`block-card-${id}`);
        target.parentNode.scrollTop = target.offsetTop - 90;
    }

    const isMouseOver = useRef(false);

    const _handleScroll = (e) => {
        if (displayMode == TRX_DISPLAY_MODE_2 || !isMouseOver.current) {
            return;
        }

        if (blocks.length != blocksPositions.current.length) {
            blocksPositions.current = [];
            blocks.map(block => {
                if (document.getElementById(`block-${block.id}`)) {
                    blocksPositions.current.push({
                        id: block.id,
                        top: document.getElementById(`block-${block.id}`)?.offsetTop
                    });
                }
            })
            //add attachment block
            if (document.getElementById(`block-0`)) {
                blocksPositions.current.push({
                    id: 0,
                    top: document.getElementById(`block-0`)?.offsetTop
                });
            }
        }

        const titleElement = document.getElementById('displayPanel');
        const scrollPosition = titleElement.scrollTop;

        if (scrollPosition < blocksPositions.current[0]?.top) {
            _handleSelectedBlockCard(blocksPositions.current[0].id)
        } else {
            for (let index = 0; index < blocksPositions.current.length; index++) {
                const val = blocksPositions.current[index];
                if (val.top <= scrollPosition && blocksPositions.current[index + 1] && blocksPositions.current[index + 1]?.top >= scrollPosition) {
                    if (blocksPositions.current[index + 1]?.top - scrollPosition <= 300) {
                        _handleSelectedBlockCard(blocksPositions.current[index + 1].id)
                    }
                    break;
                }
            }
        }
    };

    //-------this code to pic location in details feild
    const pickLocation = (feildInternalName, value) => {
        pinLocationFromMapDialogRef.current.open(feildInternalName, value);
    }
    //--------------------------------------------------

    const openAddSimilarLandNBulidFromMapDialog = (e, data, type) => {
        e.stopPropagation();
        addSimilarLandNBulidFromMapDialogRef.current.open(data, type);
    }

    const _deleteSimilars = (e, type) => {
        e.stopPropagation();
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            let data = {
                trxKey: getTrxKey(),
                type
            }
            return deleteSimilars(data)
                .then((response) => handelTrxResponse(response.data))
                .catch(() => { })
        });
    }

    const _fillDefaultDescription = (e, blockId) => {
        e.stopPropagation();
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            let data = {
                trxKey: getTrxKey(),
                blockId
            }
            return fillDefaultDescription(data)
                .then((response) => handelTrxResponse(response.data))
                .catch(() => { })
        });
    }

    const _addSimilarLand = (selectedTrx, type) => {
        const estimationTransactions = [...(selectedTrx.transactions || [])];
        const historyTransactions = [...(selectedTrx.historyTransactions || [])];
        const trxIds = [];
        const historyIds = [];

        estimationTransactions.forEach((trx) => trxIds.push(trx.estimationTrxId))
        historyTransactions.forEach((trx) => trxIds.push(trx.estimationHistoryId))

        if ([...trxIds, ...historyIds]?.length > 0) {
            let data = {
                trxKey: getTrxKey(),
                trxIds,
                historyIds,
                type
            }
            fillSimilars(data)
                .then((response) => handelTrxResponse(response.data))
                .catch(() => { })
        }
        addSimilarLandNBulidFromMapDialogRef.current?.close();
    }

    return (
        <div id='estimation-trx-container'  className={`frm-display-panel ${style.blocksDetailsMainPanel}`}
            onMouseOver={() => isMouseOver.current = true}
            onMouseLeave={() => isMouseOver.current = false}>
            <Title getTrxKey={getTrxKey} />
            <div id="displayPanel" ref={containerRef} className={style.displayPanel} onScroll={e => _handleScroll(e)}>
                {
                    blocks.map(block => <>
                        <Block
                            key={block.id}
                            user={user}
                            block={block}
                            getTrxKey={getTrxKey}
                            displayMode={displayMode}
                            pickLocation={pickLocation}
                            handleChange={handleChange}
                            openAddSimilarLandNBulidFromMapDialog={openAddSimilarLandNBulidFromMapDialog}
                            deleteSimilars={_deleteSimilars}
                            unitsDetailsBlockRef={unitsDetailsBlockRef}
                            handleChangeUnitDetail={handleChangeUnitDetail}
                            fillDefaultDescription={_fillDefaultDescription}
                            similaritiesAttachmentDialogRef={similaritiesAttachmentDialogRef}
                        />
                        {block.id == BLOCK_GENERAL &&
                            <GeneralEstimation
                                handleChange={handleChange}
                                block={block}
                                getTrxKey={getTrxKey}
                                displayMode={displayMode} />
                        }
                        {block.id == BLOCK_SELF_CONSTRUCTION &&
                            <SelfConstructionBlock
                                user={user}
                                handleChange={handleChange}
                                block={block}
                                getTrxKey={getTrxKey}
                                displayMode={displayMode}
                                selfConstructionTreeRef={selfConstructionTreeRef}
                            />
                        }
                    </>
                    )}

                {blocks?.length > 0 && <TitleInfo id="block-0" label={t('attachments')} displayMode={displayMode} />}
                {blocks?.length > 0 && <TrxUploudImages getTrxKey={getTrxKey} ref={attachmentsRef} />}
            </div>
            <PinLocationFromMapDialog ref={pinLocationFromMapDialogRef} handleChange={handleChange} />
            <AddSimilarLandNBulidFromMapDialog
                ref={addSimilarLandNBulidFromMapDialogRef}
                getTrxKey={getTrxKey}
                okAction={_addSimilarLand}
            />

            <BackToTop containerRef={containerRef} />
            <SimilaritiesAttachmentDialog ref={similaritiesAttachmentDialogRef} getTrxKey={getTrxKey} onAddAttachment={(data) => handelTrxResponse(data)} />
        </div>
    )
}

export default Lines;