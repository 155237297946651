import { DeleteBtn, EditBtn } from "@ipgd-gauge/table";
import { confirmationDialog, handleError, isAllowedAction, isEnglish, isNotEmpty, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slisting from "../../../components/slisting";
import { deletePropertiesUsersDefinition, exportPropertiesUsersDefinition, getPropertiesUsersDefinitionList } from "../../../util/apis";
import { DELETE_ACTION, VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { RSM2661_PROPERTIES_USERS_FORM_ID } from "../../../util/constants/forms";
import style from './style.module.scss';

const Row = (props) => {
    const { data, rowActions } = props;
    const { user, deleteAction, dialogRef } = rowActions;
    const { t } = useTranslation();

    return (
        <div className={`${style.propertiesUsersDefinitionRow}`}>
            <div title={data?.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data?.sequence}</label>
            </div>

            <div title={data?.code} className="cardRowMode">
                <label className="nameLblMobile">{t('propertiesUsersDefinition:code')}</label>
                <label>{data?.code}</label>
            </div>

            <div title={isEnglish() ? data?.userNameLo : data?.userNameFo} className="cardRowMode">
                <label className="nameLblMobile">{t('propertiesUsersDefinition:name')}</label>
                <label>{isEnglish() ? data?.userNameLo : data?.userNameFo}</label>
            </div>

            <div title={data?.userNickname} className="cardRowMode">
                <label className="nameLblMobile">{t('propertiesUsersDefinition:user_name')}</label>
                <label>{data?.userNickname}</label>
            </div>

            <div title={isEnglish() ? data?.partyDescLo : data?.partyDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('propertiesUsersDefinition:party_target')}</label>
                <label>{isEnglish() ? data?.partyDescLo : data?.partyDescFo}</label>
            </div>

            <div title={isEnglish() ? data?.policyDescLo : data?.policyDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('propertiesUsersDefinition:identifier')}</label>
                <label>{isEnglish() ? data?.policyDescLo : data?.policyDescFo}</label>
            </div>

            <div title={isEnglish() ? data?.activeDescLo : data?.activeDescFo} className="cardRowStatusMode">
                <label className="nameLblMobile">{t('cities:status')}</label>
                <label className={`status-${data.isActive}`}>{isEnglish() ? data?.activeDescLo : data?.activeDescFo}</label>
            </div>
            <div>
                <div className='containerButtonTable'>
                    {isAllowedAction(user, RSM2661_PROPERTIES_USERS_FORM_ID, VIEW_EDIT_ACTION) &&
                        <EditBtn onClick={() => dialogRef?.current?.open(data?.id)} />
                    }
                    {isAllowedAction(user, RSM2661_PROPERTIES_USERS_FORM_ID, DELETE_ACTION) &&
                        <DeleteBtn onClick={() => deleteAction(data?.id)} />
                    }
                </div>
            </div>
        </div>
    );
};

const PropertiesUsersDefinitionTable = (props) => {
    const { user, tableRef, searchTextRef, filtersRef, dialogRef } = props;

    const { t } = useTranslation();

    const [data, setData] = useState();

    const columns = [
        { title: '#', width: "5%" },
        { title: t('propertiesUsersDefinition:code'), width: "10%" },
        { title: t('propertiesUsersDefinition:name'), width: "14%" },
        { title: t('propertiesUsersDefinition:user_name'), width: "14%" },
        { title: t('propertiesUsersDefinition:party_target'), width: "14%" },
        { title: t('propertiesUsersDefinition:estimation_policy'), width: "14%" },
        { title: t('propertiesUsersDefinition:status'), width: "14%" },
        { title: "", width: "15%" },
    ];

    const _fetchData = (page, size) => {
        getPropertiesUsersDefinitionList(page, size, searchTextRef.current.getSearchText(), filtersRef.current?.getFilterData())
            .then((response) => setData(response.data))
            .catch((error) => handleError(error, null, t));
    }

    const _deleteAction = (id) => {
        if (isNotEmpty(id)) {
            confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
                return deletePropertiesUsersDefinition(id)
                    .then(() => {
                        showSuccessMessage("delete_successfully", t);
                        tableRef?.current?.refresh();
                    })
                    .catch((error) => handleError(null, 'delete_fields', t));
            });
        }
    }

    const _exportExcelAction = () => {
        const filterData = { ...searchTextRef?.current?.getSearchText(), ...filtersRef?.current?.getFilterData(), columnSetting: tableRef.current.getSelectedSettingId() };
        exportPropertiesUsersDefinition(filterData,"Properties Users" );
    }

    return (
        <Slisting
            id="properties-users-definition-table"
            ref={tableRef}
            columns={columns}
            formId={RSM2661_PROPERTIES_USERS_FORM_ID}
            user={user}
            Row={Row}
            tableStyle={style.propertiesUsersDefinitionTable}
            pagingData={data}
            getData={_fetchData}
            rowActions={{ user, deleteAction: _deleteAction, dialogRef }}
            withFilter
            showWating={(data) => data == null}
            showNoDataFound={(data) => (data && data.length == 0)}
            newAction={() => dialogRef?.current?.open()}
            deleteAction={deletePropertiesUsersDefinition}
            viewAction={(id) => dialogRef.current?.open(id)}
            labelCardWidth="90px"
            exportExcelAction={_exportExcelAction}

            cardInMobile
        />
    );
};

export default PropertiesUsersDefinitionTable;