import { formatDate, formatMoney, handleError, isEnglish, isNotEmpty } from "@ipgd-gauge/utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EstimationMapIcon from '../../../../icons/EstimationMap';
import { getDomainValue } from "../../../../util/apis";
import style from './style.module.scss';
import { isLocalCurrency } from "../../../../util/util";
import { getUser } from "../../../../util/cookies";

const GlobalStatus = (props) => {
    const { data } = props;

    const { t } = useTranslation("moneyTransferVouchers");

    const [status, setStatus] = useState([]);

    useEffect(() => {
        if (isNotEmpty(data?.isActive)) {
            getDomainValue(data?.isActive)
                .then((response) => setStatus(response.data))
                .catch((error) => handleError(error, null, t));
        }
    }, [data?.isActive])

    if (isNotEmpty(status?.id)) {
        return (
            <div className={`status-${status?.id}`}>
                <label>
                    {isEnglish() ? status?.descLo : status?.descFo}
                </label>
            </div>
        );
    } else {
        return (<></>);
    }
}

const HeaderSummary = (props) => {
    const { t } = useTranslation();
    const { data } = props;

    return (
        <div className="frm-summary-card">
            <div className="summary-header-title">
                <div className="header-title-panel">
                    <span><EstimationMapIcon /></span>
                    <label>{t('summary')}</label>
                </div>
            </div>
            <div className={style.summaryCode}>
                <div className="td-column" title={`#${data?.code}`}>#{data?.code}</div>
                <div>
                    <GlobalStatus data={data} />
                </div>
            </div>

            {formatDate(data?.validityFromDgr) || formatDate(data?.validityToDgr) ?
                <div className="summary-ref">
                    <div>
                        <label className="form-label">{t('priceAgreement:validity_period_from')}</label>
                        <div title={formatDate(data?.validityFromDgr)}>{formatDate(data?.validityFromDgr)}</div>
                    </div>
                    <div>
                        <label className="form-label">{t('priceAgreement:validity_period_to')}</label>
                        <div title={formatDate(data?.validityToDgr)}>{formatDate(data?.validityToDgr)}</div>
                    </div>
                </div>
                :
                <></>
            }

            {data.rem ?
                <div className="desc-summary-container">
                    <label>{t('priceAgreement:remarks')}</label>
                    <div className="desc-summary" title={data?.rem}>
                        {data?.rem}
                    </div>
                </div>
                :
                <></>
            }

            {isEnglish() ? data?.partyData?.descLo : data?.partyData?.descFo &&
                <div className="desc-summary-container">
                    <label>{t('priceAgreement:party')}</label>
                    <div className="desc-summary" title={isEnglish() ? data?.partyData?.descLo : data?.partyData?.descFo}>
                        {isEnglish() ? data?.partyData?.descLo : data?.partyData?.descFo}
                    </div>
                </div>
                ||
                <></>
            }

            {isEnglish() ? data?.levelData?.descLo : data?.levelData?.descFo &&
                <div className="summary-ref-full">
                    <div>
                        <label className="form-label">{t('priceAgreement:type')}</label>
                        <div title={isEnglish() ? data?.levelData?.descLo : data?.levelData?.descFo}>{isEnglish() ? data?.levelData?.descLo : data?.levelData?.descFo}</div>
                    </div>
                </div>
            }
            {formatMoney(data.defaultPriceAfc) || formatMoney(data.defaultPriceAlc) ?
                <div className="summary-ref-full">
                    <div>
                        <label className="form-label">{t('priceAgreement:default_price')}</label>
                        <div>{`${!isLocalCurrency(data?.fcuId) ? formatMoney(data.defaultPriceAfc) : formatMoney(data.defaultPriceAlc)}  ${data?.currencyData?.curSign || ""}`}</div>
                    </div>
                </div>
                :
                <></>
            }
            {/* <div className="tdSections">
                <div className="td-column">
                    <label>{t('contract_num')}</label>
                    <div></div>
                </div>
                <div>
                    <label>{t('contract_date')}</label>
                    <div></div>
                </div>
            </div>

            <div className="tdSections">
                <div className="td-column">
                    <label>{t('service')}</label>
                    <div></div>
                </div>
                <div>
                    <label>{t('contract_type')}</label>
                    <div></div>
                </div>
            </div>

            <div className="tdSections">
                <div className="td-column">
                    <label>{t('branch')}</label>
                    <div></div>
                </div>
                <div>
                    <label>{t('division')}</label>
                    <div></div>
                </div>
            </div>

            <div className="tdSections">
                <div>
                    <label>{t('unit')}</label>
                    <div></div>
                </div>
            </div>

            <div className="tdSections">
                <div className="td-column">
                    <label>{t('tenant')}</label>
                    <div></div>
                </div>
                <div>
                    <label>{t('mobile')}</label>
                    <div></div>
                </div>
            </div>
            <div className="tdSections">
                <div className="td-column">
                    <label>{t('salesman')}</label>
                    <div></div>
                </div>
                <div>
                    <label>{t('collectors')}</label>
                    <div></div>
                </div>
            </div>

            <div className="tdSections">
                <div className="td-column">
                    <label>{t('start_date')}</label>
                    <div></div>
                </div>
                <div>
                    <label>{t('end_date')}</label>
                    <div></div>
                </div>
            </div>

            <div className="tdSections">
                <div className="td-column">
                    <label>{t('payment_methods')}</label>
                    <div></div>
                </div>
                <div>
                    <label>{t('total_value')}</label>
                    <div></div>
                </div>
            </div>

            <div className="tdSections">
                <div className="td-column">
                    <label>{t('additional_items')}</label>
                    <div></div>
                </div>
                <div>
                    <label>{t('net_contract')}</label>
                    <div></div>
                </div>
            </div>

            <div className="tdSections">
                <div className="td-column">
                    <label>{t('down_payment')}</label>
                    <div></div>
                </div>
                <div>
                    <label>{t('auto_renewal')}</label>
                    <div></div>
                </div>
            </div>

            <div className="tdSections">
                <div className="td-column">
                    <label>{t('renewal_notification_date')}</label>
                    <div></div>
                </div>
                <div>
                    <label>{t('follow_up_date')}</label>
                    <div></div>
                </div>
            </div>

            <div className="tdSections">
                <div className="td-column">
                    <label>{t('conveyance_date')}</label>
                    <div></div>
                </div>
                <div>
                    <label>{t('conveyance_description')}</label>
                    <div></div>
                </div>
            </div>

            <div className="tdSections">
                <div className="td-column">
                    <label>{t('received_date')}</label>
                    <div></div>
                </div>
                <div>
                    <label>{t('received_status')}</label>
                    <div></div>
                </div>
            </div> */}
        </div>
    )
}

const Summary = (props) => {
    const { data } = props;
    return (
        <div className="summary-container">
            <div className="summary-table">
                <HeaderSummary data={data} />
            </div>
        </div>
    )
}

export default Summary;


// const Summary = (props) => {
//     const { order } = props;

//     return (
//         <div className="summary-container">
//             ffffffffffffffffffffff
//         </div>
//     )
// }
// export default Summary;