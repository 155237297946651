import { DeleteBtn, EditBtn } from "@ipgd-gauge/table";
import { confirmationDialog, handleError, isAllowedAction, isEnglish, isNotEmpty, showSuccessMessage } from "@ipgd-gauge/utils";
import { t } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slisting from "../../../components/slisting";
import { deleteContractAddItem, getContractAddItemList } from "../../../util/apis";
import { DELETE_ACTION, VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { RSM2877_ADDITIONAL_ITEMS_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import style from './style.module.scss';

const Row = (props) => {
    const { data, rowActions } = props;
    const { tableRef, dialogRef, deleteAction } = rowActions;
    const user = getUser();

    return (
        <div className={`${style.additionalItemsRow}`}>
            <div title={data?.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data?.sequence}</label>
            </div>

            <div title={data?.code} className="cardRowMode">
                <label className="nameLblMobile">{t('additionalItems:code')}</label>
                <label>{data?.code}</label>
            </div>

            <div title={isEnglish() ? data.descLo : data.descFo} className="table_body_label cardRowMode">
                <label className="nameLblMobile">{t('additionalItems:name')}</label>
                <label>{isEnglish() ? data.descLo : data.descFo}</label>
            </div>


            <div title={isEnglish() ? data.typeDescLo : data.typeDescFo} className="cardRowMode">
                <label className="nameLblMobile">{t('additionalItems:type')}</label>
                <label>{isEnglish() ? data.typeDescLo : data.typeDescFo}</label>
            </div>

            <div title={data?.vatPerc} className="cardRowMode">
                <label className="nameLblMobile">{t('additionalItems:vat_num')}</label>
                <label>{data?.vatPerc}</label>
            </div>

            <div className="cardRowStatusMode">
                <label className="nameLblMobile">{t('additionalItems:status')}</label>
                <label title={isEnglish() ? data?.statusDescLo : data?.statusDescFo} className={`status-${data.isActive}`}>
                    {isEnglish() ? data?.statusDescLo : data?.statusDescFo}
                </label>
            </div>

            <div>
                <div className='containerButtonTable'>
                    {isAllowedAction(user, RSM2877_ADDITIONAL_ITEMS_FORM_ID, VIEW_EDIT_ACTION) &&
                        <EditBtn onClick={() => dialogRef?.current?.open(data?.id)} />
                    }
                    {isAllowedAction(user, RSM2877_ADDITIONAL_ITEMS_FORM_ID, DELETE_ACTION) &&
                        <DeleteBtn onClick={() => deleteAction(data?.id)} />
                    }
                </div>
            </div>
        </div>
    );
};

const AdditionalItemsTable = (props) => {
    const { searchTextRef, filterRef, dialogRef, tableRef } = props;
    const user = getUser()

    const { t } = useTranslation();
    const columns = [
        { title: '#', width: "5%" },
        { title: t('additionalItems:code'), width: "12%" },
        { title: t('additionalItems:name'), width: "20%" },
        { title: t('additionalItems:type'), width: "17%" },
        { title: t('additionalItems:vat_num'), width: "16%" },
        { title: t('additionalItems:status'), width: "15%" },
        { title: "", width: "17%" },
    ];

    const [list, setList] = useState({});

    const _deleteAction = (id) => {
        if (isNotEmpty(id)) {
            confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
                return deleteContractAddItem(id)
                    .then(() => {
                        showSuccessMessage("delete_successfully", t);
                        tableRef?.current?.refresh();
                    })
                    .catch((error) => handleError(error, null, t));
            });
        }
    }

    return (
        <>
            <div className={style.additionalItemsTableContainer}>
                <Slisting
                    id="additional-items"
                    ref={tableRef}
                    columns={columns}
                    Row={Row}
                    tableStyle={style.additionalItemsTable}
                    pagingData={list}
                    getData={(page, size) => {
                        getContractAddItemList(page, size, searchTextRef.current.getSearchText(), filterRef.current?.getFilterData())
                            .then((response) => setList(response.data))
                            .catch((error) => handleError(error, null, t));
                    }}
                    rowActions={{ tableRef: tableRef, dialogRef: dialogRef, deleteAction: _deleteAction }}
                    withFilter={true}
                    rowHeight={80}
                    showWating={(data) => {
                        return data == null
                    }}
                    showNoDataFound={(data) => {
                        return (data && data.length == 0)
                    }}
                    newAction={() => dialogRef?.current?.open()}
                    deleteAction={deleteContractAddItem}
                    viewAction={(id) => dialogRef.current?.open(id)}
                    cardInMobile
                    labelCardWidth="90px"
                    formId={RSM2877_ADDITIONAL_ITEMS_FORM_ID}
                />
            </div>
        </>
    );
};

export default AdditionalItemsTable;
