import i18next from "i18next";
import { toast } from "react-toastify";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import CitiesIcon from "../icons/Cities";
import CustomerIcon from "../icons/Customer";
import DelegationIcon from "../icons/Delegation";
import EstimationFeesCalcIcon from "../icons/EstimationFeesCalc";
import EstimationFormsFieldsBuilderIcon from "../icons/EstimationFormsFieldsBuilder";
import EstimationMapIcon from "../icons/EstimationMap";
import EstimationMessagesTemplatesIcon from "../icons/EstimationMessagesTemplatesIcon";
import EstimationQuotationIcon from "../icons/EstimationQuotation";
import EstimationReportsIcon from "../icons/EstimationReports";
import EstimationTransactionsIcon from "../icons/EstimationTransactions";
import EstimationTransactionsStatusesIcon from "../icons/EstimationTransactionsStatuses";
import EstimationsHistoryIcon from "../icons/EstimationsHistory";
import InboxIcon from "../icons/Inbox";
import { DefinitionsModul } from "../icons/Modul";
import NotExecutedTasksIcon from "../icons/NotExecutedTasks";
import OutboxIcon from "../icons/Outbox";
import PriceAgreementIcon from "../icons/PriceAgreementIcon";
import ReportsDesignIcon from "../icons/ReportsDesign";
import SettingsIcon from "../icons/Settings";
import TasksListIcon from "../icons/TasksList";
import TasksStatisticsIcon from "../icons/TasksStatistics";
import TownsIcon from "../icons/Towns";
import TransactionsStatisticsIcon from "../icons/TransactionsStatistics";
import UsersLoginLogIcon from "../icons/UsersLoginLog";
import WorkflowProcedureMessagesIcon from "../icons/WorkflowProcedureMessages";
import { persistor } from "../store";
import { CORRESPONDENCE, DASHBOARDS, DASHBOARD_COLOR_PALETTES_1, DASHBOARD_COLOR_PALETTES_2, DASHBOARD_COLOR_PALETTES_3, DASHBOARD_COLOR_PALETTES_4, DASHBOARD_COLOR_PALETTES_5, DASHBOARD_COLOR_PALETTES_6, DASHBOARD_COLOR_PALETTES_7, DASHBOARD_COLOR_PALETTES_8, DEFINITIONS, FINANCIAL, OPERATIONS_MANAG, REPORTS, SETTINGS } from "./constants";
import { FND0042_USERS_DEFINITION_FORM_ID, FND0133_USERS_LOGIN_LOG_FORM_ID, FND2319_CITIES_DEFINITION_FORM_ID, FND2621_TOWNS_DEFINITION_FORM_ID, FND3168_REPORTS_DESIGN_FORM_ID, FORM_TYPE_DEFINITION, FORM_TYPE_LISTING, FORM_TYPE_REPORT, FORM_TYPE_TEMPLATE, RSM2651_PROPERTIES_PARTIES_CATEGORIES_FORM_ID, RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID, RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID, RSM3003_PROPERTIES_ESTIMATION_LIST_OF_VALUES_FORM_ID, RSM3007_PRICE_AGREEMENT_FORM_ID, RSM3009_CREATE_CUSTOMER_RSM_FORM_ID, RSM3011_ESTIMATION_FORMS_FIELDS_BUILDER_FORM_ID, RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID, RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID, RSM3043_ESTIMATION_TRANSACTIONS_REGISTER_FORM_ID, RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID, RSM3059_ESTIMATION_TRANSACTIONS_STATUSES_FORM_ID, RSM3061_WORKFLOW_PROCEDURE_AND_MESSAGES_FORM_ID, RSM3079_ESTIMATION_INVOICES_REGISTER_FORM_ID, RSM3081_ESTIMATION_INVOICES_FOLLOW_UP_FORM_ID, RSM3085_ESTIMATION_QUOTATIONS_TYPES_FORM_ID, RSM3131_ESTIMATION_FEES_COMMISSIONS_CALCULATION_FORM_ID, RSM3190_EVALUATION_QUOTATIONS_FORM_ID, RSM3192_UPDATE_EVALUATION_FIELDS_FORM_ID, RSM3230_VALUATION_TRANSACTIONS_FORM_ID, RSM3232_DELEGATION_FORM_ID, RSM3234_PROPERTIES_MAP_FORM_ID, RSM3236_VALUATION_HISTORY_FORM_ID, RSM3240_NOT_EXECUTED_TASKS_FORM_ID, RSM3242_TRANSACTIONS_STATISTICS_FORM_ID, RSM3244_TASKS_STATISTICS_FORM_ID, RSM3246_INBOX_FORM_ID, RSM3248_OUTBOX_FORM_ID, RSM3250_VALUATION_REPORTS_FORM_ID, RSM3252_TASKS_LIST_FORM_ID, RSM3268_CUSTOMER_DUES_FOLLOW_UP, RSM3270_INSPECTORS_EMPLOYEES_DUES_FOLLOW_UP, RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID } from "./constants/forms";
import { destroyAllCookie, getUser } from "./cookies";

export const isEnglish = () => {
    return i18next.language === "en";
}

export const getFormAttributeByPath = (pathname) => {
    switch (pathname) {
        // ---------------------------------------------------DASHBOARDS
        case "/not-executed-tasks":
            return { code: "RSM3240", title: "not_executed_tasks", module: DASHBOARDS, icon: <NotExecutedTasksIcon /> };
        case "/transactions-statistics":
            return { code: "RSM3242", title: "transactions_statistics", module: DASHBOARDS, icon: <TasksStatisticsIcon /> };
        case "/tasks-statistics":
            return { code: "RSM3244", title: "tasks_statistics", module: DASHBOARDS, icon: <TransactionsStatisticsIcon /> };
        case "/customer-dues-follow-up":
            return { code: "RSM3268", title: "customerDuesFollowUp:customer_dues_follow_up", module: DASHBOARDS, icon: <TransactionsStatisticsIcon /> };
        case "/inspectors-employees-dues-follow-up":
            return { code: "RSM3270", title: "customerDuesFollowUp:inspectors_employees_dues_follow_up", module: DASHBOARDS, icon: <TransactionsStatisticsIcon /> };

        // ---------------------------------------------------OPERATIONS_MANAG
        case "/estimation-trx":
            return { code: "RSM3230", title: "estimation_transactions", module: OPERATIONS_MANAG, type: FORM_TYPE_TEMPLATE };
        case "/estimation-transactions":
            return { code: "RSM3230", title: "estimation_transactions", module: OPERATIONS_MANAG, icon: <EstimationTransactionsIcon />, type: FORM_TYPE_LISTING };
        case "/trx-summary":
            return { code: "RSM3230", title: "estimation_transactions", module: OPERATIONS_MANAG, type: FORM_TYPE_TEMPLATE };
        case "/estimation-map":
            return { code: "RSM3234", title: "estimation_map", module: OPERATIONS_MANAG, icon: <EstimationMapIcon /> };
        case "/estimation-quotations":
            return { code: "RSM3190", title: "estimation_quotation", module: OPERATIONS_MANAG, icon: <EstimationQuotationIcon />, type: FORM_TYPE_LISTING };
        case "/estimation-quotation":
            return { code: "RSM3190", title: "estimation_quotation", module: OPERATIONS_MANAG, type: FORM_TYPE_TEMPLATE };
        case "/price-agreements":
            return { code: "RSM3007", title: "price_agreement", module: OPERATIONS_MANAG, icon: <PriceAgreementIcon />, type: FORM_TYPE_LISTING };
        case "/price-agreement":
            return { code: "RSM3007", title: "price_agreement", module: OPERATIONS_MANAG, icon: <PriceAgreementIcon />, type: FORM_TYPE_TEMPLATE };

        case "/valuation-contracts":
            return { code: "RSM3272", title: "valuation_contracts", module: OPERATIONS_MANAG, icon: <EstimationQuotationIcon />, type: FORM_TYPE_DEFINITION };
        case "/valuation-contract":
            return { code: "RSM3272", title: "valuation_contracts", module: OPERATIONS_MANAG, type: FORM_TYPE_TEMPLATE };
        case "/estimations-history":
            return { code: "RSM3236", title: "estimations_history", module: OPERATIONS_MANAG, icon: <EstimationsHistoryIcon />, type: FORM_TYPE_DEFINITION };
        // case "/estimations-history/short-estimation":
        //     return { title: "estimations_history", module: OPERATIONS_MANAG };
        case "/delegation":
            return { code: "RSM3232", title: "delegation", module: OPERATIONS_MANAG, icon: <DelegationIcon />, type: FORM_TYPE_DEFINITION };

        // ---------------------------------------------------CORRESPONDENCE
        case "/inbox":
            return { code: "RSM3246", title: "inbox", module: CORRESPONDENCE, icon: <InboxIcon />, type: FORM_TYPE_DEFINITION };
        case "/outbox":
            return { code: "RSM3248", title: "outbox", module: CORRESPONDENCE, icon: <OutboxIcon />, type: FORM_TYPE_DEFINITION };

        // ---------------------------------------------------FINANCIAL
        case "/customers-estimation-invoices":
            return { code: "RSM3015", title: "customers_invoices", module: FINANCIAL, icon: <EstimationQuotationIcon />, type: FORM_TYPE_LISTING };

        case "/customers-estimation-invoice":
            return { code: "RSM3015", title: "customers_invoices", module: FINANCIAL, type: FORM_TYPE_TEMPLATE };

        case "/estimation-fees-commissions-calculation":
            return { code: 'RSM3131', title: "estimationFees:estimation_fees_commissions_calculation", module: FINANCIAL, icon: <EstimationFeesCalcIcon />, type: FORM_TYPE_LISTING };
        case "/estimators-inspectors-invoices":
            return { code: "RSM3017", title: "estimators_inspectors_invoices", module: FINANCIAL, icon: <EstimationQuotationIcon />, type: FORM_TYPE_LISTING };
        case "/estimators-inspectors-invoice":
            return { code: "RSM3017", title: "estimators_inspectors_invoices", module: FINANCIAL, icon: <EstimationQuotationIcon />, type: FORM_TYPE_TEMPLATE };

        // ---------------------------------------------------DEFINITIONS
        case "/cities":
            return { code: "FND2319", title: "cities", module: DEFINITIONS, icon: <CitiesIcon />, type: FORM_TYPE_DEFINITION };
        case "/towns":
            return { code: "FND2621", title: "towns", module: DEFINITIONS, icon: <TownsIcon />, type: FORM_TYPE_DEFINITION };
        case "/customers":
            return { code: "RSM3009", title: "customers", module: DEFINITIONS, icon: <CustomerIcon />, type: FORM_TYPE_DEFINITION };
        case "/properties-types":
            return { code: "RSM2659", title: "propertiesTypes:properties_types", module: DEFINITIONS, icon: <CitiesIcon />, type: FORM_TYPE_DEFINITION };
        case "/branches-definition":
            return { code: "RSM2669", title: "branchesDefinition:branches_definition", module: DEFINITIONS, icon: <DefinitionsModul />, type: FORM_TYPE_DEFINITION };
        case "/division-definition":
            return { code: "RSM2667", title: "divisionDefinition:division_definition", module: DEFINITIONS, icon: <DefinitionsModul />, type: FORM_TYPE_DEFINITION };

        // ---------------------------------------------------SETTINGS
        case "/inspectors-and-employees-categories":
            return { code: "RSM2651", title: "inspectors_and_employees_categories", module: DEFINITIONS, icon: <CitiesIcon />, type: FORM_TYPE_DEFINITION };
        case "/properties-parties-definition":
            return { code: "RSM2653", title: "propertiesPartiesDefinition:inspectors_employees_definition", module: SETTINGS, icon: <CustomerIcon />, type: FORM_TYPE_DEFINITION };
        case "/properties-users-definition":
            return { code: "RSM2661", title: "propertiesUsersDefinition:user_roles_security", module: SETTINGS, icon: <CustomerIcon />, type: FORM_TYPE_DEFINITION };
        case "/users-definition":
            return { code: "FND0042", title: "usersDefinition:users_definition", module: SETTINGS, icon: <CustomerIcon />, type: FORM_TYPE_DEFINITION };
        case "/reports-design":
            return { code: "FND3168", title: "reports_design", module: SETTINGS, icon: <ReportsDesignIcon />, type: FORM_TYPE_DEFINITION };
        case "/valuation-form":
        case "/valuation-forms":
            return { code: "RSM3011", title: "estimation_forms_fields_builder", module: SETTINGS, icon: <EstimationFormsFieldsBuilderIcon />, type: FORM_TYPE_DEFINITION };
        case "/estimation-transactions-statuses":
            return { code: "RSM3059", title: "transactions_statuses", module: SETTINGS, icon: <EstimationTransactionsStatusesIcon />, type: FORM_TYPE_DEFINITION };
        case "/estimation-messages-templates":
            return { code: "RSM3055", title: "estimationMessagesTemplates:estimation_messages_templates", module: SETTINGS, icon: <EstimationMessagesTemplatesIcon />, type: FORM_TYPE_DEFINITION };
        case "/workflow-procedure-and-messages":
            return { code: 'RSM3061', title: "workflow_procedure_and_messages", module: SETTINGS, icon: <WorkflowProcedureMessagesIcon />, type: FORM_TYPE_DEFINITION };
        case "/estimation-quotations-types":
            return { code: "RSM3085", title: "estimation_quotations_types", module: SETTINGS, icon: <EstimationQuotationIcon />, type: FORM_TYPE_DEFINITION };
        case "/update-valuation-fields":
            return { code: "RSM3192", title: "update_valuation_fields", module: SETTINGS, icon: <EstimationFormsFieldsBuilderIcon />, type: FORM_TYPE_DEFINITION };
        case "/properties-estimation-list-of-values":
            return { code: "RSM3003", title: "properties_estimation_list_of_values", module: SETTINGS, icon: <EstimationFormsFieldsBuilderIcon />, type: FORM_TYPE_DEFINITION };
        case "/evaluation-policies":
            return { code: 'RSM2685', title: "propertiesPolicies:evaluation_policies", module: SETTINGS, icon: <SettingsIcon />, type: FORM_TYPE_LISTING };
        case "/evaluation-policy":
            return { code: 'RSM2685', title: "propertiesPolicies:evaluation_policies", module: SETTINGS, icon: <SettingsIcon />, type: FORM_TYPE_TEMPLATE };

        // ---------------------------------------------------REPORTS
        case "/tasks-list":
            return { code: "RSM3252", title: "tasks_list", module: REPORTS, icon: <TasksListIcon />, type: FORM_TYPE_REPORT };
        // case "/estimation-invoices-register":
        //     return { title: "estimation_invoices_register", module: REPORTS };

        case "/users-login-log":
            return { code: "FND0133", title: "users_login_log", module: REPORTS, icon: <UsersLoginLogIcon />, type: FORM_TYPE_DEFINITION };
        case "/estimation-reports":
            return { code: "RSM3250", title: "estimation_reports", module: REPORTS, icon: <EstimationReportsIcon />, type: FORM_TYPE_REPORT };
        case "/estimation-invoices-register":
            return { code: "RSM3079", title: "estimation_invoices_register", module: REPORTS, icon: <EstimationReportsIcon />, type: FORM_TYPE_REPORT };
        case "/estimation-invoices-follow-up":
            return { code: "RSM3081", title: "estimation_invoices_follow_up", module: REPORTS, icon: <EstimationsHistoryIcon />, type: FORM_TYPE_REPORT };
        case "/estimation-transactions-register":
            return { code: "RSM3043", title: "estimation_transactions_register", module: REPORTS, icon: <EstimationsHistoryIcon />, type: FORM_TYPE_REPORT };
        case "/properties-valuation-custom-reports":
            return { code: "MGR3188", title: "customReports:properties_valuation_custom_reports", module: REPORTS, icon: <EstimationsHistoryIcon />, type: FORM_TYPE_REPORT };
        case "/control-with-excel-fields":
            return { code: "RSM3274", title: "controlWithExcelFields:control_with_excel_fields", module: SETTINGS, icon: <EstimationFormsFieldsBuilderIcon />, type: FORM_TYPE_DEFINITION };
        case "/important-links":
            return { code: "RSM3266", title: "importantLinks:important_links", module: SETTINGS, icon: <EstimationFormsFieldsBuilderIcon />, type: FORM_TYPE_DEFINITION };
        case "/additional-items":
            return { code: "RSM2877", title: "additionalItems:additional_items", module: DEFINITIONS, icon: <DefinitionsModul />, type: FORM_TYPE_DEFINITION };

        case "/credit-notes-for-customers-estimation":
            return { code: "RSM3278", title: "creditNotesForCustomersEstimation:credit_notes_for_customers_estimation", module: DEFINITIONS, icon: <DefinitionsModul />, type: FORM_TYPE_LISTING };

        case "/credit-notes-for-customer-estimation":
            return { code: 'RSM3278', title: "creditNotesForCustomersEstimation:credit_notes_for_customers_estimation", module: DEFINITIONS, icon: <DefinitionsModul />, type: FORM_TYPE_TEMPLATE };
        default:
            return { title: "", module: "", formId: null }
    }
}


export const purgeSession = async () => {
    destroyAllCookie();
    await persistor.purge();
}

export const deletePath = () => {
    window.history.replaceState({}, "", decodeURIComponent(`/`));
}


export const getFormId = () => {
    const url = window.location.pathname.replace('/', '');

    switch (url) {
        case 'inspectors-and-employees-categories':
            return RSM2651_PROPERTIES_PARTIES_CATEGORIES_FORM_ID;
        case 'properties-parties-definition':
            return RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID;
        case 'cities':
            return FND2319_CITIES_DEFINITION_FORM_ID;
        case 'towns':
            return FND2621_TOWNS_DEFINITION_FORM_ID;
        case 'reports-design':
            return FND3168_REPORTS_DESIGN_FORM_ID;
        case 'customers':
            return RSM3009_CREATE_CUSTOMER_RSM_FORM_ID;
        case 'users-definition':
            return FND0042_USERS_DEFINITION_FORM_ID;
        case 'estimation-reports':
            return RSM3250_VALUATION_REPORTS_FORM_ID;
        case 'users-login-log':
            return FND0133_USERS_LOGIN_LOG_FORM_ID;
        case 'estimations-history':
            return RSM3236_VALUATION_HISTORY_FORM_ID;
        case 'inbox':
            return RSM3246_INBOX_FORM_ID;
        case 'outbox':
            return RSM3248_OUTBOX_FORM_ID;
        case 'estimation-map':
            return RSM3234_PROPERTIES_MAP_FORM_ID;
        case 'transactions-statistics':
            return RSM3242_TRANSACTIONS_STATISTICS_FORM_ID;
        case 'tasks-list':
            return RSM3252_TASKS_LIST_FORM_ID;
        case 'delegation':
            return RSM3232_DELEGATION_FORM_ID;
        case 'not-executed-tasks':
            return RSM3240_NOT_EXECUTED_TASKS_FORM_ID;
        case 'tasks-statistics':
            return RSM3244_TASKS_STATISTICS_FORM_ID;
        case 'customer-dues-follow-up':
            return RSM3268_CUSTOMER_DUES_FOLLOW_UP;
        case 'inspectors-employees-dues-follow-up':
            return RSM3270_INSPECTORS_EMPLOYEES_DUES_FOLLOW_UP;
        case 'estimation-quotations':
            return RSM3190_EVALUATION_QUOTATIONS_FORM_ID;
        case 'estimation-quotation':
            return RSM3190_EVALUATION_QUOTATIONS_FORM_ID;

        case 'estimation-transactions':
            return RSM3230_VALUATION_TRANSACTIONS_FORM_ID;

        case 'estimation-trx':
            return RSM3230_VALUATION_TRANSACTIONS_FORM_ID;

        case 'estimation-quotations-types':
            return RSM3085_ESTIMATION_QUOTATIONS_TYPES_FORM_ID;
        case 'update-valuation-fields':
            return RSM3192_UPDATE_EVALUATION_FIELDS_FORM_ID;
        case 'estimation-invoices-follow-up':
            return RSM3081_ESTIMATION_INVOICES_FOLLOW_UP_FORM_ID;
        case 'estimation-transactions-statuses':
            return RSM3059_ESTIMATION_TRANSACTIONS_STATUSES_FORM_ID;
        case 'estimation-fees-commissions-calculationnt':
            return RSM3131_ESTIMATION_FEES_COMMISSIONS_CALCULATION_FORM_ID;
        case 'estimation-fees-commissions-calculation':
            return RSM3131_ESTIMATION_FEES_COMMISSIONS_CALCULATION_FORM_ID;

        case 'price-agreements':
        case 'price-agreement':
            return RSM3007_PRICE_AGREEMENT_FORM_ID;
        case 'estimation-messages-templates':
            return RSM3055_ESTIMATION_MESSAGES_TEMPLATES_FORM_ID;
        case 'valuation-forms':
            return RSM3011_ESTIMATION_FORMS_FIELDS_BUILDER_FORM_ID;
        case 'workflow-procedure-and-messages':
            return RSM3061_WORKFLOW_PROCEDURE_AND_MESSAGES_FORM_ID;
        case 'customers-estimation-invoices':
        case 'customers-estimation-invoice':
            return RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID;
        case 'customers-estimation-invoice':
            return RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID;
        case 'credit-notes-for-customers-estimation':
        case 'credit-notes-for-customer-estimation':
            return RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID;
        case 'estimators-inspectors-invoices':
        case 'estimators-inspectors-invoice':
            return RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID;
        case 'estimation-invoices-register':
            return RSM3079_ESTIMATION_INVOICES_REGISTER_FORM_ID;
        case 'evaluation-policies':
        case 'evaluation-policy':
            return RSM2685_PROPERTIES_MANAGEMENT_POLICIES_FORM_ID;
        case 'estimation-transactions-register':
            return RSM3043_ESTIMATION_TRANSACTIONS_REGISTER_FORM_ID;
        case 'properties-estimation-list-of-values':
            return RSM3003_PROPERTIES_ESTIMATION_LIST_OF_VALUES_FORM_ID;
        default:
            return null;
    }
}

export const handleAdvanceFilter = (id) => {
    document.getElementById(id || 'advanceFilter').classList.toggle('advanceFilterActive');
}

export const handleError = (error, errorMessage, t, params) => {
    try {
        if (error) {
            if (error?.response?.data?.type) { //for error from print action of type Blob
                toast.error(t('no_report_found_for_this_action'), { hideProgressBar: true });
            } else {
                const parameters = {};
                params?.map(p => {
                    parameters[p.name] = p.value;
                })
                toast.error(t(errorMessage ? errorMessage : 'cannot_perform', parameters), { hideProgressBar: true });
            }
        } else {
            const parameters = {};
            params?.map(p => {
                parameters[p.name] = p.value;
            })
            toast.error(t(errorMessage ? errorMessage : 'cannot_perform', parameters), { hideProgressBar: true });
        }
    } catch (error) {
        toast.error(t(errorMessage ? errorMessage : 'cannot_perform'), { hideProgressBar: true });
    }
}
export const handleScrollShadow = event => {
    const eleId = event.currentTarget.id;
    if (event.currentTarget.scrollTop > 10) {
        if (!document.getElementById(`${eleId}-shadow`)) {
            const temp = document.createElement('div');
            temp.id = `${eleId}-shadow`;
            temp.classList.add('scrollShadow')
            const parent = document.getElementById(eleId);
            parent.prepend(temp)
        }
    } else if (document.getElementById(`${eleId}-shadow`)) {
        document.getElementById(eleId).removeChild(document.getElementById(`${eleId}-shadow`));
    }
};

export const showSuccessMessage = (message, t, params) => {
    try {
        const parameters = {};
        params?.map(p => {
            parameters[p.name] = p.value;
        })
        toast.success(t(message ? message : "action_performed_successfully", parameters), { hideProgressBar: true });
    } catch (error) {
        toast.error(t('cannot_perform'), { hideProgressBar: true });
    }
}
export const formatMoney = (num, noOfDigits) => {
    if (!num) {
        num = 0;
    }
    const formattedNum = `${Number(Number(num).toFixed(noOfDigits || 2))}`;
    const parts = formattedNum.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const result = parts.join(".");
    return (
        result
    )
}

export const parseNumber = (num) => {
    try {
        if (!num) {
            num = 0;
        }

        return Number(`${num}`.replaceAll(',', ''));
    } catch (error) {
        return 0;
    }
}
export const addUrlParam = (key, value) => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set(key, value);
    window.history.replaceState({}, "", decodeURIComponent(`${window.location.pathname}?${urlParams}`));
}

export const getUrlParam = (key) => {
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get(key);
    return param;
}
export const isEmpty = (data) => {
    return data == null || data == undefined || data === "";
}
export const isNotEmpty = (data) => {
    return !isEmpty(data);
}

export const openSummary = (id) => {
    document.getElementById(id).classList.add('active');
    if (document.getElementById(id).classList.contains('active')) {
        document.getElementById('summary-bg-mobile').classList.add('active');
    } else {
        document.getElementById('summary-bg-mobile').classList.remove('active');
    }
}
export const postOpenSummary = (id) => {
    document.getElementById(id).classList.add('active');
    if (document.getElementById(id).classList.contains('active')) {
        document.getElementById('summary-bg-mobile').classList.add('active');
    } else {
        document.getElementById('summary-bg-mobile').classList.remove('active');
    }
}

export const closeSummary = (id) => {
    document.getElementById(id).classList.remove('active');
    document.getElementById('summary-bg-mobile').classList.remove('active');
}

export const postCloseSummary = (id) => {
    document.getElementById(id).classList.remove('active');
    document.getElementById('summary-bg-mobile').classList.remove('active');
}
export const openTotalTableSm = (id) => {
    document.getElementById(id).classList.add('activeTotal');
    document.getElementById(`${id}-bg`).classList.add('active');
}
export const closeTotalTableSm = (id) => {
    document.getElementById(id).classList.remove('activeTotal');
    document.getElementById(`${id}-bg`).classList.remove('active');
}
export const confirmationDialog = (title, confirmButtonText, cancelButtonText, showLoaderOnConfirm, api) => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
        title: title,
        icon: 'warning',
        showCancelButton: true,
        focusConfirm: false,
        focusCancel: false,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        showLoaderOnConfirm: showLoaderOnConfirm,
        customClass: {
            title: isEnglish() ? 'swal2-title-en' : 'swal2-title-ar',
            actions: isEnglish() ? 'swal2-actions-panel-en' : 'swal2-actions-panel-ar',
            cancelButton: isEnglish() ? 'swal2-cancel-button swal2-cancel-en' : 'swal2-cancel-button swal2-cancel-ar',
            confirmButton: isEnglish() ? 'swal2-confirm-en' : 'swal2-confirm-ar',
            denyButton: isEnglish() ? 'swal2-deny-en' : 'swal2-deny-ar',
        },
        preConfirm: () => {
            if (api) {
                return api();
            }
        },
    })
}
export const deleteUrlParam = (key) => {
    new URLSearchParams(window.location.search).delete(key);
    window.history.replaceState({}, "", decodeURIComponent(`${window.location.pathname}`));
}
export const isDialogHasBeenOpening = () => {
    let modals = Array.from(document.getElementsByClassName('modal'));
    let modalOpen = false;
    if (modals.length > 0) {
        modals.map(dialog => {
            if (dialog.classList.contains('show')) {
                modalOpen = true;
                return;
            }
        })
    }
    return modalOpen;
}

export const getColorList = () => {
    return [
        DASHBOARD_COLOR_PALETTES_1, DASHBOARD_COLOR_PALETTES_2,
        DASHBOARD_COLOR_PALETTES_3, DASHBOARD_COLOR_PALETTES_4,
        DASHBOARD_COLOR_PALETTES_5, DASHBOARD_COLOR_PALETTES_6,
        DASHBOARD_COLOR_PALETTES_7, DASHBOARD_COLOR_PALETTES_8
    ]
}

export const getDashboardColor = (index) => {
    index = parseNumber(index);
    switch (index) {
        case 1:
            return DASHBOARD_COLOR_PALETTES_1;
        case 2:
            return DASHBOARD_COLOR_PALETTES_2;
        case 3:
            return DASHBOARD_COLOR_PALETTES_3;
        case 4:
            return DASHBOARD_COLOR_PALETTES_4;
        case 5:
            return DASHBOARD_COLOR_PALETTES_5;
        case 6:
            return DASHBOARD_COLOR_PALETTES_6;
        case 7:
            return DASHBOARD_COLOR_PALETTES_7;
        case 8:
            return DASHBOARD_COLOR_PALETTES_8;
        default:
            return DASHBOARD_COLOR_PALETTES_6;
    }
}

export const showWaiting = (actionBtnId, showDisabledLayer, withoutProgress) => {
    if (document.getElementById("disabledLayer")) {
        document.getElementById("disabledLayer").style.display = showDisabledLayer ? "flex" : "none";
    }
    if (!withoutProgress) {
        document.body.style.cursor = "progress";
    }

    const element = document.getElementById(actionBtnId);
    if (element) {
        element.style.display = "flex";
        element.parentElement.classList.add('hidden')
        element.parentElement.parentElement.classList.add('active')
    }
}

export const showChartWaiting = (actionBtnId, showDisabledLayer) => {
    showWaiting(actionBtnId + "-chartcard-whaiting-id", showDisabledLayer)
}

export const hideWaiting = (actionBtnId) => {
    if (document.getElementById("disabledLayer")) {
        document.getElementById("disabledLayer").style.display = "none";
    }
    // document.getElementsByClassName('lineProgress')[0].classList.add('finish');
    document.body.style.cursor = "default";
    setTimeout(function () {
        // document.getElementById('lineProgressLoad').classList.remove('load')
        // document.getElementById('lineProgressLoad').classList.remove('finish');
    }, 700);

    const element = document.getElementById(actionBtnId);
    if (element) {
        element.style.display = "none";
        element.parentElement.parentElement.classList.remove('active')
        element.parentElement.classList.remove('hidden')
    }
}

export const hideChartWaiting = (actionBtnId) => {
    hideWaiting(actionBtnId + "-chartcard-whaiting-id");
}

export const calculateCount = (list, includedIds) => {
    let filteredList;

    if (includedIds.length > 0) {
        filteredList = (list || [])?.filter(element => includedIds.includes(element.id))
    } else {
        filteredList = list || [];
    }

    return filteredList?.map(element => element.count).reduce((previousValue, currentValue) => parseNumber(previousValue) + parseNumber(currentValue), 0);
}

export const handleScrollLeftShadow = event => {
    const eleId = event.currentTarget.id;
    if (event.currentTarget.scrollleft > 0) {
        if (!document.getElementById(`${eleId}-shadow`)) {
            const temp = document.createElement('div');
            temp.id = `${eleId}-shadow`;
            temp.classList.add('scrollShadow')
            const parent = document.getElementById(eleId);
            parent.prepend(temp)
        }
    } else if (document.getElementById(`${eleId}-shadow`)) {
        document.getElementById(eleId).removeChild(document.getElementById(`${eleId}-shadow`));
    }
};

export const isAllowedForm = (user, formId) => {
    return user?.allowedForms?.includes(`${formId}`);
}

export const getPaginationObject = (list) => {
    return {
        list: list,
        totalItems: "0",
        totalPages: "0",
        currentPage: "0"
    }
}

export const fixImagePath = (path) => {
    return path ? path.replaceAll('/', '$').replaceAll('\\', '$').replaceAll('[', '<').replaceAll(']', '>').replaceAll(';', '|') : null;
}

export const isOrgCurrency = (user, currency) => user.fcuId == currency;

export const _openSmMenu = () => {
    document.getElementById("new-menu").classList.toggle('activeMenu');
    document.getElementById("blackScreenSm").classList.toggle('openBlackScreen');
}


export const showActionWaiting = (actionBtnId, showDisabledLayer) => {
    if (document.getElementById("disabledLayer")) {
        document.getElementById("disabledLayer").style.display = showDisabledLayer ? "flex" : "none";
    }
    document.getElementsByClassName('lineProgress')[0].classList.add('load')
    document.body.style.cursor = "progress";
    if (actionBtnId) {
        document.getElementById(actionBtnId).style.display = "flex";
        document.getElementById(actionBtnId).parentElement.classList.add('hidden')
        document.getElementById(actionBtnId).parentElement.parentElement.classList.add('active')
    }
}
export const hideActionWaiting = (actionBtnId) => {
    document.getElementById("disabledLayer").style.display = "none";
    document.getElementsByClassName('lineProgress')[0].classList.add('finish');
    document.body.style.cursor = "default";
    setTimeout(function () {
        document.getElementById('lineProgressLoad').classList.remove('load')
        document.getElementById('lineProgressLoad').classList.remove('finish');
    }, 700);
    if (actionBtnId) {
        document.getElementById(actionBtnId).style.display = "none";
        document.getElementById(actionBtnId).parentElement.parentElement.classList.remove('active')
        document.getElementById(actionBtnId).parentElement.classList.remove('hidden')
    }

}

export const formatHijriDate = (hijriDate) => {
    if (hijriDate) {
        const date = new Date(parseInt(hijriDate));
        return (`${date.getMilliseconds()}`.length == 1 ? `0${date.getMilliseconds()}` : date.getMilliseconds()) + "/" +
            ((`${date.getMonth() + 1}`).length == 1 ? `0${date.getMonth() + 1}` : date.getMonth() + 1) + "/" +
            date.getFullYear();
    }
    return '';
}

export const resize_width = (quality) => {
    switch (quality) {
        case 20:
            return 768;
            break;
        case 40:
            return 1024;
            break;
        case 60:
            return 1632;
            break;
        case 80:
            return 2048;
            break;
        default:
            return 1632;
    }
}
export const resize_height = (quality) => {
    switch (quality) {
        case 20:
            return 576;
            break;
        case 40:
            return 768;
            break;
        case 60:
            return 1224;
            break;
        case 80:
            return 1536;
            break;
        default:
            return 1224;
    }
}

export const isObjectsEqual = (obj1, obj2) => {
    const objKeys1 = Object.keys(obj1);
    const objValues1 = Object.values(obj1);
    const objKeys2 = Object.keys(obj2);
    const objValues2 = Object.values(obj2);
    for (let i = 0; i < objKeys1.length; i++) {
        const val = objValues1[i]
        // if (objKeys2.includes(objKeys1[i]) && !Array.isArray(val) && val != objValues2[objKeys2[objKeys2.indexOf(objKeys1[i])]]) {
        //     alert("here")
        // }
        // if (objKeys1.length != objKeys2.length || !objKeys2.includes(objKeys1[i])) {
        //     alert("here")
        // }
        if ((objKeys2.includes(objKeys1[i]) && val != objValues2[i]) || (!objKeys2.includes(objKeys1[i]) && (val && (Array.isArray(val) && val.length > 0 || !Array.isArray(val))))) {
            return false
        }

    }
    return true;
}

export const printPDFDocument = (response, t) => {
    if (response.size > 0) {
        const file = new Blob([response], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        var reportFrame = document.getElementById('printframe');
        if (reportFrame == null) {
            reportFrame = document.createElement("iframe");
            reportFrame.id = 'printframe';
            reportFrame.style.visibility = "hidden";
            reportFrame.style.display = 'none';
            document.body.appendChild(reportFrame);
            reportFrame.onload = function () {
                var PDF = document.getElementById('printframe')
                PDF.focus();
                PDF.contentWindow.print();
            };
        }
        reportFrame.src = fileURL;
    } else {
        handleError(null, 'cannot_be_print_the_report', t)
    }
}

export const downloadFile = (response, fileName) => {
    const link = document.createElement('a');
    let url = URL.createObjectURL(new Blob([response]))
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
}

export const handleNumbersKeyDown = (e) => {
    const invalidChars = ["-", "e"];

    if (invalidChars.includes(e.key)) {
        e.preventDefault();
    }
}

export const splitArrayToChunks = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        result.push(chunk);
    }
    return result;
}

export const roundByDigits = (val, digits) => {
    // val = parseNumber(val);
    // digits = parseInt(digits);
    return Math.floor((val + (5.0 / Math.pow(10, (digits + 1)))) * Math.pow(10, digits), 1) / Math.pow(10, digits);
}

export const isLocalCurrency = (fcuId) => {
    const userFcuId = getUser()?.fcuId;
    return isNotEmpty(fcuId) && isNotEmpty(userFcuId) && userFcuId == fcuId;
}


export const getDynamicTableColumns = (columns) => {
    let totalHidden = 0;
    let total = 0;
    const newColumns = [];
    columns.forEach((column) => newColumns.push({ ...column }))
    newColumns.forEach((column) => {
        if (column.hidden) {
            totalHidden += Number(column.width.replace('%', ''));
            column.width = '0%';
        }
    })
    newColumns.forEach((column) => {
        if (!column.hidden) {
            const columnWidth = Number(column.width.replace('%', ''));
            const newColumnWidth = parseFloat(columnWidth + ((totalHidden * columnWidth) / (100 - totalHidden))).toFixed(5);
            column.width = `${newColumnWidth}%`;
            total += Number(newColumnWidth);
        }
    });
    const lastColumn = newColumns[newColumns.length - 1];
    const lastColumnWidth = Number(lastColumn.width.replace('%', ''));
    lastColumn.width = `${lastColumnWidth + (100 - total)}%`;
    return newColumns;
}

export const getDynamicColumnsTableWidth = (columns) => {
    const defaultTableWidth = 600;
    let totalHidden = 0;
    columns.forEach((column) => {
        if (column.hidden) {
            totalHidden += Number(column.width.replace('%', ''));
        }
    })
    let newTableWidth = defaultTableWidth - ((defaultTableWidth * totalHidden) / 100);
    return newTableWidth < 100 ? 100 : newTableWidth;
}

export const downloadXml = (response) =>{
    const blob = new Blob([response.data], { type: 'application/xml' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', `${new Date().getTime()}.xml`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}