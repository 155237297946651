import { checkValidation, handleError, hideWaiting, isAllowedAction, removeAllMandatoryClasses, showSuccessMessage, showWaiting } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalWithTabs from "../../../components/modal-with-tabs";
import WorkflowProcedureMessagesIcon from "../../../icons/WorkflowProcedureMessages";
import { getActionMessageTask, getOrgnizationById, saveActionMessageTask } from "../../../util/apis";
import { SAVE_ACTION } from "../../../util/constants/actions";
import { RSM3061_WORKFLOW_PROCEDURE_AND_MESSAGES_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";

const dialogId = "workflow-procedure-messages-dialog";

const WorkflowProcedureMessagesDialog = (props, ref) => {
    const { tableRef } = props

    const { t } = useTranslation();
    const dialogRef = useRef();
    const user = getUser();
    const updateRecordRef = useRef(false);

    const selectedRow = useRef(null);
    const [data, setData] = useState({ actionMessageTaskDetails: [] });

    const _saveAction = () => {
        if (!checkValidation([{ id: dialogId },
        {
            id: `${dialogId}-messages-tasks`,
            title: t('workflowProcedureMessages:messages_tasks'),
            data: data.actionMessageTaskDetails,
            disabledConditions: {
                outputStatusOaeId: (data) => !data?.changeStatus,
                actionDdvId: (data) => !data?.relatedToAction,
            }
        }], t)) {
            return
        }

        showWaiting(`${dialogId}-okButton`, true)
        saveActionMessageTask(data)
            .then(() => {
                dialogRef.current.changeSelectedTab(1);
                showSuccessMessage('save_successfully', t);
                if (!updateRecordRef.current) {
                    _populateRecordData(null);
                }
                tableRef?.current?.refresh();
            })
            .catch((error) => {
                let message = error?.response?.data?.message;
                if (message.includes('#')) {
                    message = message.split('#')[0]
                }
                handleError(error, message, t)
            })
            .finally(() => hideWaiting(`${dialogId}-okButton`, true))
    }

    const _populateRecordData = (id,isCopy) => {
        if (id) {
            updateRecordRef.current = true;
            getActionMessageTask(id)
                .then((response) => {
                    if(isCopy){
                        response.data.id = null;
                        for (let i = 0; i < response.data.actionMessageTaskDetails.length; i++) {
                            response.data.actionMessageTaskDetails[i].id = null;
                            response.data.actionMessageTaskDetails[i].kmsId = null;
                        }
                    }
                    getOrgnizationById(response.data.fogId)
                        .then(res => {
                            response.data.orgDescFo = res.data.offecialNameFo;
                            response.data.orgDescLo = res.data.offecialNameLo;
                            response.data.key = new Date().getTime();
                            setData(response.data);
                        })
                })
                .catch((error) => handleError(error, null, t))
        } else {
            const defultData = {
                orgDescFo: user.orgNameFo,
                orgDescLo: user.orgNameLo,
                key: new Date().getTime()
            };
            setData({ ...defultData });
        }
    }

    const _preCloseAction = () => {
        selectedRow.current=null;
        updateRecordRef.current = false;
    }

    const _postClose = () => {
        setData({ actionMessageTaskDetails: [] })
        dialogRef.current.changeSelectedTab(1);
        removeAllMandatoryClasses(document.getElementById(dialogId))
    }

    const open = (id, isCopy) => {
        dialogRef.current.open();
        _populateRecordData(id, isCopy);
    }

    const close = () => dialogRef.current.close();

    useImperativeHandle(ref, () => ({ open, close }));

    return (
        <ModalWithTabs
            id={dialogId}
            ref={dialogRef}
            formId={RSM3061_WORKFLOW_PROCEDURE_AND_MESSAGES_FORM_ID}
            title={t('workflowProcedureMessages:workflow_procedure_and_messages')}
            Icon={<WorkflowProcedureMessagesIcon />}
            okBtnAction={_saveAction}
            preClose={_preCloseAction}
            postClose={_postClose}
            hideOkBtn={!(isAllowedAction(user, RSM3061_WORKFLOW_PROCEDURE_AND_MESSAGES_FORM_ID, SAVE_ACTION))}
            nextPreviousAction={_populateRecordData}
            labels={['home', 'workflowProcedureMessages:messages_tasks']}
        >
            <Tab1 data={data} setData={setData} />
            <Tab2 data={data} setData={setData} user={user} selectedRow={selectedRow} />
        </ModalWithTabs>
    )
}

export default forwardRef(WorkflowProcedureMessagesDialog);