export const DASHBOARDS = "DASHBOARDS";
export const SETTINGS = "SETTINGS";
export const ADMINISTRATOR = "ADMINISTRATOR";
export const OPERATIONS_MANAG = "OPERATIONS_MANAG";
export const FINANCIAL = "FINANCIAL";
export const REPORTS = "REPORTS";
export const CORRESPONDENCE = "CORRESPONDENCE";
export const TRANSACTIONS = "TRANSACTIONS";
export const DEFINITIONS = "DEFINITIONS";

export const STATUS_DOMAIN = 1000;
export const STATUS_ACTIVE = 100001;
export const STATUS_IN_ACTIVE = 100002;

export const STATUS_ORGANIZATION_ESTIMATION_FEES = 122608;
export const STATUS_ESTIMATOR_FEES = 122609;

export const TASK_SUBJECT_TYPE_DOMAIN = "1050";
export const TASK_SUBJECT_TYPE_DOMAIN_TEXT = "105001";
export const TASK_SUBJECT_TYPE_DOMAIN_FIELD = "105002";
export const TASK_SUBJECT_TYPE_DOMAIN_TAB = "105003";
export const TASK_SUBJECT_TYPE_DOMAIN_SPACE = "105004";
export const TASK_SUBJECT_TYPE_DOMAIN_NEW_LINE = "105005";

export const PROPERTIES_VALUATION_CUSTOM_REPORTS_SOURCE_FORM = "3188"
export const CUSTOM_FINANCIAL_REPORTS = "3180"

export const ESTIMATION_STATUS_COLOURS = "1307";
export const SCHEDULING_UNIT_DOMAIN = "1920";
export const ATTACHMENTS_DOMAIN = "8082";
export const PRICE_LIST_PARTIES_DOMAIN = "1283";
export const INVOICE_CREATION_DOMAIN = "1284";
export const INVOICE_CREATION_END_MONTH = "128401";
export const SIGN_DOMAIN = "2327";
export const LOCATION_LEVEL_DOMAIN = "1285";
export const AREA_LEVEL_DOMAIN = "8071";
export const AREA_LEVEL_LAND_AREA = "807101";

export const CUSTOMER_LEVEL_DOMAIN = "8060";
export const CUSTOMER_LEVEL_ALL = "806001";
export const CUSTOMER_LEVEL_SPECIFIC = "806002";
export const CUSTOMER_LEVEL_NONE = "806003";

export const DATE_LEVEL_DOMAIN = "8073";
export const DATE_LEVEL_DOMAIN_TRANSACTION_DATE = "807301";
export const DATE_LEVEL_DOMAIN_APPROVAL_DATE = "807302";
export const DATE_LEVEL_DOMAIN_ALL = "807303";

export const AGREEMENT_LEVEL_DOMAIN = "8070";
export const AGREEMENT_LEVEL_DOMAIN_CUSTOM_PROPERTY_TYPE = "807001";
export const AGREEMENT_LEVEL_DOMAIN_CUSTOM = "807002";
export const AGREEMENT_LEVEL_DOMAIN_PERCENTAGE_FROM_ESTIMATION_VALUE = "807003";
export const AGREEMENT_LEVEL_DOMAIN_PERCENTAGE_FROM_REVENUES = "807004";

export const SUPPLIER_PARCHASE_EXEMP = "1264";
export const SUPPLIER_PARCHASE_EXEMP_NOT_EXEMPTED = "126401";
export const SUPPLIER_PARCHASE_EXEMP_EXEMPTED = "126402";
export const SUPPLIER_PARCHASE_EXEMP_TOTALLY_EXEMPTED = "126403";

export const CUSTOMER_STATUS_ACTIVE = 215401
export const CUSTOMER_STATUS_IN_ACTIVE = 215402

export const DOMAIN_REPORT_NAME = 8083
export const DOMAIN_EXECUTED_NOT_EXECUTED = 1310
export const DOMAIN_ALERT_TYPE = 1300
export const DOMAIN_USAGE = 1018;
export const DOMAIN_DOCUMENTS_TYPES = 1305;
export const DOMAIN_CUSTOMER_ACCOUNT_TYPE = 5010;
export const DOMAIN_CUSTOMER_ACCOUNT_TYPE_MAIN = 501001;
export const DOMAIN_INVOICE_LEVEL = 1308;
export const DOMAIN_AFFECTED_ACCOUNT = 1309;

export const INVOICE_LEVEL_ALL_PARTIES = "130801";
export const INVOICE_LEVEL_SPECIFIC_PARTIES = "130802";

export const AFFECTED_ACCOUNT_PARTIES_ACCOUNTS = "130901";
export const AFFECTED_ACCOUNT_OTHER_ACCOUNT = "130902";

export const DOMAIN_PARTY_TYPE = 3004;

export const REPORT_EMPLOYEES_INSPECTORS = "808301";
export const REPORT_EMPLOYEES_INSPECTORS_ACCORDING_REGION_CITY = "808302";
export const REPORT_CUSTOMERS = "808303";
export const REPORT_CUSTOMERS_ACCORDING_REGION_CITY = "808304";
export const REPORT_REGIONS_ACCORDING_PROPERTYS_TYPE = "808305";
export const REPORT_INSPECTORS_EMPLOYEES_ACCORDING_TASK = "808306";
export const REPORT_EMPLOYEES_INSPECTORS_REPORT_ACCORDING_CUSTOMERS = "808307";
export const REPORT_EMPLOYEES_INSPECTORS_EXECUTION_PERCENTAGE = "808308";
export const DOMAIN_PROPORTIES_PARTIES_TYPE = 1167;

export const PROPORTIES_PARTIES_TYPE_OWNER = "116701";
export const PROPORTIES_PARTIES_TYPE_AGENT = "116702";
export const PROPORTIES_PARTIES_TYPE_INTERMEDIATE = "116703";
export const PROPORTIES_PARTIES_TYPE_INSPECTOR = "116704";
export const PROPORTIES_PARTIES_TYPE_ESTIMATOR = "116705";
export const PROPORTIES_PARTIES_TYPE_EMPLOYEE_COORDINATOR = "116706";
export const PROPORTIES_PARTIES_TYPE_INVESTOR = "116707";

export const CUSTOMER_LEVEL = "1175";
export const CUSTOMER_LEVEL_NEW = "117501";
export const CUSTOMER_LEVEL_PREDEFINED = "117502";

export const DOMAIN_ESTIMATIONS_QUOTATION_STATUS = 1311;
export const DOMAIN_ESTIMATIONS_QUOTATION_VALIDITY = 3012;
export const DOMAIN_GLOBAL_STATUS = 2259;
export const DOMAIN_SOURCE = 3014;
export const SOURCE_CUSTOMER_INVOICE = 301401;
export const SOURCE_INSPECTION_INVOICE = 301402;

export const DOMAIN_PAYING_STATUS_SOURCES = "2224";
export const DOMAIN_FLOORS = 9040;
export const DOMAIN_USAGE_TRANSACTION = 9041;

export const GLOBAL_STATUS_INCOMPLETE = 225901
export const GLOBAL_STATUS_PREPARED = 225902
export const GLOBAL_STATUS_SUBMITTED = 225903
export const GLOBAL_STATUS_APPROVED = 225904
export const GLOBAL_STATUS_REJECTED = 225905
export const GLOBAL_STATUS_CONFIRMED = 225906
export const GLOBAL_STATUS_CANCELLED = 225907
export const GLOBAL_STATUS_CLOSED = 225908
export const GLOBAL_STATUS_SUSPEND = 225909
export const GLOBAL_STATUS_POSTED = 225910

export const STATUS_NEW = "131101";
export const STATUS_COFIRMED = "131102";
export const STATUS_REJECTED_FROM_CUSTOMER = "131103";
export const STATUS_APPROVED_FROM_CUSTOMER = "131105";

export const ACTION_COLOR_GREEN = "var(--secondary-2)";
export const KEY_ENTER = 13;
export const KEY_ESC = 27;
export const KEY_F3 = 114;
export const KEY_F4 = 115;
export const KEY_F5 = 116;
export const KEY_F6 = 117;
export const KEY_F7 = 118;
export const KEY_F8 = 119;
export const KEY_F9 = 120;
export const KEY_F10 = 121;
export const KEY_F11 = 122;
export const KEY_F12 = 123;

export const COLOR_PALETTES_1 = ['#1C4E80', '#A5D8DD', '#EA6A47', '#0091D5', '#7E909A'];
export const COLOR_PALETTES_2 = ['#AC3E31', '#484848', '#DBAE58', '#20283E', '#488A99'];
export const COLOR_PALETTES_3 = ['#FBC6A4', '#F4A9A8', '#CE97B0', '#AFB9C8', '#488A99'];
export const COLOR_PALETTES_4 = ['#E7F2F8', '#74BDCB', '#FFA384', '#EFE7BC', '##3D5B59'];
export const COLOR_PALETTES_5 = ['#FA7070', '#FBF2CF', '#C6EBC5', '#A1C298', '#DBAE58'];
export const COLOR_PALETTES_6 = ['#05445E', '#189AB4', '#75E6DA', '#D4F1F4', '#0C2D48'];

export const DASHBOARD_COLOR_PALETTES_1 = ['#CCD5AE', '#DBE1BC', '#E9EDC9', '#F4F4D5', '#FEFAE0', '#FCF4D7', '#FAEDCD', '#E7C8A0', '#DEB68A', '#D4A373'];
export const DASHBOARD_COLOR_PALETTES_2 = ['#EA698B', '#D55D92', '#C05299', '#AC46A1', '#973AA8', '#822FAF', '#6D23B6', '#6411AD', '#571089', '#47126B'];
export const DASHBOARD_COLOR_PALETTES_3 = ['#EDDCD2', '#FFF1E6', '#FDE2E4', '#FAD2E1', '#C5DEDD', '#DBE7E4', '#F0EFEB', '#D6E2E9', '#BCD4E6', '#99C1DE'];
export const DASHBOARD_COLOR_PALETTES_4 = ['#FADDE1', '#FFC4D6', '#FFA6C1', '#FF87AB', '#FF5D8F', '#FF97B7', '#FFACC5', '#FFCAD4', '#F4ACB7', '#FFA6C1'];
export const DASHBOARD_COLOR_PALETTES_5 = ['#F72585', '#B5179E', '#7209B7', '#560BAD', '#480CA8', '#3A0CA3', '#3F37C9', '#4361EE', '#4895EF', '#4CC9F0'];
export const DASHBOARD_COLOR_PALETTES_6 = ['#590D22', '#800F2F', '#A4133C', '#C9184A', '#FF4D6D', '#FF758F', '#FF8FA3', '#FFB3C1', '#FFCCD5', '#FFF0F3'];
export const DASHBOARD_COLOR_PALETTES_7 = ['#F0502B', '#033C5A', '#31B1B6', '#5781C1', '#F9A869', '#F16271', '#2B9697', '#95CA4B', '#F0502B', '#D4A373'];
export const DASHBOARD_COLOR_PALETTES_8 = ['#797D62', '#9B9B7A', '#BAA587', '#D9AE94', '#F1DCA7', '#FFCB69', '#E8AC65', '#D08C60', '#B58463', '#997B66'];

export const ALERT_TYPE_ALERT = 130001
export const ALERT_TYPE_REMINDER = 130002
export const ALERT_TYPE_INFORMATION = 130003
export const ALERT_TYPE_FOLLOW_UP = 130004
export const ALERT_TYPE_UPDATE_DATA = 130005

export const DOMAIN_PROGRESS_STATUS = 1928
export const DOMAIN_MESSAGE_STATUS = 1304
export const DOMAIN_WORKFLOW_PRIORITIES = 1034
export const DOMAIN_UPDATED_FIELDS = 1927;

export const UPDATED_FIELDS_MANDATORY_FIELDS = 192701;
export const UPDATED_FIELDS_ALL_FIELDS = 192702;
export const UPDATED_FIELDS_SPECIFIC_FIELDS = 192703;

export const INTERVAL_PERIOD = "192002"

export const WORKFLOW_PRIORITIES_LOW = 103401;
export const WORKFLOW_PRIORITIES_NORMAL = 103402;
export const WORKFLOW_PRIORITIES_HIGH = 103403;
export const WORKFLOW_PRIORITIES_CRITICAL = 103404;

export const DOMAIN_PROGRESS_STATUS_NOT_STARTED = 192801;
export const MSG_RECEIVER_TYPE_COORDINATOR = -1;
export const MSG_RECEIVER_TYPE_INSPECTOR = -2;
export const MSG_RECEIVER_TYPE_CUSTOMER = -3;
export const MSG_RECEIVER_TYPE_ESTIMATOR = -4;
export const MSG_RECEIVER_TYPE_REVIEWED_BY = -5;

export const ACTION_FORM_ACTIONS = 'FORM_ACTIONS';
export const ACTION_LISTING_ACTIONS = "ACTION_LISTING_ACTIONS"
export const ACTION_NEW = "ACTION_NEW"
export const ACTION_EDIT = "ACTION_EDIT"
export const ACTION_DELETE = "ACTION_DELETE"


export const TRX_ACTION_DELETE = "902008";
export const TRX_ACTION_AND = "902002";

export const ACTION_CREATE = 117101;
export const ACTION_VIEW = 117102;
export const ACTION_LOG = 117107;
export const ACTION_EDIT_TRNX = 117108;
export const ACTION_CANCEL = 117111;
export const ACTION_INCOMPLETE = 117172;
export const ACTION_SAVE = 117174;
export const ACTION_APPROVE = 117115;
export const ACTION_REJECT = 117116;
export const ACTION_HANG_UP = 117118;
export const ACTION_SEND_FOR_APPROVAL = 117161;
export const ACTION_SEND_FOR_REVISION = 117162;
export const ACTION_VIEW_ESTIMATION_FORM = 117163;
export const ACTION_VIEW_REVISION_FORM = 117164;
export const ACTION_VIEW_ESTIMATION_SUMMARY = 117165;
export const ACTION_SEND_TO_ESTIMATOR = 117176;
export const ACTION_DELETE_PERMANENTLY = 117175;
export const ACTION_WORKFLOW_STATUS = 117179;
export const ACTION_ESTIMATION = 117180;
export const ACTION_VIEW_INSPECTION_FORM = 117181;
export const ACTION_REVIEW = 117182;
export const ACTION_PRINT_PDF = 117183;
export const ACTION_PRINT_WORD = 117184;
export const ACTION_SEND_MESSAGE = 117185;
export const ACTION_RECOVERY = 117186;
export const ACTION_RESUME = 117187;
export const ACTION_ESTIMATION_QUALITY = 117188;
export const ACTION_SEND_BACK_TO_ESTIMATOR = 117195;
export const ACTION_SEND_BACK_TO_COORDINATOR = 117196;
export const ACTION_SEND_BACK_TO_APPROVAL = 117197;
export const ACTION_DELETE_COMMENT = 117168;
export const ACTION_COMMENT_RECOVERY = 117169;
export const ACTION_COPY = 117159;
export const ACTION_COMMENT = 117151;
export const ACTION_DIVERT_TO_OTHER_INSPECTOR = 117189;
export const ACTION_PRINT_ALL = 117190;
export const ACTION_EXPORT_TRNX_TO_EXCEL = 117170;
export const ACTION_CHANGE_PRIORITY = 117171;
export const ACTION_DIVERT_TO_OTHER_ESTIMATOR = 117173;
export const ACTION_ADD_QR_CODE = 901506;
export const ACTION_EXECUTE = 0;

export const TRX_MODE_ALL_MODES = 0;
export const TRX_MODE_ENTRY = 1;
export const TRX_MODE_VIEW_ENTRY = 2;
export const TRX_MODE_INSPEC_N_ESTIMATION = 3;
export const TRX_MODE_VIEW_INSPEC_N_ESTIMATION = 4;
export const TRX_MODE_APPROVAL = 5;
export const TRX_MODE_VIEW_APPROVAL = 6;
export const TRX_MODE_REVISION = 7;
export const TRX_MODE_VIEW_REVISION = 8;
export const TRX_MODE_TASK = 9;
export const REV_MODE_VIEW = 10;
export const REV_MODE_NOTE_ADD = 11;
export const REV_MODE_NOTE_VIEW = 12;
export const REV_MODE_LOG = 13;

export const TRX_DISPLAY_MODE_1 = 1;
export const TRX_DISPLAY_MODE_2 = 2;

export const CUSTOMER_FORM_LARGE_LOGO = "CUSTOMER-LARGE-LOGO";
export const CUSTOMER_FORM_SMALL_LOGO = "CUSTOMER-SMALL-LOGO";
export const ESTIMATION_PRINT_OUT = "ESTIMATION-PRINT-OUT";
export const USER_PROFILE = "USER-PROFILE";
// export const ESTIMATION_ATTACHMENTS = "ESTIMATION-ATTACHMENTS";
export const ESTIMATION_HISTORY = "ESTIMATION-HISTORY";
export const ESTIMATION_TRANSACTION_ATTACHMENTS = "ESTIMATION-TRANSACTION-ATTACHMENTS";
export const ESTIMATION_TRANSACTION_QRCODE = "ESTIMATION-TRANSACTION-QRCODE";

export const IMAGE_TYPE_VALUATION_CONTRACT = "VALUATION_CONTRACT";
export const IMAGE_TYPE_ESTIMATION_QUOTATION = "ESTIMATION_QUOTATION"
export const ESTIMATION_DOMAINS_STAGES = 1064;
export const ESTIMATION_DOMAINS_TRANSACTION_TYPE = 1048;

export const LIMIT_LAT = 85;
export const LIMIT_LNG = 180;

export const DATA_TYPE_TEXT = "903901";
export const DATA_TYPE_NUMBER = "903902";
export const DATA_TYPE_DATE = "903903";
export const DATA_TYPE_DATE_TIME = "903904";
export const DATA_TYPE_HIJRI_DATE = "903905";
export const DATA_TYPE_TIME = "903906";
export const DATA_TYPE_BOOLEAN = "903907";
export const DATA_TYPE_YES_NO = "903908";
export const DATA_TYPE_LOV = "903909";
export const DATA_TYPE_MULTI_LOV = "903910";
export const DATA_TYPE_MULTI_LINES_TEXT = "903911";
export const DATA_TYPE_TEXT_ALIGN_CENTER = "903912";

export const USERS_TYPES_MANAGER = 117401;
export const USERS_TYPES_SUPERVISOR = 117402;
export const USERS_TYPES_NORMAL_EMPLOYEE = 117403;
export const USERS_TYPES_CUSTOMER = 117406;
export const USERS_TYPES_INSPECTOR = 117407;
export const USERS_TYPES_ESTIMATER = 117408;
export const USERS_TYPES_OWNER = "117405";
export const USERS_TYPES_INVESTOR = "117409";

export const POLICY_LVL_DOMAIN = 1173
export const POLICY_LVL_GENERAL = 117301;
export const POLICY_LVL_SELF_SERVICE = 117302;

export const LOCATION_LEVEL_COUNTRIES = 128501
export const LOCATION_LEVEL_REGIONS = 128502
export const LOCATION_LEVEL_CITIES = 128503
export const LOCATION_LEVEL_TOWNS = 128504

export const PROPERTY_TYPE_GENERAL = 806901
export const PROPERTY_TYPE_LAND = 806902
export const PROPERTY_TYPE_FLAT = 806903

export const LICENSE_WARNING_DAYS = 3;

export const FORM_CUSTOMER_ID = "3009";
export const FORM_ESTIMATION_TRANSACTIONS_REGISTER_ERP_ID = "3005";
export const PROPERTY_TYPE_FORM_ID = "2659";

export const ERP_FORM_ID_ESTIMATION_QUOTATIONS = "3190";
export const ERP_FORM_ID_CUSTOMERS_ESTIMATION_INVOICES = "3015";
export const ERP_FORM_ID_ESTIMATORS_AND_INSPECTORS_INVOICES = "3017";
export const ERP_FORM_ID_PROPERTIES_MANAGEMENT_EVALUATION_REPORTS = "3188";

export const PROPERTIES_POLICIES_FORM_ID = 2685;

export const ESTIMATION_STATUS_DRAFT = 285401;
export const ESTIMATION_STATUS_INCOMPLETE_TRANSACTION = 285402;
export const ESTIMATION_STATUS_UNDER_ESTIMATION_PHASE = 285406;
export const ESTIMATION_STATUS_REJECTED = 285407;
export const ESTIMATION_STATUS_REVIEWED = 285408;
export const ESTIMATION_STATUS_PRINTED = 285409;
export const ESTIMATION_STATUS_CANCELLED = 285410;
export const ESTIMATION_STATUS_HANGED_UP = 285411;

export const USER_STATUS_DOMAIN = "1011";
export const USER_STATUS_TERMINATED = "101103";
export const USER_STATUS_SUSPENDED = "101102";
export const USER_STATUS_ACTIVE = "101101";

export const USER_DEFINITION_PROFILE_IMAGE = "USER_DEFINITION_PROFILE_IMAGE";
export const USERS_CURRENT_STATUS_ON_DUTY_DOMAIN = "101001";
export const USER_DEFINITION_SIGNATURE = "USER_DEFINITION_SIGNATURE";
export const USER_DEFINITION_STAMP = "USER_DEFINITION_STAMP";
export const PROPERTIES_PARTIES_DEFINITION = "PROPERTIES_PARTIES_DEFINITION";
export const USER_CUSTOM_REPORTS = "USER CUSTOM REPORTS";
export const USERS_CURRENT_STATUS_DOMAIN = "1010";

export const GAUGE_USER_SUPPLIER_PARTY_DOMAIN = "125602";
export const SYSTEM_DIMENSIONS_SUPPLIER_DOMAIN = "300402";
export const SYSTEM_DIMENSIONS_CUSTOMER_DOMAIN = "300403";
export const SYSTEM_DIMENSIONS_PROP_PARTY_DOMAIN = "300431";
export const GAUGE_USER_SUPPLIER_EMPLOYEE_DOMAIN = "125603";
export const SYSTEM_DIMENSIONS_EMPLOYEE_DOMAIN = "300404";

export const NOTIFICATION_DOMAIN = "1214";
export const NOTIFICATION_DOMAIN_DONT_SEND = "121402";
export const PROPORTY_TYPE = "8069";

export const GAUGE_USER_SUPPLIER_DOMAIN = "1256";
export const GAUGE_USER_SUPPLIER_GAUGE_USER_DOMAIN = "125601";


export const PROPERTIES_PARTIES_DEFINITIONS_FORM_ID = "2653";
export const DOMAIN_GENDER = 7016;
export const DOMAIN_LOCATION_LEVEL = 1285;

export const LOCATION_LEVEL_COUNTRY = 128501
export const LOCATION_LEVEL_REGION = 128502
export const LOCATION_LEVEL_CITY = 128503
export const LOCATION_LEVEL_TOWN = 128504

export const PARTY_LEVEL_CUSTOMER = 128301;

export const USER_TYPE_DOMAIN = "1174";
export const PROPORTIES_USERS_TYPES_SUPERVISOR = "117402";

export const PROPORTIES_PARTIES_TYPE = "1167";
export const FINANCIAL_INTEGRATION_ACCOUNT = "8008";
export const PROPERTIES_ACCOUNT_TYPES = "5050"

export const PROPERTIES_USERS_PROPERTIES_STRUCTURE_POLICY_FORM_ID = "2687";
export const PROPERTIES_USERS_OWNERS_CONTRACTS_MANAGEMENT_POLICY_FORM_ID = "2897";
export const PROPERTIES_USERS_DEFINITION_REQUISITION_POLICY_FORM_ID = "2681";
export const PROPERTIES_USERS_DEFINITION_NEW_APPLICATION_POLICY_FORM_ID = "2707";
export const PROPERTIES_USERS_DEFINITION_BOOKING_POLICY_FORM_ID = "2721";
export const PROPERTIES_USERS_OWNERS_QUOTATIONS_POLICY_FORM_ID = "2873";
export const PROPERTIES_USERS_OWNERS_CONTRACT_POLICY_FORM_ID = "2711";
export const PROPERTIES_USERS_OWNERS_INVOICES_POLICY_FORM_ID = "2929";
export const PROPERTIES_USERS_CUSTOMER_QUOTATIONS_POLICY_FORM_ID = "2689";
export const PROPERTIES_USERS_TENANTS_CONTRACT_POLICY_FORM_ID = "2879";
export const PROPERTIES_USERS_TENANTS_CONTRACTS_MANAGEMENT_POLICY_FORM_ID = "2881";
export const PROPERTIES_USERS_TENANTS_CONTRACTS_FOLLOW_UP_POLICY_FORM_ID = "2919";
export const PROPERTIES_USERS_TENANTS_INVOICES_POLICY_FORM_ID = "2895";
export const PROPERTIES_USERS_MARKETERS_SCHEDULE_POLICY_FORM_ID = "2731";
export const PROPERTIES_USERS_ESTIMATION_POLICY_FORM_ID = "3005";
export const PROPERTIES_USERS_ESTIMATION_INVOICE_POLICY_FORM_ID = "3015";
export const PROPERTIES_USERS_ESTIMATION_QUOTATIONS_POLICY_FORM_ID = "3190";
export const PROPERTIES_PARTIES_CATEGORIES_FORM_ID = "2651";
export const ESTIMATOR_PARTY = "116705";
export const INSPECTOR_PARTY = "116704";
// **********************frm-btns-options**************
export const FORM_BTNS_OPTIONS_SEPARATED = "1";
export const FORM_BTNS_OPTIONS_WITH_CHECH = "2";
export const FORM_BTNS_OPTIONS_GROUP = "3";
// **********************frm-btns-options**************
export const DASHBOARD_NUMBER_ANIMATION_DURATION = "0.5";

export const DOMAIN_ADDITIONAL_ITEMS_SOURCES = "1226";
export const DOMAIN_ADDITIONAL_ITEMS_SOURCES_INSTALLMENT = 122601;
export const DOMAIN_ADDITIONAL_ITEMS_SOURCES_COMMISSION = 122602;
export const DOMAIN_ADDITIONAL_ITEMS_SOURCES_INSURANCE = 122603;
export const DOMAIN_ADDITIONAL_ITEMS_SOURCES_WATER = 122604;
export const DOMAIN_ADDITIONAL_ITEMS_SOURCES_ELECTRICITY = 122605;
export const DOMAIN_ADDITIONAL_ITEMS_SOURCES_SERVICES = 122606;
export const DOMAIN_ADDITIONAL_ITEMS_SOURCES_GAS_FEES = 122612;
export const DOMAIN_ADDITIONAL_ITEMS_SOURCES_PARKING_FEES = 122613;
export const DOMAIN_ADDITIONAL_ITEMS_SOURCES_ORGANIZATION_ESTIMATION_FEES = 122608;
export const DOMAIN_ADDITIONAL_ITEMS_SOURCES_OTHER = 122607;
export const DOMAIN_ADDITIONAL_ITEMS_PROP_TAX = 122611;
export const STATUS_INSPECTOR_FEES = 122610;
export const ADDITIONAL_ITEMS_SOURCES = 1226;

export const NOTIFICATION_DOMAIN_DOSE_NOT_SEND = "121402"

export const ESTIMATION_QUOTATION_DESCRIPTION_FIELD = "ISM_DESCRIPTION"
export const ESTIMATION_QUOTATION_REGION_FIELD = "ISM_FRS_ID"
export const ESTIMATION_QUOTATION_CITY_FIELD = "ISM_ECD_ID"
export const ESTIMATION_QUOTATION_TOWN_FIELD = "ISM_CRI_ID"
export const ESTIMATION_QUOTATION_PROPERTY_TYPE_FIELD = "ISM_MYO_ID"
export const ESTIMATION_QUOTATION_DEED_FIELD = "ISM_DEED_NUM"
export const ESTIMATION_QUOTATION_AREA_LEVEL_FIELD = "ISM_AREA_LEVEL"
export const ESTIMATION_QUOTATION_LAND_AREA_FROM_FIELD = "ISM_LAND_AREA_FROM"
export const ESTIMATION_QUOTATION_LAND_AREA_TO_FIELD = "ISM_LAND_AREA_TO"
export const ESTIMATION_QUOTATION_NO_OF_FLOORS_FROM_FIELD = "ISM_NO_OF_FLOORS_FROM"
export const ESTIMATION_QUOTATION_NO_OF_FLOORS_TO_FIELD = "ISM_NO_OF_FLOORS_TO"
export const ESTIMATION_QUOTATION_PRICE_FIELD = "ISM_PRICE"
export const ESTIMATION_QUOTATION_VAT_PERC_FIELD = "ISM_VAT_PERC"
export const ESTIMATION_QUOTATION_VAT_FIELD = "ISM_VAT"
export const ESTIMATION_QUOTATION_NET_PRICE_FIELD = "ISM_NET_PRICE"
export const ESTIMATION_QUOTATION_REM_FIELD = "ISM_REM"
export const ESTIMATION_QUOTATION_DEED_DGR_FIELD = "ISM_TITLE_DEED_DGR"
export const ESTIMATION_QUOTATION_DEED_DHI_FIELD = "ISM_TITLE_DEED_DHI"
export const ESTIMATION_QUOTATION_LICENCE_NUM_FIELD = "ISM_LICENCE_NUM"
export const ESTIMATION_QUOTATION_LICENCE_DATE_DGR_FIELD = "ISM_LICENCE_DATE_DGR"
export const ESTIMATION_QUOTATION_LICENCE_DATE_DHI_FIELD = "ISM_LICENCE_DATE_DHI"
export const ESTIMATION_QUOTATION_PROPERTY_DESCRIPTION_FIELD = "ISM_PROPERTY_DESCRIPTION"
export const ESTIMATION_QUOTATION_APROACH_COST_FIELD = "ISM_APROACH_COST"
export const ESTIMATION_QUOTATION_METHOD_REPLACE_COST_FIELD = "ISM_METHOD_REPLACE_COST"
export const ESTIMATION_QUOTATION_ESTIMATION_REASON_FIELD = "ISM_ESTIMATION_REASON"
export const ESTIMATION_QUOTATION_VALUE_ASSUMPTION_FIELD = "ISM_VALUE_ASSUMPTION"
export const ESTIMATION_QUOTATION_PROPERTY_USE_FIELD = "ISM_PROPERTY_USE"
export const ESTIMATION_QUOTATION_ESTIMATION_BASE_FIELD = "ISM_ESTIMATION_BASE"

export const DEFAULT_VAT_PERC = "15";

export const ZATCA_STATUS = "9060";

export const STEP_TYPE_TRIGGERING = "108601";
export const STEP_TYPE_APPROVAL = "108602";
export const STEP_TYPE_CONFIRMATION = "108603";
export const STEP_TYPE_BACK = "108604";
export const STEP_TYPE_REJECT = "108605";
export const STEP_TYPE_UN_POST = "108606";
