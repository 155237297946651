import ActionsButton from "@ipgd-gauge/actions-button";
import { confirmationDialog, handleError, isAllowedAction, isEnglish, isNotEmpty, showSuccessMessage } from "@ipgd-gauge/utils";
import i18next from "i18next";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import AddBtnIcon from "../../../icons/AddBtn";
import ApproveIcon from "../../../icons/Approve";
import DeleteTowIcon from "../../../icons/Delete2";
import IncompleteIcon from "../../../icons/Incomplete";
import InputsBtnVeiwIcon from "../../../icons/InputsBtnVeiw";
import PrinterIcon from "../../../icons/Printer";
import RejectIcon from "../../../icons/Reject";
import SaveIcon from "../../../icons/Save";
import { confirmCreditNoteCustomerEstimation, deleteCreditNotesCustomersEstimation, getLinkWithZatcaPref, rejectCreditNoteCustomerEstimation, saveCreditNoteCustomerEstimation } from "../../../util/apis";
import { GLOBAL_STATUS_CONFIRMED, GLOBAL_STATUS_PREPARED, GLOBAL_STATUS_INCOMPLETE, GLOBAL_STATUS_SUBMITTED } from "../../../util/constants";
import { CONFIRM_FORM_ACTION, DELETE_ACTION, INCOMPLETE_ACTION, NEW_ACTION, PRINT_ACTION, REJECT_ACTION, SAVE_ACTION } from "../../../util/constants/actions";
import { RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID } from "../../../util/constants/forms";
import { SAVE_ACTION_SHORT_CUT } from "../../../util/constants/hotkeys";
import WorkflowIcon from "../../../icons/Workflow";
import { ActionIcon } from "../../../icons/WorkflowIcons";

const Actions = (props, ref) => {
    const { user, creditNoteState, newAction, saveAction, dispatchCreditNote, printDialogRef, fromSaveActionRef, adjustWorkDialogRef, workflowsActionLogDialogRef, zatcaSummaryRef } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [actions, setActions] = useState([]);
    const [workflowAction, setWorkflowAction] = useState(null);
    
    const openZatcaSummary = () => {
        zatcaSummaryRef.current.open(RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID, creditNoteState?.creditNote?.header?.id);
    }

    const _executeAction = (api, isDeleteAction) => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return api(creditNoteState?.creditNote?.header?.id)
                .then(() => {
                    navigate(`/credit-notes-for-customers-estimation`);
                    showSuccessMessage(isDeleteAction ? 'delete_successfully' : 'action_performed_successfully', t);
                })
                .catch((error) => handleError(error, error.response.data.message.split('#')[0], t));
        });
    }

    const isSaved = () => isNotEmpty(creditNoteState?.creditNote?.header?.id);
    const isIncomplete = () => creditNoteState?.creditNote?.header?.globalStatus == GLOBAL_STATUS_INCOMPLETE;
    const isPrepared = () => creditNoteState?.creditNote?.header?.globalStatus == GLOBAL_STATUS_PREPARED;
    const isSubmitted = () => creditNoteState?.creditNote?.header?.globalStatus == GLOBAL_STATUS_SUBMITTED;
    const _isAllowedAction = (action) => isAllowedAction(user, RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID, action);
    const _isAllowedSaveAction = () => (isIncomplete() || isPrepared() || isSubmitted()) && _isAllowedAction(SAVE_ACTION);
    const isWorkflow = () => isNotEmpty(creditNoteState?.workflowId);
    const updateAllowed = () => workflowAction?.updateAllowed == 1;

    const _executeWorkflowAction = () => {
        if (isSaved() && isNotEmpty(workflowAction.actionId) && isNotEmpty(workflowAction.actionType)) {
            adjustWorkDialogRef.current.open(creditNoteState?.workflowId, creditNoteState?.stepId, creditNoteState?.creditNote?.header?.id);
        }
    }

    useImperativeHandle(ref, () => ({ setWorkflowAction }));

    const _isAllowedLinkZatca = async () => {
        const resp = await getLinkWithZatcaPref();
        return resp.data == '100001' && creditNoteState?.creditNote?.header?.globalStatus == GLOBAL_STATUS_CONFIRMED;
    }

    useEffect(() => {
        const temp = [];

        if (isIncomplete() && isSaved() && _isAllowedAction(DELETE_ACTION)) {
            temp.push({
                id: "delete-credit-notes-for-customers-estimation",
                label: t('creditNotesForCustomersEstimation:delete'),
                color: "var(--action-delete-bg)",
                iconColor: "var(--action-delete-bg)",
                icon: <DeleteTowIcon />,
                action: () => _executeAction(deleteCreditNotesCustomersEstimation, true)
            });
        }

        if (!isWorkflow() && isSubmitted() && _isAllowedAction(REJECT_ACTION)) {
            temp.push({
                id: "reject-credit-notes-for-customers-estimation",
                label: t('creditNotesForCustomersEstimation:reject'),
                color: "var(--action-delete-bg)",
                icon: <RejectIcon />,
                action: () => _executeAction(rejectCreditNoteCustomerEstimation, false)
            });
        }

        if (_isAllowedAction(NEW_ACTION)) {
            temp.push({
                id: "new-credit-notes-for-customers-estimation",
                label: t('creditNotesForCustomersEstimation:new'),
                color: "var(--action-copy)",
                icon: <AddBtnIcon />,
                action: () => newAction()
            });
        }

        if (isSaved() && _isAllowedAction(PRINT_ACTION)) {
            temp.push({
                id: "print-credit-notes-for-customers-estimation",
                label: t('creditNotesForCustomersEstimation:print'),
                color: "var(--pageination)",
                action: () => printDialogRef.current?.open([creditNoteState?.creditNote?.header?.id]),
                icon: <PrinterIcon />
            });
        }

        if (!isWorkflow() && isSubmitted() && _isAllowedAction(CONFIRM_FORM_ACTION)) {
            temp.push({
                id: "approve-credit-notes-for-customers-estimation",
                label: t('creditNotesForCustomersEstimation:confirm'),
                color: "var(--action-save)",
                icon: <ApproveIcon />,
                action: () => _executeAction(confirmCreditNoteCustomerEstimation, false)
            });
        }

        if (isIncomplete() && _isAllowedAction(INCOMPLETE_ACTION)) {
            temp.push({
                id: "draft-credit-notes-for-customers-estimation",
                label: t('creditNotesForCustomersEstimation:draft'),
                icon: <IncompleteIcon />,
                action: () => saveAction(false)
            });
        }

        if (_isAllowedSaveAction() && !workflowAction) {
            const isSaveAction = (!isWorkflow() || (isWorkflow() && isSaved() && updateAllowed()));
            temp.push({
                id: "save-credit-notes-for-customers-estimation",
                label: t(isSaveAction ? 'creditNotesForCustomersEstimation:save' : 'submit'),
                hotkey: SAVE_ACTION_SHORT_CUT,
                color: "var(--action-save)",
                icon: <SaveIcon />,
                action: isSaveAction ? () => saveAction(true) : () => adjustWorkDialogRef.current.open(creditNoteState?.workflowId, creditNoteState?.stepId, creditNoteState?.creditNote?.header?.id)
            });
        }

        if (isWorkflow() && isSaved()) {
            temp.push({
                id: 'workflow-status',
                label: t('workflow_status'),
                action: () => workflowsActionLogDialogRef.current.open(creditNoteState?.workflowId, creditNoteState?.creditNote?.header?.id, creditNoteState?.stepId, creditNoteState?.creditNote?.header?.globalStatus),
                iconColor: "#8A3FFC",
                icon: <WorkflowIcon />,
            });
        }

        if (isWorkflow() && workflowAction != null && Object.keys(workflowAction).length > 0) {
            temp.push({
                id: 'workflow',
                label: isEnglish() ? workflowAction?.actionDescLo : workflowAction?.actionDescFo,
                action: () => _executeWorkflowAction(),
                color: workflowAction?.statusBgColor,
                icon: <ActionIcon actionType={workflowAction.actionType} />,
            });
        }
        if (_isAllowedLinkZatca()) {
            temp.push({
                label: t('zatca_summary'),
                action: openZatcaSummary,
                icon: <InputsBtnVeiwIcon />,
                color: "#007A3D",
            })
        }
        setActions([...temp]);
    }, [i18next.language, creditNoteState, workflowAction]);

    useHotkeys(SAVE_ACTION_SHORT_CUT, () => {
        if (_isAllowedSaveAction()) {
            saveAction(true);
        }
    }, { enableOnFormTags: true });

    return <ActionsButton actions={actions} />
}

export default forwardRef(Actions);