import React from "react";

function UploudImageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="65.241"
      height="72.192"
      viewBox="0 0 65.241 72.192"
    >
      <path
        fill="#81c548"
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit="10"
        strokeWidth="1"
        d="M11.431 71.692A10.941 10.941 0 01.5 60.764V11.431A10.943 10.943 0 0111.431.5H53.81a10.945 10.945 0 0110.932 10.931v49.333A10.943 10.943 0 0153.81 71.692zM3.036 11.431v49.333a8.4 8.4 0 008.395 8.4H53.81a8.407 8.407 0 008.4-8.4V11.431a8.406 8.406 0 00-8.4-8.4H11.431a8.4 8.4 0 00-8.395 8.4zm27.793 41.852V26.266L20.248 36.847a1.69 1.69 0 01-2.39 0 1.691 1.691 0 010-2.39l13.466-13.463a1.65 1.65 0 01.55-.365 1.671 1.671 0 011.289 0 1.693 1.693 0 01.553.365L47.382 34.66a1.691 1.691 0 010 2.39 1.688 1.688 0 01-1.2.495 1.688 1.688 0 01-1.194-.495L34.204 26.266v27.017a1.689 1.689 0 01-1.689 1.689 1.689 1.689 0 01-1.686-1.69z"
        data-name="Union 35"
      ></path>
    </svg>
  );
}

export default UploudImageIcon;