import { formatDate, formatMoney, handleError, isEnglish, isNotEmpty } from "@ipgd-gauge/utils";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import PrintDialog from "../../../components/print-dialog";
import Slisting from "../../../components/slisting";
import { deleteCreditNotesCustomersEstimation, getCreditNotesCustomersEstimation, exportZatcaXml, getLinkWithZatcaPref } from "../../../util/apis";
import { GLOBAL_STATUS_INCOMPLETE } from "../../../util/constants";
import { RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import WorkflowsActionLogDialog from "../../common/dialogs/workflows-action-log";
import Actions from "./actions";
import style from './style.module.scss';
import ZatcaSummaryDialog from "../../common/dialogs/zatca-summary-dialog";
import { downloadXml } from "../../../util/util";

const CreditNotesforCustomersEstimationTable = (props) => {
    const { tableRef, filtersRef, searchTextRef } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const printDialogRef = useRef();
    const zatcaSummaryRef = useRef();
    const workflowsActionLogDialogRef = useRef();

    const user = getUser();

    const [list, setList] = useState();
    const [isAllowedLinkWithZatca, setAllowedLinkWithZatca] = useState();

    const columns = [
        { title: '#', width: '5%' },
        { title: t('creditNotesForCustomersEstimation:notes_num'), width: '14%' },
        { title: t('creditNotesForCustomersEstimation:notes_date'), width: '10%' },
        { title: t('creditNotesForCustomersEstimation:customer'), width: '10%' },
        { title: t('creditNotesForCustomersEstimation:branch_division'), width: '12%' },
        { title: t('creditNotesForCustomersEstimation:net_price'), width: '11%' },
        { title: t('creditNotesForCustomersEstimation:invoice_number'), width: '11%' },
        { title: t('ZATCA'), width: "6%" },
        { title: t('creditNotesForCustomersEstimation:status'), width: '10%' },
        { title: "", width: '11%' },
    ];

    const _fetchData = (page, size) => {
        const filterData = {
            searchValue: searchTextRef?.current?.getSearchText(),
            ...filtersRef.current.getFilterData(),
        }

        getCreditNotesCustomersEstimation(page, size, filterData)
            .then((response) => setList(response.data))
            .catch((error) => handleError(error, null, t))
    }

    useEffect(() => {
        const _fillZatcaPref = async () => {
            const resp = await getLinkWithZatcaPref();
            setAllowedLinkWithZatca(resp.data == '100001');
        }
        _fillZatcaPref();
    }, []);

    const getIsAllowedLinkWithZatca = () => {
        return isAllowedLinkWithZatca;
    }

    return (
        <>
            <Slisting
                id="credit-notes-for-customers-estimation-table"
                ref={tableRef}
                columns={columns}
                Row={Row}
                tableStyle={style.creditNotesforCustomersEstimationTable}
                getData={_fetchData}
                rowHeight={80}
                rowActions={{ user, tableRef, printDialogRef, workflowsActionLogDialogRef, zatcaSummaryRef: zatcaSummaryRef, isLinkWithZatca: getIsAllowedLinkWithZatca }}
                pagingData={list}
                withFilter
                showWating={(data) => data == null}
                showNoDataFound={(data) => (data && data.length == 0)}
                formId={RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID}
                newAction={() => navigate("/credit-notes-for-customer-estimation")}
                deleteAction={deleteCreditNotesCustomersEstimation}
                viewAction={(id) => navigate(`/credit-notes-for-customer-estimation?id=${id}`)}
                hideDeleteCondition={(data) => data.globalStatus != GLOBAL_STATUS_INCOMPLETE}
                cardInMobile
                labelCardWidth="130px"
                withPrintAction
                withWorkflow={true}
                user={user}
            />

            <PrintDialog
                ref={printDialogRef}
                id="credit-notes-for-customers-estimation-print-dialog"
                formId={RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID}
                user={user}
            />

            <WorkflowsActionLogDialog
                ref={workflowsActionLogDialogRef}
                formId={RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID}
            />

            <ZatcaSummaryDialog
                ref={zatcaSummaryRef}
            />
        </>
    );
};

const Row = (props) => {
    const { data, rowActions } = props;
    const { user, tableRef, printDialogRef, workflowsActionLogDialogRef, zatcaSummaryRef, isLinkWithZatca } = rowActions;
    const { t } = useTranslation();

    const _exportZatcaXml = (id) => {
        exportZatcaXml(RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID, id).then(response => {
            downloadXml(response);
        })
    }

    return (
        <div className={`${style.creditNotesforCustomersEstimationRow}`}>
            <div title={data.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data.sequence}</label>
            </div>

            <div title={data.code} className="cardRowMode">
                <label className="nameLblMobile">{t('creditNotesForCustomersEstimation:notes_num')}</label>
                <label>{data.code}</label>
            </div>

            <div title={formatDate(data.dateDgr)} className="cardRowMode">
                <label className="nameLblMobile">{t('creditNotesForCustomersEstimation:notes_date')}</label>
                <label>{formatDate(data.dateDgr)} </label>
            </div>

            <div title={isEnglish() ? data.customerNameLo : data.customerNameFo} className="cardRowMode">
                <label className="nameLblMobile">{t('creditNotesForCustomersEstimation:customer')}</label>
                <label>{isEnglish() ? data.customerNameLo : data.customerNameFo}</label>
            </div>

            <div title={`${isEnglish() ? data.branchLo : data.branchFo} - ${isEnglish() ? data.divisionLo : data.divisionFo}`} className="cardRowMode">
                <label className="nameLblMobile">{t('creditNotesForCustomersEstimation:branch_division')}</label>
                <label>{`${isEnglish() ? data.branchLo : data.branchFo} - ${isEnglish() ? data.divisionLo : data.divisionFo}`}</label>
            </div>

            <div title={formatMoney(data.invoiceNetAlc)} className="cardRowMode">
                <label className="nameLblMobile">{t('creditNotesForCustomersEstimation:net_price')}</label>
                <label>{formatMoney(data.invoiceNetAlc)}</label>
            </div>

            <div title={data.invoiceCode} className="cardRowMode">
                <label className="nameLblMobile">{t('creditNotesForCustomersEstimation:invoice_number')}</label>
                <label>{data.invoiceCode}</label>
            </div>

            <div className="columnSm">
                <div className="columnRowNew" style={{ alignItems: 'center' }}>
                    <label className={`status-dote-${data.zatcaSynchStatus}`}>
                        {isEnglish() ? data.zatcaSynchStatusLo : data.zatcaSynchStatusFo}
                    </label>
                    <button className='download-zatca-btn' title={t('download_xml')} onClick={() => _exportZatcaXml(data.id)}>{t('download_xml')}</button>
                </div>
                <div className="cardRowTrxMode">
                    <label className="nameLblMobile">ZATCA</label>
                    <label className={`status-dote-${data.zatcaSynchStatus}`}>
                        {isEnglish() ? data.zatcaSynchStatusLo : data.zatcaSynchStatusFo}
                    </label>
                </div>
                <div className="cardRowTrxMode">
                    <label className="nameLblMobile"></label>
                    <button className='download-zatca-btn' title={t('download_xml')} onClick={() => _exportZatcaXml(data.id)}>{t('download_xml')}</button>
                </div>
            </div>

            <div className="cardRowStatusMode">
                <label className="nameLblMobile">{t('creditNotesForCustomersEstimation:status')}</label>
                <label
                    title={isEnglish() ? data.statusDescLo : data.statusDescFo}
                    className={`${isNotEmpty(data?.workflowStatusId) ? 'workflow-status' : `status-${data.globalStatus}`}`}
                    style={isNotEmpty(data?.workflowStatusId) ? { backgroundColor: `${data?.statusBgColor}`, color: `${data?.statusColor}` } : {}}>
                    {isEnglish() ? data.statusDescLo : data.statusDescFo}
                </label>
            </div>

            <div>
                <Actions
                    user={user}
                    data={data}
                    tableRef={tableRef}
                    printDialogRef={printDialogRef}
                    workflowsActionLogDialogRef={workflowsActionLogDialogRef}
                    zatcaSummaryRef={zatcaSummaryRef}
                    isLinkWithZatca={isLinkWithZatca}
                />
            </div>
        </div>
    );
};

export default CreditNotesforCustomersEstimationTable;