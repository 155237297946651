import { DateFromToInput } from "@ipgd-gauge/date-picker";
import FrmButtonsOptions from "@ipgd-gauge/frm-button-options";
import FrmInput from "@ipgd-gauge/frm-input";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../../components/advance-filter";
import StatusSelectMenu from "../../../components/status-select-menu";
import { getCategories, getCommonBranches, getCommonCustomers, getCommonDivisionsList, getCommonPartiesLov, getCommonPropertyTypes, getDomainValues, getDomainValuesWithCondition, getEstimationDomains, getFinancialLocationsList, getFndUsers, getOrgnizationById, getPartiesDefinitionCategories, getRsmStatus } from "../../../util/apis";
import { DOMAIN_GLOBAL_STATUS, DOMAIN_SOURCE, ESTIMATION_DOMAINS_STAGES, ESTIMATION_DOMAINS_TRANSACTION_TYPE, GLOBAL_STATUS_CONFIRMED, GLOBAL_STATUS_INCOMPLETE, GLOBAL_STATUS_REJECTED, GLOBAL_STATUS_SUBMITTED, SOURCE_CUSTOMER_INVOICE, SOURCE_INSPECTION_INVOICE } from "../../../util/constants";
import { RSM3079_ESTIMATION_INVOICES_REGISTER_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";

const ALL = '-1';
const BRANCH_CONDITION = 'dependsOnPolicyId';
const DIVISION_CONDITION = 'dependsOnPolicyIdOnly';
const CATEGORY_CONDITION = "dependsOnUserOrg";
const EstimationInvoicesRegisterFilter = (props, ref) => {
    const { estimationInvoicesRegisterTableRef, policyFilterRef } = props;

    const { t } = useTranslation("");

    const currentUser = getUser();

    const advanceFilterRef = useRef();

    const [filterData, setFilterData] = useState({});

    const _getDefaultFilter = async () => {
        const defaultFilter = {
            lsoId: currentUser.estimationInvoiceLsoId
        }
        let orgnization = await getOrgnizationById(currentUser.fogId);
        defaultFilter.orgDescFo = orgnization.data.offecialNameFo;
        defaultFilter.orgDescLo = orgnization.data.offecialNameLo;
        return defaultFilter;
    }

    const handleDataChange = (name, value) => {
        let refreshData = false;
        filterData[name] = value;
        if (name == "policy") {
            refreshData = true;
            filterData.lsoId = value?.value
        }
        setFilterData({ ...filterData });
        if (refreshData) {
            estimationInvoicesRegisterTableRef.current.refresh();
        }
    }

    const getFilterData = () => Object.assign({}, filterData);

    const open = () => advanceFilterRef.current?.open();

    useImperativeHandle(ref, () => ({ handleDataChange, getFilterData, open }));


    return (
        <AdvanceFilter
            id="estimationInvoicesRegisterFilter"
            ref={advanceFilterRef}
            getDefaultFilter={_getDefaultFilter}
            filterData={filterData}
            setFilterData={setFilterData}
            searchAction={() => estimationInvoicesRegisterTableRef?.current?.refresh()}
            numOfRows={5}
            formId={RSM3079_ESTIMATION_INVOICES_REGISTER_FORM_ID}
            postDefaultFilterRetrieved={(data) => policyFilterRef.current.setPolicyId(data.lsoId)}
        >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="container">
                    <div className="row rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:source')}</label>
                            <FrmButtonsOptions
                                id="source"
                                value={filterData.sourceId}
                                handleSelect={value => handleDataChange("sourceId", value)}
                                className={'options-btn-33'}
                                isMulti={false}
                                isClearable={true}
                                api={() => getDomainValues(DOMAIN_SOURCE)}
                                options={[
                                    {
                                        id: ALL,
                                        descLo: t('all'),
                                        descFo: t('all'),
                                    },
                                    {
                                        id: SOURCE_CUSTOMER_INVOICE,
                                        descLo: t('estimationInvoicesRegister:customer_invoice'),
                                        descFo: t('estimationInvoicesRegister:customer_invoice'),
                                    },
                                    {
                                        id: SOURCE_INSPECTION_INVOICE,
                                        descLo: t('estimationInvoicesRegister:inspection_invoice'),
                                        descFo: t('estimationInvoicesRegister:inspection_invoice'),
                                    },
                                ]}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:transaction_status')}</label>
                            <StatusSelectMenu
                                id="status"
                                value={filterData.statusIds}
                                onChange={value => handleDataChange("statusIds", value)}
                                api={() => getDomainValuesWithCondition(DOMAIN_GLOBAL_STATUS, true, [GLOBAL_STATUS_INCOMPLETE, GLOBAL_STATUS_SUBMITTED, GLOBAL_STATUS_CONFIRMED, GLOBAL_STATUS_REJECTED])}
                                descFo={['descFo']}
                                descLo={['descLo']}
                                isMulti={true}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:invoice_date_from_to')}</label>
                            <DateFromToInput
                                id="invoice_date_from_to"
                                fromValue={filterData?.invoiceDateFromG}
                                toValue={filterData?.invoiceDateToG}
                                fromOnChange={(value) => handleDataChange("invoiceDateFromG", value)}
                                toOnChange={(value) => handleDataChange("invoiceDateToG", value)}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:location')}</label>
                            <FrmSelectMenu
                                id="location"
                                value={filterData.locationIds}
                                onChange={value => handleDataChange("locationIds", value)}
                                api={() => getFinancialLocationsList(null, null, filterData.locationIds)}
                                descLo={['code', "nameLo"]}
                                descFo={['code', "nameFo"]}
                                isMulti={true}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:invoice_code')}</label>
                            <FrmInput
                                id="invoice_code"
                                value={filterData.invoiceCode}
                                onBlur={value => handleDataChange("invoiceCode", value)}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:invoice_ref_code')}</label>
                            <FrmInput
                                id="invoice_ref_code"
                                value={filterData.invoiceRefCode}
                                onBlur={value => handleDataChange("invoiceRefCode", value)}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:customer_category')}</label>
                            <FrmSelectMenu
                                id="customer_category"
                                value={filterData.customerCategoryIds}
                                onChange={value => handleDataChange("customerCategoryIds", value)}
                                api={() => getCategories(null, CATEGORY_CONDITION, false)}
                                descLo={["descLo"]}
                                descFo={["descFo"]}
                                isMulti={true}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:customer')}</label>
                            <FrmSelectMenu
                                id="customer"
                                value={filterData.customerIds}
                                onChange={e => handleDataChange("customerIds", e)}
                                defualtValueId={filterData.customers}
                                defualtValueApi={() => getCommonCustomers(filterData.customers, null, null, true, false, false, [], false)}
                                api={(searchValue) => getCommonCustomers(null, null, searchValue, true, false, false, [], false)}
                                descLo={['code', 'descLo']}
                                descFo={['code', 'descFo']}
                                isAsync={true}
                                isMulti={true}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:party_category')}</label>
                            <FrmSelectMenu
                                api={() => getPartiesDefinitionCategories(null, null, null, true, null)}
                                isMulti
                                descLo={["descLo"]}
                                descFo={["descFo"]}
                                id="party_category"
                                portalTarget={document.body}
                                onChange={e => handleDataChange("partyCategoryIds", e)}
                                value={filterData.partyCategoryIds}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:party')}</label>
                            <FrmSelectMenu
                                id="party"
                                isMulti
                                descLo={["nameLo"]}
                                descFo={["nameFo"]}
                                api={() => getCommonPartiesLov(null, null, null, null, filterData.partyIds, null, null, 1)}
                                portalTarget={document.body}
                                onChange={e => handleDataChange("partyIds", e)}
                                value={filterData.partyIds}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('estimationInvoicesRegister:data')}</label>
                </div>
                <div className="container">
                    <div className="row advanceFilterContainer rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:trx_code')}</label>
                            <FrmInput
                                id="trx_code"
                                onBlur={e => handleDataChange("trxCode", e)}
                                value={filterData.trxCode}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:trx_ref_code')}</label>
                            <FrmInput
                                id="trx_ref_code"
                                onBlur={e => handleDataChange("trxRefCode", e)}
                                value={filterData.trxRefCode}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:trnx_date_from_to')}</label>
                            <DateFromToInput
                                id="trnx_date_from_to"
                                fromValue={filterData?.trxDateFromG}
                                toValue={filterData?.trxDateToG}
                                fromOnChange={(value) => handleDataChange("trxDateFromG", value)}
                                toOnChange={(value) => handleDataChange("trxDateToG", value)}
                                enableTime
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:approval_date_from_to')}</label>
                            <DateFromToInput
                                id="approval_date_from_to"
                                fromValue={filterData?.approvalDateFromG}
                                toValue={filterData?.approvalDateToG}
                                fromOnChange={(value) => handleDataChange("approvalDateFromG", value)}
                                toOnChange={(value) => handleDataChange("approvalDateToG", value)}
                                enableTime
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:invoice_approval_date_from_to')}</label>
                            <DateFromToInput
                                id="invoice_approval_date_from_to"
                                fromValue={filterData?.invApprovalDateFromG}
                                toValue={filterData?.invApprovalDateToG}
                                fromOnChange={(value) => handleDataChange("invApprovalDateFromG", value)}
                                toOnChange={(value) => handleDataChange("invApprovalDateToG", value)}
                                enableTime
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:application_status')}</label>
                            <FrmSelectMenu
                                isMulti
                                api={getRsmStatus}
                                descLo={["descLo"]}
                                descFo={["descFo"]}
                                id="application_status"
                                portalTarget={document.body}
                                onChange={e => handleDataChange("applicationStatusIds", e)}
                                value={filterData.applicationStatusIds}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:stage')}</label>
                            <FrmSelectMenu
                                isMulti
                                id="stage"
                                api={() => getEstimationDomains(ESTIMATION_DOMAINS_STAGES)}
                                descLo={["descLo"]}
                                descFo={["descFo"]}
                                onChange={e => handleDataChange("stageIds", e)}
                                value={filterData.stageIds}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:transaction_type')}</label>
                            <FrmSelectMenu
                                isMulti
                                api={() => getEstimationDomains(ESTIMATION_DOMAINS_TRANSACTION_TYPE)}
                                id="transaction_type"
                                descLo={["descLo"]}
                                descFo={["descFo"]}
                                onChange={e => handleDataChange("transactionTypeIds", e)}
                                value={filterData.transactionTypeIds}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:order_num')}</label>
                            <FrmInput
                                id="order_num"
                                onBlur={e => handleDataChange("orderNum", e)}
                                value={filterData.orderNum}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:owner')}</label>
                            <FrmInput
                                id="owner"
                                onBlur={e => handleDataChange("owner", e)}
                                value={filterData.owner}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:client_name')}</label>
                            <FrmInput
                                id="client_name"
                                onBlur={e => handleDataChange("clientName", e)}
                                value={filterData.clientName}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:property_type')}</label>
                            <FrmSelectMenu
                                isMulti
                                id="property_type"
                                descLo={["descLo"]}
                                descFo={["descFo"]}
                                portalTarget={document.body}
                                api={() => getCommonPropertyTypes(null, null, true, false, false, [], false, filterData.propertyTypeIds)}
                                onChange={e => handleDataChange("propertyTypeIds", e)}
                                value={filterData.propertyTypeIds}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:property_code')}</label>
                            <FrmInput
                                id="property_code"
                                onBlur={e => handleDataChange("propertyCode", e)}
                                value={filterData.propertyCode}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:title_deed_num')}</label>
                            <FrmInput
                                id="title_deed_num"
                                onBlur={e => handleDataChange("deedNum", e)}
                                value={filterData.deedNum}
                            />
                        </div>
                    </div>
                </div >
            </div >

            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('more_options')}</label>
                </div>
                <div className="container">
                    <div className="row advanceFilterContainer rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:created_by')}</label>
                            <FrmSelectMenu
                                isMulti
                                id="created_by"
                                api={() => getFndUsers(filterData.createdByIds, null, true)}
                                descLo={["descLo"]}
                                descFo={["descFo"]}
                                portalTarget={document.body}
                                onChange={e => handleDataChange("createdByIds", e)}
                                value={filterData.createdByIds}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:reviewer')}</label>
                            <FrmSelectMenu
                                isMulti
                                id="reviewer"
                                api={() => getFndUsers(filterData.reviewerIds, null, true)}
                                descLo={["descLo"]}
                                descFo={["descFo"]}
                                portalTarget={document.body}
                                onChange={e => handleDataChange("reviewerIds", e)}
                                value={filterData.reviewerIds}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:inspector')}</label>
                            <FrmSelectMenu
                                id="inspector"
                                isMulti
                                api={() => getFndUsers(filterData.inspectorIds, null, true)}
                                descLo={["descLo"]}
                                descFo={["descFo"]}
                                portalTarget={document.body}
                                onChange={e => handleDataChange("inspectorIds", e)}
                                value={filterData.inspectorIds}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:approved_by')}</label>
                            <FrmSelectMenu
                                isMulti
                                id="approved_by"
                                api={() => getFndUsers(filterData.createdByIds, null, true)}
                                descLo={["descLo"]}
                                descFo={["descFo"]}
                                portalTarget={document.body}
                                onChange={e => handleDataChange("approvedByIds", e)}
                                value={filterData.approvedByIds}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:branch')}</label>
                            <FrmSelectMenu
                                id="branch"
                                api={() => getCommonBranches(null, filterData.branchIds, null, BRANCH_CONDITION)}
                                descLo={["descLo"]}
                                isMulti
                                descFo={["descFo"]}
                                portalTarget={document.body}
                                onChange={e => handleDataChange("branchIds", e)}
                                value={filterData.branchIds}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationInvoicesRegister:division')}</label>
                            <FrmSelectMenu
                                api={() => getCommonDivisionsList(null, DIVISION_CONDITION, false)}
                                id="division"
                                isMulti
                                descLo={["descLo"]}
                                descFo={["descFo"]}
                                portalTarget={document.body}
                                onChange={e => handleDataChange("divisionIds", e)}
                                value={filterData.divisionIds}
                            />
                        </div>
                    </div>
                </div >
            </div >
        </AdvanceFilter >
    )
}

export default forwardRef(EstimationInvoicesRegisterFilter);