import { useEffect } from "react";

export const useWorkflow = (formId) => {

    const workflowId = sessionStorage.getItem(`wf-${formId}`);
    const stepId = sessionStorage.getItem(`step-${formId}`);

    useEffect(() => {
        return () => {
            sessionStorage.removeItem(`wf-${formId}`);
            sessionStorage.removeItem(`step-${formId}`);
        };
    }, []);

    return { workflowId, stepId };
}