import * as React from "react";

const WorkflowIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.5"
    height="21.5"
    viewBox="0 0 21.5 21.5"
  >
    <path
      id="Union_41"
      fill="#292d32"
      d="M14 17.75a3.75 3.75 0 0 1 1.966-3.3 5.24 5.24 0 0 0-4.714-2.922l-3.431.01A4.78 4.78 0 0 1 4.5 10.227v3.849a3.75 3.75 0 1 1-1.5 0v-6.2a4 4 0 1 1 1.725.061 3.29 3.29 0 0 0 3.091 2.103l3.433-.011a6.74 6.74 0 0 1 6.169 3.985q.164-.014.332-.014A3.75 3.75 0 1 1 14 17.75m1.5 0a2.25 2.25 0 1 0 2.25-2.25 2.253 2.253 0 0 0-2.25 2.25m-14 0a2.25 2.25 0 1 0 2.25-2.25 2.25 2.25 0 0 0-2.25 2.25M1.5 4A2.5 2.5 0 1 0 4 1.5 2.5 2.5 0 0 0 1.5 4"
      data-name="Union 41"
    ></path>
  </svg>
);

export default WorkflowIcon;
