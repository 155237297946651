import ActionsButton from "@ipgd-gauge/actions-button";
import { confirmationDialog, handleError, isAllowedAction, isEnglish, isNotEmpty, showSuccessMessage } from "@ipgd-gauge/utils";
import i18next from "i18next";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import AddBtnIcon from "../../../icons/AddBtn";
import ApproveIcon from "../../../icons/Approve";
import DeleteTowIcon from "../../../icons/Delete2";
import IncompleteIcon from "../../../icons/Incomplete";
import PrinterIcon from "../../../icons/Printer";
import RejectIcon from "../../../icons/Reject";
import SaveActionIcon from "../../../icons/SaveAction";
import WorkflowIcon from "../../../icons/Workflow";
import { confirmEstimatorInvoice, deleteEstimatorInvoice, rejectEstimatorInvoice } from "../../../util/apis";
import { GLOBAL_STATUS_INCOMPLETE, GLOBAL_STATUS_PREPARED, GLOBAL_STATUS_SUBMITTED } from "../../../util/constants";
import { CONFIRM_FORM_ACTION, DELETE_ACTION, NEW_ACTION, PRINT_ACTION, REJECT_ACTION, SAVE_ACTION } from "../../../util/constants/actions";
import { RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID } from "../../../util/constants/forms";
import { SAVE_ACTION_SHORT_CUT } from "../../../util/constants/hotkeys";
import { ActionIcon } from "../../../icons/WorkflowIcons";

const Actions = (props, ref) => {
    const { user, saveAction, invoiceState, printDialogRef, newAction, adjustWorkDialogRef, workflowsActionLogDialogRef } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [actions, setActions] = useState([]);
    const [workflowAction, setWorkflowAction] = useState(null);
    const _saveAction = (withSubmit) => saveAction(withSubmit, true);

    const _thenAction = (isDelete) => {
        navigate(`/estimators-inspectors-invoices`);
        showSuccessMessage(isDelete ? 'delete_successfully' : null, t);
    }

    const _confirmInvoice = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return confirmEstimatorInvoice(invoiceState?.invoice?.header?.id)
                .then(() => _thenAction(false))
                .catch((error) => {
                    let message = error?.response?.data?.message;
                    if (message.includes('#')) {
                        message = message.split('#')[0];
                    }
                    handleError(error, message, t);
                });
        });
    }

    const _rejectInvoice = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return rejectEstimatorInvoice(invoiceState?.invoice?.header?.id)
                .then(() => _thenAction(false))
                .catch((error) => handleError(error, null, t));
        });
    }

    const _deleteInvoice = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return deleteEstimatorInvoice(invoiceState?.invoice?.header?.id)
                .then(() => _thenAction(true))
                .catch((error) => handleError(error, null, t));
        });
    }

    const _isAllowedAction = (action) => isAllowedAction(user, RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID, action);
    const isSaved = () => isNotEmpty(invoiceState?.invoice?.header?.id);
    const isIncomplete = () => invoiceState?.invoice?.header?.globalStatus == GLOBAL_STATUS_INCOMPLETE;
    const isPrepared = () => invoiceState?.invoice?.header?.globalStatus == GLOBAL_STATUS_PREPARED;
    const isSubmitted = () => invoiceState?.invoice?.header?.globalStatus == GLOBAL_STATUS_SUBMITTED;
    const _isAllowedSaveAction = () => (isIncomplete() || isSubmitted()) && _isAllowedAction(SAVE_ACTION);
    const isWorkflow = () => isNotEmpty(invoiceState?.workflowId);
    const updateAllowed = () => workflowAction?.updateAllowed == 1;

    const _executeWorkflowAction = () => {
        if (isSaved() && isNotEmpty(workflowAction.actionId) && isNotEmpty(workflowAction.actionType)) {
            adjustWorkDialogRef.current.open(invoiceState?.workflowId, invoiceState?.stepId, invoiceState?.invoice?.header?.id);
        }
    }

    useImperativeHandle(ref, () => ({ setWorkflowAction }));

    useEffect(() => {
        const temp = [];

        if (isIncomplete() && isSaved() && _isAllowedAction(DELETE_ACTION)) {
            temp.push({
                id: "delete-inspector-estimator-invoice",
                label: t('estimatorsInspectorsInvoices:delete'),
                icon: <DeleteTowIcon />,
                color: "var(--action-delete-bg)",
                iconColor: "var(--action-delete-bg)",
                action: _deleteInvoice
            });
        }

        if (!isWorkflow() && isSubmitted() && _isAllowedAction(REJECT_ACTION)) {
            temp.push({
                id: "reject-inspector-estimator-invoice",
                label: t('estimatorsInspectorsInvoices:reject'),
                icon: <RejectIcon />,
                color: "var(--action-delete-bg)",
                action: _rejectInvoice
            });
        }

        if (_isAllowedAction(NEW_ACTION)) {
            temp.push({
                id: "new-inspector-estimator-invoice",
                label: t('estimatorsInspectorsInvoices:new'),
                color: "var(--action-copy)",
                icon: <AddBtnIcon />,
                action: () => newAction()
            });
        }

        if (isSaved() && _isAllowedAction(PRINT_ACTION)) {
            temp.push({
                id: "print-inspector-estimator-invoice",
                label: t('customersEstimationInvoices:print'),
                color: "var(--pageination)",
                icon: <PrinterIcon />,
                action: () => printDialogRef?.current?.open([invoiceState?.invoice?.header?.id])
            });
        }

        if (!isWorkflow() && isSubmitted() && _isAllowedAction(CONFIRM_FORM_ACTION)) {
            temp.push({
                id: "approve-inspector-estimator-invoice",
                label: t('estimatorsInspectorsInvoices:confirm'),
                color: "var(--action-save)",
                icon: <ApproveIcon />,
                action: _confirmInvoice
            });
        }

        if (isIncomplete() && _isAllowedAction(SAVE_ACTION)) {
            temp.push({
                id: "draft-inspector-estimator-invoice",
                label: t('estimatorsInspectorsInvoices:draft'),
                icon: <IncompleteIcon />,
                color: "var(--action-incomplete)",
                action: () => _saveAction(false)
            });
        }

        if ((isIncomplete() || isPrepared() || updateAllowed() || isSubmitted()) && _isAllowedSaveAction() && !workflowAction) {
            temp.push({
                id: "save-inspector-estimator-invoice",
                label: t((!isWorkflow()) ? 'estimatorsInspectorsInvoices:save' : 'submit'),
                color: "var(--action-save)",
                hotkey: SAVE_ACTION_SHORT_CUT,
                icon: <SaveActionIcon />,
                action: () => !isWorkflow() ? _saveAction(true) : adjustWorkDialogRef.current.open(invoiceState?.workflowId, invoiceState?.stepId, invoiceState?.invoice?.header?.id)

            });
        }

        if (isWorkflow() && isSaved()) {
            temp.push({
                id: 'workflow-status',
                label: t('workflow_status'),
                action: () => workflowsActionLogDialogRef.current.open(invoiceState?.workflowId, invoiceState?.invoice?.header?.id, invoiceState?.stepId, invoiceState?.invoice?.header?.globalStatus),
                iconColor: "#8A3FFC",
                icon: <WorkflowIcon />,
            });
        }

        if (isWorkflow() && workflowAction != null && Object.keys(workflowAction).length > 0) {
            temp.push({
                id: 'workflow',
                label: isEnglish() ? workflowAction?.actionDescLo : workflowAction?.actionDescFo,
                action: () => _executeWorkflowAction(),
                color: workflowAction?.statusBgColor,
                icon: <ActionIcon actionType={workflowAction.actionType} />,
            });
        }

        setActions([...temp]);
    }, [i18next.language, invoiceState, workflowAction]);

    useHotkeys(SAVE_ACTION_SHORT_CUT, () => {
        if (_isAllowedSaveAction()) {
            _saveAction(true);
        }
    }, { enableOnFormTags: true });

    return <ActionsButton actions={actions} />;
}

export default forwardRef(Actions);