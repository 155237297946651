import Timer from '@ipgd-gauge/timer';
import { formatDateNTime, handleError, isAllowedAction, isEnglish } from "@ipgd-gauge/utils";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Slisting from "../../../components/slisting";
import TableChate from "../../../components/table-chate";
import AttentionIcon from "../../../icons/Attention";
import FavMessagesIcon from "../../../icons/FavMessages";
import InformationIcon from "../../../icons/Information";
import MessagesArrowIcon from "../../../icons/MessagesArrow";
import MsgTableIcon from "../../../icons/MsgTable";
import OpeningFavIcon from "../../../icons/OpeningFav";
import OpeningMessgeIcon from "../../../icons/OpeningMessge";
import ReadMessagesIcon from "../../../icons/ReadMessages";
import RemindIcon from "../../../icons/Remind";
import ShareIcon from "../../../icons/Share";
import TrackingIcon from "../../../icons/Tracking";
import UpdateIcon from "../../../icons/Update";
import { executeListAction, favInboxMessage, getCurrentTime, getInboxMessages, updateMsgReadStatus } from "../../../util/apis";
import { ACTION_EXECUTE, ACTION_VIEW_ESTIMATION_FORM, ALERT_TYPE_ALERT, ALERT_TYPE_FOLLOW_UP, ALERT_TYPE_INFORMATION, ALERT_TYPE_REMINDER, ALERT_TYPE_UPDATE_DATA, DOMAIN_PROGRESS_STATUS_NOT_STARTED } from "../../../util/constants";
import { VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { RSM3246_INBOX_FORM_ID } from "../../../util/constants/forms";
import { getToken, getUser } from "../../../util/cookies";
import MsgDialog from "../../common/dialogs/msg-dialog";
import style from './style.module.scss';

const Row = (props) => {
    const { data, rowActions } = props;
    const { user, dialogRef, currentTimeRef } = rowActions;

    const { t } = useTranslation('inbox');
    const navigate = useNavigate();

    const [messages, setMessages] = useState(false);
    const [isFlaged, setIsFlaged] = useState(Boolean(data.isFlaged));
    const [readed, setReaded] = useState(Boolean(data.userReadingStatus));

    const _handleFav = (e) => {
        e.stopPropagation();
        favInboxMessage(data.receiverId)
            .then((res) => setIsFlaged(!isFlaged))
            .catch((error) => handleError(error, null, t))
    }

    const _handleRead = (e) => {
        e?.stopPropagation();
        updateMsgReadStatus({ receiverId: data.receiverId, status: !readed })
            .then((res) => setReaded(!readed))
            .catch((error) => handleError(error, null, t))
    }

    const _openTransaction = (event, action, sseId) => {
        event.stopPropagation();
        executeListAction({ action: action, trnxId: data.triId, token: getToken(), sseId })
            .then((response) => navigate(`/estimation-trx?key=${response.data}`))
    }

    const _openMessageDialog = () => {
        if ((isAllowedAction(user, RSM3246_INBOX_FORM_ID, VIEW_EDIT_ACTION))) {
            dialogRef?.current?.open(data.id)
            if (!readed) {
                _handleRead();
            }
        }
    }

    const _handleViewMessages = (e) => {
        e.stopPropagation();
        setMessages(!messages)
    }

    const _replyAction = (e) => {
        if (data.type == ALERT_TYPE_UPDATE_DATA && data.triId) {
            _openTransaction(e, ACTION_EXECUTE, data.id);
        } else {
            e.stopPropagation();
            dialogRef?.current?.replyOpen(data.id);
        }
    }

    return (
        <div className={`${style.inboxRow}`} onClick={_openMessageDialog}>
            <div>
                <button className={style.messagesContainer} onClick={_handleViewMessages}>
                    <span className={messages ? style.arrowMessages : ''}> <MessagesArrowIcon /></span>
                    <span><MsgTableIcon /></span>
                </button>
            </div>
            <div title={data.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data.sequence}</label>
            </div>
            <div title={data.code} className={`overflowTableField cardRowMode`} >
                <label className="nameLblMobile">{t('code')}</label>
                <label>{data.code}</label>
            </div>
            <div className={`overflowTableField cardRowMode`} onClick={(e) => _openTransaction(e, ACTION_VIEW_ESTIMATION_FORM)} title={data.trnxCode}>
                <label className="nameLblMobile">{t('trnx_code')}</label>
                {data.trnxCode &&
                    <label className="linkLabel">{data.trnxCode}</label>
                    ||
                    <label className={style.notFound}>{t('not_found')}</label>
                }
            </div>
            <div title={data.text} className={`overflowTableField cardRowMode`}>
                <label className="nameLblMobile">{t('content')}</label>
                <label>{data.text}</label>
            </div>
            <div title={formatDateNTime(data.sendingDateTime)} className="cardRowMode">
                <label className="nameLblMobile">{t('date_dash_time')}</label>
                <label>{formatDateNTime(data.sendingDateTime)}</label>
            </div>
            <div title={isEnglish() ? data.progStatusLo || "Not Started" : data.progStatusFo || "لم تبدأ"} className="cardRowStatusMode">
                <label className="nameLblMobile">{t('progress_status')}</label>
                <label className={data.progressStatus ? `status-${data.progressStatus}` : `status-${DOMAIN_PROGRESS_STATUS_NOT_STARTED}`}>{isEnglish() ? data.progStatusLo || "Not Started" : data.progStatusFo || "لم تبدأ"}</label>
            </div>
            <div className={` ${style.actionIcon} cardRowStatusMode`}>
                <label className="nameLblMobile">{t('message_type')}</label>
                <div className={style.actionRowInboxs}>
                    <div>
                        {data.type == ALERT_TYPE_REMINDER &&
                            <RemindIcon color="#fed049" />
                        }
                        {data.type == ALERT_TYPE_UPDATE_DATA &&
                            <UpdateIcon color="var(--secondary-2)" />
                        }
                        {data.type == ALERT_TYPE_INFORMATION &&
                            <InformationIcon color="var(--primary-color)" />
                        }
                        {data.type == ALERT_TYPE_ALERT &&
                            <AttentionIcon color="var(--red)" />
                        }
                        {data.type == ALERT_TYPE_FOLLOW_UP &&
                            <TrackingIcon color="var(--secondary-1)" />
                        }
                    </div>
                    <div >
                        {isEnglish() ? data.typeLo : data.typeFo}
                    </div>
                </div>
            </div>
            <div className="inboxAction">
                <div className='containerButtonTable'>
                    <button className={style.shareButton} onClick={_replyAction}>
                        <ShareIcon />
                        <label>{t('reply')}</label>
                    </button>
                    <button className='notificationButton' onClick={_handleRead}>
                        {readed ? <OpeningMessgeIcon /> : <ReadMessagesIcon />}
                    </button>
                    <button className='notificationButton' onClick={_handleFav}>
                        {isFlaged ? <FavMessagesIcon /> : <OpeningFavIcon />}
                    </button>
                </div>
                {data.dueDateTime ?
                    <Timer
                        fromDate={currentTimeRef.current}
                        toDate={data.dueDateTime}
                        textColor={currentTimeRef.current < data.dueDateTime ? '#09a4f2' : '#ff0000'}
                        className={style.timerContainer}
                        showDays
                        showSeconds
                        showIcon
                        withMilliseconds
                        isCountdown={currentTimeRef.current < data.dueDateTime}
                    />
                    :
                    <div className={style.notFound}>
                        - -
                    </div>
                }
            </div>
            {messages && <TableChate messageId={data.id} />}
        </div>
    );
};

const InboxTable = (props) => {

    const { inboxTableRef, searchTextRef, filterDialogRef, isTab } = props;

    const { t } = useTranslation();
    const user = getUser();

    const msgDialogRef = useRef();
    const currentTimeRef = useRef();

    const [messages, setMessages] = useState();

    const columns = [
        { title: '', width: '5%' },
        { title: '#', width: '3%' },
        { title: t('inbox:code'), width: '11%' },
        { title: t('inbox:trnx_code'), width: '11%' },
        { title: t('inbox:content'), width: '13%' },
        { title: t('inbox:date_dash_time'), width: '12%' },
        { title: t('inbox:progress_status'), width: '10%' },
        { title: t('inbox:message_type'), width: '8%' },
        { title: "", width: '27%' }
    ];

    const _fetchData = (page, size) => {
        const filterData = {
            msgText: searchTextRef?.current?.getSearchText(),
            ...filterDialogRef?.current?.getFilterData(),
        }
        getInboxMessages(page, size, filterData)
            .then((response) => setMessages(response.data))
            .catch((error) => handleError(error, null, t));

        getCurrentTime()
            .then((response) => currentTimeRef.current = response.data)
            .catch((error) => currentTimeRef.current = new Date().getTime());
    }

    return (
        <>
            <Slisting
                ref={inboxTableRef}
                id="inboxListing"
                formId={RSM3246_INBOX_FORM_ID}
                columns={columns}
                withFilter
                Row={Row}
                tableStyle={`${style.inboxTable} ${isTab ? style.inboxTabTable : ''}`}
                getData={_fetchData}
                pagingData={messages}
                newAction={() => msgDialogRef?.current?.open()}
                viewAction={(id) => msgDialogRef.current?.open(id)}
                rowActions={{ user, dialogRef: msgDialogRef, currentTimeRef }}
                showWating={(data) => data == null}
                showNoDataFound={(data) => (data && data.length == 0)}
                labelCardWidth="110px"
                cardInMobile
            />

            <MsgDialog tableRef={inboxTableRef} ref={msgDialogRef} formId={RSM3246_INBOX_FORM_ID} />
        </>
    );
};

export default InboxTable;