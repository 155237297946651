import ActionsButton from '@ipgd-gauge/actions-button';
import { confirmationDialog, getUrlParam, handleError, hideActionWaiting, hideWaiting, isEmpty, isEnglish, isNotEmpty, showActionWaiting, showNotificationToast, showSuccessMessage, showWaiting } from '@ipgd-gauge/utils';
import i18next from 'i18next';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DeleteTowIcon from '../../../icons/Delete2';
import EstimationTransactionsStatusesIcon from '../../../icons/EstimationTransactionsStatuses';
import { CommentTrxIcon, CopyIcon, PdfTrxIcon, SendBackIcon, SendMessageTrxIcon, SendToIcon, SubmitIcon, WorkflowStatusIcon } from '../../../icons/EstimationTrx';
import NewWordIcon from '../../../icons/NewWord';
import SaveActionIcon from '../../../icons/SaveAction';
import { changeValue, resetData, updateData } from '../../../store/est-trnx-data/actions';
import { changeTransactionValue, changeUnitDetailValue, executeTrxAction, getActions, getEstBlocks, populateTransaction } from '../../../util/apis';
import { ACTION_APPROVE, ACTION_COMMENT, ACTION_COPY, ACTION_DELETE_PERMANENTLY, ACTION_ESTIMATION, ACTION_INCOMPLETE, ACTION_PRINT_PDF, ACTION_PRINT_WORD, ACTION_REJECT, ACTION_REVIEW, ACTION_SAVE, ACTION_SEND_BACK_TO_APPROVAL, ACTION_SEND_BACK_TO_COORDINATOR, ACTION_SEND_BACK_TO_ESTIMATOR, ACTION_SEND_FOR_APPROVAL, ACTION_SEND_FOR_REVISION, ACTION_SEND_MESSAGE, ACTION_SEND_TO_ESTIMATOR, ACTION_WORKFLOW_STATUS, TRX_DISPLAY_MODE_1 } from '../../../util/constants';
import { RSM3230_VALUATION_TRANSACTIONS_FORM_ID } from '../../../util/constants/forms';
import { getToken, getUser } from '../../../util/cookies';
import { useWebSocket } from '../../../util/hooks/useWebSocket';
import MsgDialog from '../../common/dialogs/msg-dialog';
import ChooseReport from '../dialogs/ChooseReport';
import NumberOfCopies from '../dialogs/NumberOfCopies';
import WorkFlowStatus from '../dialogs/WorkFlowStatus';
import Lines from './details';
import Header from './header';
import style from './style.module.scss';

const ACTIONS_NEED_GO_BACK = [ACTION_SAVE, ACTION_APPROVE, ACTION_REJECT, ACTION_SEND_FOR_REVISION, ACTION_DELETE_PERMANENTLY, ACTION_ESTIMATION, ACTION_REVIEW]

const TrxActions = forwardRef((props, ref) => {

    const { user, getTrxKey, attachmentsRef, selfConstructionTreeRef, rebuild } = props;
    const { t } = useTranslation(['estimationTransaction', 'common']);

    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();

    const firstTimeRef = useRef(true);
    const numberOfCopiesDialogRef = useRef();
    const chooseReportDialogRef = useRef();
    const messageDialogRef = useRef();
    const workFlowStatusDialogRef = useRef();

    const [actions, setActions] = useState([]);

    const trxId = useSelector(state => state.estTrnxReducer.data.TRI_ID || -1);
    const eriId = useSelector(state => state.estTrnxReducer.data.TRI_ERI_ID);
    const code = useSelector(state => state.estTrnxReducer.data.TRI_CODE);
    const batchNum = useSelector(state => state.estTrnxReducer.data.TRI_BATCH_NUM);
    const status = useSelector(state => state.estTrnxReducer.data.TRI_STATUS);
    const oaeId = useSelector(state => state.estTrnxReducer.data.TRI_OAE_ID);
    const fnoId = useSelector(state => state.estTrnxReducer.data.TRI_FNO_ID);

    useEffect(() => {
        if (!firstTimeRef.current) {
            refreshActions();
        } else {
            firstTimeRef.current = false;
        }
    }, [i18next.language, status, code, oaeId, trxId]);

    const refreshActions = () => {
        let trxKey = getTrxKey()
        getActions(trxKey)
            .then((response) => {
                let trxAction = response.data;
                let actions = [];
                let key = `${code || -1}-${status}-${oaeId || -1}`

                if (trxAction.includes(`${ACTION_INCOMPLETE}`)) {
                    actions.push({ id: `action-${ACTION_INCOMPLETE}-${key}`, icon: <EstimationTransactionsStatusesIcon />, label: t('estimationTransaction:incomplete'), action: _executeIncompletAction });
                }
                if (trxAction.includes(`${ACTION_APPROVE}`)) {
                    actions.push({ id: `action-${ACTION_APPROVE}-${key}`, icon: <SubmitIcon />, label: t('estimationTransaction:submit'), action: () => _executeTrxAction(ACTION_APPROVE, null, null, true) });
                }
                if (trxAction.includes(`${ACTION_REVIEW}`)) {
                    actions.push({ id: `action-${ACTION_REVIEW}-${key}`, icon: <SubmitIcon />, label: t('estimationTransaction:submit'), action: () => _executeTrxAction(ACTION_REVIEW, null, null, true) });
                }
                if (trxAction.includes(`${ACTION_ESTIMATION}`)) {
                    actions.push({ id: `action-${ACTION_ESTIMATION}-${key}`, icon: <SubmitIcon />, label: t('estimationTransaction:submit'), action: () => _executeTrxAction(ACTION_ESTIMATION, null, null, true) });
                }
                if (trxAction.includes(`${ACTION_SEND_FOR_REVISION}`)) {
                    actions.push({ id: `action-${ACTION_SEND_FOR_REVISION}-${key}`, icon: <SubmitIcon />, label: t('estimationTransaction:submit'), action: () => _executeTrxAction(ACTION_SEND_FOR_REVISION, null, null, true) });
                }
                if (trxAction.includes(`${ACTION_SAVE}`)) {
                    actions.push({ id: `action-${ACTION_SAVE}-${key}`, icon: <SaveActionIcon />, label: t('estimationTransaction:estimation_saving'), action: () => (trxId < 0 || isEmpty(code) || (isNotEmpty(batchNum) && user?.estimationPolicy?.osmUpdateAllTransactionCopies)) ? numberOfCopiesDialogRef?.current?.open(ACTION_SAVE) : _incompleteAction(ACTION_SAVE, {}) });
                }
                // if (trxAction.includes(`${ACTION_REJECT}`)) {
                //     actions.push({ id: `action-${ACTION_REJECT}-${key}`, icon: <RejectIcon />, label: t('estimationTransaction:reject'), action: () => _executeTrxAction(ACTION_REJECT, null, null, true) });
                // }
                if (trxAction.includes(`${ACTION_SEND_FOR_APPROVAL}`)) {
                    actions.push({ id: `action-${ACTION_SEND_FOR_APPROVAL}-${key}`, icon: <SendToIcon />, label: t('estimationTransaction:send_for_estimation_approval'), action: () => _executeTrxAction(ACTION_SEND_FOR_APPROVAL, null, null, true) });
                }
                if (trxAction.includes(`${ACTION_SEND_TO_ESTIMATOR}`)) {
                    actions.push({ id: `action-${ACTION_SEND_TO_ESTIMATOR}-${key}`, icon: <SendToIcon />, label: t('estimationTransaction:send_to_inspector'), action: () => _executeTrxAction(ACTION_SEND_TO_ESTIMATOR, null, null, true) });
                }
                if (trxAction.includes(`${ACTION_DELETE_PERMANENTLY}`)) {
                    actions.push({ id: `action-${ACTION_DELETE_PERMANENTLY}-${key}`, icon: <DeleteTowIcon />, label: t('estimationTransaction:delete_permanently'), action: () => _executeTrxAction(ACTION_DELETE_PERMANENTLY, null, null, true) });
                }
                if (trxAction.includes(`${ACTION_SEND_BACK_TO_ESTIMATOR}`)) {
                    actions.push({ id: `action-${ACTION_SEND_BACK_TO_ESTIMATOR}-${key}`, icon: <SendBackIcon />, label: t('estimationTransaction:send_back_to_inspector'), action: () => _executeTrxAction(ACTION_SEND_BACK_TO_ESTIMATOR, null, null, true) });
                }
                if (trxAction.includes(`${ACTION_SEND_BACK_TO_COORDINATOR}`)) {
                    actions.push({ id: `action-${ACTION_SEND_BACK_TO_COORDINATOR}-${key}`, icon: <SendBackIcon />, label: t('estimationTransaction:send_back_to_coordinator'), action: () => _executeTrxAction(ACTION_SEND_BACK_TO_COORDINATOR, null, null, true) });
                }
                if (trxAction.includes(`${ACTION_SEND_BACK_TO_APPROVAL}`)) {
                    actions.push({ id: `action-${ACTION_SEND_BACK_TO_APPROVAL}-${key}`, icon: <SendBackIcon />, label: t('estimationTransaction:send_back_for_estimation_approval'), action: () => _executeTrxAction(ACTION_SEND_BACK_TO_APPROVAL, null, null, true) });
                }
                if (trxAction.includes(`${ACTION_PRINT_PDF}`)) {
                    actions.push({ id: `action-${ACTION_PRINT_PDF}-${key}`, icon: <PdfTrxIcon />, label: t('estimationTransaction:print_pdf'), action: () => chooseReportDialogRef?.current?.open(ACTION_PRINT_PDF, trxId, eriId, code) });
                }
                if (trxAction.includes(`${ACTION_PRINT_WORD}`)) {
                    actions.push({ id: `action-${ACTION_PRINT_WORD}-${key}`, icon: <NewWordIcon />, label: t('estimationTransaction:print_word'), action: () => chooseReportDialogRef?.current?.open(ACTION_PRINT_WORD, trxId, eriId, code) });
                }
                if (trxAction.includes(`${ACTION_COPY}`)) {
                    actions.push({ id: `action-${ACTION_COPY}-${key}`, icon: <CopyIcon />, label: t('estimationTransaction:copy'), action: () => _copyAction() });
                }
                if (trxAction.includes(`${ACTION_COMMENT}`)) {
                    actions.push({ id: `action-${ACTION_COMMENT}-${key}`, icon: <CommentTrxIcon />, label: t('estimationTransaction:comment'), action: () => navigate(`/trx-summary?id=${trxId}&mode=note`) });
                }
                if (trxAction.includes(`${ACTION_SEND_MESSAGE}`)) {
                    actions.push({ id: `action-${ACTION_SEND_MESSAGE}-${key}`, icon: <SendMessageTrxIcon />, label: t('estimationTransaction:send_message'), action: () => messageDialogRef?.current?.open(null, trxId, fnoId) });
                }
                if (trxAction.includes(`${ACTION_WORKFLOW_STATUS}`)) {
                    actions.push({ id: `action-${ACTION_WORKFLOW_STATUS}-${key}`, icon: <WorkflowStatusIcon />, label: t('estimationTransaction:workflow_status'), action: () => workFlowStatusDialogRef?.current?.open(trxId) });
                }

                setActions(actions);
            })
    }

    const _executeTrxAction = (action, data, onComplite, withSureDialog) => {
        if (withSureDialog) {
            confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => _executeAction(action, data, onComplite));
        } else {
            _executeAction(action, data, onComplite);
        }
    }

    const _executeAction = async (action, data, onComplite) => {
        let key = `${code || -1}-${status}-${oaeId || -1}`
        const withWaiting = document.getElementById(`action-${action}-${key}`) != null;
        if (withWaiting) {
            showActionWaiting(`action-${action}-${key}`, true)
        }
        await executeTrxAction({ action, key: getTrxKey(), ...data })
            .then((response) => {
                if (Object.keys({ ...response.data }).includes("rebuild-ui") && response.data["rebuild-ui"] == "GO_TO_LIST") {
                    handleError(null, "action_taken_on_transaction", t)
                    rebuild(response.data);
                    return;
                }
                showSuccessMessage(null, t);
                if (ACTIONS_NEED_GO_BACK.includes(action)) {
                    setTimeout(() => {
                        navigate('/estimation-transactions');
                    }, 100)
                } else {
                    rebuild(response.data);
                }
            })
            .catch((error) => {
                const message = error?.response?.data?.message;
                if (message && message.includes("#")) {
                    const messages = message.split('#');
                    if (messages[0] == 'mandatory_fields' || messages[0] == 'feild_value_duplication') {
                        handleError(null, messages[0], t, [{ name: 'feild', value: isEnglish() ? messages[1] : messages[2] }]);
                    } else {
                        handleError(null, messages[0], t);
                    }
                } else {
                    handleError(null, message, t);
                }
            })
            .finally(() => {
                if (withWaiting) {
                    hideActionWaiting(`action-${action}-${key}`)
                }
                if (onComplite) {
                    onComplite();
                }
            })

    }

    const _executeIncompletAction = () => {
        if (trxId < 0 || isEmpty(code) || (isNotEmpty(batchNum) && user?.estimationPolicy?.osmUpdateAllTransactionCopies)) {
            numberOfCopiesDialogRef?.current?.open(ACTION_INCOMPLETE)
        } else {
            _incompleteAction(ACTION_INCOMPLETE, {})
        }
    }

    const _incompleteAction = (action, dialogData) => {
        showWaiting(`number-of-copies-dialog-okButton`, true);
        _executeTrxAction(action, dialogData, (id) => {
            hideWaiting(`number-of-copies-dialog-okButton`);
            numberOfCopiesDialogRef?.current?.close()
        });
    }

    const _onCompleteCopy = () => {
        selfConstructionTreeRef?.current?.refresh();
        attachmentsRef?.current?.refreshAttachments();
        // rebuild();
    }

    const _copyAction = () => {
        MySwal.fire({
            title: t('estimationTransaction:do_you_want_add_attachments'),
            cancelButtonText: t('cancel'),
            icon: 'info',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: t('estimationTransaction:with_attachments'),
            denyButtonText: t('estimationTransaction:without_attachments'),
            customClass: {
                title: isEnglish() ? 'swal2-title-en' : 'swal2-title-ar',
                actions: isEnglish() ? 'swal2-actions-panel-en' : 'swal2-actions-panel-ar',
                cancelButton: isEnglish() ? 'swal2-cancel-button swal2-cancel-en' : 'swal2-cancel-button swal2-cancel-ar',
                confirmButton: isEnglish() ? 'swal2-confirm-en' : 'swal2-confirm-ar',
                denyButton: isEnglish() ? 'swal2-deny-en' : 'swal2-deny-ar',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                _executeTrxAction(ACTION_COPY, { withAttachments: "1" }, () => _onCompleteCopy())
            } else if (result.isDenied) {
                _executeTrxAction(ACTION_COPY, { withAttachments: "0" }, () => _onCompleteCopy())
            }
        })
    }

    useImperativeHandle(ref, () => ({ refreshActions }))

    return (
        <>
            <ActionsButton actions={actions} />
            <NumberOfCopies
                ref={numberOfCopiesDialogRef}
                user={user}
                trxId={trxId}
                code={code}
                batchNum={batchNum}
                okAction={(action, dialogData) => _incompleteAction(action, dialogData)}
            />
            <ChooseReport ref={chooseReportDialogRef} />
            <MsgDialog ref={messageDialogRef} fromTransactions={true} formId={RSM3230_VALUATION_TRANSACTIONS_FORM_ID} />
            <WorkFlowStatus ref={workFlowStatusDialogRef} user={user} />
        </>
    )
})

const EstimationTransaction = () => {

    const estimationTrxRef = useRef();
    const actuionsRef = useRef();
    const selfConstructionTreeRef = useRef();
    const refreshSelfConstructionTreeRef = useRef(false);
    const unitsDetailsBlockRef = useRef();
    const firstTimeRef = useRef(true);
    const lastUsersWorkingRef = useRef(null);
    const { t } = useTranslation();
    let navigate = useNavigate();
    let dispatch = useDispatch();

    const getTrxKey = () => {
        const trxKey = getUrlParam('key');
        return trxKey;
    }

    useEffect(() => {
        populateTransaction({ key: getTrxKey(), token: getToken() })
            .then((response) => {
                handelTrxResponse(response.data);
            })
            .catch((error) => {
                handleError(error, null, t)
            })

        return () => {
            dispatch(resetData());
        }
    }, [])

    const handelTrxResponse = (data) => {
        if (data['rebuild-ui']) {
            _rebuild(data['rebuild-ui']);
        }
        if (data['flush-changed-values']) {
            _flushChanges(data['flush-changed-values']);
        }
        if (data['flush-self-const-changed-values']) {
            _flushChanges(data['flush-self-const-changed-values']);
            if (refreshSelfConstructionTreeRef.current) {
                refreshSelfConstructionTreeRef.current = false;
                selfConstructionTreeRef?.current?.refresh();
            }
        }
        if (data['rebuild-lovs']) {
            _rebuildListOfValue(data['rebuild-lovs']);
        }
        if (data['rebuild-actions']) {
            actuionsRef?.current?.refreshActions();
        }
        if (data['flush-changed-unit-detail']) {
            unitsDetailsBlockRef?.current?.refresh();
        }
    }

    const _rebuild = (message) => {
        if (message == "REBUILD_NOW") {
            _getBlocks();
        } else if (message == "REBUILD_NEED_CHECK_FOR_METHODS") {
            estimationTrxRef?.current?.rebuildEstimationBlocks();
        } else {
            navigate('/estimation-transactions')
        }
    }

    const _flushChanges = (message) => {
        dispatch(updateData(message))
        if (firstTimeRef.current) {
            firstTimeRef.current = false;
            actuionsRef?.current?.refreshActions();
        }
    }

    const _rebuildListOfValue = (message) => {
        if (message?.length > 0) {
            message.map((lov) => {
                dispatch(changeValue(`${lov}_KEY`, new Date().getTime()))
            })
        }
    }

    const _controlUserNotAuthorizedAlert = (message) => {
        dispatch(changeValue(`SHOW_USER_NOT_AUTHORIZED_ALERT`, message == "true"))
    }

    const _controlUsersWorkingOnTrxAlert = (message) => {
        if (lastUsersWorkingRef.current != message) {
            lastUsersWorkingRef.current = message;
            dispatch(changeValue(`USERS_WORKING_ON_TRX`, JSON.parse(message)))
        }
    }

    const _showDuplicateValueAlert = (message) => {
        let data = JSON.parse(message);
        showNotificationToast(t("estimationTransaction:feild_value_duplication_2", { feild: isEnglish() ? data.fieldNameLo : data.fieldNameFo, transactions: data.trxIds }), { hideProgressBar: true, type: 'warning' })
    }

    const _getBlocks = () => {
        getEstBlocks({ key: getTrxKey() })
            .then((res) => {
                estimationTrxRef?.current?.setBlocks(res.data)
            })
    }

    const { connected, sendMessage } = useWebSocket({
        endpoint: 'rs-valuation-trx',
        listeners: [
            { url: `/control-user-not-authorized-alert/${getTrxKey()}`, action: _controlUserNotAuthorizedAlert },
            { url: `/control-users-working-on-trx-alert/${getTrxKey()}`, action: _controlUsersWorkingOnTrxAlert },
            { url: `/show-duplicate-value-alert/${getTrxKey()}`, action: _showDuplicateValueAlert },
        ],
    })

    return (
        <EstimationTransactionContainer
            ref={estimationTrxRef}
            getTrxKey={getTrxKey}
            actuionsRef={actuionsRef}
            sendMessage={sendMessage}
            connected={connected}
            firstTimeRef={firstTimeRef}
            unitsDetailsBlockRef={unitsDetailsBlockRef}
            selfConstructionTreeRef={selfConstructionTreeRef}
            handelTrxResponse={handelTrxResponse}
            refreshSelfConstructionTreeRef={refreshSelfConstructionTreeRef}
        />
    )
}

const EstimationTransactionContainer = forwardRef((props, ref) => {
    const { getTrxKey, actuionsRef, selfConstructionTreeRef, firstTimeRef, sendMessage, connected, unitsDetailsBlockRef, handelTrxResponse, refreshSelfConstructionTreeRef } = props;

    const user = getUser();
    let dispatch = useDispatch();
    const { t } = useTranslation();

    const linesRef = useRef();
    const attachmentsRef = useRef();
    const onlineIntervalRef = useRef();
    const removableWaitingRef = useRef(true);

    const [blocks, setBlocks] = useState([]);
    const [displayMode, setDisplayMode] = useState(TRX_DISPLAY_MODE_1);

    useEffect(() => {
        if (!removableWaitingRef.current) {
            removableWaitingRef.current = true;
        }
    }, [blocks])

    useEffect(() => {
        if (connected) {
            onlineIntervalRef.current = setInterval(() => {
                sendMessage('refresh-trx-online-time', { key: getTrxKey() });
            }, 5000)
        }
        return () => {
            clearInterval(onlineIntervalRef.current)
        }
    }, [connected])

    const handleChange = (name, value) => {
        dispatch(changeValue(name, value))
        if (name === "LDI_SELF_CONST_PARENT_TRI_ID") {
            refreshSelfConstructionTreeRef.current = true;
        }
        const data = { key: getTrxKey(), name, value };
        changeTransactionValue(data)
            .then((response) => {
                handelTrxResponse(response.data)
            })
            .catch((error) => {
                handleError(error, null, t)
            })
    }

    const handleChangeUnitDetail = (index, name, value) => {
        const data = { key: getTrxKey(), index, name, value };
        changeUnitDetailValue(data)
            .then((response) => handelTrxResponse(response.data))
            .catch((error) => handleError(error, null, t))
    }

    const trxBlock = useMemo(() => {
        return blocks;
    }, [blocks])

    const handleMode = (mode) => {
        setDisplayMode(mode);
        if (mode == TRX_DISPLAY_MODE_1) {
            const blocksTitle = document.querySelectorAll('.block-title');
            [...blocksTitle].map(title => {
                title.style.display = 'flex'
            })
            const blocksContent = document.querySelectorAll('.block-content');
            [...blocksContent].map(content => {
                content.style.display = 'block'
            })
        } else {
            handleSelectBlock();
        }
    }

    const handleSelectBlock = (e) => {
        let blockId = null;
        if (e) {
            blockId = e.target.id.split('-')[2];
            const activeBlock = document.querySelectorAll('.active-card');
            [...activeBlock].map(block => {
                block.classList.remove('active-card')
            })
            e.target.classList.add('active-card');
        } else {
            blockId = document.querySelectorAll('.active-card')[0].id.split('-')[2];
        }

        const blocksTitle = document.querySelectorAll('.block-title');
        [...blocksTitle].map(title => {
            title.style.display = 'none'
        })
        const blocksContent = document.querySelectorAll('.block-content');
        [...blocksContent].map(content => {
            content.style.display = 'none'
        })
        document.getElementById(`block-${blockId}`).style.display = 'flex';
        document.getElementById(`block-content-${blockId}`).style.display = 'block';

    }

    const _rebuildFromActions = (data) => {
        firstTimeRef.current = true;
        handelTrxResponse(data);
    }

    const rebuildEstimationBlocks = () => {
        let tempBlocks = [...blocks]
        tempBlocks.map((block) => {
            if (block.checkMethods) {
                block.forceUpdate = block.forceUpdate ? block.forceUpdate + 1 : 1;
            }
        })
        setBlocks([...tempBlocks])
    }
    const handleShowHideMenu = () => {
        document.getElementById('estimation-trx-container').classList.toggle('show-hide-menu');
        document.getElementById('estimation-trx-menu').classList.toggle('show-hide-menu');
        document.getElementById('estimation-trx-btn').classList.toggle('show-hide-menu');
        if (document.getElementById('estimation-trx-btn').classList.contains('show-hide-menu')) {
            document.getElementById('estimation-trx-menu-header').style.visibility = "hidden";
        } else {
            document.getElementById('estimation-trx-menu-header').style.visibility = "visible";
        }
        document.getElementById('headerCard').classList.toggle('show-hide-menu');
        const listOfItems = document.getElementsByClassName('title-panel');
        for (let index = 0; index < listOfItems.length; index++) {
            const element = listOfItems[index];
            element.classList.toggle("with-num");
        }
    }

    useImperativeHandle(ref, () => ({
        setBlocks,
        rebuildEstimationBlocks
    }))

    return (
        <>
            <div className={`frm-container ${style.frmContainerSm}`}>
                {/* <button style={{position:'absolute' , zIndex:'99999'}} onClick={handleShowHideMenu} className='primary-button'>sssssssss</button> */}
                <Header
                    linesRef={linesRef}
                    blocks={trxBlock}
                    displayMode={displayMode}
                    handleMode={handleMode}
                    handleSelectBlock={handleSelectBlock}
                    handleShowHideMenu={handleShowHideMenu}
                />
                <Lines
                    ref={linesRef}
                    user={user}
                    blocks={trxBlock}
                    displayMode={displayMode}
                    handleChange={handleChange}
                    getTrxKey={getTrxKey}
                    attachmentsRef={attachmentsRef}
                    selfConstructionTreeRef={selfConstructionTreeRef}
                    unitsDetailsBlockRef={unitsDetailsBlockRef}
                    handleChangeUnitDetail={handleChangeUnitDetail}
                    handelTrxResponse={handelTrxResponse}
                />
            </div>
            <TrxActions
                ref={actuionsRef}
                user={user}
                getTrxKey={getTrxKey}
                attachmentsRef={attachmentsRef}
                selfConstructionTreeRef={selfConstructionTreeRef}
                rebuild={_rebuildFromActions}
            />
        </>
    )
});

export default EstimationTransaction;