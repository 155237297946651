import React from "react";

function ListIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72.83 71.28">
      <rect
        width="14.96"
        height="16.26"
        fill="#454754"
        rx="3.26"
        ry="3.26"
      ></rect>
      <rect
        width="51.1"
        height="16.26"
        x="21.73"
        fill="#454754"
        rx="3.26"
        ry="3.26"
      ></rect>
      <rect
        width="14.96"
        height="16.26"
        y="27.06"
        fill="#454754"
        rx="3.26"
        ry="3.26"
      ></rect>
      <rect
        width="51.1"
        height="16.26"
        x="21.73"
        y="27.06"
        fill="#454754"
        rx="3.26"
        ry="3.26"
      ></rect>
      <rect
        width="14.96"
        height="16.26"
        y="55.02"
        fill="#454754"
        rx="3.26"
        ry="3.26"
      ></rect>
      <rect
        width="51.1"
        height="16.26"
        x="21.73"
        y="55.02"
        fill="#454754"
        rx="3.26"
        ry="3.26"
      ></rect>
    </svg>
  );
}

export default ListIcon;
