import { isEnglish, isNotEmpty } from "@ipgd-gauge/utils";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SelectLocation from '../../../../icons/SelectLocation';
import UploudImageIcon from "../../../../icons/UploudImage";
import { DATA_TYPE_DATE, DATA_TYPE_DATE_TIME, DATA_TYPE_HIJRI_DATE, DATA_TYPE_MULTI_LINES_TEXT, DATA_TYPE_NUMBER, DATA_TYPE_TEXT, DATA_TYPE_TEXT_ALIGN_CENTER, DATA_TYPE_TIME } from '../../../../util/constants';
import DatePicker from './DatePicker';
import InputNumber from './InputNumber';
import InputText from './InputText';
import style from './style.module.scss';

const TH = (props) => {
    const { feilds } = props;
    const [tableHeader, setTableHeader] = useState([]);

    useEffect(() => {
        let max = -1;
        let header = [];
        feilds?.map(feild => {
            if (feild.fieldDetails?.length > max) {
                max = feild.fieldDetails?.length
                header = feild.fieldDetails;
            }
        })
        if (max != -1) {
            setTableHeader(header)
        }
    }, [feilds])

    return (
        <>
            {tableHeader &&
                <div className={style.th} style={{ '--num-of-cells': tableHeader?.length || 0 }}>
                    <div></div>
                    {tableHeader.map((feild) => {
                        return <div key={feild.id}>
                            <div className={feild?.mandatory ? 'mandatory' : ''}>{isEnglish() ? feild.userDescLo : feild.userDescFo}</div>
                        </div>
                    })}
                </div>}
        </>
    )
}

const PickLocationButton = ({ feild, handlePickLocation }) => {
    const value = useSelector(state => state.estTrnxReducer.data[feild.internalName]);
    return (
        <button className={style.mapPinInTable} onClick={() => handlePickLocation(feild.internalName, value)}>
            <SelectLocation />
        </button>
    )
}

const UploadAttachmentsButton = (props) => {
    const { feild, similaritiesAttachmentDialogRef } = props;
    const value = useSelector(state => state.estTrnxReducer.data[feild.internalName]);

    return (
        <button
            className={style.uploudImageButton}
            onClick={() => similaritiesAttachmentDialogRef?.current?.open(feild.internalName, value)}
        >
            <UploudImageIcon />
            {isNotEmpty(value) && <span></span>}
        </button>
    )
}

const TD = (props) => {
    const { user, detail: feild, totalNumOfDetails, handleChange, pickLocation, similaritiesAttachmentDialogRef } = props;

    const handlePickLocation = (internalName, value) => {
        pickLocation(internalName, value);
    }

    const withCoordinate = () => {
        return feild.internalName == 'ELS_OFFER_LAND_COORDINATE1' ||
            feild.internalName == 'ELS_OFFER_LAND_COORDINATE2' ||
            feild.internalName == 'ELS_OFFER_LAND_COORDINATE3' ||
            feild.internalName == 'ELS_OFFER_LAND_COORDINATE4' ||
            feild.internalName == 'ELS_OFFER_LAND_COORDINATE5' ||
            feild.internalName == 'ELS_OFFER_BULID_COORDINATE1' ||
            feild.internalName == 'ELS_OFFER_BULID_COORDINATE2' ||
            feild.internalName == 'ELS_OFFER_BULID_COORDINATE3' ||
            feild.internalName == 'ELS_OFFER_BULID_COORDINATE4' ||
            feild.internalName == 'ELS_OFFER_BULID_COORDINATE5'
    }

    const attachmentFiled = (feild.internalName.includes('ELS_OFFER_LAND_IMAGE_') || feild.internalName.includes('ELS_OFFER_BULID_IMAGE_'));

    return (
        <div className={!feild.enabled ? style.disabledCell : ''} style={{ '--num-of-cells': totalNumOfDetails, background: feild.enabled ? '' : 'var(--disable-color)' }}>
            {
                (feild.dataType == DATA_TYPE_TEXT || feild.dataType == DATA_TYPE_MULTI_LINES_TEXT || feild.dataType == DATA_TYPE_TEXT_ALIGN_CENTER) && !attachmentFiled &&
                <>
                    <InputText
                        inTable
                        feild={feild}
                        handleChange={handleChange}
                        multiLines={false}
                        isCoordinateFeild={withCoordinate(feild)}
                    />
                    {withCoordinate() && <PickLocationButton feild={feild} handlePickLocation={handlePickLocation} />}
                </>
            }
            {
                (feild.dataType == DATA_TYPE_NUMBER && !attachmentFiled) &&
                <InputNumber
                    user={user}
                    inTable
                    feild={feild}
                    handleChange={handleChange}
                />
            }
            {
                (feild.dataType == DATA_TYPE_DATE || feild.dataType == DATA_TYPE_DATE_TIME || feild.dataType == DATA_TYPE_TIME || feild.dataType == DATA_TYPE_HIJRI_DATE) && !attachmentFiled &&
                <DatePicker
                    inTable
                    feild={feild}
                    handleChange={handleChange}
                />
            }
            {attachmentFiled &&
                <UploadAttachmentsButton feild={feild} similaritiesAttachmentDialogRef={similaritiesAttachmentDialogRef} />
            }
        </div>
    )
}

const Table = (props) => {
    const { user, fields, handleChange, pickLocation, similaritiesAttachmentDialogRef } = props;

    const _getTableWidth = () => {
        let numberOfColumns = 1;
        fields?.map(feild => {
            if (feild.fieldDetails?.length > numberOfColumns) {
                numberOfColumns = feild.fieldDetails?.length
            }
        })
        if (numberOfColumns > 7) {
            if (numberOfColumns <= 10) {
                return `${((numberOfColumns - 7) * 10) + 100}%`;
            } else {
                return `${((numberOfColumns - 7) * 8) + 100}%`;
            }
        }
        return "100%";
    }

    return (
        <div className={style.tableContainer}>
            <div className={style.table} style={{ width: _getTableWidth() }}>
                <TH feilds={fields} />
                {fields?.map((feild) => {
                    return (
                        <div key={feild.id} className={style.tr}>
                            <div>{isEnglish() ? feild.userDescLo : feild.userDescFo}</div>
                            {feild.fieldDetails.length > 0 &&
                                feild.fieldDetails.map(detail => {
                                    return <>
                                        <label className={style.thLblMobile}>{isEnglish() ? detail.userDescLo : detail.userDescFo}</label>
                                        <TD user={user} key={detail.id} detail={detail} handleChange={handleChange} totalNumOfDetails={feild.fieldDetails.length} pickLocation={pickLocation} similaritiesAttachmentDialogRef={similaritiesAttachmentDialogRef} />
                                    </>
                                })}
                            {feild.fieldDetails.length == 0 &&
                                <TD user={user} detail={feild} handleChange={handleChange} totalNumOfDetails={1} pickLocation={pickLocation} />
                            }
                        </div>)
                })}
            </div>
        </div>
    )
}

export default Table;