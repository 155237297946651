import ActionsButton from "@ipgd-gauge/actions-button";
import { confirmationDialog, handleError, isAllowedAction, isEnglish, isNotEmpty, showSuccessMessage } from "@ipgd-gauge/utils";
import i18next from "i18next";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import AddBtnIcon from "../../../icons/AddBtn";
import ApproveIcon from "../../../icons/Approve";
import DeleteTowIcon from "../../../icons/Delete2";
import IncompleteIcon from "../../../icons/Incomplete";
import PrinterIcon from "../../../icons/Printer";
import RejectIcon from "../../../icons/Reject";
import SaveIcon from "../../../icons/Save";
import { confirmEstimationInvoice, deleteEstimationInvoice, getLinkWithZatcaPref, rejectEstimationInvoice, saveEstimationInvoice } from "../../../util/apis";
import { GLOBAL_STATUS_CONFIRMED, GLOBAL_STATUS_INCOMPLETE, GLOBAL_STATUS_SUBMITTED } from "../../../util/constants";
import { CONFIRM_FORM_ACTION, DELETE_ACTION, INCOMPLETE_ACTION, NEW_ACTION, PRINT_ACTION, REJECT_ACTION, SAVE_ACTION } from "../../../util/constants/actions";
import { RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID } from "../../../util/constants/forms";
import { SAVE_ACTION_SHORT_CUT } from "../../../util/constants/hotkeys";
import { ESTIMATION_FEES, ITEM } from "./Details";
import { ACTION_SET_INVOICE } from "./reducer";
import InputsBtnVeiwIcon from "../../../icons/InputsBtnVeiw";
import WorkflowIcon from "../../../icons/Workflow";
import { ActionIcon } from "../../../icons/WorkflowIcons";

const Actions = (props, ref) => {
    const { user, invoiceState, printDialogRef, dispatchInvoice, newAction, adjustWorkDialogRef, workflowsActionLogDialogRef, saveAction, fromSaveActionRef, zatcaSummaryRef } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [actions, setActions] = useState([]);
    const [workflowAction, setWorkflowAction] = useState(null);

    useImperativeHandle(ref, () => ({ setWorkflowAction }));

    const _confirmInvoice = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return confirmEstimationInvoice(invoiceState?.invoice?.header?.id)
                .then(() => {
                    navigate(`/customers-estimation-invoices`);
                    showSuccessMessage('action_performed_successfully', t);
                })
                .catch((error) => handleError(error, error.response.data.message.split('#')[0], t));
        });
    }

    const _rejectInvoice = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return rejectEstimationInvoice(invoiceState?.invoice?.header?.id)
                .then(() => {
                    navigate(`/customers-estimation-invoices`);
                    showSuccessMessage('action_performed_successfully', t);
                })
                .catch((error) => handleError(error, error.response.data.message.split('#')[0], t));
        });
    }

    const _deleteInvoice = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return deleteEstimationInvoice(invoiceState?.invoice?.header?.id)
                .then(() => {
                    navigate(`/customers-estimation-invoices`);
                    showSuccessMessage("delete_successfully", t);
                })
                .catch((error) => handleError(error, error.response.data.message.split('#')[0], t));
        });
    }

    const openZatcaSummary = () => {
        zatcaSummaryRef.current.open(RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID, invoiceState.invoice.header.id);
    }
    
    const _isAllowedSaveAction = () => {
        const status = invoiceState?.invoice?.header?.globalStatus;
        return (status == GLOBAL_STATUS_INCOMPLETE || status == GLOBAL_STATUS_SUBMITTED) && isAllowedAction(user, RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID, SAVE_ACTION);
    }

    const _isAllowedLinkZatca = async () => {
        const resp = await getLinkWithZatcaPref();
        return resp.data == '100001' && invoiceState?.invoice?.header?.globalStatus == GLOBAL_STATUS_CONFIRMED;
    }

    const _executeWorkflowAction = () => {
        if (isNotEmpty(invoiceState?.invoice?.header?.id) && isNotEmpty(workflowAction.actionId) && isNotEmpty(workflowAction.actionType)) {
            adjustWorkDialogRef.current.open(invoiceState?.workflowId, invoiceState?.stepId, invoiceState?.invoice?.header?.id);
        }
    }

    const isSaved = () => isNotEmpty(invoiceState?.invoice?.header?.id);
    const isWorkflow = () => isNotEmpty(invoiceState?.workflowId);
    const updateAllowed = () => workflowAction?.updateAllowed == 1;

    useEffect(() => {
        let temp = [];

        const status = invoiceState?.invoice?.header?.globalStatus;

        if ((status == GLOBAL_STATUS_INCOMPLETE && isNotEmpty(invoiceState?.invoice?.header?.id)) && isAllowedAction(user, RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID, DELETE_ACTION)) {
            temp.push({
                id: "delete-customer-estimation-invoice",
                label: t('customersEstimationInvoices:delete'),
                color: "var(--action-delete-bg)",
                iconColor: "var(--action-delete-bg)",
                icon: <DeleteTowIcon />,
                action: _deleteInvoice
            });
        }

        if (status == GLOBAL_STATUS_SUBMITTED && isAllowedAction(user, RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID, REJECT_ACTION) && !isWorkflow()) {
            temp.push({
                id: "reject-customer-estimation-invoice",
                label: t('customersEstimationInvoices:reject'),
                color: "var(--action-delete-bg)",
                icon: <RejectIcon />,
                action: _rejectInvoice
            });
        }

        if (newAction && isAllowedAction(user, RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID, NEW_ACTION)) {
            temp.push({
                id: "new-customer-estimation-invoice",
                label: t('customersEstimationInvoices:new'),
                color: "var(--action-copy)",
                icon: <AddBtnIcon />,
                action: () => newAction()
            });
        }

        if (isAllowedAction(user, RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID, PRINT_ACTION)) {
            temp.push({
                id:
                    "print-customer-estimation-invoice",
                label: t('customersEstimationInvoices:print'),
                color: "var(--pageination)",
                action: () => printDialogRef.current?.open([invoiceState?.invoice?.header?.id]),
                icon: <PrinterIcon />
            });
        }

        if (status == GLOBAL_STATUS_SUBMITTED && !isWorkflow()) {
            if (isAllowedAction(user, RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID, CONFIRM_FORM_ACTION)) {
                temp.push({
                    id: "approve-customer-estimation-invoice",
                    label: t('customersEstimationInvoices:confirm'),
                    color: "var(--action-save)",
                    icon: <ApproveIcon />,
                    action: _confirmInvoice
                });
            }
        }

        if (status == GLOBAL_STATUS_INCOMPLETE && isAllowedAction(user, RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID, INCOMPLETE_ACTION)) {
            temp.push({
                id: "draft-customer-estimation-invoice",
                label: t('customersEstimationInvoices:draft'),
                icon: <IncompleteIcon />,
                action: () => saveAction(false)
            });
        }

        if (_isAllowedSaveAction() && !workflowAction) {
            temp.push({
                id: "save-customer-estimation-invoice",
                hotkey: SAVE_ACTION_SHORT_CUT,
                color: "var(--action-save)",
                label: t((!isWorkflow() || (isWorkflow() && isSaved() && updateAllowed())) ? 'save' : 'submit'),
                action: (!isWorkflow() || (isWorkflow() && isSaved() && updateAllowed())) ? () => saveAction(true) : () => adjustWorkDialogRef.current.open(invoiceState?.workflowId, invoiceState?.stepId, invoiceState?.invoice?.header?.id),
                icon: <SaveIcon />,
            });
        }

        if (isWorkflow() && isSaved()) {
            temp.push({
                id: 'workflow-status',
                label: t('workflow_status'),
                action: () => workflowsActionLogDialogRef.current.open(invoiceState?.workflowId, invoiceState?.invoice?.header?.id, invoiceState?.stepId, status),
                iconColor: "#8A3FFC",
                icon: <WorkflowIcon />,
            });
        }

        if (isWorkflow() && workflowAction != null && Object.keys(workflowAction).length > 0) {
            temp.push({
                id: 'workflow',
                label: isEnglish() ? workflowAction?.actionDescLo : workflowAction?.actionDescFo,
                action: () => _executeWorkflowAction(),
                color: workflowAction?.statusBgColor,
                icon: <ActionIcon actionType={workflowAction.actionType} />,
            });
        }
        if (_isAllowedLinkZatca()) {
            temp.push({
                label: t('zatca_summary'),
                action: openZatcaSummary,
                icon: <InputsBtnVeiwIcon />,
                color: "#007A3D",
            })
        }

        setActions([...temp]);
    }, [i18next.language, invoiceState, workflowAction]);

    useHotkeys(SAVE_ACTION_SHORT_CUT, () => {
        if (_isAllowedSaveAction()) {
            saveAction(true);
        }
    }, { enableOnFormTags: true });

    return <ActionsButton actions={actions} />
}

export default forwardRef(Actions);