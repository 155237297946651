export const StartIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        data-name="Layer 1"
        viewBox="0 0 24 24"
    >
        <path fill="#fff" d="M20.426 10.097c-1.231-1.445-3.415-3.622-6.832-5.779-2.81-1.774-5.311-2.716-6.915-3.194a2.94 2.94 0 0 0-2.612.464 2.94 2.94 0 0 0-1.193 2.377v16.07c0 .945.436 1.811 1.193 2.377a2.94 2.94 0 0 0 2.613.464c1.604-.478 4.105-1.42 6.915-3.194 3.417-2.158 5.601-4.334 6.832-5.78a2.924 2.924 0 0 0 0-3.805Z"></path>
    </svg>
);

const ApproveIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="workflow-approve-icon"
        width="512"
        height="512"
        data-name="workflow approve icon"
        viewBox="0 0 24 24"
    >
        <path d="M11.24 24a2.3 2.3 0 0 1-.948-.212 2.18 2.18 0 0 1-1.2-2.622L10.653 16H6.975A3 3 0 0 1 4.1 12.131l3.024-10A2.98 2.98 0 0 1 10 0h3.693a2.6 2.6 0 0 1 2.433 3.511L14.443 8H17a3 3 0 0 1 2.483 4.684l-6.4 10.3A2.2 2.2 0 0 1 11.24 24M10 2a1 1 0 0 0-.958.71l-3.024 10A1 1 0 0 0 6.975 14H12a1 1 0 0 1 .957 1.29l-1.947 6.442a.183.183 0 0 0 .121.241.19.19 0 0 0 .269-.073l6.4-10.3a1 1 0 0 0 .078-1.063A.98.98 0 0 0 17 10h-4a1 1 0 0 1-.937-1.351l2.19-5.84a.6.6 0 0 0-.56-.809Z"></path>
    </svg>
);

export const TriggeringIcon = () => (
    // <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 512 512">
    //     <path
    //         fill="#0058af"
    //         d="M405.33 0H106.67C47.79.07.07 47.79 0 106.67v298.67c.07 58.88 47.79 106.6 106.67 106.67h298.67c58.88-.07 106.6-47.79 106.67-106.67V106.67C511.94 47.79 464.22.07 405.34 0Zm64 405.33c0 35.35-28.65 64-64 64H106.67c-35.35 0-64-28.65-64-64V106.67c0-35.35 28.65-64 64-64h298.67c35.35 0 64 28.65 64 64v298.67Z"
    //     ></path>
    //     <path
    //         fill="#0058af"
    //         d="M443.68 126.57c-.78-16.03-8.4-30.97-20.85-41.08-22.25-18.21-56.18-15.72-77.5 5.6l-25.99 25.99a77.67 77.67 0 0 0-43.11-13.07c-20.85 0-40.3 8.09-55.09 22.72l-47.15 47.15a15.493 15.493 0 0 0 0 21.94 15.493 15.493 0 0 0 21.94 0l47.15-47.15c13.85-13.85 35.79-16.81 53.22-8.87L102.4 334.01c-10.74 10.74-16.65 24.9-16.65 39.99 0 9.8 2.49 19.14 7.16 27.54l-18.05 18.05a15.493 15.493 0 0 0 0 21.94c3.11 3.11 7 4.51 11.05 4.51s7.94-1.56 11.05-4.51l18.05-18.05c8.25 4.67 17.74 7.16 27.54 7.16 15.09 0 29.41-5.91 39.99-16.65l244.47-244.63c11.36-11.36 17.27-26.92 16.5-42.95l.16.16ZM160.46 392.05c-9.65 9.65-26.45 9.65-36.1 0-4.82-4.82-7.47-11.2-7.47-18.05s2.65-13.23 7.47-18.05l196.08-195.92 36.1 36.1zm244.63-244.63-26.61 26.61-36.1-36.1 24.9-24.9c5.76-5.76 13.23-8.71 20.54-8.71 5.6 0 10.89 1.71 15.25 5.29 5.76 4.67 9.03 11.2 9.49 18.52.31 7.16-2.33 14.32-7.47 19.3Z"
    //     ></path>
    // </svg>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="75"
        height="75"
        viewBox="0 0 75 75"
    >
        <path
            fill="#fff"
            d="M6.989 75A6.993 6.993 0 010 68.014V6.989A7 7 0 016.989 0h46.926a7.049 7.049 0 015.063 2.188l13.979 15.663A7.034 7.034 0 0175 22.794v45.22A6.992 6.992 0 0168.014 75zm-.172-6.986a.171.171 0 00.172.169h61.025a.17.17 0 00.169-.169V22.791c0-.049-.025-.1-.181-.264L54.026 6.867a.191.191 0 00-.11-.049H40.909v10.171a6.779 6.779 0 01-6.818 6.876H6.818zm.12-50.966h27.154V6.818H6.989a.171.171 0 00-.171.164zm16.415 27.61A14.148 14.148 0 1137.5 58.806a14.148 14.148 0 01-14.148-14.148zm6.818 0a7.33 7.33 0 107.33-7.33 7.332 7.332 0 00-7.33 7.33z"
            data-name="Union 12"
        ></path>
    </svg>
);

export const ConfirmIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        data-name="Layer 1"
        viewBox="0 0 27.45 25.92"
    >
        <path d="M7.48 10.67H-.14v15.25h7.62zm16.43.01h-6.08c-.18 0-.31-.18-.26-.35l2.21-7.18C20.27 1.58 18.99 0 17.22 0c-.88 0-1.74.37-2.32.99l-8.94 9.69v15.25h15.9c1.62 0 3.02-1.02 3.34-2.46l2.04-9.15c.41-1.89-1.19-3.64-3.34-3.64ZM5.96 24.4H1.38V12.2h4.57v12.2Zm19.79-10.42-2.04 9.15c-.15.73-.93 1.27-1.85 1.27H7.48V11.27l8.54-9.24c.29-.32.73-.5 1.19-.5.4 0 .76.17.96.46.11.15.23.4.14.72l-2.32 7.53-.37 1.21c-.12.38.17.76.56.76h7.71c.63 0 1.22.26 1.57.7.2.23.4.61.29 1.08Z"></path>
    </svg>
);

export const EndIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        data-name="Layer 1"
        viewBox="0 0 24 24"
    >
        <path fill="#fff" d="M21.806 2.858a1 1 0 0 0-.685-.67C20.948 2.139 16.848 1 12 1S3.052 2.139 2.88 2.188a1 1 0 0 0-.687.674C2.144 3.023 1 6.869 1 12.001c0 5.175 1.146 8.982 1.194 9.142.1.325.358.578.686.67.172.049 4.272 1.188 9.121 1.188s8.947-1.139 9.12-1.188c.329-.093.588-.347.687-.674.049-.161 1.193-4.007 1.193-9.139 0-5.175-1.146-8.982-1.194-9.142Z"></path>
    </svg>
);

export const BotheDecisionIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107.01 79.02">
        <g id="Layer_1" data-name="Layer 1" imageRendering="optimizeQuality" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
            <line stroke="#e7e8f3" strokeMiterlimit="10" strokeWidth="0.75" fill="none" x1="53.5" y1="15.48" x2="53.5" y2="33.16" />

            <polyline stroke="#e7e8f3" strokeMiterlimit="10" fill="none" points="63.71 37.81 87.15 37.81 87.15 68.47" />
            <path fill="#e7e8f3" d="M105.61,79.02h-36.91c-.77,0-1.4-.63-1.4-1.4v-12.09c0-.77.63-1.4,1.4-1.4h36.91c.77,0,1.4.63,1.4,1.4v12.09c0,.77-.63,1.4-1.4,1.4Z" />
            <rect fill="#f4f5fa" x="80.53" y="70.17" width="13.24" height="2.83" rx="1.04" ry="1.04" />

            <path fill="#e7e8f3" d="M71.96,14.88h-36.91c-.77,0-1.4-.63-1.4-1.4V1.4c0-.77.63-1.4,1.4-1.4h36.91c.77,0,1.4.63,1.4,1.4v12.09c0,.77-.63,1.4-1.4,1.4Z" />
            <rect fill="#f4f5fa" x="46.88" y="6.03" width="13.24" height="2.83" rx="1.04" ry="1.04" />

            <polyline stroke="#e7e8f3" strokeMiterlimit="10" fill="none" points="43.3 37.81 19.85 37.81 19.85 68.47" />
            <path fill="#e7e8f3" d="M1.4,79.02h36.91c.77,0,1.4-.63,1.4-1.4v-12.09c0-.77-.63-1.4-1.4-1.4H1.4c-.77,0-1.4.63-1.4,1.4v12.09c0,.77.63,1.4,1.4,1.4Z" />
            <rect fill="#f4f5fa" x="13.24" y="70.17" width="13.24" height="2.83" rx="1.04" ry="1.04" transform="translate(39.71 143.16) rotate(180)" />

            <rect fill="#e7e8f3" x="43.3" y="27.62" width="20.4" height="20.4" transform="translate(42.41 -26.76) rotate(45)" />
            <path fill="#f4f5fa" fillRule="evenodd" d="M57.68,39.35h-8.35c-1.08,0-1.87-.88-1.87-1.96s.79-1.87,1.87-1.87h8.35c1.08,0,1.87.79,1.87,1.87s-.79,1.96-1.87,1.96Z" />

            <rect fill="#4bc628" x="76.93" y="32.09" width="20.44" height="10.19" rx="3.73" ry="3.73" />
            <rect fill="#fc1027" x="9.63" y="32.09" width="20.44" height="10.19" rx="3.73" ry="3.73" />
        </g>
    </svg >
);

export const YesDecisionIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73.36 99.63">
        <g
            id="Layer_1"
            data-name="Layer 1"
            imageRendering="optimizeQuality"
            shapeRendering="geometricPrecision"
            textRendering="geometricPrecision"
        >
            <path fill="none" stroke="#e7e8f3" d="M19.85 15.48v17.68M30.06 37.81H53.5v30.66"></path>
            <path
                fill="#e7e8f3"
                d="M71.96 71.58H35.05c-.77 0-1.4-.63-1.4-1.4V58.09c0-.77.63-1.4 1.4-1.4h36.91c.77 0 1.4.63 1.4 1.4v12.09c0 .77-.63 1.4-1.4 1.4"
            ></path>
            <rect width="13.24" height="2.83" x="46.88" y="62.73" fill="#f4f5fa" rx="1.04" ry="1.04"></rect>
            <path
                fill="#e7e8f3"
                d="M38.31 14.88H1.4c-.77 0-1.4-.63-1.4-1.4V1.4C0 .63.63 0 1.4 0h36.91c.77 0 1.4.63 1.4 1.4v12.09c0 .77-.63 1.4-1.4 1.4Z"
            ></path>
            <rect width="13.24" height="2.83" x="13.24" y="6.03" fill="#f4f5fa" rx="1.04" ry="1.04"></rect>
            <path fill="none" stroke="#e7e8f3" d="M19.85 49.5v39.58"></path>
            <path
                fill="#e7e8f3"
                d="M1.4 99.63h36.91c.77 0 1.4-.63 1.4-1.4V86.14c0-.77-.63-1.4-1.4-1.4H1.4c-.77 0-1.4.63-1.4 1.4v12.09c0 .77.63 1.4 1.4 1.4"
            ></path>
            <rect width="13.24" height="2.83" x="13.24" y="90.77" fill="#f4f5fa" rx="1.04" ry="1.04" transform="rotate(180 19.855 92.185)"></rect>
            <path
                fill="#e7e8f3"
                d="m19.843 23.394 14.425 14.425-14.425 14.425L5.418 37.819z"
            ></path>
            <path
                fill="#f4f5fa"
                d="M24.03 39.35h-8.35c-1.08 0-1.87-.88-1.87-1.96s.79-1.87 1.87-1.87h8.35c1.08 0 1.87.79 1.87 1.87s-.79 1.96-1.87 1.96"
            ></path>
            <rect width="20.44" height="10.19" x="43.28" y="32.09" fill="#4bc628" rx="3.73" ry="3.73"></rect>
        </g>
    </svg>
);

export const NoDecisionIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73.36 99.63">
        <g
            id="Layer_1"
            data-name="Layer 1"
            imageRendering="optimizeQuality"
            shapeRendering="geometricPrecision"
            textRendering="geometricPrecision"
        >
            <path fill="none" stroke="#e7e8f3" d="M53.5 15.48v17.68M43.3 37.81H19.85v30.66"></path>
            <path
                fill="#e7e8f3"
                d="M1.4 71.58h36.91c.77 0 1.4-.63 1.4-1.4V58.09c0-.77-.63-1.4-1.4-1.4H1.4c-.77 0-1.4.63-1.4 1.4v12.09c0 .77.63 1.4 1.4 1.4"
            ></path>
            <rect width="13.24" height="2.83" x="13.24" y="62.73" fill="#f4f5fa" rx="1.04" ry="1.04" transform="rotate(180 19.855 64.14)"></rect>
            <path
                fill="#e7e8f3"
                d="M35.05 14.88h36.91c.77 0 1.4-.63 1.4-1.4V1.4c0-.77-.63-1.4-1.4-1.4H35.05c-.77 0-1.4.63-1.4 1.4v12.09c0 .77.63 1.4 1.4 1.4Z"
            ></path>
            <rect width="13.24" height="2.83" x="46.88" y="6.03" fill="#f4f5fa" rx="1.04" ry="1.04" transform="rotate(180 53.505 7.44)"></rect>
            <path fill="none" stroke="#e7e8f3" d="M53.5 49.5v39.58"></path>
            <path
                fill="#e7e8f3"
                d="M71.96 99.63H35.05c-.77 0-1.4-.63-1.4-1.4V86.14c0-.77.63-1.4 1.4-1.4h36.91c.77 0 1.4.63 1.4 1.4v12.09c0 .77-.63 1.4-1.4 1.4"
            ></path>
            <rect width="13.24" height="2.83" x="46.88" y="90.77" fill="#f4f5fa" rx="1.04" ry="1.04"></rect>
            <path
                fill="#e7e8f3"
                d="M67.922 37.807 53.497 52.232 39.072 37.807l14.425-14.425z"
            ></path>
            <path
                fill="#f4f5fa"
                d="M49.33 39.35h8.35c1.08 0 1.87-.88 1.87-1.96s-.79-1.87-1.87-1.87h-8.35c-1.08 0-1.87.79-1.87 1.87s.79 1.96 1.87 1.96"
            ></path>
            <rect width="20.44" height="10.19" x="9.63" y="32.09" fill="#fc1027" rx="3.73" ry="3.73" transform="rotate(180 19.855 37.19)"></rect>
        </g>
    </svg>
);

export const ActionIcon = (props) => {
    const { actionType } = props;

    if (actionType == "108602") {
        return <ApproveIcon />
    } else if (actionType == "108603") {
        return <ConfirmIcon />
    }

    return <TriggeringIcon />
}