import { DateFromToInput, FrmTimeDatePickr } from "@ipgd-gauge/date-picker";
import FrmInput from "@ipgd-gauge/frm-input";
import { DateFromToInputHijri } from "@ipgd-gauge/hijri-date";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import ToggleButton from "@ipgd-gauge/toggle-button";
import { getUrlParam, handleError, isEmpty, isEnglish, isNotEmpty } from "@ipgd-gauge/utils";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CodeInput from "../../../../components/code-input";
import ExchangeRateInput from "../../../../components/exchange-rate";
import HijriDate from "../../../../components/hijri-date";
import { convertGregorianDateToHijri, convertHijriToMilliseconds, getCommonBranches, getCurrenciesLov, getDomainValue, getEstimationsQuotationTypes } from "../../../../util/apis";
import { STATUS_ACTIVE } from "../../../../util/constants";
import { RSM3190_EVALUATION_QUOTATIONS_FORM_ID } from "../../../../util/constants/forms";
import { isOrgCurrency } from "../../../../util/util";
import style from '../style.module.scss';

const BRANCH_CONDITION = 'dependsOnPolicyId';
const StatusPanel = ({ data }) => {
    const { t } = useTranslation();

    const [status, setStatus] = useState({});

    useEffect(() => {
        if (isNotEmpty(data.globalStatus)) {
            getDomainValue(data.globalStatus)
                .then((response) => setStatus(response.data))
                .catch((error) => handleError(error, null, t));
        }
    }, [data.globalStatus]);

    return (
        <div className={style.statusHeader}>
            <label className={`status-${data?.globalStatus}`}>{isEnglish() ? status?.descLo : status?.descFo}</label>
        </div>
    )
}

const FollowUpStatusPanel = ({ data }) => {
    const { t } = useTranslation();
    const [status, setStatus] = useState({});

    useEffect(() => {
        if (isNotEmpty(data.status)) {
            getDomainValue(data.status)
                .then((response) => setStatus(response.data))
                .catch((error) => handleError(error, null, t));
        }
    }, [data.status]);

    return (
        <div className={style.statusHeader}>
            <label className={`status-${data?.status}`} >{isEnglish() ? status?.descLo : status?.descFo}</label>
        </div>
    )
}

const WorkflowStatusPanel = ({ data }) => {
    if (!data.workflowStatus) {
        return <></>;
    }

    return (
        <div className={style.statusHeader} style={{ backgroundColor: `${data?.workflowStatus?.backgroundColor}`, color: `${data?.workflowStatus?.color}` }}>
            <label className={`status-label`} >
                {isEnglish() ? data?.workflowStatus?.descLo : data?.workflowStatus?.descFo}
            </label>
        </div>
    )
}

const Header = (props) => {
    const { currentUser, estimationQuotation, handleDataChanged } = props;

    const { t } = useTranslation("estimationQuotation");
    const retrieveQuotationTypeFirstTime = useRef(true);

    return (
        <>
            <div>
                <div className="frm-title-panel-border-toggle">
                    <div>
                        <label className="frm-title-form-border">
                            {t('header')}
                        </label>
                        <div className={style.headerStatus}>
                            <StatusPanel data={estimationQuotation} />
                            <FollowUpStatusPanel data={estimationQuotation} />
                            <WorkflowStatusPanel data={estimationQuotation} />
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="w25">
                            <CodeInput
                                id="estimation-quotation-code"
                                value={estimationQuotation?.code}
                                onBlur={(value) => handleDataChanged("code", value)}
                                column='MMA_CODE'
                                formId={RSM3190_EVALUATION_QUOTATIONS_FORM_ID}
                            />
                        </div>
                        <div className="w25">
                            <label id='date-g-lbl' className='form-label mandatory'>{t('date_g')}</label>
                            <FrmTimeDatePickr
                                id='date-g'
                                value={estimationQuotation?.dateDgr}
                                onChange={(value) => handleDataChanged("dateDgr", value)}
                            />
                        </div>
                        <div className="w25">
                            <label className='form-label mandatory'>{t('date_h')}</label>
                            <HijriDate
                                id='date-h'
                                hijriDate={estimationQuotation?.dateDhi}
                                gregorianDate={estimationQuotation?.dateDgr}
                                onChange={(value) => handleDataChanged("dateDhi", value)}
                                disabled={true}
                            />
                        </div>
                        <div className="w100">
                            <label className='form-label'>{t('remarks')}</label>
                            <FrmInput
                                id="estimation-quotation-rem"
                                value={estimationQuotation?.rem}
                                onBlur={(value) => handleDataChanged("rem", value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="frm-title-panel-border-toggle">
                    <div>
                        <label className="frm-title-form-border">
                            {t('branch_and_policy')}
                        </label>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="w50">
                            <label id="estimation-quotation-branch-lbl" className='form-label mandatory'>{t('branch')}</label>
                            <FrmSelectMenu
                                id="estimation-quotation-branch"
                                key={`estimation-quotation-branch-${estimationQuotation?.id}`}
                                api={() => getCommonBranches(null, isEmpty(estimationQuotation.mbnId) ? [] : [estimationQuotation.mbnId], STATUS_ACTIVE, BRANCH_CONDITION)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                value={estimationQuotation.mbnId}
                                onChange={value => handleDataChanged("mbnId", value.value)}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label id="estimation-quotation-type-lbl" className='form-label mandatory'>{t('type')}</label>
                            <FrmSelectMenu
                                id="estimation-quotation-type"
                                key={`estimation-quotation-type-${estimationQuotation.id}`}
                                value={estimationQuotation.umyId}
                                api={() => getEstimationsQuotationTypes(estimationQuotation.umyId)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => handleDataChanged("umyId", value)}
                                onDataRetrieved={(list) => {
                                    const trxId = getUrlParam("id");
                                    if (trxId && estimationQuotation.umyId) {
                                        list?.forEach((d) => {
                                            if (d.id == estimationQuotation.umyId) {
                                                handleDataChanged("quotationType", { ...d, value: d.id })
                                                return
                                            }
                                        })
                                    }
                                    if (retrieveQuotationTypeFirstTime.current && estimationQuotation?.id) {
                                        retrieveQuotationTypeFirstTime.current = false;
                                    }
                                }}
                                portalTarget={document.body}
                                isDisabled={estimationQuotation?.lines?.length > 0}
                            />
                        </div>

                        <div className="w25">
                            <label id="currency-lbl" className='form-label mandatory'>{t('currency')}</label>
                            <FrmSelectMenu
                                id="currency"
                                key={`currency-${estimationQuotation?.id}`}
                                api={() => getCurrenciesLov(estimationQuotation.fcuId)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                value={estimationQuotation.fcuId}
                                onChange={value => handleDataChanged("fcuId", value.value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w25">
                            <label id="ex-rate-lbl" className={`form-label ${!isOrgCurrency(currentUser.fcuId, estimationQuotation.fcuId) ? 'mandatory' : ''}`}>{t('ex_rate')}</label>
                            <ExchangeRateInput
                                id="ex-rate"
                                value={estimationQuotation?.exchngRate}
                                onBlur={(value) => handleDataChanged("exchngRate", value)}
                                currencyId={estimationQuotation.fcuId}
                                transactionId={estimationQuotation?.id}
                                transactionDate={estimationQuotation?.dateDgr}
                                organizationCurrency={currentUser.fcuId}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div>


                <div className="frm-title-panel-border-toggle">
                    <div>
                        <label className="frm-title-form-border">
                            {t('validity_period')}
                        </label>
                        <div>
                            <ToggleButton
                                id="validity-period-toggle"
                                isChecked={estimationQuotation?.hasValidyPeriod}
                                setIsChecked={(value) => handleDataChanged("hasValidyPeriod", value)}
                            />
                        </div>
                    </div>
                </div>
                {estimationQuotation?.hasValidyPeriod &&
                    <div className="container">
                        <div className="row">
                            <div className="w50">
                                <label id='validity-date-dgr-lbl' className='form-label mandatory'>{t('validity_date_g')}</label>
                                <DateFromToInput
                                    id='validity-date-dgr'
                                    fromValue={estimationQuotation?.validityPeriodFromDgr}
                                    fromOnChange={(value) => handleDataChanged("validityPeriodFromDgr", value)}
                                    toValue={estimationQuotation?.validityPeriodToDgr}
                                    toOnChange={(value) => handleDataChanged("validityPeriodToDgr", value)}
                                />
                            </div>

                            <div className="w50">
                                <label className='form-label mandatory'>{t('validity_date_h')}</label>
                                <DateFromToInputHijri
                                    id='validity-date'
                                    fromValue={estimationQuotation?.validityPeriodFromDhi}
                                    fromOnChange={(value) => handleDataChanged("validityPeriodFromDhi", value)}
                                    toValue={estimationQuotation?.validityPeriodToDhi}
                                    toOnChange={(value) => handleDataChanged("validityPeriodToDhi", value)}
                                    fromGregorianValue={estimationQuotation?.validityPeriodFromDgr}
                                    toGregorianValue={estimationQuotation?.validityPeriodToDgr}
                                    convertHijriToMilliseconds={convertHijriToMilliseconds}
                                    convertGregorianDateToHijri={convertGregorianDateToHijri}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default Header;