
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import FiltersIcon from "../../icons/Filters";
import { RSM2669_BRANCHES_DEFINITIONS_FORM_ID } from "../../util/constants/forms";
import { getUser } from "../../util/cookies";
import BranchesDefinitionDialog from "./dialogs";
import BranchesDefinitionTable from "./listing";
import BranchesDefinitionFilter from "./listing/Filters";

const BranchesDefinition = () => {
    const searchTextRef = useRef("");
    const filtersRef = useRef({});
    const tableRef = useRef();
    const dialogRef = useRef(null);
    const { t } = useTranslation("branchesDefinition");

    const _openFilter = () => {
        filtersRef.current?.open();
    }

    const newAction = () => {
        dialogRef?.current?.open()
    }

    const user = getUser();

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle
                    formId={RSM2669_BRANCHES_DEFINITIONS_FORM_ID}
                />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        formId={RSM2669_BRANCHES_DEFINITIONS_FORM_ID}
                        placeholder={t('code') + " , " + t('name')}
                        search={() => tableRef.current.refresh()}
                    />
                    <button className="headerPanelBtn" onClick={_openFilter}>
                        <FiltersIcon />
                    </button>
                </div>
            </div>
            <BranchesDefinitionTable
                filtersRef={filtersRef}
                dialogRef={dialogRef}
                tableRef={tableRef}
                searchTextRef={searchTextRef}
                newAction={newAction}
                user={user}
            />
            <BranchesDefinitionFilter
                ref={filtersRef}
                tableRef={tableRef} />
            <BranchesDefinitionDialog
                ref={dialogRef}
                tableRef={tableRef}
                user={user}
            />


        </>
    )
}
export default BranchesDefinition;