import { FrmTimeDatePickr } from "@ipgd-gauge/date-picker";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../../components/advance-filter";
import StatusSelectMenu from "../../../components/status-select-menu";
import { getCommonBranches, getCommonCustomers, getCommonDivisionsList, getDomainValues, getDomainValuesWithCondition } from "../../../util/apis";
import { DOMAIN_GLOBAL_STATUS, GLOBAL_STATUS_CONFIRMED, GLOBAL_STATUS_INCOMPLETE, GLOBAL_STATUS_REJECTED, GLOBAL_STATUS_SUBMITTED, ZATCA_STATUS } from "../../../util/constants";
import { RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID } from "../../../util/constants/forms";
import WorkflowStatusFilter from "../../../components/workflow-status-filter";

const BRANCH_CONDITION = 'dependsOnPolicyId';
const DIVISION_CONDITION = 'dependsOnPolicyIdOnly';

const CreditNotesforCustomersEstimationFilter = (props, ref) => {
    const { tableRef } = props;

    const { t } = useTranslation();
    const advanceFilterRef = useRef();

    const [filterData, setFilterData] = useState({});

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    useImperativeHandle(ref, () => ({
        open: () => advanceFilterRef.current?.open(),
        getFilterData: () => Object.assign(filterData, {}),
    }));

    return (
        <AdvanceFilter
            id="credit-notes-for-customers-estimation-filter"
            ref={advanceFilterRef}
            filterData={filterData}
            numOfRows={4}
            hideMoreOptions
            setFilterData={setFilterData}
            searchAction={() => tableRef?.current?.refresh()}
            formId={RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID}
        >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="container">
                    <div className="row rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('creditNotesForCustomersEstimation:customer')}</label>
                            <FrmSelectMenu
                                id="credit-notes-for-customers-estimation-filter-customer"
                                value={filterData?.customers}
                                onChange={(value) => _handleDataChanged("customers", value)}
                                api={(searchValue) => getCommonCustomers(null, null, searchValue, true, false, false, [], false)}
                                defualtValueApi={() => getCommonCustomers(filterData?.customers, null, null, true, false, false, [], false)}
                                defualtValueId={filterData?.customers}
                                descLo={['code', 'descLo']}
                                descFo={['code', 'descFo']}
                                isAsync={true}
                                isMulti={true}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('creditNotesForCustomersEstimation:branch')}</label>
                            <FrmSelectMenu
                                id="credit-notes-for-customers-estimation-filter-branch"
                                isMulti={true}
                                value={filterData.branches}
                                api={() => getCommonBranches(null, filterData.branches, null, BRANCH_CONDITION)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleDataChanged("branches", value)}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('creditNotesForCustomersEstimation:division')}</label>
                            <FrmSelectMenu
                                id="credit-notes-for-customers-estimation-filter-division"
                                portalTarget={document.body}
                                api={() => getCommonDivisionsList(null, DIVISION_CONDITION, false)}
                                isMulti={true}
                                value={filterData.divisions}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleDataChanged("divisions", value)}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('creditNotesForCustomersEstimation:status')}</label>
                            <StatusSelectMenu
                                id="credit-notes-for-customers-estimation-filter-status"
                                isMulti={true}
                                value={filterData.statuses}
                                api={() => getDomainValuesWithCondition(DOMAIN_GLOBAL_STATUS, true, [GLOBAL_STATUS_INCOMPLETE, GLOBAL_STATUS_SUBMITTED, GLOBAL_STATUS_CONFIRMED, GLOBAL_STATUS_REJECTED])}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleDataChanged("statuses", value)}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('creditNotesForCustomersEstimation:invoice_date_from')}</label>
                            <FrmTimeDatePickr
                                id="credit-notes-for-customers-estimation-filter-invoice-date-from"
                                value={filterData.dateFrom}
                                onChange={(value) => _handleDataChanged("dateFrom", value)}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('creditNotesForCustomersEstimation:invoice_date_to')}</label>
                            <FrmTimeDatePickr
                                id="credit-notes-for-customers-estimation-filter-invoic-date-to"
                                value={filterData.dateTo}
                                onChange={(value) => _handleDataChanged("dateTo", value)}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('creditNotesForCustomersEstimation:zatca-status')}</label>
                            <StatusSelectMenu
                                id={`credit-notes-for-customers-estimation-zatca-status`}
                                value={filterData?.zatcaStatus}
                                onChange={(value) => _handleDataChanged("zatcaStatus", value)}
                                api={() => getDomainValues(ZATCA_STATUS)}
                                descLo={["descLo"]}
                                descFo={["descFo"]}
                                isMulti={true} />
                        </div>

                        <div className="w50">
                            <WorkflowStatusFilter
                                formId={RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID}
                                value={filterData.workflowStatus}
                                onChange={(value) => _handleDataChanged("workflowStatus", value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </AdvanceFilter>
    )
}

export default forwardRef(CreditNotesforCustomersEstimationFilter);