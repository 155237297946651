import { DeleteBtn, EditBtn } from "@ipgd-gauge/table";
import { confirmationDialog, handleError, isAllowedAction, isEnglish, showSuccessMessage } from "@ipgd-gauge/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slisting from "../../../components/slisting";
import { deletePropertyPartyDefinition, exportPropertyPartyDefinition, getPropertiesPartiesDefinitions } from "../../../util/apis";
import { DELETE_ACTION, VIEW_EDIT_ACTION } from "../../../util/constants/actions";
import { RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import style from './style.module.scss';

const PropertiesPartiesDefinitionTable = (props) => {

    const { filtersRef, propertiesPartiesDefinitionDialogRef, propertiesPartiesDefinitionTableRef, searchTextRef } = props;
    const { t } = useTranslation();
    const [parties, setParties] = useState();

    const columns = [
        { title: '#', width: '5%' },
        { title: t('propertiesPartiesDefinition:code'), width: '10%' },
        { title: t('propertiesPartiesDefinition:name'), width: '10%' },
        { title: t('propertiesPartiesDefinition:category'), width: '10%' },
        { title: t('propertiesPartiesDefinition:type'), width: '10%' },
        { title: t('propertiesPartiesDefinition:organization'), width: '10%' },
        { title: t('propertiesPartiesDefinition:mobile_num'), width: '10%' },
        { title: t('propertiesPartiesDefinition:membership_id'), width: '10%' },
        { title: t('propertiesPartiesDefinition:status'), width: '10%' },
        { title: '', width: '15%' }
    ]

    const _fetchData = (page, size) => {
        const filterData = {
            searchValue: searchTextRef?.current?.getSearchText(),
            ...filtersRef?.current?.getFilterData(),
        };
        getPropertiesPartiesDefinitions(page, size, filterData)
            .then((response) => {
                setParties(response.data)
            })
            .catch((error) => handleError(error, null, t))
    }

    const _deleteAction = (id) => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return deletePropertyPartyDefinition(id)
                .then(() => {
                    showSuccessMessage('delete_successfully', t);
                    propertiesPartiesDefinitionTableRef?.current?.refresh()
                })
                .catch((error) => handleError(error, null, t));
        });
    }

    const _export = () => {
        const filterData = {
            ...filtersRef.current.getFilterData(),
            searchValue: searchTextRef?.current?.getSearchText(),
            columnSetting: propertiesPartiesDefinitionTableRef.current.getSelectedSettingId()
        }

        exportPropertyPartyDefinition(filterData);
    }

    return (
        <div className={style.propertiesPartiesDefinitionTableContainer}>
            <Slisting
                id="propertiesPartiesDefinitionTable"
                ref={propertiesPartiesDefinitionTableRef}
                columns={columns}
                withFilter
                Row={Row}
                tableStyle={style.propertiesPartiesDefinitionTable}
                pagingData={parties}
                getData={_fetchData}
                rowActions={{ deleteAction: _deleteAction, propertiesPartiesDefinitionDialogRef }}
                exportExcelAction={_export}
                showWating={(data) => data == null}
                showNoDataFound={(data) => data && data.length == 0}
                user={getUser()}
                formId={RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID}
                newAction={() => propertiesPartiesDefinitionDialogRef.current?.open()}
                deleteAction={deletePropertyPartyDefinition}
                viewAction={(id) => propertiesPartiesDefinitionDialogRef.current?.open(id)}
                cardInMobile
                labelCardWidth="80px"
            />
        </div>
    );
};

const Row = (props) => {
    const { data, rowActions } = props;
    const { deleteAction, propertiesPartiesDefinitionDialogRef } = rowActions;

    const { t } = useTranslation();

    return (
        <div className={`${style.propertiesPartiesDefinitionRow}`}>
            <div title={data.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data.sequence}</label>
            </div>

            <div title={data.code} className="cardRowMode">
                <label className="nameLblMobile">{t('propertiesPartiesDefinition:code')}</label>
                <label>{data.code}</label>
            </div>

            <div title={isEnglish() ? data.nameLo : data.nameFo} className="cardRowMode">
                <label className="nameLblMobile">{t('propertiesPartiesDefinition:name')}</label>
                <label>{isEnglish() ? data.nameLo : data.nameFo}</label>
            </div>

            <div title={isEnglish() ? data.categoryLo : data.categoryFo} className="cardRowMode">
                <label className="nameLblMobile">{t('propertiesPartiesDefinition:category')}</label>
                <label>{isEnglish() ? data.categoryLo : data.categoryFo}</label>
            </div>

            <div title={isEnglish() ? data.typeLo : data.typeFo} className="cardRowMode">
                <label className="nameLblMobile">{t('propertiesPartiesDefinition:type')}</label>
                <label>{isEnglish() ? data.typeLo : data.typeFo}</label>
            </div>

            <div title={Boolean(data.isGeneral) ? t('all_organization') : t('specific_organization')} className="cardRowMode">
                <label className="nameLblMobile">{t('propertiesPartiesDefinition:organization')}</label>
                <label>{Boolean(data.isGeneral) ? t('all_organization') : t('specific_organization')}</label>
            </div>

            <div title={data.mobile1} className="cardRowMode">
                <label className="nameLblMobile">{t('propertiesPartiesDefinition:mobile_num')}</label>
                <label>{data.mobile1}</label>
            </div>

            <div title={data.membershipNum} className="cardRowMode">
                <label className="nameLblMobile">{t('propertiesPartiesDefinition:membership_id')}</label>
                <label>{data.membershipNum}</label>
            </div>

            <div title={isEnglish() ? data.statusLo : data.statusFo} className="cardRowStatusMode">
                <label className="nameLblMobile">{t('cities:status')}</label>
                <label className={`status-${data.isActive}`}>{isEnglish() ? data.statusLo : data.statusFo}</label>
            </div>
            <div>
                <div className='containerButtonTable'>
                    {isAllowedAction(getUser(), RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID, VIEW_EDIT_ACTION) && <EditBtn onClick={() => propertiesPartiesDefinitionDialogRef?.current?.open(data.id)} />}
                    {isAllowedAction(getUser(), RSM2653_PROPERTIES_PARTIES_DEFINITIONS_FORM_ID, DELETE_ACTION) && <DeleteBtn onClick={() => deleteAction(data.id)} />}
                </div>
            </div>
        </div>
    );
};

export default PropertiesPartiesDefinitionTable;