import { addUrlParam, checkValidation, deleteUrlParam, getUrlParam, handleError, hideActionWaiting, isEmpty, isNotEmpty, removeAllMandatoryClasses, showActionWaiting, showNotificationToast, showSuccessMessage } from "@ipgd-gauge/utils";
import { useEffect, useReducer, useRef } from "react";
import { useTranslation } from "react-i18next";
import PrintDialog from "../../../components/print-dialog";
import WorkflowDialog from "../../../components/slisting/workflow-dialog";
import { OpenCloseMenu } from "../../../icons/OpenCloseMenu";
import { getDefaultFormValues, getEstimatorInvoice, getEstimatorInvoiceParties, saveEstimatorInvoice, saveWorkflowTransaction } from "../../../util/apis";
import { AFFECTED_ACCOUNT_PARTIES_ACCOUNTS, GLOBAL_STATUS_INCOMPLETE, INVOICE_LEVEL_ALL_PARTIES, INVOICE_LEVEL_SPECIFIC_PARTIES } from "../../../util/constants";
import { RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import { useWorkflow } from "../../../util/hooks/useWorkflow";
import AdjustWorkDialog from "../../common/dialogs/adjust-work";
import WorkflowsActionLogDialog from "../../common/dialogs/workflows-action-log";
import Details, { ESTIMATION_FEES, ITEM } from "./Details";
import Header from "./Header";
import PartyList from "./PartyList";
import Actions from "./actions";
import estimatorInvoiceReducer, { ACTION_CREATE_NEW_INVOICE } from "./reducer";
import style from './style.module.scss';
import Summary from "./summary";

const EstimatorsInspectorsInvoice = () => {
    const { t } = useTranslation();

    const user = getUser();

    const DEFAULT_VALUES = {
        invoice: {
            header: {
                globalStatus: GLOBAL_STATUS_INCOMPLETE,
                exchngRate: 1,
                fogId: user?.fogId,
                invoiceLevel: INVOICE_LEVEL_ALL_PARTIES,
                affectedAccounts: AFFECTED_ACCOUNT_PARTIES_ACCOUNTS,
                lsoId: user?.estimationInvoiceLsoId,
                mbnId: user?.mbnId,
                dsvId: user?.dsvId,
            },
            lines: [],
            parties: [],
            filteredLines: null,
        },
        totals: {},
    };

    const invoiceId = getUrlParam('id');
    const partyIdParam = getUrlParam('partyId');

    const printDialogRef = useRef();
    const adjustWorkDialogRef = useRef();
    const workflowsActionLogDialogRef = useRef();
    const actionsRef = useRef();
    const workflowDialogRef = useRef();
    const fromSaveActionRef = useRef(false);
    const fromNewActionRef = useRef(false);

    const { workflowId, stepId } = useWorkflow(RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID);
    const [invoiceState, dispatchInvoice] = useReducer(estimatorInvoiceReducer, DEFAULT_VALUES);

    useEffect(() => {
        if (fromSaveActionRef.current) {
            fromSaveActionRef.current = false;
            return;
        }

        if (fromNewActionRef.current) {
            fromNewActionRef.current = false;
            return;
        }

        if (invoiceId) {
            getEstimatorInvoice(invoiceId)
                .then((response) => {
                    const transactionData = response.data.transactionData;
                    const task = response.data.task;
                    const workflowId = response.data.workflowId;
                    const stepId = response.data.stepId;
                    const workflowStatus = response.data.workflowStatus;
                    // const [transactionData, task] = _handleRetrieveDataFromResponse(response.data);

                    let header = transactionData;
                    transactionData.defaultCdaDescLo = header.defaultCdaItem?.descLo;
                    transactionData.defaultCdaDescFo = header.defaultCdaItem?.descFo;
                    transactionData.defaultCdaCode = header.defaultCdaItem?.code;
                    (transactionData.lines || []).forEach((line) => {
                        if (line.source == ESTIMATION_FEES) {
                            if (header.defaultCdaItem) {
                                line.descLo = header.defaultCdaItem?.code + ', ' + header.defaultCdaItem?.descLo;
                                line.descFo = header.defaultCdaItem?.code + ', ' + header.defaultCdaItem?.descFo;
                            }
                        }

                        if (line.source == ITEM) {
                            line.descLo = line.item.code + ', ' + line.item.descLo;
                            line.descFo = line.item.code + ', ' + line.item.descFo;
                        }
                    })
                    let invoiceData = { header: transactionData, lines: transactionData.lines || [], parties: transactionData.parties || [] };
                    let masterData = { invoice: invoiceData, workflowId, stepId, workflowStatus };
                    dispatchInvoice({ value: masterData, type: ACTION_CREATE_NEW_INVOICE });
                    actionsRef?.current?.setWorkflowAction(task);
                })
        } else {
            _newInvoice(workflowId, stepId);
        }
    }, [invoiceId]);

    const _newInvoice = async (workflowId, stepId) => {
        fromNewActionRef.current = true;
        const newInvoice = { ...DEFAULT_VALUES };
        const defaultData = (await getDefaultFormValues(RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID)).data;

        if (defaultData.hasWorkflow && (isEmpty(workflowId) || isEmpty(stepId))) {
            workflowDialogRef?.current?.open();
            return;
        }

        if (isNotEmpty(partyIdParam)) {
            newInvoice.invoice.parties.aprId = partyIdParam;
            newInvoice.invoice.header.invoiceLevel = INVOICE_LEVEL_SPECIFIC_PARTIES;
            let partiesRes = await getEstimatorInvoiceParties('', newInvoice.invoice?.header?.fogId, null, null, partyIdParam);
            let party = partiesRes.data.filter(p => p.id == partyIdParam);
            let rows = [];
            party.map((data, index) => {
                let row = {};
                row.aprId = data.id;
                row.seq = index + 1;
                row.party = {};
                row.party.code = data.code
                row.party.nameLo = data.nameLo
                row.party.nameFo = data.nameFo
                row.party.descLo = data.descLo
                row.party.descFo = data.descFo
                rows.push(row);
            })
            newInvoice.invoice.parties = rows;
        }

        newInvoice.invoice.header.dateDgr = defaultData.currentDate;
        newInvoice.invoice.header.fcuId = defaultData?.fcuId;
        newInvoice.invoice.header.defaultCdaId = defaultData?.defaultItem?.id;
        newInvoice.invoice.header.defaultCdaCode = defaultData?.defaultItem?.code;
        newInvoice.invoice.header.defaultCdaDescLo = defaultData?.defaultItem?.descLo;
        newInvoice.invoice.header.defaultCdaDescFo = defaultData?.defaultItem?.descFo;
        newInvoice.invoice.header.defaultCdaVatPerc = defaultData?.defaultItem?.vatPerc;
        newInvoice.workflowId = workflowId;
        newInvoice.stepId = stepId;

        if (isEmpty(defaultData?.estimationInvoicePolicyId)) {
            showNotificationToast(t('USER_POLICY_ERR'), { hideProgressBar: true, type: 'warning' })
        }

        dispatchInvoice({ value: { ...newInvoice }, type: ACTION_CREATE_NEW_INVOICE })
        removeAllMandatoryClasses(document.getElementById("estimators-inspectors-invoice"))
        deleteUrlParam("id");

        workflowDialogRef?.current?.close();
        actionsRef?.current?.setWorkflowAction(null);
    }

    const _handleRetrieveDataFromResponse = (responseData) => {
        const transactionData = { ...responseData.transactionData };
        transactionData.workflowId = responseData.workflowId;
        transactionData.stepId = responseData.stepId;
        transactionData.workflowStatus = responseData.workflowStatus;
        return [transactionData, responseData.task];
    }

    const _checkValidation = () => !checkValidation([
        { id: "estimators-inspectors-invoice" },
        { id: "invoices-list-table", title: t('propertiesPolicies:actions'), data: invoiceState.invoice.lines },
    ], t);

    const _whenSaveData = async (response, withSuccessMessage) => {
        const transactionData = response.transactionData;
        const task = response.task;
        const workflowId = response.workflowId;
        const stepId = response.stepId;
        const workflowStatus = response.workflowStatus;

        fromSaveActionRef.current = true;
        let invoiceData = { header: transactionData, lines: transactionData.lines || [], parties: transactionData.parties || [] };
        invoiceData.header.defaultCdaDescLo = invoiceData.header.defaultCdaItem?.descLo;
        invoiceData.header.defaultCdaDescFo = invoiceData.header.defaultCdaItem?.descFo;
        invoiceData.header.defaultCdaCode = invoiceData.header.defaultCdaItem?.code;
        invoiceData.lines.map((line) => {
            if (line.source == ESTIMATION_FEES) {
                line.descLo = invoiceData?.header?.defaultCdaItem.code + ', ' + invoiceData?.header?.defaultCdaItem.descLo
                line.descFo = invoiceData?.header?.defaultCdaItem.code + ', ' + invoiceData?.header?.defaultCdaItem.descFo
            }
            if (line.source == ITEM) {
                line.descLo = line?.item?.code + ', ' + line?.item?.descLo
                line.descFo = line?.item?.code + ', ' + line?.item?.descFo
            }
        });

        let masterData = { invoice: invoiceData, workflowId, stepId, workflowStatus };
        dispatchInvoice({ value: masterData, type: ACTION_CREATE_NEW_INVOICE })

        addUrlParam("id", transactionData.id);
        if (withSuccessMessage) {
            showSuccessMessage(t("journalVoucher:save_successfully"), t);
        }

        actionsRef?.current?.setWorkflowAction(task);
    }

    const _handleError = (error) => {
        let message = error?.response?.data?.message;
        if (message?.includes('#')) {
            message = message.split('#')[0];
        }
        handleError(error, message, t);
    }

    const _saveAction = (withSubmit, withWaiting) => {
        if (withSubmit && _checkValidation()) {
            return
        }

        if (withWaiting) {
            showActionWaiting(withSubmit ? 'save-inspector-estimator-invoice' : 'draft-inspector-estimator-invoice');
        }

        const data = { ...invoiceState.invoice.header, lines: invoiceState.invoice.lines, parties: invoiceState.invoice.parties }
        if (isNotEmpty(invoiceState.workflowId) && isNotEmpty(invoiceState.stepId)) {
            saveWorkflowTransaction(invoiceState.workflowId, RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID, data)
                .then(async (response) => {
                    const [transactionData, task] = _handleRetrieveDataFromResponse(response.data);
                    await _whenSaveData(response.data, true);
                })
                .catch(_handleError)
                .finally(withWaiting ? () => hideActionWaiting(withSubmit ? 'save-inspector-estimator-invoice' : 'draft-inspector-estimator-invoice') : () => { });
        } else {
            saveEstimatorInvoice(data, withSubmit)
                .then(async (response) => await _whenSaveData({ transactionData: response.data }, true))
                .catch(_handleError)
                .finally(withWaiting ? () => hideActionWaiting(withSubmit ? 'save-inspector-estimator-invoice' : 'draft-inspector-estimator-invoice') : () => { });
        }
    }

    const handleOpenSummary = () => {
        document.getElementById('estimators-inspectors-invoice-lines').classList.toggle('active');
        document.getElementById('estimators-inspectors-invoice-summary').classList.toggle('active');
        document.getElementById('bg-sm').classList.toggle('active');
    }

    const _onExecuteWorkflowAction = async (responseData) => {
        const [transactionData, task] = _handleRetrieveDataFromResponse(responseData);
        _whenSaveData(responseData, false);
    }

    return (
        <>
            <div className={style.container}>
                <div id="estimators-inspectors-invoice-lines" className="lines-container-open-close">
                    <div>
                        <div id='estimators-inspectors-invoice' className={`toggle-mode ${style.toggleMode}`}>
                            <Header
                                user={user}
                                invoice={invoiceState.invoice}
                                workflowStatus={invoiceState.workflowStatus}
                                dispatchInvoice={dispatchInvoice}
                            />

                            {invoiceState.invoice?.header?.invoiceLevel == INVOICE_LEVEL_SPECIFIC_PARTIES &&
                                <PartyList
                                    user={user}
                                    invoice={invoiceState.invoice}
                                    dispatchInvoice={dispatchInvoice}
                                />
                            }

                            <Details
                                user={user}
                                invoice={invoiceState.invoice}
                                dispatchInvoice={dispatchInvoice}
                            />
                        </div>
                    </div>
                </div>

                <div id="estimators-inspectors-invoice-summary" className="summary-container-panel">
                    <button className="open-close-summary-btn" onClick={handleOpenSummary}>
                        <OpenCloseMenu />
                    </button>
                    <Summary totals={invoiceState.totals} handleOpenSummary={handleOpenSummary} />
                </div>
                <div id="bg-sm" onClick={handleOpenSummary} className="summary-bg-sm"></div>
            </div>

            <Actions
                ref={actionsRef}
                user={user}
                saveAction={_saveAction}
                invoiceState={invoiceState}
                dispatchInvoice={dispatchInvoice}
                printDialogRef={printDialogRef}
                newAction={_newInvoice}
                adjustWorkDialogRef={adjustWorkDialogRef}
                workflowsActionLogDialogRef={workflowsActionLogDialogRef}
            />

            <PrintDialog
                id="estimator-inspector-invoice-print-dialog"
                ref={printDialogRef}
                formId={RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID}
                user={user}
            />

            <WorkflowsActionLogDialog
                ref={workflowsActionLogDialogRef}
                formId={RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID}
            />

            <AdjustWorkDialog
                ref={adjustWorkDialogRef}
                formId={RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID}
                getTransactionData={() => {
                    return { ...invoiceState.invoice.header, lines: invoiceState.invoice.lines, parties: invoiceState.invoice.parties };
                }}
                onExecuteAction={_onExecuteWorkflowAction}
                globalStatus={invoiceState.globalStatus}
                checkValidationAction={_checkValidation}
                onError={_handleError}
            />

            <WorkflowDialog
                ref={workflowDialogRef}
                formId={RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID}
                newAction={(workflowId, stepId) => _newInvoice(workflowId, stepId)}
            />
        </>
    );
}

export default EstimatorsInspectorsInvoice;