import { getPaginationObject, isNotEmpty } from "@ipgd-gauge/utils";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../../../components/modal";
import Table from "../../../components/table";
import { getLogs } from "../../../util/apis";
import { FND0133_USERS_LOGIN_LOG_FORM_ID } from "../../../util/constants/forms";
import { formatDateNTime } from "../../../util/dateUtil";
import style from './style.module.scss';

const Row = (props) => {
    const { data } = props;
    return (
        <div>
            <div>{data.sequence}</div>
            <div>{formatDateNTime(data.time)}</div>
            <div>{data.ip}</div>
        </div>
    )
}

const UserLoginLogs = (props, ref) => {

    const { user } = props;
    const { t } = useTranslation();

    const dialogRef = useRef();
    const tableRef = useRef();
    const idRef = useRef(null);

    const [list, setList] = useState(null);

    const columns = [
        { title: t('#'), width: '10%' },
        { title: t('usersLoginLog:date_time'), width: '40%' },
        { title: 'IP', width: '50%' }
    ];

    const _fetchData = (page, size) => {
        if (isNotEmpty(idRef.current)) {
            getLogs(idRef.current, page, size)
                .then((response) => setList(response.data))
                .catch((error) => console.log("", error));
        } else {
            setList(getPaginationObject([]))
        }
    }

    const _postClose = () => {
        setList(null);
        idRef.current = null;
    }

    const open = (id) => {
        if (isNotEmpty(id)) {
            idRef.current = id;
            tableRef.current.refresh();
        }
        dialogRef.current.open();
    }

    const close = () => dialogRef.current.close();

    useImperativeHandle(ref, () => ({ open, close }));

    return (
        <Modal
            id="userLoginLogsDialog"
            formId={FND0133_USERS_LOGIN_LOG_FORM_ID}
            ref={dialogRef}
            isTableModal={true}
            title={t('usersLoginLog:user_log_details')}
            withFooter
            withOkButton={false}
            withCancelButton
            cancelBtnStyleAction="cancel-button"
            cancelBtnLabel={t('usersLoginLog:exit')}
            postClose={_postClose}
            className={style.userLoginLogsDialog}
        >
            <div className='container'>
                <Table
                    id="logs-dialog"
                    ref={tableRef}
                    formId={FND0133_USERS_LOGIN_LOG_FORM_ID}
                    columns={columns}
                    Row={Row}
                    getData={_fetchData}
                    pagingData={list}
                    tableStyle={style.logTable}
                    showWating={(data) => data == null}
                    showNoDataFound={(data) => (data && data.length == 0)}
                    user={user}
                />
            </div>
        </Modal>
    );
}

export default forwardRef(UserLoginLogs);