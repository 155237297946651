import Tabs from "@ipgd-gauge/tabs";
import { addUrlParam, checkValidation, deleteUrlParam, getUrlParam, handleError, hideActionWaiting, isEmpty, isEnglish, isNotEmpty, parseNumber, showActionWaiting, showSuccessMessage } from "@ipgd-gauge/utils";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2';
import withReactContent from "sweetalert2-react-content";
import PrintDialog from "../../../components/print-dialog";
import WorkflowDialog from "../../../components/slisting/workflow-dialog";
import { OpenCloseMenu } from "../../../icons/OpenCloseMenu";
import { getDefaultFormValues, getEstimationsQuotation, saveEstimationsQuotation, saveWorkflowTransaction } from "../../../util/apis";
import { CUSTOMER_LEVEL_NEW, CUSTOMER_LEVEL_PREDEFINED, ESTIMATION_QUOTATION_CITY_FIELD, ESTIMATION_QUOTATION_DEED_DGR_FIELD, ESTIMATION_QUOTATION_DEED_FIELD, ESTIMATION_QUOTATION_PROPERTY_TYPE_FIELD, ESTIMATION_QUOTATION_REGION_FIELD, ESTIMATION_QUOTATION_TOWN_FIELD, GLOBAL_STATUS_INCOMPLETE, STATUS_APPROVED_FROM_CUSTOMER, STATUS_NEW, STATUS_REJECTED_FROM_CUSTOMER, STEP_TYPE_APPROVAL } from "../../../util/constants";
import { RSM3190_EVALUATION_QUOTATIONS_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import { useWorkflow } from "../../../util/hooks/useWorkflow";
import AdjustWorkDialog from "../../common/dialogs/adjust-work";
import WorkflowsActionLogDialog from "../../common/dialogs/workflows-action-log";
import Actions from "../actions";
import style from './style.module.scss';
import Summary from "./summary";
import Tab1 from "./tab1";
import Tab2 from "./tab2";

const DEFAULT_VALUE = {
    status: STATUS_NEW,
    globalStatus: GLOBAL_STATUS_INCOMPLETE,
    customerLevel: CUSTOMER_LEVEL_PREDEFINED
};

const EstimationQuotation = () => {
    const { t } = useTranslation();

    const MySwal = withReactContent(Swal);
    const id = getUrlParam('id');
    const currentUser = getUser();
    const printDialogRef = useRef();
    const fromSaveActionRef = useRef(false);
    const fromNewActionRef = useRef(false);
    const attachmentsImgRef = useRef();
    const actionsRef = useRef();
    const workflowsActionLogDialogRef = useRef();
    const adjustWorkDialogRef = useRef();
    const workflowDialogRef = useRef();

    const { workflowId, stepId } = useWorkflow(RSM3190_EVALUATION_QUOTATIONS_FORM_ID);
    const [estimationQuotation, setEstimationQuotation] = useState({ ...DEFAULT_VALUE });
    useEffect(() => {
        if (fromSaveActionRef.current) {
            fromSaveActionRef.current = false;
            return;
        }

        if (fromNewActionRef.current) {
            fromNewActionRef.current = false;
            return;
        }

        if (isNotEmpty(id)) {
            getEstimationsQuotation(id)
                .then((response) => {
                    const [transactionData, task] = _handleRetrieveDataFromResponse(response.data);
                    setEstimationQuotation(transactionData);
                    actionsRef?.current?.setWorkflowAction(task);
                })
                .catch((error) => handleError(error, null, t));
        } else {
            _newAction(workflowId, stepId);
        }
    }, [id]);

    const _newAction = async (workflowId, stepId) => {
        const defaultData = (await getDefaultFormValues(RSM3190_EVALUATION_QUOTATIONS_FORM_ID)).data;

        if (defaultData.hasWorkflow && (isEmpty(workflowId) || isEmpty(stepId))) {
            workflowDialogRef?.current?.open();
            return;
        }

        let tempDefaultValue = Object.assign({}, DEFAULT_VALUE);
        tempDefaultValue.dateDgr = defaultData?.currentTime;
        tempDefaultValue.fcuId = defaultData?.fcuId;
        tempDefaultValue.exchngRate = 1;
        tempDefaultValue.mbnId = currentUser?.mbnId;
        tempDefaultValue.workflowId = workflowId;
        tempDefaultValue.stepId = stepId;

        fromNewActionRef.current = true;
        deleteUrlParam('id');
        setEstimationQuotation(tempDefaultValue);
        workflowDialogRef?.current?.close();
        actionsRef?.current?.setWorkflowAction(null);
    }

    const countNetPrice = (estimationQuotation) => {
        let netPrice = 0
        for (let i = 0; i < estimationQuotation.lines.length; i++) {
            netPrice += Number(estimationQuotation.lines[i].netPrice)
        }
        return netPrice
    }

    const _handleDataChanged = (name, value) => {
        const newEstimationQuotation = {};
        newEstimationQuotation[name] = value;

        if (name == "customerLevel") {
            if (value == CUSTOMER_LEVEL_NEW) {
                newEstimationQuotation.atrId = null;
                newEstimationQuotation.customerData = null;
            } else if (value == CUSTOMER_LEVEL_PREDEFINED) {
                newEstimationQuotation.customerName = null;
                newEstimationQuotation.mobileNumber = null;
                newEstimationQuotation.cardNumber = null;
                newEstimationQuotation.phoneNumber = null;
                newEstimationQuotation.email = null;
                newEstimationQuotation.customerCommRegNum = null;
                newEstimationQuotation.taxNumber = null;
                newEstimationQuotation.customerContact = null;
                newEstimationQuotation.customerDeelgationNum = null;
                newEstimationQuotation.city = null;
                newEstimationQuotation.town = null;
                newEstimationQuotation.buildingNumber = null;
                newEstimationQuotation.postalCode = null;
                newEstimationQuotation.customerAddress = null;
            }
        } else if (name == "lines") {
            newEstimationQuotation.netPrice = countNetPrice(newEstimationQuotation)
        } else if (name === "totalPrice" || name === "vat") {
            newEstimationQuotation.netPrice = parseNumber(newEstimationQuotation?.totalPrice) + parseNumber(newEstimationQuotation?.vat);
        } else if (name == "hasValidyPeriod") {
            newEstimationQuotation.validityPeriodFromDgr = null;
            newEstimationQuotation.validityPeriodToDgr = null;
            newEstimationQuotation.validityPeriodFromDhi = null;
            newEstimationQuotation.validityPeriodToDhi = null;
        } else if (name == "hasOfferDetails") {
            newEstimationQuotation.quotationDesc = null;
            newEstimationQuotation.quotationDetails = null;
            newEstimationQuotation.noOfDays = null;
            newEstimationQuotation.documentsSource = null;
            newEstimationQuotation.reportUsers = null;
        } else if (name === "atrId") {
            newEstimationQuotation.atrId = value.value;
            newEstimationQuotation.customerData = value;
        } else if (name === "umyId") {
            newEstimationQuotation.umyId = value.value;
            newEstimationQuotation.quotationType = value;
            newEstimationQuotation.lines = [];
        }

        setEstimationQuotation((state) => ({ ...state, ...newEstimationQuotation }));
    }

    const handleOpenSummary = () => {
        document.getElementById('estimation-quotation-lines').classList.toggle('active');
        document.getElementById('estimation-quotation-summary').classList.toggle('active');
        document.getElementById('bg-sm').classList.toggle('active');
    }

    const _checkValidation = () => {
        let error = !checkValidation([{ id: 'estimation-quotation-container' },
        {
            id: `estimation-quotation-details-table-${estimationQuotation?.umyId}-${estimationQuotation?.quotationType?.fields}`,
            title: t('estimationQuotation:details'),
            data: estimationQuotation.lines,
            disabledConditions: {
                deedNumber: () => !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_DEED_FIELD),
                titleDeedDgr: () => !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_DEED_DGR_FIELD),
                frsId: () => !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_REGION_FIELD),
                ecdId: (lineData) => !lineData.frsId || !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_CITY_FIELD),
                criId: (lineData) => !lineData.ecdId || !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_TOWN_FIELD),
                titleDeedDgr: () => true,
                myoId: () => !estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_PROPERTY_TYPE_FIELD),
            }
        }
        ], t);

        for (let index = 0; index < (estimationQuotation?.lines || []).length; index++) {
            const element = estimationQuotation?.lines[index];
            // TODO: ERROR
            if (estimationQuotation?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_DEED_DGR_FIELD) && isEmpty(element?.titleDeedDgr) && isEmpty(element?.titleDeedDhi)) {
                handleError(null, 'mandatory_fields', t);
                document.getElementById(`estimation-quotation-${index}-buy-instrument-date-dgr`).classList.add('mandatory-input');
                document.getElementById(`estimation-quotation-${index}-buy-instrument-date-dhi-hijri-date`).classList.add('mandatory-input');
                error = true;
            }
        }

        return error;
    }

    const _handleError = (error) => {
        handleError(null, error?.response?.data?.message.split('#')[0], t);
    }

    const _whenSaveData = async (transactionData, task, withSubmit) => {
        fromSaveActionRef.current = true;

        await attachmentsImgRef?.current?.uploadImages(transactionData.id);
        for (let index = 0; index < (estimationQuotation?.lines || []).length; index++) {
            const element = estimationQuotation?.lines[index];
            transactionData.lines[index] = { ...element, ...transactionData?.lines[index] }
        }

        setEstimationQuotation({ ...estimationQuotation, ...transactionData });

        addUrlParam("id", transactionData.id);
        showSuccessMessage('save_successfully', t);

        actionsRef?.current?.setWorkflowAction(task);
        hideActionWaiting(withSubmit ? 'submit' : 'incomplete');
    }

    const _saveAction = (withSubmit) => {
        if (withSubmit && _checkValidation()) {
            return
        }

        showActionWaiting(withSubmit ? 'submit' : 'incomplete');

        if (isNotEmpty(estimationQuotation.workflowId) && isNotEmpty(estimationQuotation.stepId)) {
            saveWorkflowTransaction(estimationQuotation.workflowId, RSM3190_EVALUATION_QUOTATIONS_FORM_ID, estimationQuotation)
                .then(async (response) => {
                    const [transactionData, task] = _handleRetrieveDataFromResponse(response.data);
                    await _whenSaveData(transactionData, task, withSubmit);
                })
                .catch(_handleError)
            // .finally(() => hideActionWaiting(withSubmit ? 'submit' : 'incomplete'));
        } else {
            saveEstimationsQuotation(estimationQuotation, withSubmit)
                .then(async (response) => await _whenSaveData(response.data, null, withSubmit))
                .catch(_handleError)
            // .finally(() => hideActionWaiting(withSubmit ? 'submit' : 'incomplete'));
        }

    }

    const _onExecuteWorkflowAction = async (responseData) => {
        const [transactionData, task] = _handleRetrieveDataFromResponse(responseData);
        addUrlParam("id", transactionData.id);
        setEstimationQuotation({ ...estimationQuotation, ...transactionData });
        actionsRef?.current?.setWorkflowAction(task);
    }

    const _handleRetrieveDataFromResponse = (responseData) => {
        const transactionData = { ...responseData.transactionData };
        transactionData.workflowId = responseData.workflowId;
        transactionData.stepId = responseData.stepId;
        transactionData.workflowStatus = responseData.workflowStatus;
        return [transactionData, responseData.task];
    }

    const _confirmAction = async (action, stypeType) => {
        console.log("------> " + action);
        
        if (action != STEP_TYPE_APPROVAL || stypeType != "3") {
            return ({});
        }
        let data = null;
        await MySwal.fire({
            title: t('estimationQuotation:customer_decision'),
            // cancelButtonText: t('cancel'),
            showCloseButton: true,
            icon: 'info',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: t('estimationQuotation:rejected_from_customer'),
            denyButtonText: t('estimationQuotation:approved_from_customer'),
            customClass: {
                container: 'customerDecision',
                title: isEnglish() ? 'swal2-title-en' : 'swal2-title-ar',
                actions: isEnglish() ? 'swal2-actions-panel-en' : 'swal2-actions-panel-ar',
                confirmButton: isEnglish() ? 'swal2-colse-en' : 'swal2-colse-ar',
                denyButton: isEnglish() ? 'swal2-deny-en' : 'swal2-deny-ar',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                data = { customerDecision: STATUS_REJECTED_FROM_CUSTOMER };
            } else if (result.isDenied) {
                data = { customerDecision: STATUS_APPROVED_FROM_CUSTOMER };
            }
        });
        return data;
    }

    return (
        <>
            <div className={style.container}>
                <div id="estimation-quotation-lines" className="lines-container-open-close">
                    <Tabs
                        id="estimation-quotation-container"
                        labels={['home', 'attachments']}
                        containerStyle={style.tabsContainer}
                        tabContentStyle={style.tabContentStyle}
                    >
                        <Tab1
                            currentUser={currentUser}
                            estimationQuotation={estimationQuotation}
                            handleDataChanged={_handleDataChanged}
                            setEstimationQuotation={setEstimationQuotation}
                        />
                        <Tab2
                            data={estimationQuotation}
                            attachmentsImgRef={attachmentsImgRef}
                        />
                    </Tabs>
                </div>

                <div id="estimation-quotation-summary" className="summary-container-panel">
                    <button className="open-close-summary-btn" onClick={handleOpenSummary}>
                        <OpenCloseMenu />
                    </button>

                    <Summary estimationQuotation={estimationQuotation} handleOpenSummary={handleOpenSummary} />
                </div>

                <div id="bg-sm" onClick={handleOpenSummary} className="summary-bg-sm"></div>
            </div>

            <Actions
                ref={actionsRef}
                user={currentUser}
                isListing={false}
                data={estimationQuotation}
                newAction={_newAction}
                saveAction={_saveAction}
                printDialogRef={printDialogRef}
                adjustWorkDialogRef={adjustWorkDialogRef}
                workflowsActionLogDialogRef={workflowsActionLogDialogRef}
            />

            <PrintDialog
                id="estimation-quotation-print-dialog"
                ref={printDialogRef}
                formId={RSM3190_EVALUATION_QUOTATIONS_FORM_ID}
                user={currentUser}
            />

            <WorkflowsActionLogDialog
                ref={workflowsActionLogDialogRef}
                formId={RSM3190_EVALUATION_QUOTATIONS_FORM_ID}
            />

            <AdjustWorkDialog
                ref={adjustWorkDialogRef}
                formId={RSM3190_EVALUATION_QUOTATIONS_FORM_ID}
                getTransactionData={() => estimationQuotation}
                onExecuteAction={_onExecuteWorkflowAction}
                globalStatus={estimationQuotation.globalStatus}
                checkValidationAction={_checkValidation}
                onError={_handleError}
                getAdditionalData={_confirmAction}
            />

            <WorkflowDialog
                ref={workflowDialogRef}
                formId={RSM3190_EVALUATION_QUOTATIONS_FORM_ID}
                newAction={(workflowId, stepId) => _newAction(workflowId, stepId)}
            />
        </>
    );
}

export default EstimationQuotation;