import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import FiltersIcon from "../../icons/Filters";
import { RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID } from "../../util/constants/forms";
import EstimatorsInspectorsInvoicesTable from "./listing";
import EstimatorsInspectorsInvoicesFilter from "./listing/Filters";

const EstimatorsInspectorsInvoices = () => {
    const { t } = useTranslation("estimatorsInspectorsInvoices");

    const searchTextRef = useRef();
    const filtersRef = useRef();
    const tableRef = useRef();

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle formId={RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID} />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={t('invoice_num') + " , " + t('ref_num') + " , " + t('remarks')}
                        search={() => tableRef?.current?.refresh()}
                        formId={RSM3017_ESTIMATORS_INSPECTORS_INVOICES_FORM_ID}
                    />

                    <button className="headerPanelBtn" onClick={() => filtersRef.current?.open()}>
                        <FiltersIcon />
                    </button>
                </div>
            </div>

            <EstimatorsInspectorsInvoicesTable tableRef={tableRef} searchTextRef={searchTextRef} filtersRef={filtersRef} />
            <EstimatorsInspectorsInvoicesFilter ref={filtersRef} tableRef={tableRef} />
        </>
    );
}

export default EstimatorsInspectorsInvoices;