import FrmButtonsOptions from "@ipgd-gauge/frm-button-options";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../components/advance-filter";
import { getCategories, getCommonCustomers } from "../../util/apis";
import { FORM_BTNS_OPTIONS_WITH_CHECH } from "../../util/constants";
import { RSM3268_CUSTOMER_DUES_FOLLOW_UP } from "../../util/constants/forms";

const ID = "customer-dues-follow-up-filter";
const CATEGORY_CONDITION = "isAll";
const CustomerDuesFollowUpFilter = (props, ref) => {
    const { listRef } = props;

    const { t } = useTranslation();
    const advanceFilterRef = useRef();

    const [filterData, setFilterData] = useState({});

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const getFilterData = () => {
        return Object.assign(filterData, {});
    }

    const open = () => {
        advanceFilterRef.current?.open();
    }

    useImperativeHandle(ref, () => ({
        getFilterData,
        open
    }))

    const ALL = "0";
    const YES = "1";
    const NO = "2";

    const options = [
        { id: ALL, descLo: t('all'), descFo: t('all') },
        { id: YES, descLo: t('yes'), descFo: t('yes') },
        { id: NO, descLo: t('no'), descFo: t('no') },
    ]

    const _getDefaultFilter = () => {
        const defaultFilter = {
            hasInvoicesInProgress: ALL,
            hasDueInvoices: ALL
        }

        return defaultFilter;
    }


    return (
        <AdvanceFilter
            ref={advanceFilterRef}
            id={ID}
            filterData={filterData}
            setFilterData={setFilterData}
            searchAction={() => listRef?.current?.refresh()}
            formId={RSM3268_CUSTOMER_DUES_FOLLOW_UP}
            getDefaultFilter={_getDefaultFilter}
            numOfRows={2}
            hideMoreOptions
        >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="container">
                    <div className="row rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('customerDuesFollowUp:customer_category')}</label>
                            <FrmSelectMenu
                                id={`${ID}-customer-category`}
                                portalTarget={document.body}
                                isMulti
                                value={filterData.customerCategories}
                                api={() => getCategories(null, CATEGORY_CONDITION, false)}
                                descFo={["descFo"]}
                                descLo={["descLo"]}
                                onChange={(value) => _handleDataChanged("customerCategories", value)}
                                isClearable
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('customer')}</label>
                            <FrmSelectMenu
                                id={`${ID}-customer`}
                                value={filterData?.customers}
                                onChange={(value) => _handleDataChanged("customers", value)}
                                api={(searchValue) => getCommonCustomers(null, null, searchValue, true, false, false, [], false)}
                                defualtValueApi={() => getCommonCustomers(filterData?.customers, null, null, true, false, false, [], false)}
                                defualtValueId={filterData?.customers}
                                isAsync={true}
                                isMulti={true}
                                isClearable={true}
                                descLo={['code', 'descLo']}
                                descFo={['code', 'descFo']}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('customerDuesFollowUp:has_due_invoices')}</label>
                            <FrmButtonsOptions
                                id={`${ID}-has-due-invoices`}
                                type={FORM_BTNS_OPTIONS_WITH_CHECH}
                                className="options-btn-33"
                                handleSelect={value => _handleDataChanged("hasDueInvoices", value)}
                                value={filterData.hasDueInvoices}
                                options={options}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('customerDuesFollowUp:has_invoices_in_progress')}</label>
                            <FrmButtonsOptions
                                id={`${ID}-has-invoices-in-progress`}
                                type={FORM_BTNS_OPTIONS_WITH_CHECH}
                                className="options-btn-33"
                                handleSelect={value => _handleDataChanged("hasInvoicesInProgress", value)}
                                value={filterData.hasInvoicesInProgress}
                                options={options}
                            />
                        </div>
                        <div className="w50"></div>
                    </div>
                </div>
            </div>
        </AdvanceFilter>
    );
}

export default forwardRef(CustomerDuesFollowUpFilter);