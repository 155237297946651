import React from "react";

function DeleteTowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.289"
      height="32.638"
      viewBox="0 0 26.289 32.638"
    >
      <g
        fill="#34446e"
        data-name="Group 175470"
        transform="translate(7382.505 7415.218)"
      >
        <path
          d="M666.912 1131.867h-15.378a3.639 3.639 0 01-3.635-3.634v-20.359a.943.943 0 01.944-.943H669.6a.943.943 0 01.944.943v20.359a3.639 3.639 0 01-3.635 3.634m-17.126-23.049v19.415a1.75 1.75 0 001.748 1.747h15.378a1.749 1.749 0 001.747-1.747v-19.415z"
          data-name="Path 5780"
          transform="translate(-8028.584 -8514.447)"
        ></path>
        <path
          d="M661.533 1131.2a.944.944 0 01-.943-.944v-12.784a.944.944 0 011.887 0v12.784a.944.944 0 01-.944.944"
          data-name="Path 5781"
          transform="translate(-8034.489 -8518.914)"
        ></path>
        <path
          d="M674.978 1131.2a.944.944 0 01-.944-.944v-12.784a.944.944 0 011.887 0v12.784a.944.944 0 01-.943.944"
          data-name="Path 5782"
          transform="translate(-8040.744 -8518.914)"
        ></path>
        <path
          d="M669.841 1101.419h-24.4a.944.944 0 010-1.887h24.4a.944.944 0 010 1.887"
          data-name="Path 5783"
          transform="translate(-8027 -8511.005)"
        ></path>
        <path
          d="M668.662 1097.721a.944.944 0 01-.944-.944v-2.362h-4.381v2.362a.944.944 0 11-1.887 0v-3.306a.944.944 0 01.944-.944h6.268a.944.944 0 01.943.944v3.306a.943.943 0 01-.943.944"
          data-name="Path 5784"
          transform="translate(-8034.889 -8507.746)"
        ></path>
      </g>
    </svg>
  );
}

export default DeleteTowIcon;