import { getUser } from "../../../../util/cookies";
import { isOrgCurrency, parseNumber, roundByDigits } from "../../../../util/util";

export const ACTION_CREATE_NEW_INVOICE = '1';
export const ACTION_SET_INVOICE_VALUE = '2';
export const ACTION_SET_INVOICE = '3';
export const ACTION_SET_HEADER_VALUE = '4';
export const ACTION_DELETE_ALL_LINES = '5';
export const ACTION_DELETE_LINE = '6';
export const ACTION_ADD_LINE = '7';
export const ACTION_SET_LINE_VALUE = '8';
export const ACTION_ADD_LINES = '9';
export const ACTION_COUNT_TOTALS = '10';
export const ACTION_RECALCULATE = '11';
export const ACTION_SET_FILTERED_LINES = '12';

const _calculateLine = (line, action, exchngRate) => {
    if (action == "invoiceAmountAfc") {
        line.invoiceAmountAlc = roundByDigits(parseNumber(line.invoiceAmountAfc) * parseNumber(exchngRate || 1), 6)
    }
    if (action == "discountAfc") {
        line.discountAlc = roundByDigits(parseNumber(line.discountAfc) * (exchngRate || 1), 6)
    }
    if (action == "vatPerc" || action == "invoiceAmountAfc" || action == "invoiceAmountAlc" || !line.vatAmountAlc || action == "discountAlc" || action == "discountAfc") {
        line.vatAmountAlc = roundByDigits(((parseNumber(line.invoiceAmountAlc) - parseNumber(line.discountAlc)) * parseNumber(line.vatPerc)) / 100.0, 6)
        line.vatAmountAfc = roundByDigits(((parseNumber(line.invoiceAmountAfc) - parseNumber(line.discountAfc)) * parseNumber(line.vatPerc)) / 100.0, 6)
    }
    line.netAlc = roundByDigits(parseNumber(line.invoiceAmountAlc) - parseNumber(line.discountAlc) + parseNumber(line.vatAmountAlc), 6)
    line.netAfc = roundByDigits(parseNumber(line.invoiceAmountAfc) - parseNumber(line.discountAfc) + parseNumber(line.vatAmountAfc), 6)
    return { ...line }
}

const calculateTotals = (lines) => {
    const totals = {
        count: lines.length,
        totalPriceAlc: 0,
        totalDiscountAlc: 0,
        totalVatAlc: 0,
        totalNetPriceAlc: 0,
        totalPriceAfc: 0,
        totalDiscountAfc: 0,
        totalVatAfc: 0,
        totalNetPriceAfc: 0
    }

    for (let i = 0; i < lines.length; i++) {
        const line = lines[i]
        totals.totalPriceAlc += parseNumber(line.invoiceAmountAlc);
        totals.totalPriceAfc += parseNumber(line.invoiceAmountAfc);
        totals.totalDiscountAlc += parseNumber(line.discountAlc);
        totals.totalDiscountAfc += parseNumber(line.discountAfc);
        totals.totalVatAlc += parseNumber(line.vatAmountAlc);
        totals.totalVatAfc += parseNumber(line.vatAmountAfc);
        totals.totalNetPriceAlc += parseNumber(line.netAlc);
        totals.totalNetPriceAfc += parseNumber(line.netAfc);
    }
    return (totals);

}

const invoiceReducer = (state, action) => {
    const user = getUser();
    switch (action.type) {
        case ACTION_CREATE_NEW_INVOICE: {
            return {
                ...state,
                ...action.value,
            }
        };
        case ACTION_SET_INVOICE_VALUE: {
            return {
                ...state,
                invoice: { ...state.invoice, [action.name]: action.value }
            }
        };
        case ACTION_SET_INVOICE: {
            const customerData = state?.invoice?.header?.customerData || {};
            action.value.header.customerData = customerData;

            state.workflowId = action.workflowId;
            state.stepId = action.stepId;
            state.workflowStatus = action.workflowStatus;

            return {
                ...state,
                invoice: { ...action.value }
            }
        };
        case ACTION_SET_HEADER_VALUE: {
            state.invoice.header[action.name] = action.value
            if (action.name == 'atrId') {
                state.invoice.lines = [];
            }
            if (action.name == 'atrId') {
                state.invoice.header.atrId = action.value.value;
                state.invoice.header.customerData = action.value;
            }
            if ((action.name == 'paidAmountAfc' || action.name == 'fcuId' || action.name == 'exchngRate') && !isOrgCurrency(user?.fcuId, state.invoice.header.fcuId)) {
                state.invoice.header.paidAmountAlc = parseNumber(action.name == 'paidAmountAfc' ? action.value : state.invoice.header.paidAmountAfc) * parseNumber(action.name == 'exchngRate' ? action.value : state.invoice.header.exchngRate);
            }

            if (action.name === "mbnId") {
                state.invoice.lines = [];
                state.invoice.header.dsvId = null;
            }

            if (action.name === "billingDateMode") {
                state.invoice.header.billingPeriodFromDgr = null;
                state.invoice.header.billingPeriodToDgr = null;
                state.invoice.header.billingPeriodFromDhi = null;
                state.invoice.header.billingPeriodToDhi = null;
            }

            if (action.name === "lsoId") {
                state.invoice.lines = [];
            }

            return {
                ...state,
                invoice: { ...state.invoice, header: { ...state.invoice.header } }
            }
        };
        case ACTION_DELETE_ALL_LINES: {
            const total = calculateTotals([])
            return {
                ...state,
                invoice: { ...state.invoice, lines: [], filteredLines: null },
                totals: total
            }
        };
        case ACTION_DELETE_LINE: {
            let newLines = [];
            let newFilteredLines = null;
            if (state.invoice.filteredLines) {
                const deletedLine = state.invoice.filteredLines[action.index];
                newFilteredLines = state.invoice.filteredLines.filter((line, index) => index != action.index)
                newLines = state.invoice.lines.filter((line, index) => index != deletedLine.originalIndex)
            } else {
                newLines = state.invoice.lines.filter((line, index) => index != action.index)
            }
            const total = calculateTotals(newLines)
            return {
                ...state,
                invoice: { ...state.invoice, lines: newLines, filteredLines: newFilteredLines },
                totals: total

            }
        };
        case ACTION_ADD_LINE: {
            let line = action.value;
            line = _calculateLine(line);
            const lines = [...state.invoice.lines, line]
            const total = calculateTotals(lines)
            return {
                ...state,
                invoice: { ...state.invoice, lines: lines },
                totals: total
            }
        };
        case ACTION_ADD_LINES: {
            let lines = action.value;
            lines?.map((line) => {
                line = _calculateLine(line);
            })
            let newLines = [...state.invoice.lines, ...lines]

            const total = calculateTotals(newLines)
            return {
                ...state,
                invoice: { ...state.invoice, lines: newLines },
                totals: total
            }
        };

        case ACTION_SET_LINE_VALUE: {
            let tempLines = state.invoice.filteredLines ? state.invoice.filteredLines : state.invoice.lines;
            let changeLine = tempLines[action.index];
            changeLine[action.name] = action.value;
            _calculateLine(changeLine, action.name, action.exchngRate);
            const total = calculateTotals(state.invoice.lines)
            return {
                ...state,
                invoice: { ...state.invoice, lines: [...state.invoice.lines] },
                totals: total
            }
        };

        case ACTION_COUNT_TOTALS: {
            const total = calculateTotals(state?.invoice?.lines)
            return {
                ...state,
                totals: total,
            }
        };

        case ACTION_RECALCULATE: {
            for (let key in (action.value || [])) {
                const values = action.value[key].split('-');
                state.invoice.lines[key].invoiceAmountAlc = values[0];
                state.invoice.lines[key].invoiceAmountAfc = values[1];
                state.invoice.lines[key].dueAmountAlc = values[0];
                state.invoice.lines[key] = _calculateLine(state.invoice.lines[key], 'invoiceAmountAlc');
            }

            const total = calculateTotals(state.invoice.lines);

            return {
                ...state,
                invoice: { ...state.invoice, lines: state.invoice.lines },
                totals: total
            }
        }

        case ACTION_SET_FILTERED_LINES: {
            return {
                ...state,
                invoice: { ...state.invoice, filteredLines: action.value },
            }
        }

        default:
            return state;
    }
}

export default invoiceReducer;