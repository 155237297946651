import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import RotateOptionIcon from '../../../icons/RotateOption';
import ServiceDeniedIcon from '../../../icons/ServiceDenied';
import { clearFormsFilter } from '../../../store/formsFilter/actions';
import { userLogout } from '../../../store/user/actions';
import { destroyAllCookie } from '../../../util/cookies';
import style from './style.module.scss';

const DeniedServiceError = (props, ref) => {
    const { logoutAction } = props;
    const { t } = useTranslation();
    const [closeError, setCloseError] = useState(true);
    let dispatch = useDispatch();
    useImperativeHandle(ref, () => ({
        open: () => setCloseError(false)
    }));
    const _handleLogout = async () => {
        try {
            logoutAction(false);
            setCloseError(!closeError)
        } catch (e) {
            console.error("", e);
        }
    }

    return (
        <div className={` ${style.mainCountDwon} ${closeError ? style.closeCountDwon : ""}  `}>
            <div className="openBlackScreen"></div>
            <div className={style.countDwonContaer}>
                <div className={style.deniedService}>
                    <div className={style.icons}>
                        <ServiceDeniedIcon />
                    </div>
                    <div className={style.errorLbl}>
                        <label>
                            {t("service_denied")}
                        </label>
                    </div>
                    <div className={style.counterLbl}>
                        <label>
                            {t("service_warning_desc")}
                        </label>
                    </div>
                    <div className={style.errorContaerBtn}>
                        <button className="primary-button" onClick={_handleLogout}>
                            {t('continue')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default forwardRef(DeniedServiceError)