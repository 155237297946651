import { addUrlParam, checkValidation, deleteUrlParam, getUrlParam, handleError, hideActionWaiting, isEmpty, isNotEmpty, removeAllMandatoryClasses, showActionWaiting, showSuccessMessage } from "@ipgd-gauge/utils";
import { useEffect, useReducer, useRef } from "react";
import { useTranslation } from "react-i18next";
import PrintDialog from "../../../components/print-dialog";
import WorkflowDialog from "../../../components/slisting/workflow-dialog";
import { OpenCloseMenu } from "../../../icons/OpenCloseMenu";
import { getCreditNoteCustomerEstimation, getDefaultFormValues, saveCreditNoteCustomerEstimation, saveWorkflowTransaction } from "../../../util/apis";
import { DOMAIN_CUSTOMER_ACCOUNT_TYPE_MAIN, GLOBAL_STATUS_INCOMPLETE } from "../../../util/constants";
import { RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import { useWorkflow } from "../../../util/hooks/useWorkflow";
import AdjustWorkDialog from "../../common/dialogs/adjust-work";
import WorkflowsActionLogDialog from "../../common/dialogs/workflows-action-log";
import ZatcaSummaryDialog from "../../common/dialogs/zatca-summary-dialog";
import Actions from "./Actions";
import BranchDivision from "./BranchDivision";
import Customer from "./Customer";
import Details from "./Details";
import Header from "./Header";
import Invoice from "./Invoice";
import creditNoteReducer, { ACTION_CREATE_NEW_CREADIT_NOTE, ACTION_SET_CREADIT_NOTE } from "./reducer";
import style from './style.module.scss';
import Summary from "./summary";

const CreditNotesForCustomerEstimation = () => {
    const id = getUrlParam('id');

    const paramCustomerId = getUrlParam('atrId');

    const { t } = useTranslation();

    const user = getUser();

    const fromSaveActionRef = useRef(false);
    const fromNewActionRef = useRef(false);
    const printDialogRef = useRef();
    const zatcaSummaryRef = useRef();
    const actionsRef = useRef();
    const workflowsActionLogDialogRef = useRef();
    const adjustWorkDialogRef = useRef();
    const workflowDialogRef = useRef();

    const initialState = {
        creditNote: {
            header: {
                globalStatus: GLOBAL_STATUS_INCOMPLETE,
                exchngRate: 1,
                fogId: user?.fogId,
                accountType: DOMAIN_CUSTOMER_ACCOUNT_TYPE_MAIN,
                lsoId: user?.estimationInvoiceLsoId,
                mbnId: user?.mbnId,
                dsvId: user?.dsvId,
            },
            lines: [],
        },
        totals: {},
    }

    const { workflowId, stepId } = useWorkflow(RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID);
    const [creditNoteState, dispatchCreditNote] = useReducer(creditNoteReducer, initialState);

    useEffect(() => {
        if (fromSaveActionRef.current) {
            fromSaveActionRef.current = false;
            return;
        }

        if (fromNewActionRef.current) {
            fromSaveActionRef.current = false;
            return;
        }

        if (id) {
            getCreditNoteCustomerEstimation(id)
                .then((response) => {
                    actionsRef.current.setWorkflowAction(response.data.task)
                    dispatchCreditNote({
                        type: ACTION_SET_CREADIT_NOTE,
                        value: { header: response.data.transactionData || {}, lines: (response.data.transactionData || {}).lines || [] },
                        workflowId: response.data.workflowId,
                        stepId: response.data.stepId,
                        workflowStatus: response.data.workflowStatus,
                    });
                })
        } else {
            _newInvoice(workflowId, stepId);
        }
    }, [id]);

    const _newInvoice = async (workflowId, stepId) => {
        const defaultData = (await getDefaultFormValues(RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID)).data;

        if (defaultData.hasWorkflow && (isEmpty(workflowId) || isEmpty(stepId))) {
            workflowDialogRef?.current?.open();
            return;
        }

        deleteUrlParam("id");

        let newCreaditNote = { ...initialState };
        if (isNotEmpty(paramCustomerId)) {
            newCreaditNote.creditNote.header.atrId = paramCustomerId;
            newCreaditNote.creditNote.header.accountType = DOMAIN_CUSTOMER_ACCOUNT_TYPE_MAIN;
        }
        newCreaditNote.creditNote.header.dateDgr = defaultData.currentDate;
        newCreaditNote.creditNote.header.fcuId = defaultData?.fcuId;
        newCreaditNote.workflowId = workflowId;
        newCreaditNote.stepId = stepId;
        newCreaditNote.workflowStatus = null;
        fromNewActionRef.current = true;
        dispatchCreditNote({ value: newCreaditNote, type: ACTION_CREATE_NEW_CREADIT_NOTE });
        removeAllMandatoryClasses(document.getElementById("credit-notes-for-customer-estimation"));
        workflowDialogRef?.current?.close();
        actionsRef?.current?.setWorkflowAction(null);
    }

    const _checkValidation = () => !checkValidation([{ id: 'credit-notes-for-customer-estimation' }], t);
    const _handleError = (error) => handleError(error, error?.response?.data?.message.split('#')[0], t);

    const _whenSaveData = (transactionData, workflowId = null, stepId = null, workflowStatus = {}, task = null, withSuccessMessage = true) => {
        fromSaveActionRef.current = true;
        let creditNoteData = { header: transactionData, lines: transactionData.lines };

        dispatchCreditNote({
            type: ACTION_SET_CREADIT_NOTE,
            value: creditNoteData,
            workflowId,
            stepId,
            workflowStatus,
        });

        addUrlParam("id", transactionData.id);

        if (withSuccessMessage) {
            showSuccessMessage('save_successfully', t);
        }

        actionsRef?.current?.setWorkflowAction(task);
    }

    const _saveAction = (withSubmit) => {
        const actionId = withSubmit ? 'save-credit-notes-for-customers-estimation' : 'draft-credit-notes-for-customers-estimation';

        if (withSubmit && _checkValidation()) {
            return
        }

        showActionWaiting(actionId);

        const data = { ...creditNoteState.creditNote.header, lines: creditNoteState.creditNote.lines };

        if (isNotEmpty(creditNoteState.workflowId) && isNotEmpty(creditNoteState.stepId)) {
            saveWorkflowTransaction(creditNoteState.workflowId, RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID, data)
                .then(response => _whenSaveData(
                    response.data.transactionData,
                    response.data.workflowId,
                    response.data.stepId,
                    response.data.workflowStatus,
                    response.data.task))
                .catch(_handleError)
                .finally(() => hideActionWaiting(actionId));
        } else {
            saveCreditNoteCustomerEstimation(data, withSubmit)
                .then(response => _whenSaveData(response.data))
                .catch(_handleError)
                .finally(() => hideActionWaiting(actionId));
        }
    }

    const _onExecuteWorkflowAction = async (responseData) => {
        _whenSaveData(
            responseData.transactionData,
            responseData.workflowId,
            responseData.stepId,
            responseData.workflowStatus,
            responseData.task,
            false);
    }

    const handleOpenSummary = () => {
        document.getElementById('credit-notes-for-customer-estimation-lines').classList.toggle('active');
        document.getElementById('credit-notes-for-customer-estimation-summary').classList.toggle('active');
        document.getElementById('bg-sm').classList.toggle('active');
    }

    return (
        <>
            <div className={style.container}>
                <div id="credit-notes-for-customer-estimation-lines" className="lines-container-open-close">
                    <div id='credit-notes-for-customer-estimation' className={`toggle-mode ${style.toggleMode}`}>
                        <Header creditNote={creditNoteState.creditNote} workflowStatus={creditNoteState.workflowStatus} dispatchCreditNote={dispatchCreditNote} />
                        <BranchDivision user={user} creditNote={creditNoteState.creditNote} dispatchCreditNote={dispatchCreditNote} />
                        <Customer creditNote={creditNoteState.creditNote} dispatchCreditNote={dispatchCreditNote} />
                        <Invoice user={user} creditNote={creditNoteState.creditNote} dispatchCreditNote={dispatchCreditNote} />
                        <Details user={user} creditNote={creditNoteState.creditNote} dispatchCreditNote={dispatchCreditNote} />
                    </div>
                </div>

                <div id="credit-notes-for-customer-estimation-summary" className="summary-container-panel">
                    <button className="open-close-summary-btn" onClick={handleOpenSummary}>
                        <OpenCloseMenu />
                    </button>
                    <Summary
                        dispatchCreditNote={dispatchCreditNote}
                        creditNote={creditNoteState.creditNote}
                        totals={creditNoteState.totals}
                        handleOpenSummary={handleOpenSummary}
                    />
                </div>
                <div id="bg-sm" onClick={handleOpenSummary} className="summary-bg-sm"></div>
            </div>

            <Actions
                ref={actionsRef}
                user={user}
                creditNoteState={creditNoteState}
                newAction={_newInvoice}
                saveAction={_saveAction}
                printDialogRef={printDialogRef}
                adjustWorkDialogRef={adjustWorkDialogRef}
                workflowsActionLogDialogRef={workflowsActionLogDialogRef}
                zatcaSummaryRef={zatcaSummaryRef}
            />

            <ZatcaSummaryDialog
                ref={zatcaSummaryRef}
            />

            <PrintDialog
                ref={printDialogRef}
                id="credit-notes-for-customer-estimation-print-dialog"
                formId={RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID}
                user={user}
            />

            <WorkflowsActionLogDialog
                ref={workflowsActionLogDialogRef}
                formId={RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID}
            />

            <AdjustWorkDialog
                ref={adjustWorkDialogRef}
                formId={RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID}
                getTransactionData={() => ({ ...creditNoteState?.creditNote?.header, lines: creditNoteState?.creditNote?.lines })}
                onExecuteAction={_onExecuteWorkflowAction}
                globalStatus={creditNoteState?.creditNote?.header?.globalStatus}
                checkValidationAction={_checkValidation}
                onError={_handleError}
            />
            
            <WorkflowDialog
                ref={workflowDialogRef}
                formId={RSM3278_CREDIT_NOTES_FOR_CUSTOMERS_ESTIMATION_FORM_ID}
                newAction={(workflowId, stepId) => _newInvoice(workflowId, stepId)}
            />
        </>
    );
}

export default CreditNotesForCustomerEstimation;