import { formatDate, formatMoney, handleError, isEnglish, isNotEmpty } from "@ipgd-gauge/utils";
import i18next from "i18next";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import PrintDialog from "../../../components/print-dialog";
import Slisting from "../../../components/slisting";
import { deleteEstimationInvoice, exportCustomerEstimationInvoices, exportZatcaXml, getEstimationInvoices, getLinkWithZatcaPref, getPreferenceValue } from "../../../util/apis";
import { GLOBAL_STATUS_INCOMPLETE } from "../../../util/constants";
import { RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import Actions from "./actions";
import style from './style.module.scss';
import ZatcaSummaryDialog from "../../common/dialogs/zatca-summary-dialog";
import { downloadXml } from "../../../util/util";
import WorkflowsActionLogDialog from "../../common/dialogs/workflows-action-log";

const Row = (props) => {
    const { data, rowActions } = props;
    const { user, tableRef, printDialogRef, zatcaSummaryRef, isLinkWithZatca, workflowsActionLogDialogRef } = rowActions;

    const { t } = useTranslation();

    const _exportZatcaXml = (id) => {
        exportZatcaXml(RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID, id).then(response => {
            downloadXml(response);
        })
    }

    return (
        <div className={`${style.customersEstimationInvoicesRow}`}>
            <div title={data.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data.sequence}</label>
            </div>

            <div title={data.code} className="cardRowMode">
                <label className="nameLblMobile">{t('customersEstimationInvoices:invoice_num')}</label>
                <label>{data.code}</label>
            </div>

            <div title={formatDate(data.dateDgr)} className="cardRowMode">
                <label className="nameLblMobile">{t('customersEstimationInvoices:invoice_date')}</label>
                <label>{formatDate(data.dateDgr)}</label>
            </div>

            <div title={i18next.language == "en" ? data.customerNameLo : data.customerNameFo} className="cardRowMode">
                <label className="nameLblMobile">{t('customersEstimationInvoices:customer')}</label>
                <label>{i18next.language == "en" ? data.customerNameLo : data.customerNameFo}</label>
            </div>

            <div title={`${i18next.language == "en" ? data.branchLo : data.branchFo} - ${i18next.language == "en" ? data.divisionLo : data.divisionFo}`} className="cardRowMode">
                <label className="nameLblMobile">{t('customersEstimationInvoices:branch_division')}</label>
                <label>{`${i18next.language == "en" ? data.branchLo : data.branchFo} - ${i18next.language == "en" ? data.divisionLo : data.divisionFo}`}</label>
            </div>

            <div title={formatMoney(data.invoiceNetAlc)} className="cardRowMode">
                <label className="nameLblMobile">{t('customersEstimationInvoices:net_price')}</label>
                <label>{formatMoney(data.invoiceNetAlc)}</label>
            </div>

            <div title={data.refCode} className="cardRowMode">
                <label className="nameLblMobile">{t('customersEstimationInvoices:ref_code')}</label>
                <label>{data.refCode}</label>
            </div>

            <div title={data.rem} className="cardRowMode">
                <label className="nameLblMobile">{t('customersEstimationInvoices:remarks')}</label>
                <label>{data.rem}</label>
            </div>
            <div className="columnSm">
                <div className="columnRowNew" style={{ alignItems: 'center' }}>
                    <label className={`status-dote-${data.zatcaSynchStatus}`}>
                        {isEnglish() ? data.zatcaSynchStatusLo : data.zatcaSynchStatusFo}
                    </label>
                    <button className='download-zatca-btn' title={t('download_xml')} onClick={() => _exportZatcaXml(data.id)}>{t('download_xml')}</button>
                </div>
                <div className="cardRowTrxMode">
                    <label className="nameLblMobile">ZATCA</label>
                    <label className={`status-dote-${data.zatcaSynchStatus}`}>
                        {isEnglish() ? data.zatcaSynchStatusLo : data.zatcaSynchStatusFo}
                    </label>
                </div>
                <div className="cardRowTrxMode">
                    <label className="nameLblMobile"></label>
                    <button className='download-zatca-btn' title={t('download_xml')} onClick={() => _exportZatcaXml(data.id)}>{t('download_xml')}</button>
                </div>
            </div>



            <div className="cardRowStatusMode">
                <label className="nameLblMobile">{t('payablesInvoices:status')}</label>
                <label
                    title={isEnglish() ? data.statusDescLo : data.statusDescFo}
                    className={`${isNotEmpty(data?.workflowStatusId) ? 'workflow-status' : `status-${data.globalStatus}`}`}
                    style={isNotEmpty(data?.workflowStatusId) ? { backgroundColor: `${data?.statusBgColor}`, color: `${data?.statusColor}` } : {}}>
                    {isEnglish() ? data.statusDescLo : data.statusDescFo}
                </label>
            </div>

            {/* <div title={i18next.language == "en" ? data.statusDescLo : data.statusDescFo} className="cardRowStatusMode">
                <label className="nameLblMobile">{t('customersEstimationInvoices:status')}</label>
                <label className={`status-${data.globalStatus}`}>{i18next.language == "en" ? data.statusDescLo : data.statusDescFo}</label>
            </div> */}

            <div>
                <Actions user={user} data={data} tableRef={tableRef} printDialogRef={printDialogRef} workflowsActionLogDialogRef={workflowsActionLogDialogRef} zatcaSummaryRef={zatcaSummaryRef} isLinkWithZatca={isLinkWithZatca} />
            </div>
        </div>
    );
};

const CustomersEstimationInvoicesTable = (props) => {
    const { customersEstimationInvoicesTableRef, searchTextRef, filtersRef } = props;

    const user = getUser();
    const workflowsActionLogDialogRef = useRef();

    const { t } = useTranslation();
    const navigate = useNavigate();
    const printDialogRef = useRef();
    const zatcaSummaryRef = useRef();

    const [estimationInvoices, setEstimationInvoices] = useState();
    const [isAllowedLinkWithZatca, setAllowedLinkWithZatca] = useState();

    useEffect(() => {
        const _fillZatcaPref = async () => {
            const resp = await getLinkWithZatcaPref();
            setAllowedLinkWithZatca(resp.data == '100001');
        }
        _fillZatcaPref();
    }, []);

    const getIsAllowedLinkWithZatca = () => {
        return isAllowedLinkWithZatca
    }
    const columns = [
        { title: '#', width: '3%' },
        { title: t('customersEstimationInvoices:invoice_num'), width: '11%', excelColumn: "ISN_CODE", sortBy: "ISN_CODE" },
        { title: t('customersEstimationInvoices:invoice_date'), width: '8%', excelColumn: "ISN_DATE_DGR", sortBy: "ISN_DATE_DGR" },
        { title: t('customersEstimationInvoices:customer'), width: '10%', excelColumn: "CUSTOMER_NAME_LO,CUSTOMER_NAME_FO", sortBy: "CUSTOMER_NAME_LO,CUSTOMER_NAME_FO" },
        { title: t('customersEstimationInvoices:branch_division'), width: '11%', excelColumn: "BRANCH_LO,BRANCH_FO,DIVISION_LO,DIVISION_FO", sortBy: "BRANCH_LO,BRANCH_FO" },
        { title: t('customersEstimationInvoices:net_price'), width: '10%', excelColumn: "ISN_INVOICE_NET_ALC", sortBy: "ISN_INVOICE_NET_ALC" },
        { title: t('customersEstimationInvoices:ref_code'), width: '9%', excelColumn: "ISN_REF_CODE", sortBy: "ISN_REF_CODE" },
        { title: t('customersEstimationInvoices:remarks'), width: '10%', excelColumn: "ISN_REM", sortBy: "ISN_REM" },
        { title: t('ZATCA'), width: "6%", sortBy: "ZATCA_SYNCH_STATUS_LO,ZATCA_SYNCH_STATUS_FO" },
        { title: t('customersEstimationInvoices:status'), width: '10%', excelColumn: "STATUS_LO,STATUS_FO", sortBy: "STATUS_DESC_LO,STATUS_DESC_FO" },
        { title: "", width: '12%' },
    ];

    const _fetchData = (pageNumber, pageSize, sortBy) => {
        const filterData = {
            searchValue: searchTextRef?.current?.getSearchText(),
            sorting: sortBy,
            ...filtersRef.current.getFilterData(),
        }
        getEstimationInvoices(pageNumber, pageSize, filterData)
            .then((response) => setEstimationInvoices(response.data))
            .catch((error) => handleError(error, null, t));
    }

    const _export = () => {
        const filterData = {
            ...filtersRef.current.getFilterData(),
            searchValue: searchTextRef?.current?.getSearchText(),
            columnSetting: customersEstimationInvoicesTableRef.current.getSelectedSettingId()
        }
        exportCustomerEstimationInvoices(filterData);
    }

    return (
        <>
            <Slisting
                id="customers-estimation-invoices-table"
                ref={customersEstimationInvoicesTableRef}
                columns={columns}
                Row={Row}
                tableStyle={style.customersEstimationInvoicesTable}
                getData={_fetchData}
                rowActions={{ user, tableRef: customersEstimationInvoicesTableRef, printDialogRef, workflowsActionLogDialogRef, zatcaSummaryRef: zatcaSummaryRef, isLinkWithZatca: getIsAllowedLinkWithZatca }}
                pagingData={estimationInvoices}
                withFilter
                showWating={(data) => data == null}
                showNoDataFound={(data) => (data && data.length == 0)}
                formId={RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID}
                newAction={() => navigate("/customers-estimation-invoice")}
                deleteAction={deleteEstimationInvoice}
                viewAction={(id) => navigate(`/customers-estimation-invoice?id=${id}`)}
                hideDeleteCondition={(data) => data.globalStatus != GLOBAL_STATUS_INCOMPLETE}
                exportExcelAction={_export}
                withPrintAction
                user={user}
                cardInMobile
                labelCardWidth="100px"
                withWorkflow
            />

            <PrintDialog
                ref={printDialogRef}
                id="customer-estimation-invoice-print-dialog"
                formId={RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID}
                user={user}
            />
            <ZatcaSummaryDialog
                ref={zatcaSummaryRef}
            />

            <WorkflowsActionLogDialog
                ref={workflowsActionLogDialogRef}
                formId={RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID}
            />
        </>
    );
};

export default CustomersEstimationInvoicesTable;