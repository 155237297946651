import { FrmTimeDatePickr } from "@ipgd-gauge/date-picker";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../../components/advance-filter";
import StatusSelectMenu from "../../../components/status-select-menu";
import { getCommonCustomers, getDomainValues, getDomainValuesWithCondition } from "../../../util/apis";
import { CUSTOMER_LEVEL, DOMAIN_ESTIMATIONS_QUOTATION_STATUS, DOMAIN_ESTIMATIONS_QUOTATION_VALIDITY, DOMAIN_GLOBAL_STATUS, GLOBAL_STATUS_CONFIRMED, GLOBAL_STATUS_INCOMPLETE, GLOBAL_STATUS_REJECTED, GLOBAL_STATUS_SUBMITTED } from "../../../util/constants";
import { RSM3190_EVALUATION_QUOTATIONS_FORM_ID } from "../../../util/constants/forms";
import WorkflowStatusFilter from "../../../components/workflow-status-filter";

const EstimationQuotationFilter = (props, ref) => {
    const { tableRef } = props;
    const advanceFilterRef = useRef();

    const { t } = useTranslation();

    const [filterData, setFilterData] = useState({});

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const getFilterData = () => Object.assign({}, filterData);

    const open = () => advanceFilterRef.current?.open();

    useImperativeHandle(ref, () => ({ getFilterData, open }));

    return (
        <AdvanceFilter
            ref={advanceFilterRef}
            id="estimations-quotation-filter"
            filterData={filterData}
            setFilterData={setFilterData}
            searchAction={() => tableRef?.current?.refresh()}
            hideMoreOptions
            formId={RSM3190_EVALUATION_QUOTATIONS_FORM_ID}
            numOfRows={4}
        >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="container">
                    <div className="row rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('estimationQuotation:status')}</label>
                            <StatusSelectMenu
                                id="estimations-quotation-filter-global-status"
                                isMulti={true}
                                value={filterData.globalStatuses}
                                api={() => getDomainValuesWithCondition(DOMAIN_GLOBAL_STATUS, true, [GLOBAL_STATUS_INCOMPLETE, GLOBAL_STATUS_SUBMITTED, GLOBAL_STATUS_CONFIRMED, GLOBAL_STATUS_REJECTED])}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={e => _handleDataChanged("globalStatuses", e)}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationQuotation:follow_up_status')}</label>
                            <StatusSelectMenu
                                id="estimations-quotation-filter-status"
                                isMulti={true}
                                value={filterData.statusIds}
                                api={() => getDomainValues(DOMAIN_ESTIMATIONS_QUOTATION_STATUS)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={e => _handleDataChanged("statusIds", e)}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationQuotation:customer')}</label>
                            <FrmSelectMenu
                                id="estimations-quotation-filter-customer"
                                value={filterData.customerIds}
                                onChange={e => _handleDataChanged("customerIds", e)}
                                defualtValueId={filterData.customerIds}
                                defualtValueApi={() => getCommonCustomers(filterData.customerIds, null, null, true, false, false, [], false)}
                                api={(searchValue) => getCommonCustomers(null, null, searchValue, true, false, false, [], false)}
                                descLo={['code', 'descLo']}
                                descFo={['code', 'descFo']}
                                isAsync={true}
                                isMulti={true}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationQuotation:validity')}</label>
                            <FrmSelectMenu
                                id="estimations-quotation-filter-validity"
                                // isMulti={true}
                                value={filterData.validityId}
                                api={() => getDomainValues(DOMAIN_ESTIMATIONS_QUOTATION_VALIDITY)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={e => _handleDataChanged("validityId", e.value)}
                                portalTarget={document.body}
                                isClearable
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationQuotation:from_date')}</label>
                            <FrmTimeDatePickr
                                id="estimations-quotation-filter-from-date"
                                value={filterData?.fromDate}
                                onChange={(value) => _handleDataChanged("fromDate", value)}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('estimationQuotation:to_date')}</label>
                            <FrmTimeDatePickr
                                id="estimations-quotation-filter-to-date"
                                value={filterData?.toDate}
                                onChange={(value) => _handleDataChanged("toDate", value)}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('estimationQuotation:customer_level')}</label>
                            <FrmSelectMenu
                                id="estimations-quotation-filter-customer-level"
                                value={filterData.customerLevel}
                                api={() => getDomainValues(CUSTOMER_LEVEL)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleDataChanged("customerLevel", value)}
                                isMulti={true}
                                portalTarget={document.body}
                            />
                        </div>

                        <div className="w50">
                            <WorkflowStatusFilter
                                formId={RSM3190_EVALUATION_QUOTATIONS_FORM_ID}
                                value={filterData.workflowStatus}
                                onChange={(value) => _handleDataChanged("workflowStatus", value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </AdvanceFilter>
    )
}

export default forwardRef(EstimationQuotationFilter);