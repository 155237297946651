import IPGDWorkflowDialog from "@ipgd-gauge/workflow/WorkflowDialog";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { getWorkflowBasedOnFormId } from "../../../util/apis";
const WorkflowDialog = ({ formId, newAction }, ref) => {
    const workflowDialogRef = useRef();
    useImperativeHandle(ref, () => ({
        ...workflowDialogRef.current
    }));
    return <IPGDWorkflowDialog ref={workflowDialogRef} formId={formId} getWorkflowBasedOnFormId={getWorkflowBasedOnFormId} newAction={newAction} />;
}


export default forwardRef(WorkflowDialog);