import i18next from "i18next";
import { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DetailsImgIcon from "../../icons/DetailsImg";
import { NoOneReadIcon } from "../../icons/MsgStatus";
import { getInboxMessageDetails, getMessages } from "../../util/apis";
import { formatDateNTime } from "../../util/dateUtil";
import NoDataFound from "../no-data-found";
import style from "./style.module.scss";

const SenderCard = (props) => {

    const { message } = props
    const { t } = useTranslation();

    return (
        <div className={style.containerRecipient}>
            <div className={style.recipientImg} title={message.userFullLo ? message.userFullLo?.charAt(0) : message.descLo?.charAt(0)}>
                {message.userFullLo ? message.userFullLo?.charAt(0) : message.descLo?.charAt(0)}
            </div>
            <div>
                <div className={style.recipient}>
                    <div className={style.senderMsg}>
                        <div className={style.recipientTitle}>
                            <label title={i18next.language == "en" ? message.userFullLo : message.userFullFo}>{i18next.language == "en" ? message.userFullLo : message.userFullFo}</label>
                            <label title={message.code == 'NOTE' ? t('note') : message.code == 'D-NOTE' ? t('deleted_note') : message.code}>{message.code == 'NOTE' ? t('note') : message.code == 'D-NOTE' ? t('deleted_note') : message.code}</label>
                        </div>
                        <div className={style.chatDesc} title={message.text}>
                            {message.text}
                        </div>
                        <div className={style.recipientDetails}>
                            {message.isMsgExec ?
                                <span className={style.executedMsg}>
                                    <NoOneReadIcon />
                                </span>
                                :
                                <DetailsImgIcon />
                            }
                            <label title={formatDateNTime(message.sendingDateTime)}>{formatDateNTime(message.sendingDateTime)}</label>
                        </div>
                    </div>
                </div>
                {/* <div className={style.mention}>
                    <label>
                        <span>@</span>
                        saif alsafeen
                    </label>
                    <label>
                        <span>@</span>
                        saif alsafeen
                    </label>
                </div> */}
            </div>
        </div>
    )

}

const RecipientCard = (props) => {

    const { message } = props
    const { t } = useTranslation();

    return (
        <div className={style.containerSeander}>
            <div className={style.senderImg}>{message.userFullLo ? message.userFullLo?.charAt(0) : message.descLo?.charAt(0)}</div>
            <div>
                <div className={style.sender}>
                    <div className={style.senderMsg}>
                        <div className={style.senderTitle}>
                            <label title={i18next.language == "en" ? message.userFullLo : message.userFullFo}>{i18next.language == "en" ? message.userFullLo : message.userFullFo}</label>
                            <label title={message.code == 'NOTE' ? t('note') : message.code == 'D-NOTE' ? t('deleted_note') : message.code}>{message.code == 'NOTE' ? t('note') : message.code == 'D-NOTE' ? t('deleted_note') : message.code}</label>
                        </div>
                        <div className={style.chatDesc} title={message.text}>
                            {message.text}
                        </div>
                        <div className={style.senderDetails}>
                            {message.isMsgExec ?
                                <span className={style.executedMsg}>
                                    <NoOneReadIcon />
                                </span>
                                :
                                <DetailsImgIcon />
                            }
                            <label title={formatDateNTime(message.sendingDateTime)}>{formatDateNTime(message.sendingDateTime)}</label>
                        </div>
                    </div>
                </div>
                {/* <div className={style.mention}>
                    <label>
                        <span>@</span>
                        saif alsafeen
                    </label>
                    <label>
                        <span>@</span>
                        saif alsafeen
                    </label>
                </div> */}
            </div>
        </div>
    )

}

const TableChate = (props, ref) => {
    const { messageId, transactionId } = props;

    const [data, setData] = useState();
    useEffect(() => {
        if (messageId) {
            getInboxMessageDetails(messageId)
                .then((response) => {
                    setData(response.data);
                })
                .catch((error) => {
                    console.error("", error)
                })
        } else if (transactionId) {
            getMessages(transactionId)
                .then((response) => {
                    setData(response.data);
                })
                .catch((error) => {
                    console.error("", error)
                })
        }
    }, [messageId])

    return (
        <div className={style.containerChat}>
            <div className={` ${style.chat} ${data?.length == 0 ? style.noData : ""} `}>
                {/* <div className={style.chatDate}>Mon 21:26</div> */}
                {data?.map(message =>
                    message.refMessageId ?
                        <SenderCard message={message} />
                        :
                        <RecipientCard message={message} />
                )}
                {data?.length == 0 &&
                    <NoDataFound />
                }
            </div>
        </div>
    );
}

export default forwardRef(TableChate);