import { checkValidation, deleteUrlParam, getUrlParam, handleError, hideActionWaiting, isEmpty, isNotEmpty, removeAllMandatoryClasses, showActionWaiting, showNotificationToast, showSuccessMessage } from "@ipgd-gauge/utils";
import { useEffect, useReducer, useRef } from "react";
import { useTranslation } from "react-i18next";
import PrintDialog from "../../../components/print-dialog";
import { OpenCloseMenu } from "../../../icons/OpenCloseMenu";
import ZatcaSummaryDialog from "../../common/dialogs/zatca-summary-dialog";
import XIcon from "../../../icons/X";
import { getDefaultFormValues, getEstimationInvoice, saveEstimationInvoice, saveWorkflowTransaction, getLinkWithZatcaPref } from "../../../util/apis";
import { DATE_LEVEL_DOMAIN_ALL, DOMAIN_CUSTOMER_ACCOUNT_TYPE_MAIN, ERP_FORM_ID_CUSTOMERS_ESTIMATION_INVOICES, GLOBAL_STATUS_INCOMPLETE } from "../../../util/constants";
import { RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import { addUrlParam, closeSummary } from "../../../util/util";
import Actions from "./Actions";
import Details, { ESTIMATION_FEES, ITEM } from "./Details";
import Header from "./Header";
import invoiceReducer, { ACTION_CREATE_NEW_INVOICE, ACTION_SET_INVOICE } from "./reducer";
import style from './style.module.scss';
import Summary from "./summary";
import WorkflowDialog from "../../../components/slisting/workflow-dialog";
import AdjustWorkDialog from "../../common/dialogs/adjust-work";
import WorkflowsActionLogDialog from "../../common/dialogs/workflows-action-log";
import { useWorkflow } from "../../../util/hooks/useWorkflow";

const CustomersEstimationInvoice = () => {
    const user = getUser();

    const { t } = useTranslation();

    const { workflowId, stepId } = useWorkflow(RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID);

    const invoiceId = getUrlParam('id');
    const paramCustomerId = getUrlParam('atrId');

    const fromSaveActionRef = useRef(false);
    const zatcaSummaryRef = useRef();
    const adjustWorkDialogRef = useRef();
    const workflowsActionLogDialogRef = useRef();
    const actionsRef = useRef();
    const workflowDialogRef = useRef();
    const printDialogRef = useRef();

    const initialState = {
        invoice: {
            header: {
                globalStatus: GLOBAL_STATUS_INCOMPLETE,
                exchngRate: 1,
                fogId: user?.fogId,
                accountType: DOMAIN_CUSTOMER_ACCOUNT_TYPE_MAIN,
                lsoId: user?.estimationInvoiceLsoId,
                mbnId: user?.mbnId,
                dsvId: user?.dsvId,
                billingDateMode: DATE_LEVEL_DOMAIN_ALL
            },
            lines: [],
            filteredLines: null
        },
        totals: {},
    }

    const [invoiceState, dispatchInvoice] = useReducer(invoiceReducer, initialState);

    useEffect(() => {
        if (invoiceId && !fromSaveActionRef.current) {
            getEstimationInvoice(invoiceId)
                .then((response) => {
                    const transactionData = response.data.transactionData;
                    const task = response.data.task;
                    // transactionData.workflowId = responseData.workflowId;
                    // transactionData.stepId = responseData.stepId;
                    // transactionData.workflowStatus = responseData.workflowStatus;

                    actionsRef?.current?.setWorkflowAction(task || null);
                    transactionData.lines.map((line) => {
                        if (line.source == ESTIMATION_FEES) {
                            line.applicationNum = line?.transaction?.code
                        } else if (line.source == ITEM) {
                            line.descLo = line?.item?.code + ', ' + line?.item?.descLo
                            line.descFo = line?.item?.code + ', ' + line?.item?.descFo
                        }
                    });

                    let invoiceData = {
                        id: transactionData.id,
                        header: transactionData,
                        lines: transactionData.lines,
                        globalStatus: transactionData.globalStatus,
                    };

                    dispatchInvoice({
                        value: { ...invoiceData },
                        workflowId: response.data.workflowId,
                        stepId: response.data.stepId,
                        workflowStatus: response.data.workflowStatus,
                        type: ACTION_SET_INVOICE,
                    });
                })
        } else if (!fromSaveActionRef.current) {
            _newInvoice(workflowId, stepId);
        }
        if (fromSaveActionRef.current) {
            fromSaveActionRef.current = false;
        }
        if (fromSaveActionRef.current) {
            fromSaveActionRef.current = false;
        }
    }, [invoiceId]);

    const _newInvoice = async (workflowId, stepId) => {
        const defaultData = (await getDefaultFormValues(RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID)).data

        if (defaultData.hasWorkflow && (isEmpty(workflowId) || isEmpty(stepId))) {
            workflowDialogRef?.current?.open();
            return;
        }

        deleteUrlParam("id");
        let newInvoice = { ...initialState };

        newInvoice.invoice.header.dateDgr = defaultData.currentDate;
        newInvoice.invoice.header.fcuId = defaultData?.fcuId;
        newInvoice.workflowId = workflowId;
        newInvoice.stepId = stepId;
        newInvoice.invoice.header.lsoId = defaultData?.estimationInvoicePolicyId;

        if (isNotEmpty(paramCustomerId)) {
            newInvoice.invoice.header.atrId = paramCustomerId;
            newInvoice.invoice.header.accountType = DOMAIN_CUSTOMER_ACCOUNT_TYPE_MAIN;
        }

        if (isEmpty(defaultData?.estimationInvoicePolicyId)) {
            showNotificationToast(t('USER_POLICY_ERR'), { hideProgressBar: true, type: 'warning' })
        }

        dispatchInvoice({ value: newInvoice, type: ACTION_CREATE_NEW_INVOICE })
        removeAllMandatoryClasses(document.getElementById("customers-estimation-container"))

        workflowDialogRef?.current?.close();
        actionsRef?.current?.setWorkflowAction(null);
    }

    const handleOpenSummary = () => {
        document.getElementById('customers-estimation-invoices-lines').classList.toggle('active');
        document.getElementById('customers-estimation-invoices-summary').classList.toggle('active');
        document.getElementById('bg-sm').classList.toggle('active');
    }

    const isLinkWithZatca = async () => {
        const resp = await getLinkWithZatcaPref();
        return resp.data == '100001';
    }
    const _onExecuteWorkflowAction = async (responseData) => {
        _whenSaveData(responseData.transactionData, false, responseData.workflowId, responseData.stepId, responseData.workflowStatus, responseData.task);
    }

    const _checkValidation = () => !checkValidation([{ id: 'customers-estimation-container' }], t);
    const _handleError = (error) => handleError(error, error?.response?.data?.message.split('#')[0], t);

    const handleData = (transactionData) => {
        let invoiceData = { header: transactionData, lines: transactionData.lines };

        invoiceData.lines.map((line) => {
            if (line.source == ESTIMATION_FEES) {
                line.applicationNum = line.transaction.code;
            } else if (line.source == ITEM) {
                line.descLo = line.item?.code + ', ' + line.item?.descLo;
                line.descFo = line.item?.code + ', ' + line.item?.descFo;
            }
        });

        return invoiceData;
    }

    const _whenSaveData = (transactionData, withSuccessMessage, workflowId, stepId, workflowStatus, task) => {
        fromSaveActionRef.current = true;
        let invoiceData = handleData(transactionData)
        addUrlParam("id", transactionData.id);

        if (withSuccessMessage) {
            showSuccessMessage('save_successfully', t);
        }

        actionsRef?.current?.setWorkflowAction(task);
        dispatchInvoice({
            value: invoiceData,
            workflowId,
            stepId,
            workflowStatus,
            type: ACTION_SET_INVOICE
        });
    }

    const _saveAction = (withSubmit) => {
        if (withSubmit && _checkValidation()) {
            return
        }

        let data = {
            ...invoiceState.invoice.header, lines: invoiceState.invoice.lines,
            workflowId: invoiceState.workflowId,
            stepId: invoiceState.stepId,
            workflowStatus: invoiceState.workflowStatus,
        }

        showActionWaiting(withSubmit ? 'save-customer-estimation-invoice' : 'draft-customer-estimation-invoice');

        if (isNotEmpty(invoiceState.workflowId) && isNotEmpty(invoiceState.stepId)) {
            saveWorkflowTransaction(data.workflowId, RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID, data).then((response) => {
                const transactionData = response.data.transactionData;
                const workflowId = response.data.workflowId;
                const stepId = response.data.stepId;
                const workflowStatus = response.data.workflowStatus;
                const task = response.data.task;

                _whenSaveData(transactionData, true, workflowId, stepId, workflowStatus, task);
            })
                .catch(_handleError)
                .finally(() => hideActionWaiting(withSubmit ? 'save-customer-estimation-invoice' : 'draft-customer-estimation-invoice'));
        }
        else {
            saveEstimationInvoice(data, withSubmit)
                .then(response => _whenSaveData(response.data, true))
                .catch(_handleError)
                .finally(() => hideActionWaiting(withSubmit ? 'save-customer-estimation-invoice' : 'draft-customer-estimation-invoice'))
        }
    }

    return (
        <>
            <div className={style.container}>
                <div id="customers-estimation-invoices-lines" className="lines-container-open-close">
                    <div>
                        <div id='customers-estimation-container' className={`toggle-mode ${style.toggleMode}`}>
                            <Header
                                user={user}
                                invoice={invoiceState.invoice}
                                workflowStatus={invoiceState.workflowStatus}
                                invoiceState={invoiceState}
                                dispatchInvoice={dispatchInvoice}
                            />
                            <Details
                                user={user}
                                invoice={invoiceState.invoice}
                                dispatchInvoice={dispatchInvoice}
                            />
                        </div>
                    </div>
                </div>
                <div id="customers-estimation-invoices-summary" className="summary-container-panel">
                    <button className="open-close-summary-btn" onClick={handleOpenSummary}>
                        <OpenCloseMenu />
                    </button>
                    <Summary
                        dispatchInvoice={dispatchInvoice}
                        invoice={invoiceState.invoice}
                        totals={invoiceState.totals}
                        handleOpenSummary={handleOpenSummary}
                    />
                </div>
                <div id="bg-sm" onClick={handleOpenSummary} className="summary-bg-sm"></div>
            </div>

            <Actions
                ref={actionsRef}
                workflowsActionLogDialogRef={workflowsActionLogDialogRef}
                workflowDialogRef={workflowDialogRef}
                adjustWorkDialogRef={adjustWorkDialogRef}
                user={user}
                invoiceState={invoiceState}
                dispatchInvoice={dispatchInvoice}
                printDialogRef={printDialogRef}
                newAction={_newInvoice}
                saveAction={_saveAction}
                fromSaveActionRef={fromSaveActionRef}
                zatcaSummaryRef={zatcaSummaryRef}
            />
            <ZatcaSummaryDialog
                ref={zatcaSummaryRef}
            />
            <PrintDialog
                id="customer-estimation-invoice-print-dialog"
                ref={printDialogRef}
                formId={ERP_FORM_ID_CUSTOMERS_ESTIMATION_INVOICES}
                user={user}
            />

            <WorkflowsActionLogDialog
                ref={workflowsActionLogDialogRef}
                formId={RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID}
            />
            <AdjustWorkDialog
                ref={adjustWorkDialogRef}
                formId={RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID}
                getTransactionData={() => ({ ...invoiceState.invoice.header, lines: invoiceState.invoice.lines })}
                onExecuteAction={_onExecuteWorkflowAction}
                globalStatus={invoiceState.globalStatus || invoiceState?.invoice?.header?.globalStatus}
                checkValidationAction={_checkValidation}
                onError={_handleError}
            />
            <WorkflowDialog
                ref={workflowDialogRef}
                formId={RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID}
                newAction={(workflowId, stepId) => _newInvoice(workflowId, stepId)}
            />
        </>
    )
}

export default CustomersEstimationInvoice;