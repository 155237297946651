import Tabs from "@ipgd-gauge/tabs";
import { addUrlParam, checkValidation, deleteUrlParam, getUrlParam, handleError, hideActionWaiting, isEmpty, isNotEmpty, parseNumber, removeAllMandatoryClasses, showActionWaiting, showSuccessMessage } from "@ipgd-gauge/utils";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PrintDialog from "../../../components/print-dialog";
import WorkflowDialog from "../../../components/slisting/workflow-dialog";
import { OpenCloseMenu } from "../../../icons/OpenCloseMenu";
import { getDefaultFormValues, getValuationContracts, saveValuationContracts, saveWorkflowTransaction } from "../../../util/apis";
import { CUSTOMER_LEVEL_NEW, CUSTOMER_LEVEL_PREDEFINED, ESTIMATION_QUOTATION_CITY_FIELD, ESTIMATION_QUOTATION_DEED_DGR_FIELD, ESTIMATION_QUOTATION_DEED_FIELD, ESTIMATION_QUOTATION_PROPERTY_TYPE_FIELD, ESTIMATION_QUOTATION_REGION_FIELD, ESTIMATION_QUOTATION_TOWN_FIELD, GLOBAL_STATUS_INCOMPLETE } from "../../../util/constants";
import { RSM3272_EVALUATION_CONTRACTS_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import { useWorkflow } from "../../../util/hooks/useWorkflow";
import AdjustWorkDialog from "../../common/dialogs/adjust-work";
import WorkflowsActionLogDialog from "../../common/dialogs/workflows-action-log";
import Actions from "../Actions";
import style from "./style.module.scss";
import Summary from "./summary";
import Tab1 from "./tab1";
import Tab2 from "./tab2";

const DEFAULT_VALUE = {
    globalStatus: GLOBAL_STATUS_INCOMPLETE,
    customerLevel: CUSTOMER_LEVEL_PREDEFINED
};

const ValuationContract = () => {
    const { t } = useTranslation();

    const id = getUrlParam('id');
    const currentUser = getUser();
    const printDialogRef = useRef();
    const attachmentsImgRef = useRef();
    const fromSaveActionRef = useRef(false);
    const fromNewActionRef = useRef(false);
    const actionsRef = useRef();
    const workflowsActionLogDialogRef = useRef();
    const adjustWorkDialogRef = useRef();
    const workflowDialogRef = useRef();

    const { workflowId, stepId } = useWorkflow(RSM3272_EVALUATION_CONTRACTS_FORM_ID);
    const [valuationContract, setValuationContract] = useState({ ...DEFAULT_VALUE });

    const _handleRetrieveDataFromResponse = (responseData) => {
        const transactionData = { ...responseData.transactionData };
        transactionData.workflowId = responseData.workflowId;
        transactionData.stepId = responseData.stepId;
        transactionData.workflowStatus = responseData.workflowStatus;
        return [transactionData, responseData.task];
    }

    useEffect(() => {
        if (fromSaveActionRef.current) {
            fromSaveActionRef.current = false;
            return;
        }

        if (fromNewActionRef.current) {
            fromNewActionRef.current = false;
            return;
        }

        if (isNotEmpty(id)) {
            getValuationContracts(id)
                .then((response) => {
                    const [transactionData, task] = _handleRetrieveDataFromResponse(response.data);
                    setValuationContract(transactionData);
                    actionsRef?.current?.setWorkflowAction(task);
                })
                .catch((error) => handleError(error, null, t));
        } else {
            _newAction(workflowId, stepId);
        }
    }, [id]);

    const _newAction = async (workflowId, stepId) => {
        const defaultData = (await getDefaultFormValues(RSM3272_EVALUATION_CONTRACTS_FORM_ID)).data;

        if (defaultData.hasWorkflow && (isEmpty(workflowId) || isEmpty(stepId))) {
            workflowDialogRef?.current?.open();
            return;
        }

        deleteUrlParam('id');
        fromNewActionRef.current = true;

        let tempDefaultValue = Object.assign({}, DEFAULT_VALUE);
        tempDefaultValue.dateDgr = defaultData?.currentTime;
        tempDefaultValue.fcuId = defaultData?.fcuId;
        tempDefaultValue.exchngRate = 1;
        tempDefaultValue.workflowId = workflowId;
        tempDefaultValue.stepId = stepId;
        setValuationContract(tempDefaultValue);

        removeAllMandatoryClasses(document.getElementById('valuation-contract-main'))
        workflowDialogRef?.current?.close();
        actionsRef?.current?.setWorkflowAction(null);
    }

    const _checkValidation = () => !checkValidation([{ id: 'valuation-contract-main' },
    {
        id: `valation-contract-details-table-${valuationContract?.umyId}-${valuationContract?.quotationType?.fields}`,
        title: t('estimationQuotation:details'),
        data: valuationContract.lines,
        disabledConditions: {
            deedNum: () => !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_DEED_FIELD),
            titleDeedDgr: () => !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_DEED_DGR_FIELD),
            frsId: () => !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_REGION_FIELD),
            ecdId: (lineData) => !lineData.frsId || !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_CITY_FIELD),
            criId: (lineData) => !lineData.ecdId || !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_TOWN_FIELD),
            myoId: () => !valuationContract?.quotationType?.fields?.includes(ESTIMATION_QUOTATION_PROPERTY_TYPE_FIELD),
        }
    }
    ], t);

    const _handleError = (error) => handleError(null, error?.response?.data?.message.split('#')[0], t);

    const _whenSaveData = async (transactionData, task) => {
        fromSaveActionRef.current = true;
        await attachmentsImgRef?.current?.uploadImages(transactionData.id);

        for (let index = 0; index < valuationContract?.lines?.length; index++) {
            const element = valuationContract?.lines[index];
            transactionData.lines[index] = { ...element, ...transactionData?.lines[index] }
        }

        setValuationContract({ ...valuationContract, ...transactionData });

        addUrlParam('id', transactionData.id);

        showSuccessMessage('save_successfully', t);

        actionsRef?.current?.setWorkflowAction(task);
    }

    const _saveAction = (withSubmit) => {
        if (withSubmit && _checkValidation()) {
            return
        }

        showActionWaiting(withSubmit ? 'submit' : 'incomplete');

        if (isNotEmpty(valuationContract.workflowId) && isNotEmpty(valuationContract.stepId)) {
            saveWorkflowTransaction(valuationContract.workflowId, RSM3272_EVALUATION_CONTRACTS_FORM_ID, valuationContract)
                .then(async (response) => {
                    const [transactionData, task] = _handleRetrieveDataFromResponse(response.data);
                    await _whenSaveData(transactionData, task);
                })
                .catch(_handleError)
                .finally(() => hideActionWaiting(withSubmit ? 'submit' : 'incomplete'));
        } else {
            saveValuationContracts(valuationContract, withSubmit)
                .then(async (response) => await _whenSaveData(response.data, null))
                .catch(_handleError)
                .finally(() => hideActionWaiting(withSubmit ? 'submit' : 'incomplete'));
        }
    }

    const _onExecuteWorkflowAction = async (responseData) => {
        const [transactionData, task] = _handleRetrieveDataFromResponse(responseData);
        await _whenSaveData(transactionData, task);
    }

    const handleDataChanged = (name, value) => {
        valuationContract[name] = value;
        if (name == "customerLevel") {
            if (value == CUSTOMER_LEVEL_NEW) {
                valuationContract.atrId = null;
            } else if (value == CUSTOMER_LEVEL_PREDEFINED) {
                valuationContract.customerName = null;
                valuationContract.mobileNum = null;
                valuationContract.cardNum = null;
                valuationContract.phoneNumber = null;
                valuationContract.email = null;
                valuationContract.customerCommRegNum = null;
                valuationContract.taxNumber = null;
                valuationContract.customerContact = null;
                valuationContract.customerDeelgationNum = null;
                valuationContract.city = null;
                valuationContract.town = null;
                valuationContract.buildingNumber = null;
                valuationContract.postalCode = null;
                valuationContract.customerAddress = null;
            }
        } else if (name == "lines") {
            let netPrice = 0

            for (let i = 0; i < valuationContract.lines.length; i++) {
                netPrice += valuationContract.lines[i].netPrice
            }

            valuationContract.netPrice = netPrice
        } else if (name === "totalPrice" || name === "vat") {
            valuationContract.netPrice = parseNumber(valuationContract?.totalPrice) + parseNumber(valuationContract?.vat);
        } else if (name == "ecdId") {
            valuationContract.criId = null;

        } else if (name == "atrId") {
            valuationContract.atrId = value.value;
            valuationContract.customerData = value;
        } else if (name === "umyId") {
            valuationContract.umyId = value.value;
            valuationContract.quotationType = value;
            valuationContract.lines = [];
        }

        setValuationContract({ ...valuationContract });
    }

    const handleOpenSummary = () => {
        document.getElementById('valuation-contracts-lines').classList.toggle('active');
        document.getElementById('valuation-contracts-summary').classList.toggle('active');
        document.getElementById('bg-sm').classList.toggle('active');
    }

    return (
        <>
            <div className={style.container}>
                <div id="valuation-contracts-lines" className="lines-container-open-close">
                    <Tabs
                        id="valuation-contract-main"
                        labels={['home', 'valuationContracts:attachments']}
                        containerStyle={style.tabsContainer}
                        tabContentStyle={style.tabContentStyle}
                    >
                        <Tab1
                            valuationContract={valuationContract}
                            setValuationContract={setValuationContract}
                            currentUser={currentUser}
                            handleDataChanged={handleDataChanged}
                        />
                        <Tab2
                            data={valuationContract}
                            attachmentsImgRef={attachmentsImgRef}
                            handleDataChanged={handleDataChanged}
                        />
                    </Tabs>
                </div>

                <div id="valuation-contracts-summary" className="summary-container-panel">
                    <button className="open-close-summary-btn" onClick={handleOpenSummary}>
                        <OpenCloseMenu />
                    </button>
                    <Summary
                        valuationContract={valuationContract}
                        handleOpenSummary={handleOpenSummary}
                    />
                </div>
                <div id="bg-sm" onClick={handleOpenSummary} className="summary-bg-sm"></div>
            </div>

            <Actions
                ref={actionsRef}
                user={currentUser}
                isListing={false}
                data={valuationContract}
                setData={setValuationContract}
                newAction={_newAction}
                printDialogRef={printDialogRef}
                attachmentsImgRef={attachmentsImgRef}
                fromSaveActionRef={fromSaveActionRef}
                saveAction={_saveAction}
                adjustWorkDialogRef={adjustWorkDialogRef}
                workflowsActionLogDialogRef={workflowsActionLogDialogRef}
            />

            <PrintDialog
                id="valuation-contract-print-dialog"
                ref={printDialogRef}
                formId={RSM3272_EVALUATION_CONTRACTS_FORM_ID}
                user={currentUser}
            />

            <WorkflowsActionLogDialog
                ref={workflowsActionLogDialogRef}
                formId={RSM3272_EVALUATION_CONTRACTS_FORM_ID}
            />

            <AdjustWorkDialog
                ref={adjustWorkDialogRef}
                formId={RSM3272_EVALUATION_CONTRACTS_FORM_ID}
                getTransactionData={() => valuationContract}
                onExecuteAction={_onExecuteWorkflowAction}
                globalStatus={valuationContract.globalStatus}
                checkValidationAction={_checkValidation}
                onError={_handleError}
            />

            <WorkflowDialog
                ref={workflowDialogRef}
                formId={RSM3272_EVALUATION_CONTRACTS_FORM_ID}
                newAction={(workflowId, stepId) => _newAction(workflowId, stepId)}
            />
        </>
    )
}

export default ValuationContract;