import React, { forwardRef, useImperativeHandle, useRef } from "react";
import Waiting from "../../../../components/waiting";
import { getWorkflowSteps, getWorkflowTasksLog, workflowStepLogs } from "../../../../util/apis";
import IPGDWorkflowActionLogDialog from "@ipgd-gauge/workflow/WorkflowActionLog";

const WorkflowsActionLogDialog = (props, ref) => {
    const { formId, newAction } = props;
    const activityLogRef = useRef();
    useImperativeHandle(ref, () => ({ ...activityLogRef.current }));

    return (
        <IPGDWorkflowActionLogDialog
            ref={activityLogRef}
            formId={formId}
            newAction={newAction}
            workflowStepLogs={workflowStepLogs}
            getWorkflowSteps={getWorkflowSteps}
            getWorkflowTasksLog={getWorkflowTasksLog}
            Waiting={() => <Waiting />}
        />
    );
}

export default forwardRef(WorkflowsActionLogDialog);