import { formatDate, formatMoney, handleError, isEnglish, isNotEmpty } from "@ipgd-gauge/utils";
import { t } from "i18next";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Slisting from "../../../components/slisting";
import { createNLinkCustomer, deleteEstimationsQuotation, exportQuotations, getEstimationsQuotationList } from "../../../util/apis";
import { CUSTOMER_LEVEL_NEW, CUSTOMER_LEVEL_PREDEFINED, GLOBAL_STATUS_INCOMPLETE } from "../../../util/constants";
import { RSM3190_EVALUATION_QUOTATIONS_FORM_ID } from "../../../util/constants/forms";
import { getUser } from "../../../util/cookies";
import PrintDialog from "../.././../components/print-dialog";
import CustomerDialog from "../../common/dialogs/customer-dialog";
import WorkflowsActionLogDialog from "../../common/dialogs/workflows-action-log";
import Actions from "../actions";
import CreateTransactionDialog from "../dialogs/create-transaction";
import GenerateTransactionDialog from "../dialogs/generate-transaction";
import style from './style.module.scss';

const EstimationQuotationTable = (props) => {
    const { tableRef, filterRef, searchTextRef } = props;

    const user = getUser();

    const { t } = useTranslation("estimationQuotation");
    const navigate = useNavigate();

    const printDialogRef = useRef();
    const generateTransactionRef = useRef();
    const createTransactionRef = useRef();
    const customerDialogRef = useRef();
    const transactionIdForCreateNLinkRef = useRef();
    const workflowsActionLogDialogRef = useRef();

    const [quotations, setQuotation] = useState();

    const columns = [
        { title: '#', width: '3%' },
        { title: t('code'), width: '8%', excelColumn: "MMA_CODE" },
        { title: t('date_g'), width: '8%', excelColumn: "MMA_DATE_DGR" },
        { title: t('customer'), width: '18%', excelColumn: "MMA_CUSTOMER_NAME" },
        { title: t('prepared_by'), width: '14%', excelColumn: "PREPARED_BY_DESC_LO,PREPARED_BY_DESC_FO" },
        { title: t('total_price'), width: '10%', excelColumn: "MMA_NET_PRICE" },
        { title: t('have_agrement'), width: '5%', excelColumn: "HAVE_AGREMENT" },
        { title: t('have_transaction'), width: '5%', excelColumn: "HAVE_TRANSACTION" },
        { title: t('follow_up_status'), width: '8%', excelColumn: "FOLLOWUP_STATUS_DESC_LO,FOLLOWUP_STATUS_DESC_FO" },
        { title: t('status'), width: '10%', excelColumn: "STATUS_DESC_LO,STATUS_DESC_FO" },
        { title: "", width: '11%' }
    ];

    const _fetchData = (page, size) => {
        getEstimationsQuotationList(page, size, searchTextRef.current.getSearchText(), filterRef?.current?.getFilterData())
            .then((response) => setQuotation(response.data))
            .catch((error) => handleError(error, null, t));
    }

    const _export = () => {
        const filterData = {
            ...filterRef?.current?.getFilterData(),
            searchKey: searchTextRef?.current?.getSearchText(),
            columnSetting: tableRef.current.getSelectedSettingId()
        }
        exportQuotations(filterData);
    }

    return (
        <>
            <Slisting
                id="estimations-quotation-listing"
                formId={RSM3190_EVALUATION_QUOTATIONS_FORM_ID}
                ref={tableRef}
                columns={columns}
                Row={Row}
                tableStyle={style.estimationQuotationTable}
                withFilter
                pagingData={quotations}
                getData={_fetchData}
                rowActions={{ user, tableRef, generateTransactionRef, createTransactionRef, printDialogRef, customerDialogRef, transactionIdForCreateNLinkRef, workflowsActionLogDialogRef }}
                showWating={(data) => data == null}
                showNoDataFound={(data) => (data && data.length == 0)}
                deleteAction={deleteEstimationsQuotation}
                viewAction={(id) => navigate(`/estimation-quotation?id=${id}`)}
                newAction={() => navigate("/estimation-quotation")}
                hideDeleteCondition={(data) => data.globalStatus != GLOBAL_STATUS_INCOMPLETE}
                withPrintAction
                withWorkflow
                cardInMobile
                labelCardWidth="80px"
                exportExcelAction={_export}
            />

            <PrintDialog
                ref={printDialogRef}
                id="print-estimations-quotation"
                formId={RSM3190_EVALUATION_QUOTATIONS_FORM_ID}
                user={user}
            />

            <GenerateTransactionDialog ref={generateTransactionRef} isListing={true} tableRef={tableRef} />

            <CreateTransactionDialog ref={createTransactionRef} tableRef={tableRef} />

            <CustomerDialog
                ref={customerDialogRef}
                saveApi={createNLinkCustomer}
                getApiParam={() => transactionIdForCreateNLinkRef.current}
                onSave={() => {
                    customerDialogRef.current.close();
                    tableRef.current.refresh();
                }}
                preClose={() => transactionIdForCreateNLinkRef.current = null}
            />

            <WorkflowsActionLogDialog
                ref={workflowsActionLogDialogRef}
                formId={RSM3190_EVALUATION_QUOTATIONS_FORM_ID}
            />
        </>
    );
};

const Row = (props) => {
    const { data, rowActions } = props;
    const { user, tableRef, generateTransactionRef, createTransactionRef, printDialogRef, customerDialogRef, transactionIdForCreateNLinkRef, workflowsActionLogDialogRef } = rowActions;

    return (
        <div className={`${style.estimationQuotationRow}`}>
            <div title={data?.sequence} className="cardRowMode">
                <label className="nameLblMobile">#</label>
                <label>{data?.sequence}</label>
            </div>
            <div title={data?.code} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationQuotation:code')}</label>
                <label>{data?.code}</label>
            </div>
            <div title={formatDate(data?.dateDgr)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationQuotation:date_g')}</label>
                <label>{formatDate(data?.dateDgr)}</label>
            </div>
            {data?.customerLevel == CUSTOMER_LEVEL_NEW &&
                <div title={data?.customerName} className="cardRowMode">
                    <label className="nameLblMobile">{t('estimationQuotation:customer')}</label>
                    <label>{data?.customerName}</label>
                </div>
            }

            {data?.customerLevel == CUSTOMER_LEVEL_PREDEFINED &&
                <div title={isEnglish() ? data?.officialNameLo : data?.officialNameFo} className="cardRowMode">
                    <label className="nameLblMobile">{t('estimationQuotation:customer')}</label>
                    <label>
                        {isEnglish() ? data?.officialNameLo : data?.officialNameFo}
                    </label>
                </div>
            }

            <div title={(isEnglish() ? data?.preparedByDescLo : data?.preparedByDescFo) || '-'} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationQuotation:customer')}</label>
                <label>
                    {(isEnglish() ? data?.preparedByDescLo : data?.preparedByDescFo) || '-'}
                </label>
            </div>

            <div title={formatMoney(data?.netPrice)} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationQuotation:total_price')}</label>
                <label>
                    {formatMoney(data?.netPrice)}
                </label>
            </div>


            <div className="cardRowMode">
                <label className="nameLblMobile">{t('estimationQuotation:have_agrement')}</label>
                <label>
                    {data?.haveAgrement > 0 ? t('yes') : t('no')}
                </label>
            </div>

            <div title={data?.haveTransaction > 0 ? t('yes') : t('no')} className="cardRowMode">
                <label className="nameLblMobile">{t('estimationQuotation:have_transaction')}</label>
                <label>
                    {data?.haveTransaction > 0 ? t('yes') : t('no')}
                </label>
            </div>

            <div title={isEnglish() ? data?.followupStatusDescLo : data?.followupStatusDescFo} className="cardRowStatusMode">
                <label className="nameLblMobile">{t('estimationQuotation:follow_up_status')}</label>
                <label className={`status-${data?.status}`}>{isEnglish() ? data?.followupStatusDescLo : data?.followupStatusDescFo}</label>
            </div>

            <div className="cardRowStatusMode">
                <label className="nameLblMobile">{t('estimationQuotation:status')}</label>
                <label
                    title={isEnglish() ? data.statusDescLo : data.statusDescFo}
                    className={`${isNotEmpty(data?.workflowStatusId) ? 'workflow-status' : `status-${data.globalStatus}`}`}
                    style={isNotEmpty(data?.workflowStatusId) ? { backgroundColor: `${data?.statusBgColor}`, color: `${data?.statusColor}` } : {}}>
                    {isEnglish() ? data.statusDescLo : data.statusDescFo}
                </label>
            </div>

            <div className='containerButtonTable'>
                <Actions
                    user={user}
                    isListing={true}
                    data={data}
                    tableRef={tableRef}
                    generateTransactionRef={generateTransactionRef}
                    createTransactionRef={createTransactionRef}
                    printDialogRef={printDialogRef}
                    customerDialogRef={customerDialogRef}
                    transactionIdForCreateNLinkRef={transactionIdForCreateNLinkRef}
                    workflowsActionLogDialogRef={workflowsActionLogDialogRef}
                />
            </div>
        </div >
    );
};

export default EstimationQuotationTable;