import ActionsButton from "@ipgd-gauge/actions-button";
import TableActionsButton from "@ipgd-gauge/table-actions-buttons";
import { confirmationDialog, handleError, hideActionWaiting, isAllowedAction, isEmpty, isEnglish, isNotEmpty, showActionWaiting, showSuccessMessage } from "@ipgd-gauge/utils";
import i18next from "i18next";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AddBtnIcon from "../../icons/AddBtn";
import ApproveIcon from "../../icons/Approve";
import CreateAgreementIcon from "../../icons/CreateAgreement";
import DeleteTowIcon from "../../icons/Delete2";
import EditTableIcon from "../../icons/EditTable";
import { PdfTrxIcon } from "../../icons/EstimationTrx";
import NewWordIcon from "../../icons/NewWord";
import RejectIcon from "../../icons/Reject";
import SaveActionIcon from "../../icons/SaveAction";
import UnpostIcon from "../../icons/Unpost";
import ViewIcon from "../../icons/View";
import { approveEstimationsQuotation, createAgreement, createContract, deleteEstimationsQuotation, rejectEstimationsQuotation, unPostEstimationsQuotation } from "../../util/apis";
import { GLOBAL_STATUS_APPROVED, GLOBAL_STATUS_CONFIRMED, GLOBAL_STATUS_INCOMPLETE, GLOBAL_STATUS_PREPARED, GLOBAL_STATUS_REJECTED, GLOBAL_STATUS_SUBMITTED, STATUS_APPROVED_FROM_CUSTOMER, STATUS_REJECTED_FROM_CUSTOMER, STEP_TYPE_TRIGGERING } from "../../util/constants";
import { CONFIRM_FORM_ACTION, DELETE_ACTION, DIVERT_TO_CUSTOMER, GENERATE_AGREEMENT, GENERATE_CONTRACT, GENERATE_VALUATION_TRXN, INCOMPLETE_ACTION, NEW_ACTION, PRINT_ACTION, REJECT_ACTION, SAVE_ACTION, UN_POST_ACTION, VIEW_EDIT_ACTION } from "../../util/constants/actions";
import { RSM3190_EVALUATION_QUOTATIONS_FORM_ID } from "../../util/constants/forms";
import { SAVE_ACTION_SHORT_CUT } from "../../util/constants/hotkeys";
import WorkflowIcon from "../../icons/Workflow";
import { ActionIcon } from "../../icons/WorkflowIcons";

const Actions = (props, ref) => {
    const { user, isListing, data, tableRef, newAction, saveAction, generateTransactionRef, createTransactionRef, printDialogRef, customerDialogRef, transactionIdForCreateNLinkRef, adjustWorkDialogRef, workflowsActionLogDialogRef } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);
    const [actions, setActions] = useState([]);
    const [workflowAction, setWorkflowAction] = useState(null);
    const _saveAction = (withSubmit) => saveAction(withSubmit, true);

    const handleActionCompleted = () => {
        if (isListing) {
            tableRef?.current?.refresh();
        } else {
            navigate(`/estimation-quotations`);
        }
    }

    const _deleteAction = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            if (!isListing) {
                showActionWaiting('delete');
            }
            return deleteEstimationsQuotation(data.id)
                .then(() => {
                    if (!isListing) {
                        hideActionWaiting('delete')
                    }
                    handleActionCompleted();
                    showSuccessMessage("delete_successfully", t);
                })
                .catch((error) => handleError(error, null, t))
        });
    }

    const _rejectAction = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return rejectEstimationsQuotation(data.id)
                .then(() => {
                    handleActionCompleted();
                    showSuccessMessage('action_performed_successfully', t);
                })
                .catch((error) => handleError(error, null, t));
        });
    }

    const _approveEstimationsQuotation = (status) => {
        approveEstimationsQuotation(data.id, status)
            .then(() => {
                handleActionCompleted();
                showSuccessMessage('action_performed_successfully', t);
            })
            .catch((error) => handleError(error, null, t));
    }

    const _unPostEstimationsQuotation = () => {
        unPostEstimationsQuotation(data.id)
            .then(() => {
                handleActionCompleted();
                showSuccessMessage('action_performed_successfully', t);
            })
            .catch((error) => handleError(error, error?.response?.data?.message, t));
    }

    const _confirmAction = () => {
        MySwal.fire({
            title: t('estimationQuotation:customer_decision'),
            // cancelButtonText: t('cancel'),
            showCloseButton: true,
            icon: 'info',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: t('estimationQuotation:rejected_from_customer'),
            denyButtonText: t('estimationQuotation:approved_from_customer'),
            customClass: {
                container: 'customerDecision',
                title: isEnglish() ? 'swal2-title-en' : 'swal2-title-ar',
                actions: isEnglish() ? 'swal2-actions-panel-en' : 'swal2-actions-panel-ar',
                confirmButton: isEnglish() ? 'swal2-colse-en' : 'swal2-colse-ar',
                denyButton: isEnglish() ? 'swal2-deny-en' : 'swal2-deny-ar',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                _approveEstimationsQuotation(STATUS_REJECTED_FROM_CUSTOMER);
            } else if (result.isDenied) {
                _approveEstimationsQuotation(STATUS_APPROVED_FROM_CUSTOMER);
            }
        });
    }

    const _createAgreement = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return createAgreement(data?.id)
                .then(() => {
                    showSuccessMessage('action_performed_successfully', t);
                    tableRef?.current?.refresh();
                })
                .catch(error => handleError(null, error?.response?.data?.message.split('#')[0], t));
        });
    }

    const _createContract = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return createContract(data?.id)
                .then(() => {
                    showSuccessMessage('action_performed_successfully', t);
                    tableRef?.current?.refresh();
                })
                .catch(error => handleError(null, error?.response?.data?.message.split('#')[0], t));
        });
    }

    const _createCustomer = () => {
        transactionIdForCreateNLinkRef.current = data.id
        const customer = {
            officialNameLo: data.customerName,
            officialNameFo: data.customerName,
            idCardNum: data.cardNum,
            mobileNum: data.mobileNum,
            email: data.email,
            comerceRegNum: data.customerCommRegNum,
            taxNumber: data.taxNumber,
            address: data.customerAddress
        };
        customerDialogRef?.current?.open(null, customer);
    }

    const isSaved = () => isNotEmpty(data?.id);
    const isIncomplete = () => data.globalStatus == GLOBAL_STATUS_INCOMPLETE;
    const isPrepared = () => data.globalStatus == GLOBAL_STATUS_PREPARED;
    const isSubmitted = () => data.globalStatus == GLOBAL_STATUS_SUBMITTED;
    const isApproved = () => data.globalStatus == GLOBAL_STATUS_APPROVED;
    const isRejected = () => data.globalStatus == GLOBAL_STATUS_REJECTED;
    const isConfirmed = () => data.globalStatus == GLOBAL_STATUS_CONFIRMED;
    const isRejectedFromCustomer = () => data.status == STATUS_REJECTED_FROM_CUSTOMER;
    const _isAllowedAction = (action) => isAllowedAction(user, RSM3190_EVALUATION_QUOTATIONS_FORM_ID, action);
    const _isAllowedSaveAction = () => !isListing && (isIncomplete() || isPrepared() || updateAllowed() || isSubmitted()) && _isAllowedAction(SAVE_ACTION);
    const isWorkflow = () => isNotEmpty(data?.workflowId) && (isListing ? isNotEmpty(data?.stepId) : true);
    const updateAllowed = () => workflowAction?.updateAllowed == 1;

    const _executeWorkflowAction = () => {
        if (isSaved() && isNotEmpty(workflowAction.actionId) && isNotEmpty(workflowAction.actionType)) {
            adjustWorkDialogRef.current.open(data?.workflowId, data?.stepId, data?.id);
        }
    }

    useImperativeHandle(ref, () => ({ setWorkflowAction }));

    useEffect(() => {
        let actions = [];

        if (isIncomplete() && isSaved() && _isAllowedAction(DELETE_ACTION)) {
            actions.push({
                id: 'delete',
                label: t('estimationQuotation:delete'),
                action: _deleteAction,
                color: "var(--action-delete-bg)",
                iconColor: "var(--action-delete-bg)",
                icon: <DeleteTowIcon />,
            });
        }

        if (isSaved() && _isAllowedAction(PRINT_ACTION)) {
            actions.push({
                id: 'print-word',
                label: t('print_word'),
                action: () => printDialogRef?.current?.open([data?.id], null, true),
                color: "var(--pageination)",
                icon: <NewWordIcon />
            });

            actions.push({
                id: 'print-pdf',
                label: t('print_pdf'),
                action: () => printDialogRef?.current?.open([data?.id]),
                color: "var(--pageination)",
                icon: <PdfTrxIcon />
            });
        }

        if (!isWorkflow() && isSubmitted() && isSaved() && _isAllowedAction(REJECT_ACTION)) {
            actions.push({
                id: 'reject',
                label: t('estimationQuotation:reject'),
                action: () => _rejectAction(),
                color: "var(--action-delete-bg)",
                icon: <RejectIcon />,
            });
        }

        if (!isListing && _isAllowedAction(NEW_ACTION)) {
            actions.push({
                id: 'new',
                label: t('estimationQuotation:new'),
                action: () => newAction(),
                color: "var(--action-copy)",
                icon: <AddBtnIcon />,
            });
        }

        if (!isWorkflow() && isSubmitted() && isSaved() && _isAllowedAction(CONFIRM_FORM_ACTION)) {
            actions.push({
                id: 'approve',
                label: t('estimatorsInspectorsInvoices:confirm'),
                action: () => _confirmAction(),
                color: "var(--action-save)",
                icon: <ApproveIcon />,
            });
        }

        if (!isListing && isIncomplete() && _isAllowedAction(INCOMPLETE_ACTION)) {
            actions.push({
                id: 'incomplete',
                label: t('estimationQuotation:incomplete'),
                action: () => _saveAction(false),
                color: "var(--action-incomplete)",
                icon: <SaveActionIcon />,
            })
        }
        if (isListing && isConfirmed() && (!isRejectedFromCustomer()) && _isAllowedAction(GENERATE_CONTRACT) && data.haveContract == "0" && isNotEmpty(data?.atrId)) {
            actions.push({
                id: 'create_contract',
                label: t('estimationQuotation:create_contract'),
                action: () => _createContract(),
                icon: <CreateAgreementIcon />
            })
        }

        if (isListing && isConfirmed() && (!isRejectedFromCustomer()) && _isAllowedAction(GENERATE_AGREEMENT)) {
            if (!data.haveAgrement && isNotEmpty(data?.atrId)) {
                actions.push({
                    id: 'create_agreement',
                    label: t('estimationQuotation:create_agreement'),
                    action: () => _createAgreement(),
                    icon: <CreateAgreementIcon />
                })
            }

            if (data.haveTransaction == "0" && data?.lines != "0" && isNotEmpty(data?.atrId) && (!isRejectedFromCustomer()) && _isAllowedAction(GENERATE_VALUATION_TRXN)) {
                actions.push({
                    id: 'create_transaction',
                    label: t('estimationQuotation:create_transaction'),
                    action: () => generateTransactionRef?.current?.open(data?.id),
                    icon: <CreateAgreementIcon />
                })
            }

            if (isEmpty(data?.atrId) && _isAllowedAction(DIVERT_TO_CUSTOMER)) {
                actions.push({
                    id: 'link-customer',
                    label: t('estimationQuotation:link_with_customer'),
                    action: () => createTransactionRef?.current?.open(data?.id),
                    icon: <CreateAgreementIcon />
                });

                actions.push({
                    id: 'create-link-customer',
                    label: t('estimationQuotation:create_customer'),
                    action: _createCustomer,
                    icon: <CreateAgreementIcon />
                });
            }
        }

        if (isListing && isConfirmed() && data.haveTransaction == "0" && data.haveAgrement == "0" && data.haveContract == '0' && _isAllowedAction(UN_POST_ACTION)) {
            actions.push({
                id: 'un_post',
                label: t('estimationQuotation:un_post'),
                action: () => _unPostEstimationsQuotation(),
                icon: <UnpostIcon />
            });
        }

        if (isListing && (isRejected() || isConfirmed()) && _isAllowedAction(VIEW_EDIT_ACTION)) {
            actions.push({
                id: 'view',
                label: t('estimationQuotation:view'),
                action: () => navigate(`/estimation-quotation?id=${data.id}`),
                color: "var(--action-save)",
                iconColor: "var(--green)",
                icon: <ViewIcon />,
            });
        }

        if (_isAllowedSaveAction() && !workflowAction) {
            actions.push({
                id: 'submit',
                label: t((!isWorkflow() || (isWorkflow() && isSaved() && updateAllowed())) ? 'estimationQuotation:save' : 'submit'),
                action: (!isWorkflow() || (isWorkflow() && isSaved() && updateAllowed())) ? () => _saveAction(true) : () => adjustWorkDialogRef.current.open(data?.workflowId, data?.stepId, data?.id),
                hotkey: SAVE_ACTION_SHORT_CUT,
                color: "var(--action-save)",
                icon: <SaveActionIcon />,
            });
        }
        if (isNotEmpty(data?.workflowId) && isSaved()) {
            actions.push({
                id: 'workflow-status',
                label: t('workflow_status'),
                action: () => workflowsActionLogDialogRef.current.open(data?.workflowId, data?.id, data?.stepId, data?.globalStatus),
                iconColor: "#8A3FFC",
                icon: <WorkflowIcon />,
            });
        }

        if (isListing && (isIncomplete() || isSubmitted() || isApproved()) && _isAllowedAction(VIEW_EDIT_ACTION)) {
            actions.push({
                id: 'edit',
                label: t('estimationQuotation:edit'),
                action: () => navigate(`/estimation-quotation?id=${data.id}`),
                color: "var(--green)",
                icon: <EditTableIcon />,
            });
        }

        if (isWorkflow() && workflowAction != null && Object.keys(workflowAction).length > 0) {
            actions.push({
                id: 'workflow',
                label: isEnglish() ? workflowAction?.actionDescLo : workflowAction?.actionDescFo,
                action: () => _executeWorkflowAction(),
                color: workflowAction?.statusBgColor,
                icon: <ActionIcon actionType={workflowAction.actionType} />,
            });
        }

        if (isListing && isWorkflow() && isNotEmpty(data.actionType) && data.actionType != STEP_TYPE_TRIGGERING) {
            actions.push({
                label: isEnglish() ? data?.actionDescLo : data?.actionDescFo,
                action: () => navigate(`/estimation-quotation?id=${data.id}`),
                icon: <ActionIcon actionType={data.actionType} />,
            });
        }

        if (isListing) {
            actions.reverse();
        }
        setActions(actions);
    }, [i18next.language, data, workflowAction]);

    useHotkeys(SAVE_ACTION_SHORT_CUT, () => {
        if (_isAllowedSaveAction()) {
            _saveAction(true);
        }
    }, { enableOnFormTags: true });

    return isListing ? <TableActionsButton defaultIndex={0} buttons={actions} /> : <ActionsButton actions={actions} />
}

export default forwardRef(Actions);