import { useRef } from "react";
import { useTranslation } from "react-i18next";
import FormTitle from "../../components/form-title";
import QuickSearch from "../../components/quick-search";
import FiltersIcon from "../../icons/Filters";
import { RSM3272_EVALUATION_CONTRACTS_FORM_ID } from "../../util/constants/forms";
import { getUser } from "../../util/cookies";
import ValuationContractTable from "./listing";
import ValuationContractsFilter from "./listing/Filter";

const ValuationContracts = () => {
    const { t } = useTranslation('valuationContracts');
    const searchTextRef = useRef();
    const tableRef = useRef();
    const filterRef = useRef();
    const user = getUser();

    return (
        <>
            <div className="headerPagesPanel">
                <FormTitle formId={RSM3272_EVALUATION_CONTRACTS_FORM_ID} />
                <div className="headerSearchPanel">
                    <QuickSearch
                        ref={searchTextRef}
                        placeholder={`${t('code')}, ${t('contract_code')}, ${t('customer_name')} , ${t('mobile_num')}, ${t('card_num')}`}
                        search={() => tableRef?.current?.refresh()}
                        formId={RSM3272_EVALUATION_CONTRACTS_FORM_ID}
                    />
                    <button className="headerPanelBtn" onClick={() => filterRef.current?.open()}>
                        <FiltersIcon />
                    </button>
                </div>

            </div>

            <ValuationContractTable
                user={user}
                tableRef={tableRef}
                filterRef={filterRef}
                searchTextRef={searchTextRef}
            />

            <ValuationContractsFilter ref={filterRef} tableRef={tableRef} />
        </>
    );
}

export default ValuationContracts;