import IPGDAdjustWorkDialog from "@ipgd-gauge/workflow/AdjustWorkDialog";
import { forwardRef } from "react";
import { executeWorkflowAction, getCurrentTime, getDomainValues, getWorkflowEscalateSteps, workflowStep } from "../../../../util/apis";

const AdjustWorkDialog = (props, ref) => {
    const { formId, getTransactionData, onExecuteAction, globalStatus, checkValidationAction, onError, getAdditionalData } = props;
    return (
        <IPGDAdjustWorkDialog
            formId={formId}
            ref={ref}
            getTransactionData={getTransactionData}
            onExecuteAction={onExecuteAction}
            globalStatus={globalStatus}
            checkValidationAction={checkValidationAction}
            onError={onError}
            executeWorkflowAction={executeWorkflowAction}
            getCurrentTime={getCurrentTime}
            getDomainValues={getDomainValues}
            getWorkflowEscalateSteps={getWorkflowEscalateSteps}
            workflowStep={workflowStep}
            getAdditionalData={getAdditionalData}
        />

    )
}

export default forwardRef(AdjustWorkDialog);