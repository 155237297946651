import ActionsButton from "@ipgd-gauge/actions-button";
import PrintDialog from "@ipgd-gauge/print-dialog";
import { Table, TABLE_MODE_ROWS } from "@ipgd-gauge/table";
import { confirmationDialog, handleError, isAllowedAction, isNotEmpty, showSuccessMessage } from "@ipgd-gauge/utils";
import i18next from "i18next";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import WorkflowDialog from "../workflow-dialog";
import AddBtnIcon from "./icons/AddBtn";
import CardIcon from "./icons/Card";
import DeleteTowIcon from "./icons/Delete2";
import EditTableIcon from "./icons/EditTable";
import ExcelIcon from "./icons/Excel";
import ListIcon from "./icons/List";
import PrinterIcon from "./icons/Printer";

const SListing = (props, ref) => {

    const NEW_ACTION = "1";
    const DELETE_ACTION = "3";
    const PRINT_ACTION = "6";
    const VIEW_EDIT_ACTION = "29";
    const EXPORT_ACTION = "52";

    const { id, columns, Row, rowHeight, cardInMobile, reportsTable, multiLevel, rowActions,
        getData, pagingData, tableStyle, noDataFoundLabel, showWating, showNoDataFound, rowNumber,
        withFilter, Total, postSelect, listingActions, deleteAction, viewAction, newAction, withPrintAction,
        exportExcelAction, user, formId, clearSelectedRecords, saveFormsFilter, updateUserTableProfiles,
        deleteUserTableProfiles, getUserTableProfiles, saveUserTableProfiles, updateSelectedUserTableProfiles, Waiting,
        getReportsForSelect, printReport, changeCurrentRecordId, isTransactionForm, changeSelectedRecords, currentId,
        hideDeleteCondition, labelCardWidth, printAction, onTableModeChange, hidePrintCondition, withWorkflow, isFormHasWorkflow, getWorkflowBasedOnFormId } = props;

    const { t } = useTranslation();
    const firstTimeRef = useRef(true);
    const dialogRef = useRef(null);
    const currentTableModeRef = useRef(null);
    const workflowDialogRef = useRef(null);

    const [actions, setActions] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedData, setSelectedData] = useState([]);

    useEffect(() => {
        if (isNotEmpty(formId)) {
            clearSelectedRecords();
        }
    }, [])

    useEffect(() => {
        _getActions();
    }, [listingActions, selectedIds.length, i18next.language])

    const _changeTableMode = () => {
        ref?.current?.changeTableMode();
    }

    const _onTableModeChange = (newMode) => {
        currentTableModeRef.current = newMode;
        _getActions();
        if (onTableModeChange) {
            onTableModeChange();
        }
    }

    const _newAction = () => {
        if (withWorkflow) {
            isFormHasWorkflow(formId)
                .then(response => {
                    if (response.data) {
                        workflowDialogRef?.current?.open();
                    } else {
                        newAction();
                    }
                })
                .catch(error => handleError(error, null, t));
        } else {
            newAction()
        }
    }

    const _getActions = () => {
        const actions = [];
        let hideDelete = false;
        let hidePrintAction = false;

        if (currentTableModeRef.current == null) {
            currentTableModeRef.current = ref?.current?.getTableMode()
        }

        if (window.innerWidth > 650) {
            if (currentTableModeRef.current === TABLE_MODE_ROWS) {
                actions.push({ id: `list-card-table-1-${id}`, icon: <CardIcon />, label: t('cards'), action: _changeTableMode, color: "var(--card-mode-table)" });
            } else {
                actions.push({ id: `list-card-table-2-${id}`, icon: <ListIcon />, label: t('list'), action: _changeTableMode, color: "var(--card-mode-table)" });
            }
        }

        if (hideDeleteCondition) {
            hideDelete = true;
            for (let i = 0; i < selectedData.length; i++) {
                if (!hideDeleteCondition(selectedData[i])) {
                    hideDelete = false;
                    break;
                }
            }
        }

        if (hidePrintCondition) {
            for (let i = 0; i < selectedData.length; i++) {
                if (hidePrintCondition(selectedData[i])) {
                    hidePrintAction = true;
                    break;
                }
            }
        }

        if (selectedIds.length > 1) {
            if (isAllowedAction(user, formId, DELETE_ACTION) && deleteAction && !hideDelete) {
                actions.push({ id: `form-action-${DELETE_ACTION}`, icon: <DeleteTowIcon />, label: t('delete'), action: _deleteAllAction, color: "var(--action-delete-bg)" });
            }
        }
        if ((isAllowedAction(user, formId, NEW_ACTION) && newAction)) {
            actions.push({ id: `form-action-${NEW_ACTION}`, icon: <AddBtnIcon />, label: t('new'), action: _newAction, color: "var(--action-copy)" });
        }
        if (isAllowedAction(user, formId, EXPORT_ACTION) && exportExcelAction) {
            actions.push({ id: `form-action-${EXPORT_ACTION}`, icon: <ExcelIcon />, label: t('export'), action: exportExcelAction });
        }
        if (listingActions?.length > 0) {
            listingActions.map((action) => {
                let actionId = action.id.replace("form-action-", "");
                if (isAllowedAction(user, formId, actionId)) {
                    actions.push(action);
                }
            })
        }
        if (selectedIds.length > 1) {
            if (isAllowedAction(user, formId, PRINT_ACTION) && withPrintAction && !hidePrintAction) {
                actions.push({ id: `form-action-${PRINT_ACTION}`, icon: <PrinterIcon />, label: t('print'), action: _printAction, color: "var(--pageination)" });
            }
            if (isAllowedAction(user, formId, VIEW_EDIT_ACTION) && viewAction) {
                actions.push({ id: `form-action-${VIEW_EDIT_ACTION}`, icon: <EditTableIcon />, label: t('view'), action: _viewAction, color: "var(--action-save)" });
            }
        }

        setActions(actions);
    }

    const _viewAction = () => {
        changeSelectedRecords([...selectedIds]);
        viewAction(currentId || selectedIds[0])
    }

    const _deleteAllAction = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            let apis = [];
            selectedIds?.map((selectedId) => {
                apis.push(deleteAction(selectedId));
            })
            return Promise.all(apis)
                .then(() => showSuccessMessage("delete_successfully", t))
                .catch((error) => handleError(error, "delete_fields", t))
                .finally(() => ref?.current?.refresh())
        });
    }

    const _printAction = () => {
        if (printAction) {
            printAction([...selectedIds]);
        } else {
            dialogRef?.current?.open([...selectedIds]);
        }
    }

    const _postSelect = (ids, data) => {
        if (firstTimeRef.current) {
            firstTimeRef.current = false;
        } else {
            setSelectedIds([...ids])
            setSelectedData([...data])
        }
        if (postSelect) {
            postSelect(ids)
        }
    }

    return (
        <>
            <Table
                ref={ref}
                id={id}
                columns={columns}
                Row={Row}
                rowHeight={rowHeight}
                cardInMobile={cardInMobile}
                reportsTable={reportsTable}
                multiLevel={multiLevel}
                rowActions={rowActions}
                getData={getData}
                pagingData={pagingData}
                tableStyle={tableStyle}
                noDataFoundLabel={noDataFoundLabel}
                showWating={showWating}
                showNoDataFound={showNoDataFound}
                rowNumber={rowNumber}
                withFilter={withFilter}
                Total={Total}
                withCheckbox={isNotEmpty(formId)}
                postSelect={_postSelect}
                saveFormsFilter={saveFormsFilter}
                user={user}
                formId={formId}
                updateUserTableProfiles={updateUserTableProfiles}
                deleteUserTableProfiles={deleteUserTableProfiles}
                getUserTableProfiles={getUserTableProfiles}
                saveUserTableProfiles={saveUserTableProfiles}
                updateSelectedUserTableProfiles={updateSelectedUserTableProfiles}
                Waiting={Waiting}
                labelCardWidth={labelCardWidth}
                onTableModeChange={_onTableModeChange}
            />
            {actions?.length > 0 &&
                <ActionsButton actions={actions} isListing={true} />
            }
            {withPrintAction && !printAction &&
                <PrintDialog
                    id={`SListing-${formId}`}
                    ref={dialogRef}
                    formId={formId}
                    getReportsForSelect={getReportsForSelect}
                    printReport={printReport}
                    userId={user.fndUserId}
                    isTransactionForm={isTransactionForm}
                    selectedIds={selectedIds}
                    cleareSelectedRecords={clearSelectedRecords}
                    changeCurrentRecordId={changeCurrentRecordId}
                />
            }

            {withWorkflow &&
                <WorkflowDialog
                    ref={workflowDialogRef}
                    formId={formId}
                    api={getWorkflowBasedOnFormId}
                    newAction={newAction}
                />
            }
        </>
    )
}

export default forwardRef(SListing);