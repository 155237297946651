import { FrmTimeDatePickr } from "@ipgd-gauge/date-picker";
import { FrmSelectMenu } from "@ipgd-gauge/select-menu";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AdvanceFilter from "../../../components/advance-filter";
import { getCommonBranches, getCommonCustomers, getCommonDivisionsList, getDomainValues } from "../../../util/apis";
import { DOMAIN_GLOBAL_STATUS, ZATCA_STATUS } from "../../../util/constants";
import { RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID } from "../../../util/constants/forms";
import FromToDecimalInput from "@ipgd-gauge/from-to-decimal-input";
import { DateFromToInput } from "@ipgd-gauge/date-picker";
import StatusSelectMenu from "../../../components/status-select-menu";
import WorkflowStatusFilter from "../../../components/workflow-status-filter";

const BRANCH_CONDITION = 'dependsOnPolicyId';
const CustomersEstimationInvoicesFilter = (props, ref) => {
    const { customersEstimationInvoicesTableRef } = props;

    const advanceFilterRef = useRef();
    const { t } = useTranslation();

    const [filterData, setFilterData] = useState({});

    const _handleDataChanged = (name, value) => {
        filterData[name] = value;
        setFilterData({ ...filterData });
    }

    const getFilterData = () => Object.assign(filterData, {});

    const open = () => advanceFilterRef.current?.open();

    useImperativeHandle(ref, () => ({ getFilterData, open }))

    const DIVISION_CONDITION = 'dependsOnPolicyIdOnly';
    return (
        <AdvanceFilter
            ref={advanceFilterRef}
            id="customers-estimation-invoices-filter"
            filterData={filterData}
            setFilterData={setFilterData}
            searchAction={() => customersEstimationInvoicesTableRef?.current?.refresh()}
            formId={RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID}
            numOfRows={4}
            hideMoreOptions
        >
            <div>
                <div className="frm-title-panel">
                    <label className="frm-title-form">{t('filters')}</label>
                </div>
                <div className="container">
                    <div className="row rowSmModel">
                        <div className="w50">
                            <label className='form-label'>{t('customersEstimationInvoices:customer')}</label>
                            <FrmSelectMenu
                                id="customer"
                                value={filterData?.customers}
                                onChange={(value) => _handleDataChanged("customers", value)}
                                api={(searchValue) => getCommonCustomers(null, null, searchValue, true, false, false, [], false)}
                                defualtValueApi={() => getCommonCustomers(filterData?.customers, null, null, true, false, false, [], false)}
                                defualtValueId={filterData?.customers}
                                descLo={['code', 'descLo']}
                                descFo={['code', 'descFo']}
                                isAsync={true}
                                isMulti={true}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('customersEstimationInvoices:branch')}</label>
                            <FrmSelectMenu
                                id="branch"
                                isMulti={true}
                                value={filterData.branches}
                                api={() => getCommonBranches(null, filterData.branches, null, BRANCH_CONDITION)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleDataChanged("branches", value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('customersEstimationInvoices:division')}</label>
                            <FrmSelectMenu
                                id="division"
                                portalTarget={document.body}
                                api={() => getCommonDivisionsList(null, DIVISION_CONDITION, false)}
                                isMulti={true}
                                value={filterData.divisions}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleDataChanged("divisions", value)}
                            />
                        </div>

                        {/* <div className="w50">
                            <label className='form-label'>{t('customersEstimationInvoices:invoice_date_from')}</label>
                            <FrmTimeDatePickr
                                id="invoice_date_from"
                                value={filterData.dateFrom}
                                onChange={(value) => _handleDataChanged("dateFrom", value)}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('customersEstimationInvoices:invoice_date_to')}</label>
                            <FrmTimeDatePickr
                                id="invoice_date_to"
                                value={filterData.dateTo}
                                onChange={(value) => _handleDataChanged("dateTo", value)}
                            />
                        </div> */}

                        <div className="w50">
                            <label className='form-label'>{t('customersEstimationInvoices:invoice_date_from_to')}</label>
                            <DateFromToInput
                                id="invoice-date-from-to"
                                fromValue={filterData.dateFrom}
                                toValue={filterData.dateTo}
                                fromOnChange={(value) => _handleDataChanged("dateFrom", value)}
                                toOnChange={(value) => _handleDataChanged("dateTo", value)}
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('customersEstimationInvoices:status')}</label>
                            <FrmSelectMenu
                                id="status"
                                isMulti={true}
                                value={filterData.statuses}
                                api={() => getDomainValues(DOMAIN_GLOBAL_STATUS)}
                                descLo={['descLo']}
                                descFo={['descFo']}
                                onChange={value => _handleDataChanged("statuses", value)}
                                portalTarget={document.body}
                            />
                        </div>
                        <div className="w50">
                            <WorkflowStatusFilter
                                formId={RSM3015_CUSTOMERS_ESTIMATION_INVOICES_FORM_ID}
                                value={filterData.workflowStatus}
                                onChange={(value) => _handleDataChanged("workflowStatus", value)}
                            />
                        </div>
                        <div className="w50">
                            <label className='form-label'>{t('customersEstimationInvoices:price_from_to')}</label>
                            <FromToDecimalInput
                                id="price-from-to"
                                fromValue={filterData.priceFrom || ''}
                                toValue={filterData.priceTo}
                                fromOnBlur={value => _handleDataChanged("priceFrom", value)}
                                toOnBlur={value => _handleDataChanged("priceTo", value)}
                                decimalScale={2}
                                allowDecimals={true}
                                nullable
                            />
                        </div>

                        <div className="w50">
                            <label className='form-label'>{t('customersEstimationInvoices:zatca-status')}</label>
                            <StatusSelectMenu
                                id={`zatca-status`}
                                value={filterData?.zatcaStatus}
                                onChange={(value) => _handleDataChanged("zatcaStatus", value)}
                                api={() => getDomainValues(ZATCA_STATUS)}
                                descLo={["descLo"]}
                                descFo={["descFo"]}
                                isMulti={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </AdvanceFilter>
    )
}

export default forwardRef(CustomersEstimationInvoicesFilter);