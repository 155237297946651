import ActionsButton from "@ipgd-gauge/actions-button";
import TableActionsButton from "@ipgd-gauge/table-actions-buttons";
import { confirmationDialog, handleError, hideActionWaiting, isAllowedAction, isEnglish, isNotEmpty, showActionWaiting, showSuccessMessage } from "@ipgd-gauge/utils";
import i18next from "i18next";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import AddBtnIcon from "../../icons/AddBtn";
import ApproveIcon from "../../icons/Approve";
import DeleteTowIcon from "../../icons/Delete2";
import EditTableIcon from "../../icons/EditTable";
import { PdfTrxIcon } from "../../icons/EstimationTrx";
import NewWordIcon from "../../icons/NewWord";
import RejectIcon from "../../icons/Reject";
import SaveActionIcon from "../../icons/SaveAction";
import UnpostIcon from "../../icons/Unpost";
import ViewIcon from "../../icons/View";
import WorkflowIcon from "../../icons/Workflow";
import { approveValuationContract, deleteValuationContracts, rejectValuationContract, unpostValuationContract } from "../../util/apis";
import { GLOBAL_STATUS_CONFIRMED, GLOBAL_STATUS_INCOMPLETE, GLOBAL_STATUS_PREPARED, GLOBAL_STATUS_REJECTED, GLOBAL_STATUS_SUBMITTED, STEP_TYPE_TRIGGERING } from '../../util/constants';
import { CONFIRM_FORM_ACTION, DELETE_ACTION, INCOMPLETE_ACTION, NEW_ACTION, PRINT_ACTION, REJECT_ACTION, SAVE_ACTION, UN_POST_ACTION, VIEW_EDIT_ACTION } from "../../util/constants/actions";
import { RSM3272_EVALUATION_CONTRACTS_FORM_ID } from "../../util/constants/forms";
import { SAVE_ACTION_SHORT_CUT } from "../../util/constants/hotkeys";
import { ActionIcon } from "../../icons/WorkflowIcons";

const Actions = (props, ref) => {
    const { user, isListing, data, tableRef, newAction, printDialogRef, saveAction, adjustWorkDialogRef, workflowsActionLogDialogRef } = props;
    const { t } = useTranslation();

    const navigate = useNavigate();

    const [actions, setActions] = useState([]);
    const [workflowAction, setWorkflowAction] = useState(null);

    const handleActionCompleted = () => {
        if (isListing) {
            tableRef?.current?.refresh();
        } else {
            navigate(`/valuation-contracts`);
        }
    }

    const _deleteAction = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            if (!isListing) {
                showActionWaiting('delete');
            }

            return deleteValuationContracts(data.id)
                .then(() => {
                    if (!isListing) {
                        hideActionWaiting('delete')
                    }
                    handleActionCompleted();
                    showSuccessMessage("delete_successfully", t);
                })
                .catch((error) => handleError(error, null, t))
        });
    }

    const _rejectAction = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return rejectValuationContract(data.id)
                .then(() => {
                    handleActionCompleted();
                    showSuccessMessage('action_performed_successfully', t);
                })
                .catch((error) => handleError(error, null, t));
        });
    }

    const _confirmAction = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return approveValuationContract(data.id)
                .then(() => {
                    handleActionCompleted();
                    showSuccessMessage('action_performed_successfully', t);
                })
                .catch((error) => handleError(error, null, t));
        });
    }

    const _unpostAction = () => {
        confirmationDialog(t('are_you_sure'), t('yes'), t('no'), true, () => {
            return unpostValuationContract(data.id)
                .then(() => {
                    handleActionCompleted();
                    showSuccessMessage('action_performed_successfully', t);
                })
                .catch((error) => handleError(error, null, t));
        });
    }

    const isSaved = () => isNotEmpty(data?.id);
    const isIncomplete = () => data.globalStatus == GLOBAL_STATUS_INCOMPLETE;
    const isPrepared = () => data.globalStatus == GLOBAL_STATUS_PREPARED;
    const isSubmitted = () => data.globalStatus == GLOBAL_STATUS_SUBMITTED;
    const isRejected = () => data.globalStatus == GLOBAL_STATUS_REJECTED;
    const isConfirmed = () => data.globalStatus == GLOBAL_STATUS_CONFIRMED;
    const _isAllowedAction = (action) => isAllowedAction(user, RSM3272_EVALUATION_CONTRACTS_FORM_ID, action);
    const _isAllowedSaveAction = () => !isListing && (isIncomplete() || isPrepared() || isSubmitted()) && _isAllowedAction(SAVE_ACTION);
    const isWorkflow = () => isNotEmpty(data?.workflowId) && (isListing ? isNotEmpty(data?.stepId) : true);
    const updateAllowed = () => workflowAction?.updateAllowed == 1;

    const _executeWorkflowAction = () => {
        if (isSaved() && isNotEmpty(workflowAction.actionId) && isNotEmpty(workflowAction.actionType)) {
            adjustWorkDialogRef.current.open(data?.workflowId, data?.stepId, data?.id);
        }
    }

    useImperativeHandle(ref, () => ({ setWorkflowAction }));

    useEffect(() => {
        const tempActions = [];

        if (isListing && isWorkflow() && isNotEmpty(data.actionType) && data.actionType != STEP_TYPE_TRIGGERING) {
            actions.push({
                label: isEnglish() ? data?.actionDescLo : data?.actionDescFo,
                action: () => navigate(`/valuation-contract?id=${data.id}`),
                icon: <ActionIcon actionType={data.actionType} />,
            });
        }

        if (!isWorkflow() && isListing && (isSubmitted() || isConfirmed()) && _isAllowedAction(UN_POST_ACTION)) {
            tempActions.push({
                id: 'unpost',
                label: t('valuationContracts:unpost'),
                action: _unpostAction,
                color: "var(--action-save)",
                icon: <UnpostIcon />,
            });
        }

        if (isSaved() && _isAllowedAction(PRINT_ACTION)) {
            tempActions.push({
                id: 'print-word',
                label: t('print_word'),
                action: () => printDialogRef?.current?.open([data?.id], null, true),
                color: "var(--pageination)",
                icon: <NewWordIcon />
            });

            tempActions.push({
                id: 'print-pdf',
                label: t('print_pdf'),
                action: () => printDialogRef?.current?.open([data?.id]),
                color: "var(--pageination)",
                icon: <PdfTrxIcon />
            });
        }

        if (isIncomplete() && isSaved() && _isAllowedAction(DELETE_ACTION)) {
            tempActions.push({
                id: 'delete',
                label: t('valuationContracts:delete'),
                color: "var(--action-delete-bg)",
                iconColor: "var(--action-delete-bg)",
                action: _deleteAction,
                icon: <DeleteTowIcon />,
            });
        }

        if (!isWorkflow() && isSubmitted() && _isAllowedAction(REJECT_ACTION)) {
            tempActions.push({
                id: 'reject',
                label: t('valuationContracts:reject'),
                action: () => _rejectAction(),
                color: "var(--action-delete-bg)",
                icon: <RejectIcon />,
            });
        }

        if (!isWorkflow() && isSubmitted() && _isAllowedAction(CONFIRM_FORM_ACTION)) {
            tempActions.push({
                id: 'confirm',
                label: t('valuationContracts:confirm'),
                action: _confirmAction,
                color: "var(--action-save)",
                icon: <ApproveIcon />,
            });
        }

        if (isListing && _isAllowedAction(VIEW_EDIT_ACTION)) {
            if (isRejected() || isConfirmed()) {
                tempActions.push({
                    id: 'view',
                    label: t('valuationContracts:view'),
                    action: () => navigate(`/valuation-contract?id=${data.id}`),
                    color: "var(--action-save)",
                    iconColor: "var(--green)",
                    icon: <ViewIcon />,
                });
            } else {
                tempActions.push({
                    id: 'edit',
                    label: t('valuationContracts:edit'),
                    action: () => navigate(`/valuation-contract?id=${data.id}`),
                    color: "var(--green)",
                    icon: <EditTableIcon />,
                });
            }
        }

        if (!isListing && _isAllowedAction(NEW_ACTION)) {
            tempActions.push({
                id: 'new',
                label: t('valuationContracts:new'),
                action: () => newAction(),
                color: "var(--action-copy)",
                icon: <AddBtnIcon />,
            });
        }

        if (!isListing && isIncomplete() && _isAllowedAction(INCOMPLETE_ACTION)) {
            tempActions.push({
                id: 'incomplete',
                label: t('valuationContracts:incomplete'),
                action: () => saveAction(false),
                color: "var(--action-incomplete)",
                icon: <SaveActionIcon />,
            });
        }

        if (_isAllowedSaveAction() && !workflowAction) {
            const isSavedAction = !isWorkflow() || (isWorkflow() && isSaved() && updateAllowed());
            tempActions.push({
                id: 'submit',
                label: t(isSavedAction ? 'valuationContracts:save' : 'submit'),
                action: isSavedAction ? () => saveAction(true) : () => adjustWorkDialogRef.current.open(data?.workflowId, data?.stepId, data?.id),
                color: "var(--action-save)",
                hotkey: SAVE_ACTION_SHORT_CUT,
                icon: <SaveActionIcon />,
            });
        }

        if (isNotEmpty(data?.workflowId) && isSaved()) {
            tempActions.push({
                id: 'workflow-status',
                label: t('workflow_status'),
                action: () => workflowsActionLogDialogRef.current.open(data?.workflowId, data?.id, data?.stepId, data?.globalStatus),
                iconColor: "#8A3FFC",
                icon: <WorkflowIcon />,
            });
        }


        if (isWorkflow() && workflowAction != null && Object.keys(workflowAction).length > 0) {
            tempActions.push({
                id: 'workflow',
                label: isEnglish() ? workflowAction?.actionDescLo : workflowAction?.actionDescFo,
                action: () => _executeWorkflowAction(),
                color: workflowAction?.statusBgColor,
                icon: <ActionIcon actionType={workflowAction.actionType} />,
            });
        }

        setActions(tempActions);

        if (isListing) {
            tempActions.reverse();
        }
    }, [i18next.language, data, workflowAction]);

    useHotkeys(SAVE_ACTION_SHORT_CUT, () => {
        if (_isAllowedSaveAction()) {
            saveAction(true);
        }
    }, { enableOnFormTags: true });

    if (isListing) {
        return <TableActionsButton defaultIndex={0} buttons={actions} />
    }

    return <ActionsButton actions={actions} />
}

export default forwardRef(Actions);